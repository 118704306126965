import "./RefreshStyles.css"
import XButton from "./assets/XButton"
import { useEffect, Dispatch, SetStateAction, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CarLanguageContext } from "../../quotes/car/CarContext";
import { LanguageContext } from "../../quotes/shared/HomeContext";
import { CarStepContext } from "../../quotes/car/CarContext";
import { HomeStepContext } from "../../quotes/shared/HomeContext";

type RestartModalProps = {
    handleRestartModalClose: () => void;
    setRestartModal: Dispatch<SetStateAction<boolean>>;
  }

  const RestartModal: React.FC<RestartModalProps> = (props: RestartModalProps) => {
    const [restart, setRestart] = useState(false)
    const navigate = useNavigate();
    const modalRef = useRef<HTMLDivElement>(null);
    const { carLanguage } = useContext(CarLanguageContext);
    const { language } = useContext(LanguageContext)
    const { setCarFormSteps } = useContext(CarStepContext)
    const { setFormSteps } = useContext(HomeStepContext)

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === "Escape") {
            props.handleRestartModalClose();
          }
        };
    
        document.addEventListener("keydown", handleKeyDown);
    
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, [props.handleRestartModalClose]);

      useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            props.handleRestartModalClose();
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [props.handleRestartModalClose]);

      useEffect(() => {
        if (restart) {
          localStorage.clear();
          props.setRestartModal(false)
          
          if (window.location.href.includes("car")) {
            setCarFormSteps(1)
            navigate("/car");
          } else if (window.location.href.includes("home")) {
            setFormSteps(0)
            navigate("/home");
          } else {
            navigate("/");
          }
        }
      }, [restart])
      

  return (
    <div className="modal-container">
        <div ref={modalRef} className="modal-restart-wrapper">
          {language ? (
        <>
        <p className="modal-restart-heading">{language === "en" ? "Are you sure you want to restart?" : "Êtes-vous sûr de vouloir redémarrer?"}</p>
        <div className="modal-restart-buttons-box">
        <button className="modal-restart-button" tabIndex={0} onClick={() => setRestart(true)}>
        {language === "en" ? "Yes" : "Oui"}
        </button>
        <button className="modal-restart-button" tabIndex={0} onClick={props.handleRestartModalClose}>
        {language === "en" ? "No" : "Non"}
        </button>
        </div>
        </>
        )
        : (
        <>
        <p className="modal-restart-heading">{carLanguage === "en" ? "Are you sure you want to restart?" : "Êtes-vous sûr de vouloir redémarrer?"}</p>
        <div className="modal-restart-buttons-box">
        <button className="modal-restart-button" tabIndex={0} onClick={() => setRestart(true)}>
        {carLanguage === "en" ? "Yes" : "Oui"}
        </button>
        <button className="modal-restart-button" tabIndex={0} onClick={props.handleRestartModalClose}>
        {carLanguage === "en" ? "No" : "Non"}
        </button>
        </div>
        </>
      )
      }
        <button aria-label="Close" className="modal-close-button restart" onClick={props.handleRestartModalClose}><XButton /></button>
        </div>
    </div>
  )
}

export default RestartModal