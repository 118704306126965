const DarkSun = () => {
    return (
      <><svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.28583 8.87031C8.31867 8.87031 9.15596 8.03303 9.15596 7.00018C9.15596 5.96734 8.31867 5.13005 7.28583 5.13005C6.25298 5.13005 5.4157 5.96734 5.4157 7.00018C5.4157 8.03303 6.25298 8.87031 7.28583 8.87031ZM7.28583 10.1171C9.00724 10.1171 10.4027 8.72159 10.4027 7.00018C10.4027 5.27878 9.00724 3.8833 7.28583 3.8833C5.56442 3.8833 4.16895 5.27878 4.16895 7.00018C4.16895 8.72159 5.56442 10.1171 7.28583 10.1171Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.90935 0.143212L7.90935 2.63672L6.6626 2.63672L6.6626 0.143212L7.90935 0.143212Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.90935 11.3639L7.90935 13.8574L6.6626 13.8574L6.6626 11.3639L7.90935 11.3639Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1434 7.62371L11.6499 7.62371L11.6499 6.37695L14.1434 6.37695L14.1434 7.62371Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.92222 7.62371L0.428711 7.62371L0.428711 6.37695L2.92222 6.37695L2.92222 7.62371Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6936 12.2898L9.93042 10.5266L10.812 9.64502L12.5752 11.4082L11.6936 12.2898Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75952 4.3557L1.99634 2.59253L2.87793 1.71094L4.64111 3.47411L3.75952 4.3557Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99635 11.408L3.75953 9.64478L4.64111 10.5264L2.87794 12.2895L1.99635 11.408Z" fill="#0D172E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.93092 3.47387L11.6941 1.7107L12.5757 2.59229L10.8125 4.35546L9.93092 3.47387Z" fill="#0D172E"/>
      </svg>
      </>
    )
  }
  
  export default DarkSun