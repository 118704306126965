import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/Footer";
import Question from "../../../components/refresh/Question";
import SubQuestions from "../../../components/questions/SubQuestions";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../HomeContext";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";

function StepTen() {
  const { language } = useContext(LanguageContext);
  const [accept, setAccept] = useState<boolean | null>(null);
  const [valid, setValid] = useState<boolean>(true);
  const [data, setData] = useState<any>({
    insuranceGap: "",
    howLongInsuredCurrentCompany: "",
    // claimsFiveYears: "",
    // nonPaymentCancellations: "",
  });
  let LSActive = useLocalStorage("activePolicy");
  const LSInsuranceGap = useLocalStorage("insuranceGap");
  const LSCurrentCompany = useLocalStorage("howLongInsuredCurrentCompany");
  const LSClaims = useLocalStorage("claimsFiveYears");
  const LSNonPayment = useLocalStorage("nonPaymentCancellations");

  useEffect(() => {
    if (LSActive) {
      if (LSActive === "true") {
        setAccept(true);
        setData({
          ...data,
          insuranceGap: LSInsuranceGap,
          howLongInsuredCurrentCompany: LSCurrentCompany,
          claimsFiveYears: LSClaims,
          nonPaymentCancellations: LSNonPayment,
        });
      } else {
        setAccept(false);
      }
    }
  }, [LSActive, LSClaims, LSCurrentCompany, LSInsuranceGap, LSNonPayment]);

  useEffect(() => {
    if (accept === null) {
      setValid(false);
    }
    if (accept === false) {
      setValid(true);
    } else if (accept === true) {
      setValid(true);
      for (let key in data) {
        if (data[key] === "") {
          setValid(false);
        }
      }
    }
  }, [data, valid, accept]);

  useEffect(() => {
    //insuranceGap
    if (data.insuranceGap === answers["fr"].oneYear) {
      setData({...data, insuranceGap: answers["en"].oneYear})
    }
    if (data.insuranceGap === answers["fr"].twoYears) {
      setData({...data, insuranceGap: answers["en"].twoYears})
    }
    if (data.insuranceGap === answers["fr"].threeYears) {
      setData({...data, insuranceGap: answers["en"].threeYears})
    }
    if (data.insuranceGap === answers["fr"].fourYears) {
      setData({...data, insuranceGap: answers["en"].fourYears})
    }
    if (data.insuranceGap === answers["fr"].fiveYears) {
      setData({...data, insuranceGap: answers["en"].fiveYears})
    }
        //howLongInsuredCurrentCompany
        if (data.howLongInsuredCurrentCompany === answers["fr"].oneYear) {
          setData({...data, howLongInsuredCurrentCompany: answers["en"].oneYear})
        }
        if (data.howLongInsuredCurrentCompany === answers["fr"].twoYears) {
          setData({...data, howLongInsuredCurrentCompany: answers["en"].twoYears})
        }
        if (data.howLongInsuredCurrentCompany === answers["fr"].threeYears) {
          setData({...data, howLongInsuredCurrentCompany: answers["en"].threeYears})
        }
        if (data.howLongInsuredCurrentCompany === answers["fr"].fourYears) {
          setData({...data, howLongInsuredCurrentCompany: answers["en"].fourYears})
        }
        if (data.howLongInsuredCurrentCompany === answers["fr"].fiveYears) {
          setData({...data, howLongInsuredCurrentCompany: answers["en"].fiveYears})
        }
    //claimsFiveYears
    if (data.claimsFiveYears === answers["fr"].noClaims) {
      setData({...data, claimsFiveYears: answers["en"].noClaims})
    }
    if (data.claimsFiveYears === answers["fr"].oneClaim) {
      setData({...data, claimsFiveYears: answers["en"].oneClaim})
    }
    if (data.claimsFiveYears === answers["fr"].twoClaims) {
      setData({...data, claimsFiveYears: answers["en"].twoClaims})
    }
    if (data.claimsFiveYears === answers["fr"].threePlusClaims) {
      setData({...data, claimsFiveYears: answers["en"].threePlusClaims})
    }
        //nonPaymentCancellations
        if (data.nonPaymentCancellations === answers["fr"].noCancellations) {
          setData({...data, nonPaymentCancellations: answers["en"].noCancellations})
        }
        if (data.nonPaymentCancellations === answers["fr"].oneCancellation) {
          setData({...data, nonPaymentCancellations: answers["en"].oneCancellation})
        }
        if (data.nonPaymentCancellations === answers["fr"].twoCancellations) {
          setData({...data, nonPaymentCancellations: answers["en"].twoCancellations})
        }
        if (data.nonPaymentCancellations === answers["fr"].threePlusCancellations) {
          setData({...data, nonPaymentCancellations: answers["en"].threePlusCancellations})
        }

  }, [data])

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={texts[language].activePolicy} />
      <RadioButtonGroup setAccept={setAccept} accept={accept} labelLeft={texts[language].yes} labelRight={texts[language].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>
      {accept ? (
        <div className="t-content-container" style={{ marginLeft: '0%' }}>
          <SubQuestions
            question={texts[language].insuranceGap}
            type="dropdown"
            dropdownType="insuranceGap"
            placeholder={texts[language].select}
            options={[
              answers[language].oneYear,
              answers[language].twoYears,
              answers[language].threeYears,
              answers[language].fourYears,
              answers[language].fiveYears,
            ]}
            onChange={(value) => {
              setData({ ...data, insuranceGap: value });
            }}
            inputSize="large"
            nonCenter={true}
            lsValue={'insuranceGap'}
          />
          <SubQuestions
            question={texts[language].howLongInsuredCurrentCompany}
            type="dropdown"
            dropdownType="insuranceLong"
            placeholder={texts[language].select}
            options={[
              answers[language].oneYear,
              answers[language].twoYears,
              answers[language].threeYears,
              answers[language].fourYears,
              answers[language].fiveYears,
            ]}
            onChange={(value) => {
              setData({ ...data, howLongInsuredCurrentCompany: value });
            }}
            inputSize="large"
            nonCenter={true}
            lsValue={'howLongInsuredCurrentCompany'}
          />
          {/* <SubQuestions
            question={texts[language].claimsFiveYears}
            type="dropdown"
            dropdownType="claimHome"
            placeholder={texts[language].select}
            options={[
              answers[language].noClaims,
              answers[language].oneClaim,
              answers[language].twoClaims,
              answers[language].threePlusClaims,
            ]}
            onChange={(value) => {
              setData({ ...data, claimsFiveYears: value });
            }}
            inputSize="large"
            nonCenter={true}
            lsValue={'claimsFiveYears'}
          />
          <SubQuestions
            question={texts[language].nonPaymentCancellations}
            type="dropdown"
            dropdownType={'cancellations'}
            placeholder={texts[language].select}
            options={[
              answers[language].noCancellations,
              answers[language].oneCancellation,
              answers[language].twoCancellations,
              answers[language].threePlusCancellations,
            ]}
            onChange={(value) => {
              setData({ ...data, nonPaymentCancellations: value });
            }}
            inputSize="large"
            nonCenter={true}
            lsValue={'nonPaymentCancellations'}
          /> */}
        </div>
      ) : null}
      <Footer
        type="submit"
        data={{
          activePolicy: accept,
          insuranceGap: data.insuranceGap,
          howLongInsuredCurrentCompany: data.howLongInsuredCurrentCompany,
          claimsFiveYears: "No claims",
          nonPaymentCancellations: "No cancellations",
        }}
        formSteps={11}
        backFormSteps={9}
        disabled={!valid}
      />
    </div>
    </div>
  );
}

export default StepTen;
