import "../refresh/RefreshStyles.css"
import XButton from "./assets/XButton"
import { useRef, useEffect } from "react"

type DescriptionModalProps = {
    description: string;
    title: string;
    handleModalClose: () => void;
  }
  

const DescriptionModal = (props: DescriptionModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            props.handleModalClose();
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [props.handleModalClose, props]);

  return (
    <div className="modal-container">
        <div ref={modalRef} className="modal-restart-wrapper desc">
                <p className="description">
                <span className="description-title">{props.title}</span>
                {props.description}
                </p>
                <button aria-label="Close" className="modal-close-button restart" onClick={props.handleModalClose}><XButton /></button>
        </div>
    </div>    
  )
}

export default DescriptionModal