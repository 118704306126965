const Help = () => {
    return (
      <>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#B0B0AD"/>
          <path d="M6.92285 6.59353V5.32831C7.49506 5.00657 8.18611 4.8457 8.996 4.8457C9.8323 4.8457 10.5013 5.05875 11.0031 5.48483C11.5137 5.90222 11.769 6.48483 11.769 7.23266C11.769 7.97179 11.5137 8.5457 11.0031 8.9544C10.4925 9.36309 9.92474 9.58918 9.29971 9.63266L9.28651 10.9501H8.05846L8.04526 8.85005C9.63863 8.85005 10.4353 8.34136 10.4353 7.32396C10.4353 6.91527 10.3033 6.58918 10.0392 6.3457C9.77508 6.09353 9.38774 5.96744 8.87716 5.96744C8.22572 5.96744 7.57429 6.17614 6.92285 6.59353ZM8.00564 13.5979C7.83838 13.424 7.75475 13.2109 7.75475 12.9587C7.75475 12.7066 7.84278 12.4935 8.01885 12.3196C8.19491 12.1457 8.40619 12.0587 8.65268 12.0587C8.90797 12.0587 9.12365 12.1457 9.29971 12.3196C9.47578 12.4935 9.56381 12.7066 9.56381 12.9587C9.56381 13.2109 9.47578 13.424 9.29971 13.5979C9.12365 13.7631 8.90797 13.8457 8.65268 13.8457C8.39739 13.8457 8.18171 13.7631 8.00564 13.5979Z" fill="white"/>
        </svg>
      </>
    )
  }
  
  export default Help