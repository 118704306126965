import { useContext, useState, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import Question from "../../../components/refresh/Question";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";

function CarStepTwentyFive() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [mobileTelematics, setMobileTelematics] = useState<boolean | null>(
    true
  );
  const LSTelematics = useLocalStorage("mobileTelematics");

  useEffect(() => {
    if (LSTelematics) {
      setMobileTelematics(LSTelematics === "true");
    }
  }, [LSTelematics]);

  useEffect(() => {
    setMobileTelematics(null)
  }, [])

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].mobileTelematics}
        subQuestion={carTexts[carLanguage].saveTenPercent}
      />
      <RadioButtonGroup setAccept={setMobileTelematics} accept={mobileTelematics} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>

      <CarFooter
        data={{
          mobileTelematics: mobileTelematics,
        }}
        formSteps={26}
        backFormSteps={24}
        type="submit"
        disabled={mobileTelematics === null}
      />
    </div>
    </div>
  );
}

export default CarStepTwentyFive;
