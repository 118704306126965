import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/Footer";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
// import HomeCircle from "../../../components/question-icons/HomeCircle";
// import Question from "../../../components/questions/Question";
import Question from "../../../components/refresh/Question";
import SubQuestions from "../../../components/questions/SubQuestions";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../../shared/HomeContext";

function TenantStepFour(props: { yearBuilt: number | null | undefined }) {
  const { language } = useContext(LanguageContext);
  const [isValid, setIsValid] = useState(true);
  const [data, setData] = useState<any>({
    year: 0,
    buildingStyle: "High Rise Apartment",
    distanceToFirehall: "8km",
    distanceToFireHydrant: "Within 300m",
    primaryHeatingType: "Electric",
  });

  useEffect(() => {
    if (props) {
      setData({ ...data, year: props.yearBuilt });
    }
  }, []);

  const LSYear = useLocalStorage("year");
  const LSFirehall = useLocalStorage("distanceToFirehall");
  const LSFireHydrant = useLocalStorage("distanceToFireHydrant");
  const LSPrimHeating = useLocalStorage("primaryHeatingType");
  const LSBuilding = useLocalStorage("buildingStyle");

  useEffect(() => {
    if (LSYear) {
      setData({
        ...data,
        year: LSYear,
        distanceToFirehall: LSFirehall,
        distanceToFireHydrant: LSFireHydrant,
        primaryHeatingType: LSPrimHeating,
        buildingStyle: LSBuilding,
      });
    }
  }, [LSBuilding, LSFireHydrant, LSFirehall, LSPrimHeating, LSYear, data]);

  useEffect(() => {
    setIsValid(true);
    for (let key in data) {
      if (
        data[key] === "0" ||
        data[key] === 0 ||
        data[key] < 0 ||
        data[key] === "" ||
        data[key] === " " ||
        data[key] === ""
      ) {
        setIsValid(false);
      }
    }

    const currentYear = new Date().getFullYear();

    if (data.year < 1800 || data.year > currentYear) {
      setIsValid(false);
    }
  }, [data, isValid]);

  useEffect(() => {
    //buildingStyle
    if (data.buildingStyle === answers["fr"].townHouse) {
      setData({...data, buildingStyle: answers["en"].townHouse})
    }
    if (data.buildingStyle === answers["fr"].rowHouse) {
      setData({...data, buildingStyle: answers["en"].rowHouse})
    }
    if (data.buildingStyle === answers["fr"].triplex) {
      setData({...data, buildingStyle: answers["en"].triplex})
    }
    if (data.buildingStyle === answers["fr"].highRiseApartment) {
      setData({...data, buildingStyle: answers["en"].highRiseApartment})
    }
    if (data.buildingStyle === answers["fr"].lowRiseApartment) {
      setData({...data, buildingStyle: answers["en"].lowRiseApartment})
    }
    if (data.buildingStyle === answers["fr"].commercial) {
      setData({...data, buildingStyle: answers["en"].commercial})
    }
    //distanceToFireHall
    if (data.distanceToFirehall === answers["fr"].fiveKm) {
      setData({...data, distanceToFirehall: answers["en"].fiveKm})
    }
    if (data.distanceToFirehall === answers["fr"].eightKm) {
      setData({...data, distanceToFirehall: answers["en"].eightKm})
    }
    if (data.distanceToFirehall === answers["fr"].overEightKm) {
      setData({...data, distanceToFirehall: answers["en"].overEightKm})
    }
    //distanceToFireHydrant
    if (data.distanceToFireHydrant === answers["fr"].withinThreeHunderdM) {
      setData({...data, distanceToFireHydrant: answers["en"].withinThreeHunderdM})
    }
    if (data.distanceToFireHydrant === answers["fr"].overThreeHunderedM) {
      setData({...data, distanceToFireHydrant: answers["en"].overThreeHunderedM})
    }
    //primaryHeatingType
    if (data.primaryHeatingType === answers["fr"].centralFurnaceGas) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnaceGas})
    }
    if (data.primaryHeatingType === answers["fr"].centralFurnaceOil) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnaceOil})
    }
    if (data.primaryHeatingType === answers["fr"].centralFurnacePropane) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnacePropane})
    }
    if (data.primaryHeatingType === answers["fr"].centralFurnaceWood) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnaceWood})
    }
    if (data.primaryHeatingType === answers["fr"].electric) {
      setData({...data, primaryHeatingType: answers["en"].electric})
    }
    if (data.primaryHeatingType === answers["fr"].hotWaterSteam) {
      setData({...data, primaryHeatingType: answers["en"].hotWaterSteam})
    }
    if (data.primaryHeatingType === answers["fr"].combinationFurnace) {
      setData({...data, primaryHeatingType: answers["en"].combinationFurnace})
    }
    if (data.primaryHeatingType === answers["fr"].spaceHeaterGas) {
      setData({...data, primaryHeatingType: answers["en"].spaceHeaterGas})
    }
    if (data.primaryHeatingType === answers["fr"].spaceHeaterOil) {
      setData({...data, primaryHeatingType: answers["en"].spaceHeaterOil})
    }
    if (data.primaryHeatingType === answers["fr"].spaceHeaterElectric) {
      setData({...data, primaryHeatingType: answers["en"].spaceHeaterElectric})
    }
    if (data.primaryHeatingType === answers["fr"].woodBurningStove) {
      setData({...data, primaryHeatingType: answers["en"].woodBurningStove})
    }
    if (data.primaryHeatingType === answers["fr"].fireplace) {
      setData({...data, primaryHeatingType: answers["en"].fireplace})
    }
    if (data.primaryHeatingType === answers["fr"].solar) {
      setData({...data, primaryHeatingType: answers["en"].solar})
    }
    if (data.primaryHeatingType === answers["fr"].other) {
      setData({...data, primaryHeatingType: answers["en"].other})
    }
    if (data.primaryHeatingType === answers["fr"].none) {
      setData({...data, primaryHeatingType: answers["en"].none})
    }
  }, [data])

  return (
    <div className="t-container">
      <div className="t-content-container">
      {/* <HomeCircle /> */}
      {/* <div> */}
        <Question questionHtml={texts[language].prefilledInfo} />
      {/* </div> */}
      {/* <div className="long-div"> */}
        <SubQuestions
          question={texts[language].yearBuilt}
          type="number"
          placeholder={texts[language].year}
          options={[]}
          onChange={(value) => {
            setData({ ...data, year: value });
          }}
          inputSize="small"
          lsValue={data.year}
          error={data.year < 1800 ? true : false}
          initialValue={props.yearBuilt ? props.yearBuilt : '1950'}
          maxLength={4}
        />
        <SubQuestions
          question={texts[language].buildingStyle}
          type="dropdown"
          dropdownType={'buildingApt'}
          nonCenter={true}
          placeholder={answers[language].highRiseApartment}
          options={[
            answers[language].detached,
            answers[language].townHouse,
            answers[language].rowHouse,
            answers[language].triplex,
            answers[language].highRiseApartment,
            answers[language].lowRiseApartment,
            answers[language].commercial,
          ]}
          onChange={(value) => {
            setData({ ...data, buildingStyle: value });
          }}
          inputSize="small"
          lsValue={'buildingStyle'}
          error={data.buildingStyle === "" ? true : false}
          initialValue={answers[language].highRiseApartment}
        />
        <SubQuestions
          question={texts[language].distanceToFirehall}
          type="dropdown"
          dropdownType={'firehall'}
          nonCenter={true}
          placeholder={answers[language].eightKM}
          options={[
            answers[language].fiveKm,
            answers[language].eightKM,
            answers[language].overEightKm,
          ]}
          onChange={(value) => {
            setData({ ...data, distanceToFirehall: value });
          }}
          inputSize="small"
          lsValue={'distanceToFirehall'}
          initialValue={answers[language].eightKM}
        />
        <SubQuestions
          question={texts[language].distanceToFireHydrant}
          type="dropdown"
          dropdownType={'fireHydrant'}
          nonCenter={true}
          placeholder={answers[language].withinThreeHunderdM}
          options={[
            answers[language].withinThreeHunderdM,
            answers[language].overThreeHunderedM,
          ]}
          onChange={(value) => {
            setData({ ...data, distanceToFireHydrant: value });
          }}
          inputSize="small"
          lsValue={'distanceToFireHydrant'}
          initialValue={answers[language].withinThreeHunderdM}
        />
        <SubQuestions
          question={texts[language].primaryHeatingType}
          type="dropdown"
          dropdownType={'heating'}
          nonCenter={true}
          placeholder={answers[language].electric}
          options={[
            answers[language].centralFurnaceGas,
            answers[language].centralFurnaceOil,
            answers[language].centralFurnacePropane,
            answers[language].centralFurnaceWood,
            answers[language].electric,
            answers[language].hotWaterSteam,
            answers[language].combinationFurnace,
            answers[language].spaceHeaterGas,
            answers[language].spaceHeaterOil,
            answers[language].spaceHeaterElectric,
            answers[language].woodBurningStove,
            answers[language].fireplace,
            answers[language].solar,
            answers[language].other,
            answers[language].none,
          ]}
          onChange={(value) => {
            setData({ ...data, primaryHeatingType: value });
          }}
          inputSize="small"
          lsValue={'primaryHeatingType'}
          initialValue={answers[language].electric}
        />
      {/* </div> */}
      <Footer
        type="submit"
        data={{
          year: data.year,
          buildingStyle: data.buildingStyle,
          distanceToFirehall: data.distanceToFirehall,
          distanceToFireHydrant: data.distanceToFireHydrant,
          primaryHeatingType: data.primaryHeatingType,
        }}
        formSteps={5}
        backFormSteps={3}
        disabled={!isValid}
      />
    </div>
    </div>
  );
}

export default TenantStepFour;
