const SelectArrow = () => {
    return (
      <>
      <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 4L6 0L0 0L3 4Z" fill="#414141"/>
      </svg>
      </>
    )
  }
  
  export default SelectArrow