import { useState, useEffect } from "react";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 600) {
      setIsMobile(true);
    }
  }, []);

  return isMobile;
}

export default useIsMobile;
