import { createContext, useState, ReactChild } from "react";

export const CarContext = createContext({} as any);
export const CarStepContext = createContext({} as any);
export const CarLanguageContext = createContext({} as any);

type contextChildren = {
  children: ReactChild;
};

export const CarProvider = ({ children }: contextChildren) => {
  const [carFormData, setCarFormData] = useState<any>({
    year: "",
    make: "",
    model: "",
    EXT_VICC: "",
    VICC_CODE: "",
    carPurchaseYear: "",
    primaryCarCondition: "",
    primaryCarHowManyKM: 0,
    purchaseType: "",
    primaryCarPurchasePrice: 0,
    tagTracking: "",
    sherlock: "",
    noSecurity: "",
    primaryUseOfCar: "",
    annualKM: 0,
    commuteLength: 0,
    outsideQuebec: false,
    numOfWeeksOutsideQuebec: 0,
    collision: "",
    comprehensive: "",
    extraVehicleList: [],
    firstName: "",
    lastName: "",
    dob: "",
    howOldDriversLicense: 0,
    gender: "",
    maritalStatus: "",
    placeObject: [],
    unitNumber: "",
    addressNumber: "",
    address: "",
    postalCode: "",
    city: "",
    province: "",
    dateMovedIn: "",
    job: "",
    currentPolicy: "",
    claims: [],
    violations: "",
    convictions: [],
    secondaryFirstName: "",
    secondaryLastName: "",
    secondaryBirthday: "",
    secondaryGender: "",
    secondaryMaritalStatus: "",
    secondaryJob: "",
    secondaryHowOldDriversLicense: "",
    secondaryAccidents: "",
    secondaryDrivingConvictions: "",
    policyStartDate: "",
    bundleHome: "",
    mobileTelematics: "",
    phoneNumber: "",
    email: "",
    creditCheck: "",
  });
  
  const [carFormSteps, setCarFormSteps] = useState<any>(0);
  const [carLanguage, setCarLanguage] = useState<string>("en");

  return (
    <CarContext.Provider value={{ carFormData, setCarFormData }}>
      <CarStepContext.Provider value={{ carFormSteps, setCarFormSteps }}>
        <CarLanguageContext.Provider value={{ carLanguage, setCarLanguage }}>
          {children}
        </CarLanguageContext.Provider>
      </CarStepContext.Provider>
    </CarContext.Provider>
  );
};
