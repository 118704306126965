import React from "react";

function FemaleImage() {
  return (
    <div className="radio-images-circular-div">
      <svg
        width="51"
        height="51"
        viewBox="0 0 51 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25.5" cy="25.5" r="25.5" fill="#F7F7F7" />
        <path
          d="M21.668 26.4884C19.2323 24.0527 19.2323 20.1036 21.668 17.6679C24.1037 15.2322 28.0527 15.2322 30.4884 17.6679C32.9241 20.1036 32.9241 24.0527 30.4884 26.4884C28.0527 28.9241 24.1037 28.9241 21.668 26.4884ZM20.3624 16.3623C17.2057 19.5191 17.2057 24.6372 20.3624 27.7939C23.5192 30.9507 28.6373 30.9507 31.794 27.7939C34.9508 24.6372 34.9508 19.5191 31.794 16.3623C28.6373 13.2056 23.5192 13.2056 20.3624 16.3623Z"
          fill="#37375B"
          stroke="#37375B"
          strokeWidth="0.5"
        />
        <path
          d="M25.2206 38.3325V38.5825H25.4706H27.0293H27.2793V38.3325V28.9805V28.7305H27.0293H25.4706H25.2206V28.9805V38.3325Z"
          fill="#37375B"
          stroke="#37375B"
          strokeWidth="0.5"
        />
        <path
          d="M22.7418 33.7987L22.4918 33.7987L22.4918 34.0487L22.4918 35.6074L22.4918 35.8574L22.7418 35.8574L29.7559 35.8574L30.0059 35.8574L30.0059 35.6074L30.0059 34.0487L30.0059 33.7987L29.7559 33.7987L22.7418 33.7987Z"
          fill="#37375B"
          stroke="#37375B"
          strokeWidth="0.5"
        />
      </svg>
    </div>
  );
}

export default FemaleImage;
