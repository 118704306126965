import { useState, useContext, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import MoneyInput from "../../../components/refresh/MoneyInput";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";

function CarStepNine() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [outsideQuebec, setOutsideQuebec] = useState<boolean | null>(null);
  const [numOfWeeksOutsideQuebec, setNumOfWeeksOutsideQuebec] = useState<
    number | null>(0);
  const [valid, setValid] = useState<boolean>(false);
  const LSOutsideQuebec = useLocalStorage("outsideQuebec");
  const LSNumOfWeeksOutsideQuebec = useLocalStorage("numOfWeeksOutsideQuebec");
  const [dateError, setDateError] = useState<boolean>(false)

  useEffect(() => {
    if (LSOutsideQuebec) {
      setOutsideQuebec(LSOutsideQuebec === "true");
      if (LSOutsideQuebec === 'true') {
        setNumOfWeeksOutsideQuebec(LSNumOfWeeksOutsideQuebec);
      }
    }
  }, [LSNumOfWeeksOutsideQuebec, LSOutsideQuebec]);

  useEffect(() => {
    setDateError(false)
    if (outsideQuebec === null) {
      setValid(false);
    }

    if (outsideQuebec === true && numOfWeeksOutsideQuebec === null) {
      setValid(false);
    }

    if (numOfWeeksOutsideQuebec && numOfWeeksOutsideQuebec > 52) {
      setValid(false)
      setDateError(true)
    }

    if (numOfWeeksOutsideQuebec && numOfWeeksOutsideQuebec >= 1 && numOfWeeksOutsideQuebec < 52) {
      setValid(true)
    }

    if (numOfWeeksOutsideQuebec === 0 && outsideQuebec !== null) {
      setValid(true)
    }
    if (outsideQuebec && numOfWeeksOutsideQuebec === 0) {
      setValid(false)
    }

    if (outsideQuebec === false) {
      setNumOfWeeksOutsideQuebec(0)
      setValid(true)
    }
  }, [numOfWeeksOutsideQuebec, outsideQuebec]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].useCarOutsideQuebec} />
      <RadioButtonGroup setAccept={setOutsideQuebec} accept={outsideQuebec} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />} />
      
      {outsideQuebec === true ? (

        <>

        <p className="dropdown-title">{carTexts[carLanguage].numOfWeeksOutsideQuebec}</p>
        <MoneyInput 
        placeholder='e.g. 5'
        label={carTexts[carLanguage].weeks}
        setState={setNumOfWeeksOutsideQuebec}
        type="number"
        maxLength={2}
        lsValue={numOfWeeksOutsideQuebec}
        />
        </>
      ) : null}
      
      {dateError && <div className="modal-error-msg">{carTexts[carLanguage].weeksError}</div>}
      <CarFooter
        data={{
          outsideQuebec: outsideQuebec,
          numOfWeeksOutsideQuebec: numOfWeeksOutsideQuebec,
        }}
        formSteps={10}
        backFormSteps={8}
        type="submit"
        disabled={!valid}
      />
    </div>
    </div>
  );
}

export default CarStepNine;
