import { useState, useContext, useEffect, SetStateAction, Dispatch  } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import axios from "axios";
import Question from "../../../components/refresh/Question";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import '../../../components/refresh/RefreshStyles.css'

type CarModel = {
  year: number;
  make: string;
  model: string;
  EXT_VICC: string;
  VICC_CODE: string;
};

type CarInfo = {
  year: number | undefined;
  make: string | undefined;
  model: string | undefined;
  EXT_VICC: string | undefined;
  VICC_CODE: string | undefined;
  carPurchaseYear: string | undefined;
  carCondition: string;
  purchaseType: string;
  tagTracking: boolean;
  sherlock: boolean;
  noSecurity: boolean;
  useOfCar: string;
  comprehensive: boolean | null;
  collision: boolean | null;
};

type CarFormProps = {
  onSubmit: (car: CarInfo) => null | void;
  setDisplayForm: Dispatch<SetStateAction<boolean>>;
  anotherVehicleBoolean: boolean;
};

function AddCarForm(props: CarFormProps) {
  const { carLanguage } = useContext(CarLanguageContext);
  const [yearList, setYearList] = useState<string[]>([]);
  const [carYear, setCarYear] = useState<string>("");
  const [makeList, setMakeList] = useState<string[]>([]);
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [modelList, setModelList] = useState<CarModel[]>([]);
  const [selectedCar, setSelectedCar] = useState<string>("");
  const [carInfo, setCarInfo] = useState<CarModel | null>(null);
  const [carPurchaseYear, setCarPurchaseYear] = useState<Date | null>(null);
  const [primaryCarCondition, setPrimaryCarCondition] = useState<string>("");
  const [purchaseType, setPurchaseType] = useState<string>("");
  const [tagTracking, setTagTracking] = useState<boolean>(false);
  const [sherlock, setSherlock] = useState<boolean>(false);
  const [noSecurity, setNoSecurity] = useState<boolean>(false);
  const [primaryUseOfCar, setPrimaryUseOfCar] = useState<string>("");
  const [comprehensive, setComprehensive] = useState<null | boolean>(null);
  const [collision, setCollision] = useState<null | boolean>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [security, setSecurity] = useState<string>('')
  const [coverage, setCoverage] = useState<string>('')
  const [month, setMonth] = useState<null | string>(null);
  const [monthNumber, setMonthNumber] = useState<null | string>(null);
  const [dateYearList, setDateYearList] = useState<string[]>([]);
  const [dayList, setDayList] = useState<string[]>([]);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [chosenMonth, setChosenMonth] = useState<string>('');
  const [chosenDay, setChosenDay] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [dayNumber, setDayNumber] = useState<number>(32)

  useEffect(() => {

    const isLeap = (numberYear: number): boolean => {
      return new Date(numberYear, 1, 29).getDate() === 29;
    };
  
    if (chosenMonth) {
      if (chosenMonth === 'January' || chosenMonth === 'March' || chosenMonth === 'May' || chosenMonth === 'July' || chosenMonth === 'August' || chosenMonth === 'October' || chosenMonth === 'December'
      || chosenMonth === 'Janvier' || chosenMonth === 'Mars' || chosenMonth === 'Mai' || chosenMonth === 'Juillet' || chosenMonth === 'Août' || chosenMonth === 'Octobre' || chosenMonth === 'Décembre') {
        setDayNumber(32)
      }
      else if (chosenMonth === 'April' || chosenMonth === 'June' || chosenMonth === 'September' || chosenMonth === 'November'
      || chosenMonth === 'Avril' || chosenMonth === 'Juin' || chosenMonth === 'Septembre' || chosenMonth === 'Novembre') {
        setDayNumber(31)
      }
      else if (chosenMonth === 'February' || chosenMonth === 'Février') {
        if (isLeap(Number(chosenYear))) {
          setDayNumber(30)
        } else {
        setDayNumber(29)
      }
      }
      else {
        setDayNumber(32)
      }
    }
  }, [chosenMonth, chosenYear]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const years = [];
    const days = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1980; i < currentYear + 1; i++) {
      years.push(i.toString());
    }
    setYearList(years.reverse());

    for (let i = 1; i < dayNumber; i++) {
      if (i <= 9) {
        days.push(`0${i}`);
      } else {
        days.push(i.toString());
      }
    }
    setDayList(days);
  }, [dayNumber]);
  
  const months = [
  carTexts[carLanguage].january,
  carTexts[carLanguage].february,
  carTexts[carLanguage].march,
  carTexts[carLanguage].april,
  carTexts[carLanguage].may,
  carTexts[carLanguage].june,
  carTexts[carLanguage].july,
  carTexts[carLanguage].august,
  carTexts[carLanguage].september,
  carTexts[carLanguage].october,
  carTexts[carLanguage].november,
  carTexts[carLanguage].december,]

  function handleMonth(month: string) {
    setMonth(month);
    const list = [
      carTexts[carLanguage].january,
      carTexts[carLanguage].february,
      carTexts[carLanguage].march,
      carTexts[carLanguage].april,
      carTexts[carLanguage].may,
      carTexts[carLanguage].june,
      carTexts[carLanguage].july,
      carTexts[carLanguage].august,
      carTexts[carLanguage].september,
      carTexts[carLanguage].october,
      carTexts[carLanguage].november,
      carTexts[carLanguage].december,
    ];

    for (let i = 0; i < list.length; i++) {
      if (month === list[i]) {
        if (i < 9) {
          setMonthNumber(`0${i + 1}`);
        } else {
          setMonthNumber(`${i + 1}`);
        }
      }
    }
  }

  
let dateString = ''

useEffect(() => {
  handleMonth(chosenMonth)
  dateString = `${chosenYear}/${monthNumber}/${chosenDay}`
  const dateObject = new Date(dateString)

  function isValidDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  if (isValidDate(dateObject) && dateString.length === 10) {
    if (dateObject.toISOString().slice(0, 10).replace(/-/g, '/') === dateString) {
      setCarPurchaseYear(dateObject)
      setDateError(false)
    } else {
      setDateError(true)
      setCarPurchaseYear(null)
    }
  } else {
    setCarPurchaseYear(null)
  }


}, [chosenDay, chosenMonth, chosenYear])


  function handleReverseMonth(monthNumber: number) {
    const list = [
      carTexts[carLanguage].january,
      carTexts[carLanguage].february,
      carTexts[carLanguage].march,
      carTexts[carLanguage].april,
      carTexts[carLanguage].may,
      carTexts[carLanguage].june,
      carTexts[carLanguage].july,
      carTexts[carLanguage].august,
      carTexts[carLanguage].september,
      carTexts[carLanguage].october,
      carTexts[carLanguage].november,
      carTexts[carLanguage].december,
    ];

    const selectedMonth = list[monthNumber - 1];
    setMonth(selectedMonth);
    handleMonth(selectedMonth);
  }

  useEffect(() => {
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1980; i < currentYear + 2; i++) {
      years.push(i.toString());
    }
    setDateYearList(years.reverse());
  }, []);

  useEffect(() => {
    if (noSecurity === true) {
      setTagTracking(false);
      setSherlock(false);
    }
  }, [noSecurity]);

  useEffect(() => {
    if (tagTracking === true || sherlock === true) {
      setNoSecurity(false);
    }
  }, [sherlock, tagTracking]);

  useEffect(() => {
    if (carYear) {
      axios
        .post("https://shrouded-thicket-97880.herokuapp.com/get-car-makes", {
          year: parseInt(carYear),
        })
        .then((response) => {
          setMakeList(response.data);
        });
    }
  }, [carYear]);

  useEffect(() => {
    if (carYear && selectedMake !== "") {
      axios
        .post("https://shrouded-thicket-97880.herokuapp.com/get-car-models", {
          year: parseInt(carYear),
          make: selectedMake,
        })
        .then((response) => {
          setModelList(response.data);
        })
    }

    if (carYear && selectedMake !== "" && selectedCar !== "") {
      for (let i = 0; i < modelList.length; i++) {
        if (selectedCar === modelList[i].model) {
          setCarInfo(modelList[i]);
        }
      }
    }
  }, [carYear, selectedMake, selectedCar, modelList]);

  useEffect(() => {
    setValid(true);
    if (
      carInfo?.year === null ||
      carInfo?.model === null ||
      carInfo?.make === null ||
      carPurchaseYear === null ||
      primaryCarCondition === null ||
      purchaseType === null ||
      primaryUseOfCar === null ||
      comprehensive === null ||
      collision === null
    ) {
      setValid(false);
    }

    if (tagTracking === null && sherlock === null && noSecurity === null) {
      setValid(false);
    }
  }, [
    carInfo?.make,
    carInfo?.model,
    carInfo?.year,
    carPurchaseYear,
    collision,
    comprehensive,
    noSecurity,
    primaryCarCondition,
    primaryUseOfCar,
    purchaseType,
    sherlock,
    tagTracking,
  ]);

    useEffect(() => {
    if (carInfo?.EXT_VICC && carInfo.VICC_CODE) {
      if (carInfo.EXT_VICC.length === 5) {
        setCarInfo({
          ...carInfo,
          EXT_VICC: `0${carInfo.EXT_VICC}`,
        });
      }
      if (carInfo.VICC_CODE.length === 3) {
        setCarInfo({
          ...carInfo,
          VICC_CODE: `0${carInfo.VICC_CODE}`,
        });
      }
    }
  }, [carInfo]);


  const filteredModelList: any = []
  
  modelList.filter((modelObj) => {
      return filteredModelList.push(modelObj.model)
  })

  const conditionList = [carTexts[carLanguage].new, carTexts[carLanguage].used, carTexts[carLanguage].demo]
  const purchaseTypeList = [carTexts[carLanguage].financed, carTexts[carLanguage].leased, carTexts[carLanguage].ownedOutright]
  const securityList = [carTexts[carLanguage].tagTracking, carTexts[carLanguage].sherlock, carTexts[carLanguage].tagTrackingAndSherlock, carTexts[carLanguage].none]

  useEffect(() => {
    if (security === 'Tag tracking') {
      setTagTracking(true)
      setSherlock(false)
      setNoSecurity(false)
    } else if (security === 'Sherlock') {
      setTagTracking(false)
      setSherlock(true)
      setNoSecurity(false)
    } else if (security === 'Sherlock and tag tracking') {
      setTagTracking(true)
      setSherlock(true)
      setNoSecurity(false)
    } else if (security === 'None') {
      setTagTracking(false)
      setSherlock(false)
      setNoSecurity(true)
    }
  }, [security])

  const primaryList = [carTexts[carLanguage].pleasure, carTexts[carLanguage].pleasureAndCommuting]
  const coverageList = [carTexts[carLanguage].comprehensive, carTexts[carLanguage].collision, carTexts[carLanguage].comprehensiveAndCollision, carTexts[carLanguage].none]

  useEffect(() => {
    if (coverage === carTexts[carLanguage].comprehensive) {
      setComprehensive(true)
      setCollision(false)
    } else if (coverage === carTexts[carLanguage].collision) {
      setComprehensive(false)
      setCollision(true)
    } else if (coverage === carTexts[carLanguage].comprehensiveAndCollision) {
      setComprehensive(true)
      setCollision(true)
    } else if (coverage === carTexts[carLanguage].none) {
      setComprehensive(false)
      setCollision(false)
    }
  }, [coverage])

  useEffect(() => {
    if (purchaseType === carTexts["fr"].financed) {
      setPurchaseType(carTexts["en"].financed)
    }
    if (purchaseType === carTexts["fr"].leased) {
      setPurchaseType(carTexts["en"].leased)
    }
    if (purchaseType === carTexts["fr"].ownedOutright) {
      setPurchaseType(carTexts["en"].ownedOutright)
    }
  }, [purchaseType])

  useEffect(() => {
    if (primaryCarCondition === carTexts["fr"].new) {
      setPrimaryCarCondition(carTexts["en"].new)
    }

    if (primaryCarCondition === carTexts["fr"].used) {
      setPrimaryCarCondition(carTexts["en"].used)
    }

    if (primaryCarCondition === carTexts["fr"].demo) {
      setPrimaryCarCondition(carTexts["en"].demo)
    }
  }, [primaryCarCondition]);

  useEffect(() => {
    if (primaryUseOfCar === carTexts["fr"].pleasure) {
      setPrimaryUseOfCar(carTexts["en"].pleasure)
    }
    if (primaryUseOfCar === carTexts["fr"].pleasureAndCommuting) {
      setPrimaryUseOfCar(carTexts["en"].pleasureAndCommuting)
    }
  }, [primaryUseOfCar])


  return (
    <div className="t-content-container" style={{ marginLeft: '0%' }}>
      <p className="form-title">{carTexts[carLanguage].newVehicle}</p>
      <Question subQuestion={carTexts[carLanguage].carDetails} />
        <p className="dropdown-title">{carTexts[carLanguage].year}</p>
        <DropdownMenu list={yearList} setState={setCarYear} placeholder={carTexts[carLanguage].year} />
        <p className="dropdown-title">{carTexts[carLanguage].make}</p>
        <DropdownMenu list={makeList.sort()} setState={setSelectedMake} placeholder={carTexts[carLanguage].make}/>
        <p className="dropdown-title">{carTexts[carLanguage].model}</p>
        <DropdownMenu list={filteredModelList.sort()} setState={setSelectedCar} placeholder={carTexts[carLanguage].model}/>
      <p className="dropdown-title">{carTexts[carLanguage].whenPurchaseCar}</p>
      <div className="date-container">
        <div className="input-container">
          <DropdownMenu list={dateYearList} setState={setChosenYear} placeholder={carTexts[carLanguage].year}/>
          </div>
          <div className="input-container">
          <DropdownMenu type={'month'} list={months} setState={setChosenMonth} placeholder={carTexts[carLanguage].month}/> 
          </div>
          <div className="input-container">
          <DropdownMenu list={dayList} setState={setChosenDay} placeholder={carTexts[carLanguage].day}/>
          </div>
          </div>
          {dateError && <div className="modal-error-msg">{carTexts[carLanguage].dateError}</div>}
      <p className="dropdown-title">{carTexts[carLanguage].primaryCarCondition}</p>
          <DropdownMenu type='condition' list={conditionList} setState={setPrimaryCarCondition} placeholder={carTexts[carLanguage].select} />
      <p className="dropdown-title">{carTexts[carLanguage].purchaseType} </p>
          <DropdownMenu type='purchase' list={purchaseTypeList} setState={setPurchaseType} placeholder={carTexts[carLanguage].select}/>
      <p className="dropdown-title">{carTexts[carLanguage].securitySystem}</p>
          <DropdownMenu type='security' list={securityList} setState={setSecurity} placeholder={carTexts[carLanguage].select}/>              
      <p className="dropdown-title">{carTexts[carLanguage].primaryUseOfCar}</p>
          <DropdownMenu type='use' list={primaryList} setState={setPrimaryUseOfCar} placeholder={carTexts[carLanguage].select}/>  
      <p className="dropdown-title">{carTexts[carLanguage].includeCompAndCollision} </p>
          <DropdownMenu type='insurance' list={coverageList} setState={setCoverage} placeholder={carTexts[carLanguage].select}/>      

      <div>
        <div className="button-group-add-driver">
        <button
          onClick={() => {
            props.onSubmit({
              year: carInfo?.year,
              make: carInfo?.make,
              model: carInfo?.model,
              EXT_VICC: carInfo?.EXT_VICC,
              VICC_CODE: carInfo?.VICC_CODE,
              carPurchaseYear: carPurchaseYear
                ?.toLocaleDateString("sv")
                .replaceAll("-", ""),
              carCondition: primaryCarCondition,
              purchaseType: purchaseType,
              tagTracking: tagTracking,
              sherlock: sherlock,
              noSecurity: noSecurity,
              useOfCar: primaryUseOfCar,
              comprehensive: comprehensive,
              collision: collision,
            });
          }}
          className="btn form"
          disabled={!valid}
        >
          {carTexts[carLanguage].add}
        </button>
        {props.anotherVehicleBoolean ? (
                  <button
                  className="btn form"
                  onClick={() => {
                    props.setDisplayForm(false)
                  }}>
                    {carTexts[carLanguage].cancel}
            </button> ) : (null)
        }
        </div>

      </div>
    </div>
  );
}


export default AddCarForm;
