const Phone = () => {
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <circle cx="9" cy="9" r="9" fill="#B0B0AD"/>
            <path d="M7.22056 4.48089C7.07018 4.11762 6.67371 3.92427 6.29482 4.02778L4.57615 4.49651C4.23632 4.59026 4 4.89884 4 5.25039C4 10.0822 7.9178 14 12.7496 14C13.1012 14 13.4097 13.7637 13.5035 13.4239L13.9722 11.7052C14.0757 11.3263 13.8824 10.9298 13.5191 10.7794L11.6442 9.99822C11.3258 9.86542 10.9567 9.95721 10.7399 10.2248L9.95091 11.1876C8.57597 10.5373 7.46274 9.42403 6.81238 8.04909L7.77522 7.26202C8.04279 7.04328 8.13458 6.6761 8.00178 6.35776L7.22056 4.48284V4.48089Z" fill="white"/>
    </svg>  
    </>
  )
}

export default Phone