import { useContext, useState, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import Question from "../../../components/refresh/Question";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import TextInput from "../../../components/refresh/TextInput";
import MoneyInput from "../../../components/refresh/MoneyInput";

function CarStepTwentySix() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<number>(0);
  const LSEmail = useLocalStorage("email");
  const LSPhone = useLocalStorage("phoneNumber");
  useEffect(() => {
    if (LSEmail) {
      setEmail(LSEmail);
      setPhoneNumber(LSPhone);
    }
  }, [LSEmail, LSPhone]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].contactInfo}
        subQuestion={carTexts[carLanguage].justKidding}
      />
      <div className="two-input-div">
        <TextInput 
        placeholder={carTexts[carLanguage].email}
        label={carTexts[carLanguage].weeks}
        setState={setEmail}
        lsValue={email}
        type="string"
        />
        <MoneyInput 
        placeholder={carTexts[carLanguage].phoneNumber}
        label={carTexts[carLanguage].weeks}
        setState={setPhoneNumber}
        lsValue={phoneNumber}
        type="number"
        maxLength={10}
        />
      </div>

      <CarFooter
        data={{
          phoneNumber: phoneNumber,
          email: email,
        }}
        formSteps={27}
        backFormSteps={25}
        type="submit"
        disabled={String(phoneNumber).length < 10 || email === ""}
      />
    </div>
    </div>
  );
}

export default CarStepTwentySix;
