import react from "react"

function HomeHomeIcon(){
    return(
        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25.5" cy="25.5" r="25.5" fill="#F7F7F7"/>
            <path d="M17 32.927V23.4455L15 23.5L25.2963 15L35 23.5H33V32.927H27.0741V28.1863H22.9259V32.927H17Z" fill="#37375B"/>
        </svg>
    )
}

export default HomeHomeIcon;