import { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import MaleImage from "../../../components/RadioImages/MaleImage";
import FemaleImage from "../../../components/RadioImages/FemaleImage";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";

function CarStepFifteen() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [gender, setGender] = useState<string>("");
  const LSGender = useLocalStorage("gender");

  useEffect(() => {
    if (LSGender) {
      setGender(LSGender);
    }
  }, [LSGender]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].whatIsYourGender} />
      <div className="t-boolean-buttons-container">
      <RadioButtonGroup setGender={setGender} gender={gender} labelLeft={carTexts[carLanguage].male} labelRight={carTexts[carLanguage].female} iconLeft={<MaleImage />} iconRight={<FemaleImage />}/>
      </div>
      
      <CarFooter
        data={{
          gender: gender,
        }}
        formSteps={17}
        type="submit"
        disabled={gender === ""}
        backFormSteps={14}
      />
    </div>
    </div>  
  );
}

export default CarStepFifteen;
