import { useContext } from "react";
import {
  HomeStepContext,
  LanguageContext,
} from "../../quotes/shared/HomeContext";
import useIsMobile from "../hooks/useIsMobile";
import { texts } from "../../text";
import ArrowLeft from "./assets/ArrowLeft";

type BackButtonProps = {
  hidden?: boolean;
  disabled?: boolean;
  newQuote?: boolean;
  backFormSteps?: number;
};

function BackButton(props: BackButtonProps) {
  const { formSteps, setFormSteps } = useContext(HomeStepContext);
  const { language } = useContext(LanguageContext);
  const isMobile = useIsMobile();

  const handleClick = () => {
    setFormSteps(props.backFormSteps);
    if (props.backFormSteps) {
      localStorage.setItem("carStep", props.backFormSteps.toString());
    }
    // setFormSteps((prevForm: number) => prevForm - 1);
    // localStorage.setItem("step", (formSteps - 1).toString());
  };

  const handleNewQuote = () => {
    setFormSteps(1);
    localStorage.clear();
  };

  if (props.newQuote) {
    return (
      <button className="quote-buy-button new" onClick={handleNewQuote}>
        {texts[language].startOver}
      </button>
    );
  } else {
    return (
      <button
      style={
        props.hidden
          ? isMobile
            ? { display: "none" }
            : { display: "none" }
          : {}
      }
        className="btn pink text-with-arrow-left footer-btns"
        disabled={formSteps === 1 || props.disabled}
        onClick={handleClick}
      >
            <div>
                <ArrowLeft />
            </div>
      </button>
    );
  }
}

export default BackButton;
