import { useContext, useState } from "react";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext, CarContext, CarStepContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
// import ModalBox from "../../../components/ModalBox";
import "../../../components/refresh/RefreshStyles.css"
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";

function CarStepOne() {
  const { carLanguage } = useContext(CarLanguageContext);
  // const { setCarFormData } = useContext(CarContext);
  // const { setCarFormSteps } = useContext(CarStepContext);
  const [accept, setAccept] = useState<boolean | null>(null);
  // const [showModal, setShowModal] = useState<boolean>(false);
  // const [resume, setResume] = useState<string>("");


  return (
    // <div className="container step">
    <div className="t-container">

      <div className="t-content-container">

      <Question
        question={carTexts[carLanguage].beforeStartQuestion}
        subQuestion={
          accept === false
            ? carTexts[carLanguage].beforeStartSubQuestion1
            : accept === null
            ? carTexts[carLanguage].beforeStartSubQuestion1
            : (
              <>
              {carTexts[carLanguage].beforeStartMessage}{carTexts[carLanguage].quebecClient}<a href="tel:5146360002" className="phone-number">{carTexts[carLanguage].quebecNumber}</a>. {carTexts[carLanguage].ontarioClient}<a href="tel:6132122352" className="phone-number">{carTexts[carLanguage].ontarioNumber}</a>.
              </>
            )
        }
      />

      <div className="t-boolean-buttons-container">
      <RadioButtonGroup setAccept={setAccept} accept={accept} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>
      </div>
      <CarFooter
        formSteps={1.5}
        type="submit"
        disabled={accept === true ? true : accept === null ? true : false}
        backHidden={true}
      />

      </div>
      
    </div>
  );
}

export default CarStepOne;
