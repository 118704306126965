import './RefreshStyles.css'

type questionProps = {
    question?: string;
    questionHtml?: string;
    subQuestion?: string | React.ReactNode;
    subQuestion2?: string;
    subQuestion3?: string;
    className?: string;
  };

const Question = (props: questionProps) => {
  return (
            <div className="t-text-box">
              {props.questionHtml ? 
              <p className="question-heading" data-testid="step-question" dangerouslySetInnerHTML={{ __html: props.questionHtml}}></p>
              :  
                <p className="question-heading" data-testid="step-question">{props.question}</p>
              }
                {props.subQuestion ? 
                <p className={`sub-question ${props.className}`} data-testid="step-sub-question">{props.subQuestion}<a href="tel:4388434814" className="phone-number">{props.subQuestion2}</a>{props.subQuestion3}</p>
                :
                null
                }
            </div>
  )
}

export default Question