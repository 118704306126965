import './RefreshStyles.css'
import { Dispatch, SetStateAction, useState, useEffect } from 'react';

type InputProps = {
    placeholder: string;
    setState?: Dispatch<SetStateAction<number | string | null>>,
    setStringState?: Dispatch<SetStateAction<string | null>>
    margin?: string;
    type?: "text" | "number";
    lsValue?: any;
    error?: boolean;
    label?: string;
    onChange?: (text: string) => void;
    maxLength?: number;
  };
  
  const MoneyInput = (props: InputProps) => {
      const [value, setValue] = useState('')

      useEffect(() => {
        if (props.lsValue) { setValue(String(props.lsValue)) }
      }, [])
      


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    

    if (inputValue.length > (props.maxLength ?? Infinity)) {
      setValue(inputValue.slice(0, props.maxLength));
    } else {
      setValue(inputValue);
    }

     if (props.setState) props.setState(Number(e.target.value))
     if (props.onChange) props.onChange(e.target.value)
     if (props.setStringState) props.setStringState(e.target.value)
  };


  return (
    <input 
    className="unit-number-box" 
    type={props.type === "number" ? "number" : "string"} 
    id="price-of-car" 
    value={props.lsValue ? String(props.lsValue) : value} 
    name="price of car" 
    placeholder={props.placeholder}
    onChange={handleChange}
    >    
    </input>
    
  )
}

export default MoneyInput