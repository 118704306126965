import React, { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import { usePlacesWidget } from "react-google-autocomplete";
import UnitNumberInput from "../../../components/refresh/UnitNumberInput";
import { LegacyRef } from "react";
import DropdownMenu from "../../../components/refresh/DropdownMenu"
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import AddressInput from "../../../components/refresh/AddressInput";

function CarStepSeventeen() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [placeAddress, setPlaceAddress] = useState(null);
  const [unitNumber, setUnitNumber] = useState<string | null>(null);
  const [addressNumber, setAddressNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [provinceError, setProvinceError] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const LSAddressNumber = useLocalStorage("addressNumber");
  const LSAddress = useLocalStorage("address");
  const LSPostalcode = useLocalStorage("postalCode");
  const LSCity = useLocalStorage("city");
  const LSProvince = useLocalStorage("province");
  const LSDate = useLocalStorage("dateMovedIn");
  // const LSUnitNumber = useLocalStorage("unitNumber")
  // const [dob, setDob] = useState<Date | null>(null);
  // const [chosenYear, setChosenYear] = useState<string>('');
  // const [chosenMonth, setChosenMonth] = useState<string>('');
  // const [chosenDay, setChosenDay] = useState<string>('');
  // const [dateError, setDateError] = useState<boolean>(false);
  // const LSDMI = useLocalStorage("dateMovedIn");
  // const [month, setMonth] = useState<null | string>(null);
  // const [monthNumber, setMonthNumber] = useState<null | string>(null);
  // const [day, setDay] = useState<string>("");
  // const [year, setYear] = useState<string>("");
  // const [yearList, setYearList] = useState<string[]>([]);
  // const [dayList, setDayList] = useState<string[]>([]);
  // const [dayNumber, setDayNumber] = useState<number>(0)
  const [postalCodeError, setPostalCodeError] = useState<boolean | null>(null);
  const [addressError, setAddressError] = useState<boolean | null>(null)
  const [streetError, setStreetError] = useState<boolean | null>(null)

  // useEffect(() => {
  //   if (LSDMI) {
  //     let year = LSDMI.substring(0, 4);
  //     let month = LSDMI.substring(4, 6);
  //     let day = LSDMI.substring(6, 8);
  //     setYear(year);
  //     setDay(day);
  //     handleReverseMonth(month);
  //   }
  //   if (LSUnitNumber) {
  //     setUnitNumber(LSUnitNumber)
  //   }
  // }, [LSDMI, LSUnitNumber]);

//   useEffect(() => {

//     const isLeap = (numberYear: number): boolean => {
//       return new Date(numberYear, 1, 29).getDate() === 29;
//     };
  
//     if (chosenMonth) {
//       if (chosenMonth === 'January' || chosenMonth === 'March' || chosenMonth === 'May' || chosenMonth === 'July' || chosenMonth === 'August' || chosenMonth === 'October' || chosenMonth === 'December'
//       || chosenMonth === 'Janvier' || chosenMonth === 'Mars' || chosenMonth === 'Mai' || chosenMonth === 'Juillet' || chosenMonth === 'Août' || chosenMonth === 'Octobre' || chosenMonth === 'Décembre') {
//         setDayNumber(32)
//       }
//       else if (chosenMonth === 'April' || chosenMonth === 'June' || chosenMonth === 'September' || chosenMonth === 'November'
//       || chosenMonth === 'Avril' || chosenMonth === 'Juin' || chosenMonth === 'Septembre' || chosenMonth === 'Novembre') {
//         setDayNumber(31)
//       }
//       else if (chosenMonth === 'February' || chosenMonth === 'Février') {
//         if (isLeap(Number(chosenYear))) {
//           setDayNumber(30)
//         } else {
//         setDayNumber(29)
//       }
//       }
//       else {
//         setDayNumber(32)
//       }
//     }
//   }, [chosenMonth, chosenYear]);
  

//   useEffect(() => {
//     window.scrollTo(0, 0);

//     const years = [];
//     const days = [];
//     const currentYear = new Date().getFullYear();

//     for (let i = 1900; i < currentYear + 1; i++) {
//       years.push(i.toString());
//     }
//     setYearList(years.reverse());

//     for (let i = 1; i < dayNumber; i++) {
//       if (i <= 9) {
//         days.push(`0${i}`);
//       } else {
//         days.push(i.toString());
//       }
//     }
//     setDayList(days);
//   }, [dayNumber]);

//   useEffect(() => {
//     setValid(true);
//     if (year === "" || day === "" || month === null) {
//       setValid(false);
//     }
//   }, [day, month, year]);

  
//   const months = [
//   carTexts[carLanguage].january,
//   carTexts[carLanguage].february,
//   carTexts[carLanguage].march,
//   carTexts[carLanguage].april,
//   carTexts[carLanguage].may,
//   carTexts[carLanguage].june,
//   carTexts[carLanguage].july,
//   carTexts[carLanguage].august,
//   carTexts[carLanguage].september,
//   carTexts[carLanguage].october,
//   carTexts[carLanguage].november,
//   carTexts[carLanguage].december,]

//   function handleMonth(month: string) {
//     setMonth(month);
//     const list = [
//       carTexts[carLanguage].january,
//       carTexts[carLanguage].february,
//       carTexts[carLanguage].march,
//       carTexts[carLanguage].april,
//       carTexts[carLanguage].may,
//       carTexts[carLanguage].june,
//       carTexts[carLanguage].july,
//       carTexts[carLanguage].august,
//       carTexts[carLanguage].september,
//       carTexts[carLanguage].october,
//       carTexts[carLanguage].november,
//       carTexts[carLanguage].december,
//     ];

//     for (let i = 0; i < list.length; i++) {
//       if (month === list[i]) {
//         if (i < 9) {
//           setMonthNumber(`0${i + 1}`);
//         } else {
//           setMonthNumber(`${i + 1}`);
//         }
//       }
//     }
//   }

  
// let dateString = ''

// useEffect(() => {
//   handleMonth(chosenMonth)
//   dateString = `${chosenYear}/${monthNumber}/${chosenDay}`
//   const dateObject = new Date(dateString)

//   function isValidDate(date: Date): boolean {
//     return !isNaN(date.getTime());
//   }

//   if (isValidDate(dateObject) && dateString.length === 10) {
//     if (dateObject.toISOString().slice(0, 10).replace(/-/g, '/') === dateString) {
//       setDate(dateObject)
//       setDateError(false)
//     } else {
//       setDateError(true)
//       setDob(null)
//     }
//   } else {
//     setDob(null)
//   }


// }, [chosenDay, chosenMonth, chosenYear])


//   function handleReverseMonth(monthNumber: number) {
//     const list = [
//       carTexts[carLanguage].january,
//       carTexts[carLanguage].february,
//       carTexts[carLanguage].march,
//       carTexts[carLanguage].april,
//       carTexts[carLanguage].may,
//       carTexts[carLanguage].june,
//       carTexts[carLanguage].july,
//       carTexts[carLanguage].august,
//       carTexts[carLanguage].september,
//       carTexts[carLanguage].october,
//       carTexts[carLanguage].november,
//       carTexts[carLanguage].december,
//     ];

//     const selectedMonth = list[monthNumber - 1];
//     setMonth(selectedMonth);
//     handleMonth(selectedMonth);
//   }


  const { ref: materialRef }: { ref: LegacyRef<HTMLInputElement> } = usePlacesWidget({
    apiKey: "AIzaSyAtd3_E1gfiWI5S3RhICppVT_S0Sm6lZq4",
    onPlaceSelected: (place) => {
      setPlaceAddress(place);
      for (var i = 0; i < place.address_components.length; i++) {
        for (var j = 0; j < place.address_components[i].types.length; j++) {
          if (place.address_components[i].types[j] === "street_number") {
            setAddressNumber(place.address_components[i].long_name);
          }
          if (place.address_components[i].types[j] === "route") {
            setAddress(place.address_components[i].long_name);
          }
          if (place.address_components[i].types[j] === "postal_code") {
            setPostalCode(place.address_components[i].long_name);
          }
          if (place.address_components[i].types[j] === "locality") {
            setCity(place.address_components[i].long_name);
          }
          if (
            place.address_components[i].types[j] ===
            "administrative_area_level_1"
          ) {
            setProvince(place.address_components[i].long_name);
          }
        }
      }
    },
    inputAutocompleteValue: "country",
    options: {
      types: [],
      componentRestrictions: { country: "ca" },
    },
  });

  useEffect(() => {
    if (LSDate) {
      setAddressNumber(LSAddressNumber);
      setAddress(LSAddress);
      setPostalCode(LSPostalcode);
      setCity(LSCity);
      setProvince(LSProvince);
      let year = LSDate.substring(0, 4);
      let month = LSDate.substring(4, 6);
      let day = LSDate.substring(6, 8);
      let date = new Date(year, month - 1, day);
      setDate(date);
      if (materialRef.current) {
        materialRef.current.value = `${LSAddressNumber} ${LSAddress}, ${LSCity}, ${LSProvince}, ${LSPostalcode}`;
      }
    }
  }, [LSAddress, LSAddressNumber, LSCity, LSDate, LSPostalcode, LSProvince]);
  

  useEffect(() => {
    if (province !== "" && province !== "Quebec" && province !== "Québec" && province !== "QC") {
      setProvinceError(true);
    } else {
      setProvinceError(false);
    }
    if (postalCode && postalCode.length < 6 && addressNumber.length < 1) {
      setPostalCodeError(true)
    } else {
      setPostalCodeError(false)
    }
    if (province && address && (postalCode === '' || postalCode.length < 6)) {
      setAddressError(true)
    } else {
      setAddressError(false)
    }
    if (province && postalCode && city && (address.length <= 1)) {
      setStreetError(true)
    } else {
      setStreetError(false)
    }
  }, [address, province, postalCode, addressNumber, city, province]);

  useEffect(() => {
    setValid(true);
    if (city === "") {
      setValid(false)
    } else if (provinceError) {
      setValid(false)
    } else if (postalCodeError) {
      setValid(false)
    } else if (addressError) {
      setValid(false)
    } else if (streetError) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [city, addressError, postalCodeError, provinceError]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].address} />
      <Question subQuestion={carTexts[carLanguage].typeAddress} />
      {provinceError ? (
        <Question subQuestion={carTexts[carLanguage].provinceError} />
      ) :
      postalCodeError ? (
        <Question subQuestion={carTexts[carLanguage].postalCodeError} />
      ) :
      addressError ? (
        <Question subQuestion={carTexts[carLanguage].addressError} />
      ) :
      streetError ? (
        <Question subQuestion={carTexts[carLanguage].streetError} />
      ) :
      null}

   
        {LSAddress &&
        LSAddressNumber &&
        LSCity &&
        LSPostalcode &&
        LSProvince ? (
          <AddressInput
          inputRef={materialRef}
          placeholder="e.g. H9P 2W9 1425 Trans Canada Route Suite 300, Dorval, Quebec"
          defaultValue={`${LSAddress} ${LSCity} ${LSPostalcode} ${LSProvince}`}
           />
        ) : (
          <>
          <AddressInput
          inputRef={materialRef}
          placeholder="e.g. H9P 2W9 1425 Trans Canada Route Suite 300, Dorval, Quebec"
          defaultValue={`${LSAddress} ${LSCity} ${LSPostalcode} ${LSProvince}`}
           />
           </>
        )}
        <UnitNumberInput placeholder={carTexts[carLanguage].unitNumber} lsValue={unitNumber} setStringState={setUnitNumber} maxLength={5}/>

      {/* {city === "" || provinceError || postalCodeError || addressError || streetError ? null : (
        <div>

          <Question subQuestion={carTexts[carLanguage].whenAddress} />
          <div className="date-container">
          <div className="input-container">
          <DropdownMenu list={yearList} setState={setChosenYear} placeholder={carTexts[carLanguage].year} lsValue={'addressYear'}/>
          </div>
          <div className="input-container">
          <DropdownMenu type={'month'} list={months} setState={setChosenMonth} placeholder={carTexts[carLanguage].month} lsValue={'addressMonth'}/>
          </div>
          <div className="input-container">
          <DropdownMenu dateError={dateError} list={dayList} setState={setChosenDay} placeholder={carTexts[carLanguage].day} lsValue={'addressDay'}/>
          </div>
          </div>

      {dateError && <div className="modal-error-msg">{carTexts[carLanguage].dateError}</div>}

        </div>
      )} */}

      <CarFooter
        data={{
          maritalStatus: "Married",
          unitNumber: unitNumber,
          placeObject: placeAddress,
          addressNumber: addressNumber,
          address: address,
          postalCode: postalCode,
          city: city,
          province: province,
          dateMovedIn: "20180101"
        }}
        formSteps={18}
        type="submit"
        disabled={!postalCode || !city || !address || !province || !valid}
        backFormSteps={15}
      />
    </div>
    </div>
  );
}

export default CarStepSeventeen;
