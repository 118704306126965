import './RefreshStyles.css'

type NextStepsProps = {
  quoteNextStep: string;
  quoteCall: string;
  quoteNumber: string;
  review: string;
};

const NextSteps = (props: NextStepsProps) => {
  
  return (
    <>
        <div className="next-steps">
            <p className="next-steps-statement">
                {props.quoteNextStep}
            </p>
            <div className="next-steps-small">    
            <p className="next-step-sub">
                {props.quoteCall}<a href="tel:4388434814"><span className="phone-number">{props.quoteNumber}</span></a>{props.review}
            </p>
            </div>    
        </div>
    </>
  )
}

export default NextSteps