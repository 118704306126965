import { useState, useContext, useEffect, KeyboardEvent } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import Help from "../../../components/refresh/assets/Help";
import DescriptionModal from "../../../components/refresh/DescriptionModal";

function CarStepTen() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [comprehensive, setComprehensive] = useState<null | boolean>(null);
  const [collision, setCollision] = useState<null | boolean>(null);
  const LSComp = useLocalStorage("comprehensive");
  const LSColl = useLocalStorage("collision");
  const comp = "comprehensive"
  const coll = "collision"
  const [compAnswer, setCompAnswer] = useState<string>('') 
  const [coliAnswer, setColiAnswer] = useState<string>('') 
  const [mobile, setMobile] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [hoverOff, setHoverOff] = useState<boolean>(false)

  useEffect(() => {
    if (LSComp) {
      setComprehensive(LSComp === "true");
      setCollision(LSColl === "true");
    }
  }, [LSColl, LSComp]);

  useEffect(() => {
  if (window.innerWidth <= 576) {
    setHoverOff(true)
  } else {
    setHoverOff(false)
  }
  }, [])

  
useEffect(() => {
  if (mobile) {
  if (window.innerWidth <= 576) {
    setModal(true)
    document.body.style.overflow = "hidden";
  }
}
}, [mobile])

  const answers = [carTexts[carLanguage].yes, carTexts[carLanguage].no]

  useEffect(() => {
    if (compAnswer === carTexts[carLanguage].yes) {
      setComprehensive(true)
    } else if (compAnswer === carTexts[carLanguage].no) {
      setComprehensive(false)
    }
  }, [compAnswer])

  useEffect(() => {
    if (coliAnswer === carTexts[carLanguage].yes) {
      setCollision(true)
    } else if (coliAnswer === carTexts[carLanguage].no) {
      setCollision(false)
    }
  }, [coliAnswer])

  const collisionClass = 'collision'

  const handleComp = () => {
    setMobile(true)
    setTitle(carTexts[carLanguage].comprehensive)
    setDescription(carTexts[carLanguage].comprehensiveDescription)
    document.body.style.overflow = "hidden";
    if (!hoverOff) {
      document.body.style.overflow = "auto";
    }
  }
  
  const handleColl = () => {
    setMobile(true)
    setTitle(carTexts[carLanguage].collision)
    setDescription(carTexts[carLanguage].collisionDescription)
    document.body.style.overflow = "hidden";
    if (!hoverOff) {
      document.body.style.overflow = "auto";
    }
  }

  const handleKeyColl = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      // handleColl();
      if (hoverOff) {
        handleColl()
      }
      else if (!mobile) {
      const pElement = document.querySelector(
        ".security-systems-popup-sec.collision"
      ) as HTMLElement;
      if (pElement) {
        pElement.style.display = "block";
        setTimeout(() => {
          pElement.style.display = "";
        }, 1500);
      }
    }
  }
};

  const handleKeyComp = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      if (hoverOff) {
        handleComp()
      }
      else if (!mobile) {
      const pElement = document.querySelector(
        ".security-systems-popup-sec"
      ) as HTMLElement;
      if (pElement) {
        pElement.style.display = "block";
        setTimeout(() => {
          pElement.style.display = ""
        }, 1500);
        }
      }
    }
  }

  const handleModalClose = () => {
    setModal(false);
    setMobile(false)
    document.body.style.overflow = 'auto';
  }


  return (
    <div className="t-container">
      <div className="t-content-container">
      {modal ? <DescriptionModal handleModalClose={handleModalClose} title={title} description={description} /> : null}
      <Question
        question={carTexts[carLanguage].includeCompAndCollision}
        subQuestion={carTexts[carLanguage].dealershipRequired}
      />
      <p className="dropdown-title">{carTexts[carLanguage].comprehensive}
      <span className="help-span" onClick={handleColl} onKeyDown={handleKeyComp} tabIndex={0}><Help /></span>
          <p style={{ display: modal ? 'none' : '' }} className="security-systems-popup-sec">
          <span className="description-title">{carTexts[carLanguage].comprehensive}</span>
          {carTexts[carLanguage].comprehensiveDescription}</p> 
      </p>

      <DropdownMenu type={'boolean'} list={answers} setState={setCompAnswer} placeholder={carTexts[carLanguage].select} lsValue={comp}/>

      <p className="dropdown-title">{carTexts[carLanguage].collision}
      <span className="help-span" onClick={handleComp} onKeyDown={handleKeyColl} tabIndex={0}><Help /></span>
          <p style={{ display: modal ? 'none' : '' }} className={`security-systems-popup-sec ${collisionClass}`}>
          <span className="description-title">{carTexts[carLanguage].collision}</span>
          {carTexts[carLanguage].collisionDescription}</p> 
      </p>
      <DropdownMenu type={'boolean'} list={answers} setState={setColiAnswer} placeholder={carTexts[carLanguage].select} lsValue={coll}/>

      <CarFooter
        data={{
          collision: collision,
          comprehensive: comprehensive,
        }}
        formSteps={11}
        type="submit"
        disabled={collision === null || comprehensive === null}
        backFormSteps={9}
      />
    </div>
  </div>
  );
}

export default CarStepTen;
