import { useContext, useState, useEffect } from "react";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext, CarContext, CarStepContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import ModalBox from "../../../components/ModalBox";
import "../../../components/refresh/RefreshStyles.css"
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import OntarioProvince from "../../../components/refresh/assets/OntarioProvince";
import QuebecProvince from "../../../components/refresh/assets/QuebecProvince";
const CarStepZero = () => {
    const { carLanguage } = useContext(CarLanguageContext);
    const { setCarFormData } = useContext(CarContext);
    const { setCarFormSteps } = useContext(CarStepContext);
    const [accept, setAccept] = useState<boolean | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [resume, setResume] = useState<string>("");
    const [provinceSelector, setProvinceSelector] = useState('')

    //modal box functionaliy!

  useEffect(() => {
    const step = localStorage.getItem("carStep");
    

    if (step) {
      setShowModal(true);
      if (resume === "Yes") {
        setShowModal(false);
        const homeData = Object.keys(localStorage);
        homeData.forEach((key) => {
          if (key !== "type" && key !== "step") {
            if (
              key === "claims" ||
              key === "extraVehicleList" ||
              key === "convictions"
            ) {
              setCarFormData((homeFormData: any) => ({
                ...homeFormData,
                [key]: JSON.parse(localStorage.getItem(key)!),
              }));
            } else {
              setCarFormData((homeFormData: any) => ({
                ...homeFormData,
                [key]: localStorage.getItem(key),
              }));
            }
          }

          setCarFormSteps(parseInt(step));
        });
      }

      if (resume === "No") {
        setShowModal(false);
        localStorage.clear();
      }
    } else {
      setShowModal(false);
    }
  }, [resume]);

  return (
    <div className="t-container">
    <div className="t-content-container">
    <ModalBox
      onClick={(e) => {
        setResume(e);
      }}
      open={showModal}
    />
    <Question
      question={carTexts[carLanguage].beforeStartQuestion}
      subQuestion={
        accept === false
          ? `${carTexts[carLanguage].residentOf}`
          :
          `${carTexts[carLanguage].residentOf}`
      }
    />
      <RadioButtonGroup setProvinceSelector={setProvinceSelector} setAccept={setAccept} accept={accept} labelLeft={carTexts[carLanguage].ontario} labelRight={carTexts[carLanguage].quebec} iconLeft={<OntarioProvince />} iconRight={<QuebecProvince />}/>

    <CarFooter
      formSteps={1}
      type="submit"
      disabled={accept === true && provinceSelector === 'Ontario' ? false : accept === null ? true : false}
      backHidden={true}
      province={provinceSelector}
    />
  </div>
  </div>
  )
}

export default CarStepZero