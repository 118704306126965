

const OntarioProvince = () => {
  return (
    <>
<svg id="Layer_1" width="51" height="51" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51">
  <defs>
  </defs>
  <circle className="cls-1" fill={"#f6f6f3"} cx="25.5" cy="25.5" r="25.5"/>
  <polygon className="cls-2" fill={"#0047b2"} points="8.66 25.33 9.92 17.01 12.23 15.53 19.03 8.73 20.38 10.68 24.02 12.3 27.72 12.84 27.72 17.55 30.08 20.18 30.08 21.86 31.06 21.75 31.7 20.31 32.1 21.7 32.84 29.54 34.8 32.24 36.48 32.24 37.76 33.18 40.86 32.51 42.34 33.18 38.1 36.82 34.05 37.69 33.38 38.84 34.39 38.84 34.73 39.91 30.42 40.92 29.27 42.27 27.92 42.27 28.94 39.85 29.88 39.31 30.35 35.47 32.24 36.48 32.84 35.27 31.16 33.31 26.44 33.31 24.69 32.03 24.29 29.07 22.6 29.07 22.2 27.59 19.57 26.98 18.16 28.6 14.18 28.46 10.21 26.71 11.09 25.5 10.68 24.62 8.66 25.33"/>
</svg>
    </>
  )
}

export default OntarioProvince