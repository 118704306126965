import { useContext, useState, useEffect } from "react";
import Footer from "../../../components/footer/Footer";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../HomeContext";
import Question from "../../../components/refresh/Question";
import TextInput from "../../../components/refresh/TextInput";

function StepFive() {
  const { language } = useContext(LanguageContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [valid, setValid] = useState(false);
  const LSFirstName = useLocalStorage("firstName");
  const LSLastName = useLocalStorage("lastName");

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  useEffect(() => {
    if (LSFirstName) {
      setFirstName(LSFirstName);
      setLastName(LSLastName);
    }
  }, [LSFirstName, LSLastName]);

  useEffect(() => {
    setValid(true);
    if (firstName === "" || firstName === " " || firstName.length < 2) {
      setValid(false);
    }
    if (lastName === "" || lastName === " " || lastName.length < 2) {
      setValid(false);
    }
  }, [firstName, lastName]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={texts[language].nameQuestion}
        subQuestion={texts[language].nameSubQuestion}
      />
      <div className="two-input-div">
      <TextInput 
        placeholder={answers[language].firstName}
        setState={setFirstName}
        lsValue={firstName}
        type="string"
        />

              <TextInput 
        placeholder={answers[language].lastName}
        setState={setLastName}
        lsValue={lastName}
        type="string"
        />
      </div>
      <Footer
        type="submit"
        data={{ firstName: firstName, lastName: lastName }}
        formSteps={6}
        backFormSteps={4}
        disabled={!valid}
      />
    </div>
    </div>
  );
}

export default StepFive;
