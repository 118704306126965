import { useEffect, useState } from "react";

type ModalProps = {
  open: boolean;
  onClick: (value: string) => void;
};

function ModalBox(props: ModalProps) {
  const currentUrl = window.location.href;
  const [english, setEnglish] = useState(true)

  useEffect(() => {
    if (currentUrl.includes("fr")) {
      setEnglish(false)
    }
  }, []);


return (
  <div className="modal-container" style={{display: props.open ? 'flex' : 'none'}}>
    <div className="modal-restart-wrapper">
            <>
            <h2 className="modal-restart-heading">{english ? "Are you still there?" : "Vous êtes toujours là?"}</h2>
          <p className="sub-question-modal">
            {english ? "We detected you already partially filled our form." : "Nous avons détecté que vous avez déjà partiellement rempli notre formulaire."}
            
          </p>
          <div className="modal-restart-buttons-box">
          <button
            className="modal-restart-button"
            value={"Yes"}
            onClick={(e: any) => {
              props.onClick(e.target.value);
            

            }}
          >
            {english ? "Continue" : "Continuer"}
          </button>
          <button
            className="modal-restart-button"
            value={"No"}
            onClick={(e: any) => {
              props.onClick(e.target.value);
              
            
            }}
          >
            {english ? "Start Over" : "Recommencer"}
          </button>
          </div>
            </>
          </div>
    </div>
)
}

export default ModalBox;
