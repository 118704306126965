import { createContext, useState, ReactChild } from "react";

export const MyHomeContext = createContext({} as any);
export const HomeTypeContext = createContext("" as any);
export const HomeStepContext = createContext({} as any);
export const LanguageContext = createContext({} as any);

type contextChildren = {
  children: ReactChild;
};

export const HomeProvider = ({ children }: contextChildren) => {
  const [homeFormData, setHomeFormData] = useState<any>({
    unitNumber: "",
    addressNumber: "",
    address: "",
    postalCode: "",
    city: "",
    province: "",
    year: 0,
    livingArea: "",
    exteriorFinish: "",
    constructionType: "",
    garageType: "",
    basementPercentageFinished: 0,
    numOfStoreys: 0,
    yearRoofReplaced: 0,
    distanceToFirehall: "",
    distanceToFireHydrant: "",
    primaryHeatingType: "",
    secondaryHeatingType: "",
    buildingStyle: "",
    numOfUnits: 0,
    firstName: "",
    lastName: "",
    dob: "",
    residenceOccupancy: "",
    yearMovedIn: "",
    policyStartDate: "",
    activePolicy: null,
    insuranceGap: "",
    howLongInsuredCurrentCompany: "",
    claimsFiveYears: "",
    nonPaymentCancellations: "",
    havePriorPolicy: "",
    job: "",
    priorPolicyClaimsFiveYears: "",
    priorPolicyNonPaymentCancellations: "",
    saveEighteenPercent: null,
    discountMonitoredBurglar: null,
    discountFireAlarm: null,
    discountSprinkler: null,
    discountNonSmoker: null,
    numOfMortgages: null,
    userEmail: "",
    phoneNumber: null,
    creditCheck: null,
    createdAt: Date.now(),
    replacementCost: null
  });

  const [homeType, setHomeType] = useState<string>("");

  const [formSteps, setFormSteps] = useState<any>(0);

  const [language, setLanguage] = useState<string>("en");


  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <HomeTypeContext.Provider value={{ homeType, setHomeType }}>
        <HomeStepContext.Provider value={{ formSteps, setFormSteps }}>
          <MyHomeContext.Provider value={{ homeFormData, setHomeFormData }}>
            {children}
          </MyHomeContext.Provider>
        </HomeStepContext.Provider>
      </HomeTypeContext.Provider>
    </LanguageContext.Provider>
  );
};
