
import { useContext, useState } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import { useEffect } from "react";
import uuid from "react-uuid";

import MoneyInput from "../../../components/refresh/MoneyInput";

type Claim = {
  claimType: string | null;
  dateOfLoss: string | undefined;
  amountPaid: number | null;
  id: string;
};

type ClaimFormProps = {
  onSubmit: (claim: Claim) => null | void;
};

function ClaimForm(props: ClaimFormProps) {
  const { carLanguage } = useContext(CarLanguageContext);
  const [claimType, setClaimType] = useState<string>('');
  const [date, setDate] = useState<Date | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [valid, setValid] = useState<boolean>(false);
  const [dob, setDob] = useState<Date | null>(null);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [chosenMonth, setChosenMonth] = useState<string>('');
  const [chosenDay, setChosenDay] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [month, setMonth] = useState<null | string>(null);
  const [monthNumber, setMonthNumber] = useState<null | string>(null);
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [yearList, setYearList] = useState<string[]>([]);
  const [dayList, setDayList] = useState<string[]>([]);

  useEffect(() => {
    const years = [];
    const days = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1900; i < currentYear + 1; i++) {
      years.push(i.toString());
    }
    setYearList(years.reverse());

    for (let i = 1; i < 32; i++) {
      if (i <= 9) {
        days.push(`0${i}`);
      } else {
        days.push(i.toString());
      }
    }
    setDayList(days);
  }, []);

  useEffect(() => {
    setValid(true);
    if (year === "" || day === "" || month === null) {
      setValid(false);
    }
  }, [day, month, year]);
  
  const months = [
  carTexts[carLanguage].january,
  carTexts[carLanguage].february,
  carTexts[carLanguage].march,
  carTexts[carLanguage].april,
  carTexts[carLanguage].may,
  carTexts[carLanguage].june,
  carTexts[carLanguage].july,
  carTexts[carLanguage].august,
  carTexts[carLanguage].september,
  carTexts[carLanguage].october,
  carTexts[carLanguage].november,
  carTexts[carLanguage].december,]

  function handleMonth(month: string) {
    setMonth(month);
    const list = [
      carTexts[carLanguage].january,
      carTexts[carLanguage].february,
      carTexts[carLanguage].march,
      carTexts[carLanguage].april,
      carTexts[carLanguage].may,
      carTexts[carLanguage].june,
      carTexts[carLanguage].july,
      carTexts[carLanguage].august,
      carTexts[carLanguage].september,
      carTexts[carLanguage].october,
      carTexts[carLanguage].november,
      carTexts[carLanguage].december,
    ];

    for (let i = 0; i < list.length; i++) {
      if (month === list[i]) {
        if (i < 9) {
          setMonthNumber(`0${i + 1}`);
        } else {
          setMonthNumber(`${i + 1}`);
        }
      }
    }
  }

  
let dateString = ''

useEffect(() => {
  handleMonth(chosenMonth)
  dateString = `${chosenYear}-${monthNumber}-${chosenDay}`
  const dateObject = new Date(dateString)

  function isValidDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  if (isValidDate(dateObject) && dateString.length === 10) {
    if (dateObject.toISOString().slice(0, 10) === dateString) {
      setDate(dateObject)
      setDateError(false)
    } else {
      setDateError(true)
      setDob(null)
    }
  } else {
    setDob(null)
  }


}, [chosenDay, chosenMonth, chosenYear])


  const claimOptions = [
    carTexts[carLanguage].hail,
            carTexts[carLanguage].vandalism,
            carTexts[carLanguage].fire,
            carTexts[carLanguage].theft,
            carTexts[carLanguage].glassBreakage,
            carTexts[carLanguage].glassRepair,
            carTexts[carLanguage].nonResponsibleCollision,
            carTexts[carLanguage].liabilityAndCollision,
            carTexts[carLanguage].collisionClaim,
            carTexts[carLanguage].liabilityClaim,
            carTexts[carLanguage].theftEntireVehicle,
            carTexts[carLanguage].minorCollision,
  ]

  useEffect(() => {
    if (claimType === carTexts["fr"].hail) {
      setClaimType(carTexts["en"].hail)
    }
    if (claimType === carTexts["fr"].vandalism) {
      setClaimType(carTexts["en"].vandalism)
    }
    if (claimType === carTexts["fr"].fire) {
      setClaimType(carTexts["en"].fire)
    }
    if (claimType === carTexts["fr"].theft) {
      setClaimType(carTexts["en"].theft)
    }
    if (claimType === carTexts["fr"].glassBreakage) {
      setClaimType(carTexts["en"].glassBreakage)
    }
    if (claimType === carTexts["fr"].glassRepair) {
      setClaimType(carTexts["en"].glassRepair)
    }
    if (claimType === carTexts["fr"].nonResponsibleCollision) {
      setClaimType(carTexts["en"].nonResponsibleCollision)
    }
    if (claimType === carTexts["fr"].liabilityAndCollision) {
      setClaimType(carTexts["en"].liabilityAndCollision)
    }
    if (claimType === carTexts["fr"].collisionClaim) {
      setClaimType(carTexts["en"].collisionClaim)
    }
    if (claimType === carTexts["fr"].liabilityClaim) {
      setClaimType(carTexts["en"].liabilityClaim)
    }
    if (claimType === carTexts["fr"].theftEntireVehicle) {
      setClaimType(carTexts["en"].theftEntireVehicle)
    }
    if (claimType === carTexts["fr"].minorCollision) {
      setClaimType(carTexts["en"].minorCollision)
    }
  }, [claimType])

  return (
    <div className="t-content-container" style={{ marginLeft: '0%' }} >
          <p className="dropdown-title">{carTexts[carLanguage].typeOfClaim}</p>
          <DropdownMenu type={'claim'} list={claimOptions} setState={setClaimType} placeholder={carTexts[carLanguage].select} />
          <p className="dropdown-title">{carTexts[carLanguage].dateOfLoss}</p>
          <div className="date-container">
            <div className="input-container">
          <DropdownMenu list={yearList} setState={setChosenYear} placeholder={carTexts[carLanguage].year}/>
            </div>
            <div className="input-container">
          <DropdownMenu type={'month'} list={months} setState={setChosenMonth} placeholder={carTexts[carLanguage].month}/>
            </div>
            <div className="input-container">
          <DropdownMenu dateError={dateError} list={dayList} setState={setChosenDay} placeholder={carTexts[carLanguage].day}/>
            </div>
          </div>
          {dateError && <div className="modal-error-msg">{carTexts[carLanguage].dateError}</div>}
        <p className="dropdown-title">{carTexts[carLanguage].amountPaid}</p>
          <MoneyInput 
          placeholder='$2000'
          setParsedState={setAmount}
          type="number"
          maxLength={7}
          />
      <button
        onClick={() => {
          props.onSubmit({
            claimType: claimType,
            dateOfLoss: date?.toLocaleDateString("sv").replaceAll("-", ""),
            amountPaid: amount,
            id: uuid(),
          });
        }}
        className="btn form"
        disabled={
          claimType === '' ||
          date === null ||
          !amount ||
          isNaN(amount)
        }
      >
        {carTexts[carLanguage].add}
      </button>
    </div>
  );
}

export default ClaimForm;
