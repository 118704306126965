import React from "react";
type ProgressIconProps = {
  progress: "active" | "inactive" | "done";
};
function MyPolicyIcon(props: ProgressIconProps) {
  return (
    <span className="progress-icons">
      {props.progress === "active" ? (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.56477 7.41417L8.63675 4.10567C8.69731 4.04059 8.7692 3.98896 8.84832 3.95374C8.92744 3.91852 9.01224 3.90039 9.09788 3.90039C9.18351 3.90039 9.26831 3.91852 9.34743 3.95374C9.42655 3.98896 9.49844 4.04059 9.559 4.10567C9.61955 4.17075 9.66759 4.24801 9.70036 4.33304C9.73313 4.41807 9.75 4.50921 9.75 4.60124C9.75 4.69328 9.73313 4.78442 9.70036 4.86945C9.66759 4.95448 9.61955 5.03174 9.559 5.09682L6.02589 8.89393C5.96551 8.95935 5.89368 9.01127 5.81454 9.04671C5.73539 9.08215 5.6505 9.10039 5.56477 9.10039C5.47903 9.10039 5.39414 9.08215 5.315 9.04671C5.23585 9.01127 5.16402 8.95935 5.10364 8.89393L3.441 7.10705C3.38045 7.04197 3.33241 6.96471 3.29964 6.87968C3.26687 6.79465 3.25 6.70351 3.25 6.61147C3.25 6.4256 3.31871 6.24733 3.441 6.1159C3.5633 5.98446 3.72917 5.91062 3.90213 5.91062C4.07508 5.91062 4.24095 5.98446 4.36325 6.1159L5.56477 7.41417Z"
            fill="var(--progress)"
          />
          <path
            d="M0 6.5C0 7.78558 0.381218 9.04229 1.09545 10.1112C1.80968 11.1801 2.82484 12.0132 4.01256 12.5052C5.20028 12.9972 6.50721 13.1259 7.76809 12.8751C9.02896 12.6243 10.1872 12.0052 11.0962 11.0962C12.0052 10.1872 12.6243 9.02896 12.8751 7.76809C13.1259 6.50721 12.9972 5.20028 12.5052 4.01256C12.0132 2.82484 11.1801 1.80968 10.1112 1.09545C9.04229 0.381218 7.78558 0 6.5 0C5.64641 0 4.80117 0.168128 4.01256 0.494783C3.22394 0.821439 2.50739 1.30023 1.90381 1.90381C0.68482 3.12279 0 4.77609 0 6.5ZM11.7 6.5C11.7 7.52846 11.395 8.53383 10.8236 9.38897C10.2523 10.2441 9.44013 10.9106 8.48995 11.3042C7.53978 11.6978 6.49423 11.8007 5.48553 11.6001C4.47683 11.3994 3.55028 10.9042 2.82305 10.177C2.09581 9.44972 1.60056 8.52317 1.39992 7.51447C1.19927 6.50577 1.30225 5.46022 1.69583 4.51005C2.0894 3.55987 2.7559 2.74774 3.61104 2.17636C4.46617 1.60497 5.47154 1.3 6.5 1.3C7.87913 1.3 9.20177 1.84786 10.177 2.82305C11.1521 3.79823 11.7 5.12087 11.7 6.5Z"
            fill="var(--progress)"
          />
        </svg>
      ) : props.progress === "done" ? (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.56477 7.41417L8.63675 4.10567C8.69731 4.04059 8.7692 3.98896 8.84832 3.95374C8.92744 3.91852 9.01224 3.90039 9.09788 3.90039C9.18351 3.90039 9.26831 3.91852 9.34743 3.95374C9.42655 3.98896 9.49844 4.04059 9.559 4.10567C9.61955 4.17075 9.66759 4.24801 9.70036 4.33304C9.73313 4.41807 9.75 4.50921 9.75 4.60124C9.75 4.69328 9.73313 4.78442 9.70036 4.86945C9.66759 4.95448 9.61955 5.03174 9.559 5.09682L6.02589 8.89393C5.96551 8.95935 5.89368 9.01127 5.81454 9.04671C5.73539 9.08215 5.6505 9.10039 5.56477 9.10039C5.47903 9.10039 5.39414 9.08215 5.315 9.04671C5.23585 9.01127 5.16402 8.95935 5.10364 8.89393L3.441 7.10705C3.38045 7.04197 3.33241 6.96471 3.29964 6.87968C3.26687 6.79465 3.25 6.70351 3.25 6.61147C3.25 6.4256 3.31871 6.24733 3.441 6.1159C3.5633 5.98446 3.72917 5.91062 3.90213 5.91062C4.07508 5.91062 4.24095 5.98446 4.36325 6.1159L5.56477 7.41417Z"
            fill="var(--progress)"
          />
          <path
            d="M0 6.5C0 7.78558 0.381218 9.04229 1.09545 10.1112C1.80968 11.1801 2.82484 12.0132 4.01256 12.5052C5.20028 12.9972 6.50721 13.1259 7.76809 12.8751C9.02896 12.6243 10.1872 12.0052 11.0962 11.0962C12.0052 10.1872 12.6243 9.02896 12.8751 7.76809C13.1259 6.50721 12.9972 5.20028 12.5052 4.01256C12.0132 2.82484 11.1801 1.80968 10.1112 1.09545C9.04229 0.381218 7.78558 0 6.5 0C5.64641 0 4.80117 0.168128 4.01256 0.494783C3.22394 0.821439 2.50739 1.30023 1.90381 1.90381C0.68482 3.12279 0 4.77609 0 6.5ZM11.7 6.5C11.7 7.52846 11.395 8.53383 10.8236 9.38897C10.2523 10.2441 9.44013 10.9106 8.48995 11.3042C7.53978 11.6978 6.49423 11.8007 5.48553 11.6001C4.47683 11.3994 3.55028 10.9042 2.82305 10.177C2.09581 9.44972 1.60056 8.52317 1.39992 7.51447C1.19927 6.50577 1.30225 5.46022 1.69583 4.51005C2.0894 3.55987 2.7559 2.74774 3.61104 2.17636C4.46617 1.60497 5.47154 1.3 6.5 1.3C7.87913 1.3 9.20177 1.84786 10.177 2.82305C11.1521 3.79823 11.7 5.12087 11.7 6.5Z"
            fill="var(--progress)"
          />
        </svg>
      ) : (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.56477 7.41417L8.63675 4.10567C8.69731 4.04059 8.7692 3.98896 8.84832 3.95374C8.92744 3.91852 9.01224 3.90039 9.09788 3.90039C9.18351 3.90039 9.26831 3.91852 9.34743 3.95374C9.42655 3.98896 9.49844 4.04059 9.559 4.10567C9.61955 4.17075 9.66759 4.24801 9.70036 4.33304C9.73313 4.41807 9.75 4.50921 9.75 4.60124C9.75 4.69328 9.73313 4.78442 9.70036 4.86945C9.66759 4.95448 9.61955 5.03174 9.559 5.09682L6.02589 8.89393C5.96551 8.95935 5.89368 9.01127 5.81454 9.04671C5.73539 9.08215 5.6505 9.10039 5.56477 9.10039C5.47903 9.10039 5.39414 9.08215 5.315 9.04671C5.23585 9.01127 5.16402 8.95935 5.10364 8.89393L3.441 7.10705C3.38045 7.04197 3.33241 6.96471 3.29964 6.87968C3.26687 6.79465 3.25 6.70351 3.25 6.61147C3.25 6.4256 3.31871 6.24733 3.441 6.1159C3.5633 5.98446 3.72917 5.91062 3.90213 5.91062C4.07508 5.91062 4.24095 5.98446 4.36325 6.1159L5.56477 7.41417Z"
            fill="#AAAABB"
          />
          <path
            d="M0 6.5C0 7.78558 0.381218 9.04229 1.09545 10.1112C1.80968 11.1801 2.82484 12.0132 4.01256 12.5052C5.20028 12.9972 6.50721 13.1259 7.76809 12.8751C9.02896 12.6243 10.1872 12.0052 11.0962 11.0962C12.0052 10.1872 12.6243 9.02896 12.8751 7.76809C13.1259 6.50721 12.9972 5.20028 12.5052 4.01256C12.0132 2.82484 11.1801 1.80968 10.1112 1.09545C9.04229 0.381218 7.78558 0 6.5 0C5.64641 0 4.80117 0.168128 4.01256 0.494783C3.22394 0.821439 2.50739 1.30023 1.90381 1.90381C0.68482 3.12279 0 4.77609 0 6.5ZM11.7 6.5C11.7 7.52846 11.395 8.53383 10.8236 9.38897C10.2523 10.2441 9.44013 10.9106 8.48995 11.3042C7.53978 11.6978 6.49423 11.8007 5.48553 11.6001C4.47683 11.3994 3.55028 10.9042 2.82305 10.177C2.09581 9.44972 1.60056 8.52317 1.39992 7.51447C1.19927 6.50577 1.30225 5.46022 1.69583 4.51005C2.0894 3.55987 2.7559 2.74774 3.61104 2.17636C4.46617 1.60497 5.47154 1.3 6.5 1.3C7.87913 1.3 9.20177 1.84786 10.177 2.82305C11.1521 3.79823 11.7 5.12087 11.7 6.5Z"
            fill="#AAAABB"
          />
        </svg>
      )}
    </span>
  );
}

export default MyPolicyIcon;
