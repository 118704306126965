import { useContext, useState, useEffect } from "react";
import Footer from "../../../components/footer/Footer";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../HomeContext";
import DropdownMenu from "../../../components/refresh/DropdownMenu";


function StepSix() {
  const { language } = useContext(LanguageContext);
  const [month, setMonth] = useState<null | string>(null);
  const [monthNumber, setMonthNumber] = useState<null | string>(null);
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [yearList, setYearList] = useState<string[]>([]);
  const [dayList, setDayList] = useState<string[]>([]);
  const LSDOB = useLocalStorage("dob");
  const [valid, setValid] = useState<boolean>(false);

  const [dob, setDob] = useState<Date | null>(null);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [chosenMonth, setChosenMonth] = useState<string>('');
  const [chosenDay, setChosenDay] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [yearError, setYearError] = useState<boolean>(false)
  const [dayNumber, setDayNumber] = useState<number>(32)

  useEffect(() => {
    if (LSDOB) {
      let year = LSDOB.substring(0, 4);
      let month = LSDOB.substring(4, 6);
      let day = LSDOB.substring(6, 8);
      setChosenYear(year);
      setChosenDay(day);
      handleReverseMonth(month);
    }
  }, [LSDOB]);

  useEffect(() => {

    const isLeap = (numberYear: number): boolean => {
      return new Date(numberYear, 1, 29).getDate() === 29;
    };
  
    if (chosenMonth) {
      if (chosenMonth === 'January' || chosenMonth === 'March' || chosenMonth === 'May' || chosenMonth === 'July' || chosenMonth === 'August' || chosenMonth === 'October' || chosenMonth === 'December'
      || chosenMonth === 'Janvier' || chosenMonth === 'Mars' || chosenMonth === 'Mai' || chosenMonth === 'Juillet' || chosenMonth === 'Août' || chosenMonth === 'Octobre' || chosenMonth === 'Décembre') {
        setDayNumber(32)
      }
      else if (chosenMonth === 'April' || chosenMonth === 'June' || chosenMonth === 'September' || chosenMonth === 'November'
      || chosenMonth === 'Avril' || chosenMonth === 'Juin' || chosenMonth === 'Septembre' || chosenMonth === 'Novembre') {
        setDayNumber(31)
      }
      else if (chosenMonth === 'February' || chosenMonth === 'Février') {
        if (isLeap(Number(chosenYear))) {
          setDayNumber(30)
        } else {
        setDayNumber(29)
      }
      }
      else {
        setDayNumber(32)
      }
    }
  }, [chosenMonth, chosenYear]);

  useEffect(() => {
    const years = [];
    const days = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1935; i < currentYear + 1; i++) {
      years.push(i.toString());
    }
    setYearList(years);

    for (let i = 1; i < dayNumber; i++) {
      if (i <= 9) {
        days.push(`0${i}`);
      } else {
        days.push(i.toString());
      }
    }
    setDayList(days);
  }, [dayNumber]);

  useEffect(() => {
    setValid(true);
    if (year === "" || day === "" || month === null) {
      setValid(false);
    }
  }, [day, month, year]);

  function handleMonth(month: string) {
    setMonth(month);
    const list = [
      answers[language].january,
      answers[language].february,
      answers[language].march,
      answers[language].april,
      answers[language].may,
      answers[language].june,
      answers[language].july,
      answers[language].august,
      answers[language].september,
      answers[language].october,
      answers[language].november,
      answers[language].december,
    ];

    for (let i = 0; i < list.length; i++) {
      if (month === list[i]) {
        if (i < 10) {
          setMonthNumber(`0${i + 1}`);
        } else {
          setMonthNumber(`${i + 1}`);
        }
      }
    }
  }
  
  const months = [
    answers[language].january,
    answers[language].february,
    answers[language].march,
    answers[language].april,
    answers[language].may,
    answers[language].june,
    answers[language].july,
    answers[language].august,
    answers[language].september,
    answers[language].october,
    answers[language].november,
    answers[language].december,]

  let dateString = ''

  useEffect(() => {
    handleMonth(chosenMonth)
    dateString = `${chosenYear}/${monthNumber}/${chosenDay}`
    const dateObject = new Date(dateString)
  
    function isValidDate(date: Date): boolean {
      return !isNaN(date.getTime());
    }
  
    if (isValidDate(dateObject) && dateString.length === 10) {
      if (dateObject.toISOString().slice(0, 10).replace(/-/g, '/') === dateString) {
        setDob(dateObject)
        setDateError(false)
      } else {
        setDateError(true)
        setDob(null)
      }
    } else {
      setDob(null)
    }
  
  
  }, [chosenDay, chosenMonth, chosenYear])

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    if (currentYear - Number(chosenYear) < 15) {
      setYearError(true)
    } else {
      setYearError(false)
    }
  }, [chosenYear])

  function handleReverseMonth(monthNumber: number) {
    const list = [
      answers[language].january,
      answers[language].february,
      answers[language].march,
      answers[language].april,
      answers[language].may,
      answers[language].june,
      answers[language].july,
      answers[language].august,
      answers[language].september,
      answers[language].october,
      answers[language].november,
      answers[language].december,
    ];

    
    const selectedMonth = list[monthNumber - 1];
    setMonth(selectedMonth);
    handleMonth(selectedMonth);
  }

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={texts[language].oldestApplicantDOB} />
      <div className="date-container">
        <div className="input-container">
      <DropdownMenu dateError={yearError} list={yearList} setState={setChosenYear} placeholder={texts[language].year} lsValue={'homeBirthYear'}/>
      </div>
        <div className="input-container">
      <DropdownMenu type={'month'} list={months} setState={setChosenMonth} placeholder={texts[language].month} lsValue={'homeBirthMonth'}/>
      </div>
        <div className="input-container">
      <DropdownMenu dateError={dateError} list={dayList} setState={setChosenDay} placeholder={texts[language].day} lsValue={'homeBirthDay'}/>
      </div>
      </div>
      {dateError && <div className="modal-error-msg">{texts[language].dateError}</div>}
      {yearError && <div className="modal-error-msg">{texts[language].yearError}</div>}

      <Footer
        type="submit"
        data={{           dob: dob
          ?.toLocaleDateString("sv")
          .replaceAll("-", ""),
          homeBirthYear: chosenYear,
          homeBirthMonth: chosenMonth,
          homeBirthDay: chosenDay,
        }}
        formSteps={7}
        backFormSteps={5}
        disabled={dob === null ? true : false || yearError}
      />
    </div>
    </div>
  );
}

export default StepSix;
