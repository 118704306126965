import { useContext, useEffect, useState } from "react";
import Question from "../../../components/questions/Question";
import { texts } from "../../../text";
import {
  LanguageContext,
  MyHomeContext,
  HomeTypeContext,
} from "../HomeContext";
import Footer from "../../../components/footer/Footer";
import axios from "axios";
import FailedQuote from "../../../components/FailedQuote/FailedQuote";
import NextSteps from "../../../components/refresh/NextSteps";
import RadioLargeQuote from "../../../components/refresh/RadioLargeQuote";
import KbdLogo from "../../../components/refresh/assets/KbdLogo";

function StepFourteen() {
  const [loading, setLoading] = useState<boolean>(true);
  const { language } = useContext(LanguageContext);
  const { homeFormData } = useContext(MyHomeContext);
  const { homeType } = useContext(HomeTypeContext);
  const [quote, setQuote] = useState<number>(0);
  const [failed, setFailed] = useState<boolean | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    axios
      .post("https://shrouded-thicket-97880.herokuapp.com/home-form", {
        data: homeFormData,
        type: homeType,
      })
      .then((response) => {
        setQuote(parseInt(response.data));
        if (!response.data || response.data === 0) {
          setFailed(true);
        } else {
          setFailed(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setFailed(true);
      });

    localStorage.clear();
  }, []);

    if (loading) {
      return <div className="t-container"><div className="t-content-container"><div className="loading-state"><KbdLogo /></div></div></div>;
    } else if (failed) {
      return (
        <div>
          <FailedQuote />
        </div>
      );
    } else {
      return (
        <div className="t-container">
          <div className="t-content-container">
          <Question question={texts[language].yourQuote} />
          <RadioLargeQuote quotePrice={quote.toFixed(2)} perMonth={texts[language].quotePrice} language={language} />
        <a href="tel:4388434814">
        <div className="quote-buy-button">
          {texts[language].buy}
        </div>
        </a>
        <NextSteps quoteNextStep={texts[language].quoteNextStep} quoteCall={texts[language].quoteCall} quoteNumber={texts[language].quoteNumber} review={texts[language].review} />

          <Footer newQuote backHidden nextHidden />
        </div>
        </div>
      );
    }
  }
// }

export default StepFourteen;
