import { useState, useContext, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import AverageRadioButtons from "../../../components/refresh/AverageRadioButtons";
import MoneyInput from "../../../components/refresh/MoneyInput";

function CarStepSix() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [purchaseType, setPurchaseType] = useState<string>("");
  const [primaryCarPurchasePrice, setPrimaryCarPurchasePrice] =
    useState<number>(0);
  const LSPurchaseType = useLocalStorage("purchaseType");
  const LSprimaryCarPurchasePrice = useLocalStorage("primaryCarPurchasePrice");

  useEffect(() => {
    if (LSPurchaseType && LSprimaryCarPurchasePrice) {
      setPurchaseType(LSPurchaseType);
      setPrimaryCarPurchasePrice(LSprimaryCarPurchasePrice);
    }
  }, [LSPurchaseType, LSprimaryCarPurchasePrice]);

  useEffect(() => {
    if (purchaseType === carTexts["fr"].financed) {
      setPurchaseType(carTexts["en"].financed)
    }
    if (purchaseType === carTexts["fr"].leased) {
      setPurchaseType(carTexts["en"].leased)
    }
    if (purchaseType === carTexts["fr"].ownedOutright) {
      setPurchaseType(carTexts["en"].ownedOutright)
    }
  }, [purchaseType])


  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].purchaseType} />
      
      <AverageRadioButtons multiple={true} setState={setPurchaseType} value={carTexts[carLanguage].financed} label={carTexts[carLanguage].financed} checked={purchaseType === carTexts["en"].financed}/>

      <AverageRadioButtons multiple={true} setState={setPurchaseType} value={carTexts[carLanguage].leased} label={carTexts[carLanguage].leased} checked={purchaseType === carTexts["en"].leased}/>

      <AverageRadioButtons multiple={true} setState={setPurchaseType} value={carTexts[carLanguage].ownedOutright} label={carTexts[carLanguage].ownedOutright} checked={purchaseType === carTexts["en"].ownedOutright}/>
      


      {/* {purchaseType !== "" ? (
        <>
        <p className="dropdown-title">{carTexts[carLanguage].carPrice}</p>
        <MoneyInput 
        placeholder='$'
        setState={setPrimaryCarPurchasePrice}
        type="number"
        maxLength={10}
        lsValue={primaryCarPurchasePrice}
        />
        </>
      ) : null} */}

      <CarFooter
        data={{
          purchaseType: purchaseType,
          primaryCarPurchasePrice: 30000,
        }}
        formSteps={7}
        type="submit"
        disabled={!purchaseType}
        backFormSteps={5}
      />
    </div>
  </div>
  );
}

export default CarStepSix;
