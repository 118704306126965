const ThumbsDown = () => {
  return (
    <>
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25.5" cy="25.5" r="25.5" fill="#ECECE9"/>
<path d="M28.2422 35.7148C29.2578 35.5117 29.918 34.5234 29.7148 33.5078L29.625 33.0625C29.418 32.0195 29.0352 31.0273 28.5 30.125H34.125C35.1602 30.125 36 29.2852 36 28.25C36 27.5273 35.5898 26.8984 34.9883 26.5859C35.4141 26.2422 35.6875 25.7148 35.6875 25.125C35.6875 24.2109 35.0312 23.4492 34.168 23.2852C34.3398 23 34.4375 22.668 34.4375 22.3125C34.4375 21.4805 33.8945 20.7734 33.1445 20.5312C33.1719 20.4023 33.1875 20.2656 33.1875 20.125C33.1875 19.0898 32.3477 18.25 31.3125 18.25H27.5039C26.7617 18.25 26.0391 18.4687 25.4219 18.8789L23.918 19.8828C22.875 20.5781 22.25 21.75 22.25 23.0039V24.5V26.375V27.3477C22.25 28.4883 22.7695 29.5625 23.6562 30.2773L23.9453 30.5078C24.9805 31.3359 25.6875 32.5 25.9453 33.7969L26.0352 34.2422C26.2383 35.2578 27.2266 35.918 28.2422 35.7148ZM17.25 32H19.75C20.4414 32 21 31.4414 21 30.75V22C21 21.3086 20.4414 20.75 19.75 20.75H17.25C16.5586 20.75 16 21.3086 16 22V30.75C16 31.4414 16.5586 32 17.25 32Z" fill="#0D172E"/>
</svg>
    </>
  )
}

export default ThumbsDown