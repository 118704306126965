import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./Homepage";
import { HomeProvider } from "./quotes/shared/HomeContext";
import { CarProvider } from "./quotes/car/CarContext";
import HomeQuoter from "./quotes/shared/HomeQuoter";
import CarQuoter from "./quotes/car/CarQuoter";
import AdminHome from "./admin/homeAdmin/AdminHome";
import AdminCar from "./admin/carAdmin/AdminCar";
import { ThemeProvider } from "./components/refresh/ThemeContext";

function App() {
  return (
    <Router>
      <HomeProvider>
        <ThemeProvider>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/home/en" element={<HomeQuoter />} />
              <Route path="/home/fr" element={<HomeQuoter />} />
              <Route path="/home" element={<HomeQuoter />} />
            </Routes>
        </ThemeProvider>
      </HomeProvider>
      <CarProvider>
        <ThemeProvider>
            <Routes>
              <Route path="/car" element={<CarQuoter />} />
              <Route path="/car/en" element={<CarQuoter />} />
              <Route path="/car/fr" element={<CarQuoter />} />
            </Routes>
          </ThemeProvider>
      </CarProvider>
      <Routes>
        <Route path="/get-home-submissions" element={<AdminHome />} />
        <Route path="/get-car-submissions" element={<AdminCar />} />
      </Routes>
    </Router>
  );
}

export default App;
