import React from "react";

function MaleImage() {
  return (
    <div className="radio-images-circular-div">
      <svg
        width="51"
        height="51"
        viewBox="0 0 51 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25.5" cy="25.5" r="25.5" fill="#F7F7F7" />
        <path
          d="M29.7502 27.7951C29.7502 31.4098 26.8199 34.3401 23.2053 34.3401C19.5906 34.3401 16.6603 31.4098 16.6603 27.7951C16.6603 24.1804 19.5906 21.2501 23.2053 21.2501C26.8199 21.2501 29.7502 24.1804 29.7502 27.7951ZM23.2053 36.2504C27.875 36.2504 31.6605 32.4648 31.6605 27.7951C31.6605 23.1254 27.875 19.3398 23.2053 19.3398C18.5355 19.3398 14.75 23.1254 14.75 27.7951C14.75 32.4648 18.5355 36.2504 23.2053 36.2504Z"
          fill="#37375B"
          stroke="#37375B"
          strokeWidth="0.5"
        />
        <path
          d="M35.1763 17.2029L35.3531 17.0262L35.1763 16.8494L34.1791 15.8522L34.0023 15.6754L33.8255 15.8522L27.7998 21.8779L27.623 22.0547L27.7998 22.2315L28.797 23.2287L28.9738 23.4055L29.1506 23.2287L35.1763 17.2029Z"
          fill="#37375B"
          stroke="#37375B"
          strokeWidth="0.5"
        />
        <path
          d="M35.0002 17.6603L35.2502 17.6603L35.2502 17.4103L35.2502 16L35.2502 15.75L35.0002 15.75L28.5898 15.75L28.3398 15.75L28.3398 16L28.3398 17.4103L28.3398 17.6603L28.5898 17.6603L35.0002 17.6603Z"
          fill="#37375B"
          stroke="#37375B"
          strokeWidth="0.5"
        />
        <path
          d="M33.3397 22.4104L33.3397 22.6604L33.5897 22.6604L35 22.6604L35.25 22.6604L35.25 22.4104L35.25 16L35.25 15.75L35 15.75L33.5897 15.75L33.3397 15.75L33.3397 16L33.3397 22.4104Z"
          fill="#37375B"
          stroke="#37375B"
          strokeWidth="0.5"
        />
      </svg>
    </div>
  );
}

export default MaleImage;
