import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/Footer";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import SubQuestions from "../../../components/questions/SubQuestions";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../../shared/HomeContext";
import { Autofill } from "../../shared/step4/StepFour";

function HomeOwnerStepFour(props: Autofill) {
  const { language } = useContext(LanguageContext);
  const [isValid, setIsValid] = useState(true);
  const [data, setData] = useState<any>({
    year: 1954,
    livingArea: 2047,
    exteriorFinish: props.exteriorFinish,
    constructionType: "Frame (Wood)",
    garageType: "Attached-Frame",
    basementPercentageFinished: 100,
    numOfStoreys: 2,
    yearRoofReplaced: '1950',
    distanceToFirehall: "8km",
    distanceToFireHydrant: "Within 300m",
    primaryHeatingType: "Electric",
    secondaryHeatingType: "None",
    buildingStyle: "Detached",
    numOfUnits: 1,
    replacementCost: props.replacementCost
  });
  const LSYear = useLocalStorage("year");
  const LSLivingArea = useLocalStorage("livingArea");
  const LSExteriorFInish = useLocalStorage("exteriorFinish");
  const LSCOnstruction = useLocalStorage("constructionType");
  const LSGarage = useLocalStorage("garageType");
  const LSBasement = useLocalStorage("basementPercentageFinished");
  const LSNumOFStoreys = useLocalStorage("numOfStoreys");
  const LSYearRoof = useLocalStorage("yearRoofReplaced");
  const LSFirehall = useLocalStorage("distanceToFirehall");
  const LSFireHydrant = useLocalStorage("distanceToFireHydrant");
  const LSPrimHeating = useLocalStorage("primaryHeatingType");
  const LSSecondHeating = useLocalStorage("secondaryHeatingType");
  const LSBuilding = useLocalStorage("buildingStyle");
  const LSUnits = useLocalStorage("numOfUnits");
  const LSreplacementCost = useLocalStorage("replacementCost");

  useEffect(() => {
    let basementAPI;
    if (props) {
      if (
        props.basementPercentageFinished &&
        props.basementPercentageFinished === "None"
      ) {
        basementAPI = 0;
      } else {
        basementAPI = 100;
      }
      setData({
        ...data,
        year: props.yearBuilt,
        livingArea: props.livingArea,
        garageType: props.garageType ? props.garageType : "Attached-Frame",
        basementPercentageFinished: basementAPI,
        numOfStoreys: props.numOfStoreys,
        exteriorFinish: props.exteriorFinish,
        yearRoofReplaced: props.yearRoofReplaced,
        replacementCost: props.replacementCost
      });
    }
  }, []);

useEffect(() => {
  console.log(data)
}, [data])

  useEffect(() => {
    if (LSYear) {
      setData({
        ...data,
        year: LSYear,
        livingArea: LSLivingArea,
        exteriorFinish: LSExteriorFInish,
        constructionType: LSCOnstruction,
        garageType: LSGarage,
        basementPercentageFinished: LSBasement,
        numOfStoreys: LSNumOFStoreys,
        yearRoofReplaced: LSYearRoof,
        distanceToFirehall: LSFirehall,
        distanceToFireHydrant: LSFireHydrant,
        primaryHeatingType: LSPrimHeating,
        secondaryHeatingType: LSSecondHeating,
        buildingStyle: LSBuilding,
        numOfUnits: LSUnits,
        replacementCost: LSreplacementCost
      });
    }
  }, [
    LSBasement,
    LSBuilding,
    LSCOnstruction,
    LSExteriorFInish,
    LSFireHydrant,
    LSFirehall,
    LSGarage,
    LSLivingArea,
    LSNumOFStoreys,
    LSPrimHeating,
    LSSecondHeating,
    LSUnits,
    LSYear,
    LSYearRoof,
    LSreplacementCost
  ]);

  useEffect(() => {
    setIsValid(true);
    for (let key in data) {
      if (
        data[key] === "0" ||
        data[key] === 0 ||
        data[key] < 0 ||
        data[key] === "" ||
        data[key] === " " ||
        data[key] === " " ||
        data[key] === ""
      ) {
        setIsValid(false);
      }
    }
    const currentYear = new Date().getFullYear();

    if (data.year < 1800 || data.year > currentYear) {
      setIsValid(false);
    }

    if (data.yearRoofReplaced < 1800 || data.yearRoofReplaced > currentYear) {
      setIsValid(false);
    }
  }, [data, isValid]);

  useEffect(() => {
    //exteriorFinish
    if (data.exteriorFinish === answers["fr"].log) {
      setData({...data, exteriorFinish: answers["en"].log})
    }
    if (data.exteriorFinish === answers["fr"].monocoque) {
      setData({...data, exteriorFinish: answers["en"].monocoque})
    }
    if (data.exteriorFinish === answers["fr"].brick) {
      setData({...data, exteriorFinish: answers["en"].brick})
    }
    if (data.exteriorFinish === answers["fr"].cement) {
      setData({...data, exteriorFinish: answers["en"].cement})
    }
    if (data.exteriorFinish === answers["fr"].concreteBlock) {
      setData({...data, exteriorFinish: answers["en"].concreteBlock})
    }
    if (data.exteriorFinish === answers["fr"].frameWood) {
      setData({...data, exteriorFinish: answers["en"].frameWood})
    }
    if (data.exteriorFinish === answers["fr"].stone) {
      setData({...data, exteriorFinish: answers["en"].stone})
    }
    if (data.exteriorFinish === answers["fr"].brickVeneer) {
      setData({...data, exteriorFinish: answers["en"].brickVeneer})
    }
    if (data.exteriorFinish === answers["fr"].vinyl) {
      setData({...data, exteriorFinish: answers["en"].vinyl})
    }
    if (data.exteriorFinish === answers["fr"].other) {
      setData({...data, exteriorFinish: answers["en"].other})
    }
    //constructionType
    if (data.constructionType === answers["fr"].log) {
      setData({...data, constructionType: answers["en"].log})
    }
    if (data.constructionType === answers["fr"].monocoque) {
      setData({...data, constructionType: answers["en"].monocoque})
    }
    if (data.constructionType === answers["fr"].brick) {
      setData({...data, constructionType: answers["en"].brick})
    }
    if (data.constructionType === answers["fr"].cement) {
      setData({...data, constructionType: answers["en"].cement})
    }
    if (data.constructionType === answers["fr"].concreteBlock) {
      setData({...data, constructionType: answers["en"].concreteBlock})
    }
    if (data.constructionType === answers["fr"].frameWood) {
      setData({...data, constructionType: answers["en"].frameWood})
    }
    if (data.constructionType === answers["fr"].stone) {
      setData({...data, constructionType: answers["en"].stone})
    }
    if (data.constructionType === answers["fr"].brickVeneer) {
      setData({...data, constructionType: answers["en"].brickVeneer})
    }
    if (data.constructionType === answers["fr"].vinyl) {
      setData({...data, constructionType: answers["en"].vinyl})
    }
    if (data.constructionType === answers["fr"].other) {
      setData({...data, constructionType: answers["en"].other})
    }
    //garageType
    if (data.garageType === answers["fr"].attachedFrame) {
      setData({...data, garageType: answers["en"].attachedFrame})
    }
    if (data.garageType === answers["fr"].attachedMasonry) {
      setData({...data, garageType: answers["en"].attachedMasonry})
    }
    if (data.garageType === answers["fr"].attachedMasonryOrVeneer) {
      setData({...data, garageType: answers["en"].attachedMasonryOrVeneer})
    }
    if (data.garageType === answers["fr"].basement) {
      setData({...data, garageType: answers["en"].basement})
    }
    if (data.garageType === answers["fr"].basementUnderGarage) {
      setData({...data, garageType: answers["en"].basementUnderGarage})
    }
    if (data.garageType === answers["fr"].builtIn) {
      setData({...data, garageType: answers["en"].builtIn})
    }
    if (data.garageType === answers["fr"].carport) {
      setData({...data, garageType: answers["en"].carport})
    }
    if (data.garageType === answers["fr"].carportStorage) {
      setData({...data, garageType: answers["en"].carportStorage})
    }
    if (data.garageType === answers["fr"].detachedMasonry) {
      setData({...data, garageType: answers["en"].detachedMasonry})
    }
    if (data.garageType === answers["fr"].detachedFrame) {
      setData({...data, garageType: answers["en"].detachedFrame})
    }
    if (data.garageType === answers["fr"].detachedMasonryOrVeneer) {
      setData({...data, garageType: answers["en"].detachedMasonryOrVeneer})
    }
    if (data.garageType === answers["fr"].other) {
      setData({...data, garageType: answers["en"].other})
    }
    if (data.garageType === answers["fr"].none) {
      setData({...data, garageType: answers["en"].none})
    }
    //distanceToFireHall
    if (data.distanceToFirehall === answers["fr"].fiveKm) {
      setData({...data, distanceToFirehall: answers["en"].fiveKm})
    }
    if (data.distanceToFirehall === answers["fr"].eightKm) {
      setData({...data, distanceToFirehall: answers["en"].eightKm})
    }
    if (data.distanceToFirehall === answers["fr"].overEightKm) {
      setData({...data, distanceToFirehall: answers["en"].overEightKm})
    }
    //distanceToFireHydrant
    if (data.distanceToFireHydrant === answers["fr"].withinThreeHunderdM) {
      setData({...data, distanceToFireHydrant: answers["en"].withinThreeHundredM})
    }
    if (data.distanceToFireHydrant === answers["fr"].overThreeHunderedM) {
      setData({...data, distanceToFireHydrant: answers["en"].overThreeHunderedM})
    }
    //primaryHeatingType
    if (data.primaryHeatingType === answers["fr"].centralFurnaceGas) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnaceGas})
    }
    if (data.primaryHeatingType === answers["fr"].centralFurnaceOil) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnaceOil})
    }
    if (data.primaryHeatingType === answers["fr"].centralFurnacePropane) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnacePropane})
    }
    if (data.primaryHeatingType === answers["fr"].centralFurnaceWood) {
      setData({...data, primaryHeatingType: answers["en"].centralFurnaceWood})
    }
    if (data.primaryHeatingType === answers["fr"].electric) {
      setData({...data, primaryHeatingType: answers["en"].electric})
    }
    if (data.primaryHeatingType === answers["fr"].hotWaterSteam) {
      setData({...data, primaryHeatingType: answers["en"].hotWaterSteam})
    }
    if (data.primaryHeatingType === answers["fr"].combinationFurnace) {
      setData({...data, primaryHeatingType: answers["en"].combinationFurnace})
    }
    if (data.primaryHeatingType === answers["fr"].spaceHeaterGas) {
      setData({...data, primaryHeatingType: answers["en"].spaceHeaterGas})
    }
    if (data.primaryHeatingType === answers["fr"].spaceHeaterOil) {
      setData({...data, primaryHeatingType: answers["en"].spaceHeaterOil})
    }
    if (data.primaryHeatingType === answers["fr"].spaceHeaterElectric) {
      setData({...data, primaryHeatingType: answers["en"].spaceHeaterElectric})
    }
    if (data.primaryHeatingType === answers["fr"].woodBurningStove) {
      setData({...data, primaryHeatingType: answers["en"].woodBurningStove})
    }
    if (data.primaryHeatingType === answers["fr"].fireplace) {
      setData({...data, primaryHeatingType: answers["en"].fireplace})
    }
    if (data.primaryHeatingType === answers["fr"].solar) {
      setData({...data, primaryHeatingType: answers["en"].solar})
    }
    if (data.primaryHeatingType === answers["fr"].other) {
      setData({...data, primaryHeatingType: answers["en"].other})
    }
    if (data.primaryHeatingType === answers["fr"].none) {
      setData({...data, primaryHeatingType: answers["en"].none})
    }
    //secondaryHeatingType
    if (data.secondaryHeatingType === answers["fr"].centralFurnaceGas) {
      setData({...data, secondaryHeatingType: answers["en"].centralFurnaceGas})
    }
    if (data.secondaryHeatingType === answers["fr"].centralFurnaceOil) {
      setData({...data, secondaryHeatingType: answers["en"].centralFurnaceOil})
    }
    if (data.secondaryHeatingType === answers["fr"].centralFurnacePropane) {
      setData({...data, secondaryHeatingType: answers["en"].centralFurnacePropane})
    }
    if (data.secondaryHeatingType === answers["fr"].centralFurnaceWood) {
      setData({...data, secondaryHeatingType: answers["en"].centralFurnaceWood})
    }
    if (data.secondaryHeatingType === answers["fr"].electric) {
      setData({...data, secondaryHeatingType: answers["en"].electric})
    }
    if (data.secondaryHeatingType === answers["fr"].hotWaterSteam) {
      setData({...data, secondaryHeatingType: answers["en"].hotWaterSteam})
    }
    if (data.secondaryHeatingType === answers["fr"].combinationFurnace) {
      setData({...data, secondaryHeatingType: answers["en"].combinationFurnace})
    }
    if (data.secondaryHeatingType === answers["fr"].spaceHeaterGas) {
      setData({...data, secondaryHeatingType: answers["en"].spaceHeaterGas})
    }
    if (data.secondaryHeatingType === answers["fr"].spaceHeaterOil) {
      setData({...data, secondaryHeatingType: answers["en"].spaceHeaterOil})
    }
    if (data.secondaryHeatingType === answers["fr"].spaceHeaterElectric) {
      setData({...data, secondaryHeatingType: answers["en"].spaceHeaterElectric})
    }
    if (data.secondaryHeatingType === answers["fr"].woodBurningStove) {
      setData({...data, secondaryHeatingType: answers["en"].woodBurningStove})
    }
    if (data.secondaryHeatingType === answers["fr"].fireplace) {
      setData({...data, secondaryHeatingType: answers["en"].fireplace})
    }
    if (data.secondaryHeatingType === answers["fr"].solar) {
      setData({...data, secondaryHeatingType: answers["en"].solar})
    }
    if (data.secondaryHeatingType === answers["fr"].other) {
      setData({...data, secondaryHeatingType: answers["en"].other})
    }
    if (data.secondaryHeatingType === answers["fr"].none) {
      setData({...data, secondaryHeatingType: answers["en"].none})
    }
    //buildingStyle
    if (data.buildingStyle === answers["fr"].detached) {
      setData({...data, buildingStyle: answers["en"].detached})
    }
    if (data.buildingStyle === answers["fr"].semiDetached) {
      setData({...data, buildingStyle: answers["en"].semiDetached})
    }
    if (data.buildingStyle === answers["fr"].townHouse) {
      setData({...data, buildingStyle: answers["en"].townHouse})
    }
    if (data.buildingStyle === answers["fr"].rowHouse) {
      setData({...data, buildingStyle: answers["en"].rowHouse})
    }
    if (data.buildingStyle === answers["fr"].duplexSide) {
      setData({...data, buildingStyle: answers["en"].duplexSide})
    }
    if (data.buildingStyle === answers["fr"].duplexUpDown) {
      setData({...data, buildingStyle: answers["en"].duplexUpDown})
    }
    if (data.buildingStyle === answers["fr"].triplex) {
      setData({...data, buildingStyle: answers["en"].triplex})
    }
  }, [data])

  const prefilledMarkup = { __html: `<p>${texts[language].prefilledInfo}</p>`}

  return (
    <div className="t-container">
      <div className="t-content-container">
        <Question questionHtml={texts[language].prefilledInfo} />
        <SubQuestions
          question={texts[language].yearBuilt}
          type="number"
          placeholder={data.yearBuilt ? data.yearBuilt : '1950'}
          options={[]}
          maxLength={4}
          onChange={(value) => {
            setData({ ...data, year: value });
          }}
          inputSize="small"
          lsValue={data.year}
          error={data.year < 1800 ? true : false}
          initialValue={props.yearBuilt ? props.yearBuilt : '1950'}
        />
        <SubQuestions
          question={texts[language].totalLivingArea}
          type="number"
          placeholder={data.livingArea ? `${data.livingArea}` : '2047'}
          options={[]}
          onChange={(value) => {
            setData({ ...data, livingArea: value });
          }}
          inputSize="small"
          lsValue={data.livingArea}
          maxLength={7}
          initialValue={props.livingArea ? props.livingArea : 2049}
        />
        <SubQuestions
          question={texts[language].exteriorFinish}
          type="dropdown"
          nonCenter={true}
          dropdownType="exteriorFinish"
          placeholder={answers[language].brick}
          options={[
            answers[language].log,
            answers[language].monocoque,
            answers[language].brick,
            answers[language].cement,
            answers[language].concreteBlock,
            answers[language].frameWood,
            answers[language].stone,
            answers[language].brickVeneer,
            answers[language].vinyl,
            answers[language].other,
          ]}
          onChange={(value) => {
            setData({ ...data, exteriorFinish: value });
          }}
          inputSize="small"
          lsValue={'exteriorFinish'}
          initialValue={props.exteriorFinish}
        />
        <SubQuestions
          question={texts[language].constructionType}
          type="dropdown"
          dropdownType="construction"
          nonCenter={true}
          placeholder={answers[language].frameWood}
          options={[
            answers[language].log,
            answers[language].monocoque,
            answers[language].brick,
            answers[language].cement,
            answers[language].concreteBlock,
            answers[language].frameWood,
            answers[language].stone,
            answers[language].brickVeneer,
            answers[language].vinyl,
            answers[language].other,
          ]}
          onChange={(value) => {
            setData({ ...data, constructionType: value });
          }}
          inputSize="small"
          lsValue={'constructionType'}
          initialValue={answers[language].frameWood}
        />
        <SubQuestions
          question={texts[language].garageType}
          type="dropdown"
          dropdownType={'garage'}
          nonCenter={true}
          placeholder={answers[language].attachedFrame}
          options={[
            answers[language].attachedFrame,
            answers[language].attachedMasonry,
            answers[language].attachedMasonryOrVeneer,
            answers[language].basement,
            answers[language].basementUnderGarage,
            answers[language].builtIn,
            answers[language].carport,
            answers[language].carportStorage,
            answers[language].detachedMasonry,
            answers[language].detachedFrame,
            answers[language].detachedMasonryOrVeneer,
            answers[language].other,
            answers[language].none,
          ]}
          onChange={(value) => {
            setData({ ...data, garageType: value });
          }}
          inputSize="small"
          lsValue={'garageType'}
          initialValue={answers[language].attachedFrame}
        />
        <SubQuestions
          question={texts[language].basementPercentageFinished}
          type="number"
          placeholder="0%"
          options={[]}
          onChange={(value) => {
            setData({ ...data, basementPercentageFinished: value });
          }}
          inputSize="small"
          lsValue={data.basementPercentageFinished}
          initialValue={100}
          maxLength={3}
        />
        <SubQuestions
          question={texts[language].numOfStoreys}
          type="number"
          placeholder="0"
          options={[]}
          onChange={(value) => {
            setData({ ...data, numOfStoreys: value });
          }}
          inputSize="small"
          lsValue={data.numOfStoreys}
          initialValue={data.numOfStoreys} 
          error={data.numOfStoreys < 1 ? true : false}
          maxLength={2}
        />
        <SubQuestions
          question={texts[language].yearRoofReplaced}
          type="number"
          placeholder={data.yearRoofReplaced ? data.yearRoofReplaced : '1950'}
          options={[]}
          onChange={(value) => {
            setData({ ...data, yearRoofReplaced: value });
          }}
          inputSize="small"
          lsValue={data.yearRoofReplaced}
          error={data.yearRoofReplaced < 1800 ? true : false}
          initialValue={props.yearRoofReplaced ? props.yearRoofReplaced : '1950'}
          maxLength={4}
        />
        <SubQuestions
          question={texts[language].distanceToFirehall}
          type="dropdown"
          dropdownType={'firehall'}
          nonCenter={true}
          placeholder={answers[language].eightKM}
          options={[
            answers[language].fiveKm,
            answers[language].eightKM,
            answers[language].overEightKm,
          ]}
          onChange={(value) => {
            setData({ ...data, distanceToFirehall: value });
          }}
          inputSize="small"
          lsValue={'distanceToFirehall'}
          initialValue={answers[language].eightKM}
        />
        <SubQuestions
          question={texts[language].distanceToFireHydrant}
          type="dropdown"
          dropdownType={'fireHydrant'}
          nonCenter={true}
          placeholder={answers[language].withinThreeHunderdM}
          options={[
            answers[language].withinThreeHunderdM,
            answers[language].overThreeHunderedM,
          ]}
          onChange={(value) => {
            setData({ ...data, distanceToFireHydrant: value });
          }}
          inputSize="small"
          lsValue={'distanceToFireHydrant'}
          initialValue={answers[language].withinThreeHunderdM
          }
        />
        <SubQuestions
          question={texts[language].primaryHeatingType}
          type="dropdown"
          dropdownType="heating"
          nonCenter={true}
          placeholder={answers[language].electric}
          options={[
            answers[language].centralFurnaceGas,
            answers[language].centralFurnaceOil,
            answers[language].centralFurnacePropane,
            answers[language].centralFurnaceWood,
            answers[language].electric,
            answers[language].hotWaterSteam,
            answers[language].combinationFurnace,
            answers[language].spaceHeaterGas,
            answers[language].spaceHeaterOil,
            answers[language].spaceHeaterElectric,
            answers[language].woodBurningStove,
            answers[language].fireplace,
            answers[language].solar,
            answers[language].other,
            answers[language].none,
          ]}
          onChange={(value) => {
            setData({ ...data, primaryHeatingType: value });
          }}
          inputSize="small"
          lsValue={'primaryHeatingType'}
          initialValue={answers[language].electric}
        />
        <SubQuestions
          question={texts[language].secondaryHeatingType}
          type="dropdown"
          dropdownType={'secondHeating'}
          nonCenter={true}
          placeholder={answers[language].electric}
          options={[
            answers[language].centralFurnaceGas,
            answers[language].centralFurnaceOil,
            answers[language].centralFurnacePropane,
            answers[language].centralFurnaceWood,
            answers[language].electric,
            answers[language].hotWaterSteam,
            answers[language].combinationFurnace,
            answers[language].spaceHeaterGas,
            answers[language].spaceHeaterOil,
            answers[language].spaceHeaterElectric,
            answers[language].woodBurningStove,
            answers[language].fireplace,
            answers[language].solar,
            answers[language].other,
            answers[language].none,
          ]}
          onChange={(value) => {
            setData({ ...data, secondaryHeatingType: value });
          }}
          inputSize="small"
          lsValue={'secondaryHeatingType'}
          initialValue={answers[language].none}
        />
        <SubQuestions
          question={texts[language].buildingStyle}
          type="dropdown"
          dropdownType={'building'}
          nonCenter={true}
          placeholder={answers[language].detached}
          options={[
            answers[language].detached,
            answers[language].semiDetached,
            answers[language].townHouse,
            answers[language].rowHouse,
            answers[language].duplexSide,
            answers[language].duplexUpDown,
            answers[language].triplex,
          ]}
          onChange={(value) => {
            setData({ ...data, buildingStyle: value });
          }}
          inputSize="small"
          lsValue={'buildingStyle'}
          initialValue={answers[language].detached}
          error={data.buildingStyle === "" ? true : false}
        />
        <SubQuestions
          question={texts[language].numOfUnits}
          type="number"
          placeholder={`${data.numOfUnits}`}
          options={[]}
          onChange={(value) => {
            setData({ ...data, numOfUnits: value });
          }}
          inputSize="small"
          lsValue={data.numOfUnits}
          initialValue={data.numOfUnits ? data.numOfUnits : 1}
          error={data.numOfUnits < 1 ? true : false}
          maxLength={2}
        />
      <Footer
        type="submit"
        data={{
          year: data.year,
          livingArea: data.livingArea,
          exteriorFinish: data.exteriorFinish,
          constructionType: data.constructionType,
          garageType: data.garageType,
          basementPercentageFinished: data.basementPercentageFinished,
          numOfStoreys: data.numOfStoreys,
          yearRoofReplaced: data.yearRoofReplaced ? data.yearRoofReplaced : props.yearRoofReplaced ? props.yearRoofReplaced : '1950',
          distanceToFirehall: data.distanceToFirehall,
          distanceToFireHydrant: data.distanceToFireHydrant,
          primaryHeatingType: data.primaryHeatingType,
          secondaryHeatingType: data.secondaryHeatingType,
          buildingStyle: data.buildingStyle,
          numOfUnits: data.numOfUnits,
          replacementCost: data.replacementCost
        }}
        formSteps={5}
        backFormSteps={3}
        disabled={!isValid}
      />
    </div>
    </div>
  );
}

export default HomeOwnerStepFour;
