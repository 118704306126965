import './RefreshStyles.css'
import { useState, Dispatch, SetStateAction, useEffect } from 'react';


type InputProps = {
    placeholder: string;
    setState?: Dispatch<SetStateAction<any>>,
    margin?: string;
    type?: string;
    lsValue?: any;
    error?: boolean;
    label?: string;
    setParsedState?: Dispatch<SetStateAction<number>>
    maxLength?: number;
    howOld?: any
    initialValue?: number;
    onChange?: (text: string) => void;
  };
  

  const MoneyInput = (props: InputProps) => {
      const [value, setValue] = useState<any>('')

      useEffect(() => {
        if (props.lsValue) { setValue(String(props.lsValue)) }
      }, [])


    useEffect(() => {
      if (props.initialValue) setValue(props.initialValue)
    }, [props.initialValue])
      


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue) {
    inputValue.replace(".", "");
  }

    if (inputValue.length > (props.maxLength ?? Infinity)) {
      setValue(inputValue.slice(0, props.maxLength));
    } else {
      setValue(inputValue);
    }
    
    if (props.setState) {
      props.setState(Number(inputValue))
    }
    if (props.setParsedState) {
      props.setParsedState(parseInt(inputValue))
    }
    if (props.onChange) props.onChange(inputValue)
  };

  return (
    <input 
    className="price-box" 
    type={props.type === "number" ? "number" : "string"} 
    id="price-of-car" 
    value={value}
    placeholder={props.lsValue ? String(props.lsValue) : props.placeholder} 
    onChange={handleChange}
    onKeyDown={(e) =>["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
    >    
    </input>
    
  )
}

export default MoneyInput