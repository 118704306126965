import { useState, useContext, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import "../../../components/refresh/RefreshStyles.css"

function CarStepFour() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [carPurchaseYear, setCarPurchaseYear] = useState<Date | null>(null);
  const LSPurchaseYear = useLocalStorage("carPurchaseYear");
  const LSYear = useLocalStorage("purchaseYear")
  const LSMonth = useLocalStorage("purchaseMonth") 
  const LSDay = useLocalStorage("purchaseDay")
  const LSDOB = useLocalStorage("dob");
  const [month, setMonth] = useState<null | string>(null);
  const [monthNumber, setMonthNumber] = useState<null | string>(null);
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [yearList, setYearList] = useState<string[]>([]);
  const [dayList, setDayList] = useState<string[]>([]);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [chosenMonth, setChosenMonth] = useState<string>('');
  const [chosenDay, setChosenDay] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [dayNumber, setDayNumber] = useState<number>(0)

useEffect(() => {
  if (LSPurchaseYear) {
    let year = LSPurchaseYear.substring(0, 4);
    let month = LSPurchaseYear.substring(4, 6);
    let day = LSPurchaseYear.substring(6, 8);
    setYear(year);
    setDay(day);
    handleReverseMonth(month);
    setChosenYear(LSYear)
    setChosenMonth(LSMonth)
    setChosenDay(LSDay)
  }
}, [LSPurchaseYear]);

useEffect(() => {

  const isLeap = (numberYear: number): boolean => {
    return new Date(numberYear, 1, 29).getDate() === 29;
  };

  if (chosenMonth) {
    if (chosenMonth === 'January' || chosenMonth === 'March' || chosenMonth === 'May' || chosenMonth === 'July' || chosenMonth === 'August' || chosenMonth === 'October' || chosenMonth === 'December'
    || chosenMonth === 'Janvier' || chosenMonth === 'Mars' || chosenMonth === 'Mai' || chosenMonth === 'Juillet' || chosenMonth === 'Août' || chosenMonth === 'Octobre' || chosenMonth === 'Décembre') {
      setDayNumber(32)
    }
    else if (chosenMonth === 'April' || chosenMonth === 'June' || chosenMonth === 'September' || chosenMonth === 'November'
    || chosenMonth === 'Avril' || chosenMonth === 'Juin' || chosenMonth === 'Septembre' || chosenMonth === 'Novembre') {
      setDayNumber(31)
    }
    else if (chosenMonth === 'February' || chosenMonth === 'Février') {
      if (isLeap(Number(chosenYear))) {
        setDayNumber(30)
      } else {
      setDayNumber(29)
    }
    }
    else {
      setDayNumber(32)
    }
  }
}, [chosenMonth, chosenYear]);

useEffect(() => {
  const years = [];
  const days = [];
  const currentYear = new Date().getFullYear();

  for (let i = 1900; i < currentYear + 1; i++) {
    years.push(i.toString());
  }
  setYearList(years.reverse());

  for (let i = 1; i < dayNumber; i++) {
    if (i <= 9) {
      days.push(`0${i}`);
    } else {
      days.push(i.toString());
    }
  }
  setDayList(days);
}, [dayNumber]);

const months = [
carTexts[carLanguage].january,
carTexts[carLanguage].february,
carTexts[carLanguage].march,
carTexts[carLanguage].april,
carTexts[carLanguage].may,
carTexts[carLanguage].june,
carTexts[carLanguage].july,
carTexts[carLanguage].august,
carTexts[carLanguage].september,
carTexts[carLanguage].october,
carTexts[carLanguage].november,
carTexts[carLanguage].december,]


function handleMonth(month: string) {
  setMonth(month);
  const list = [
    carTexts[carLanguage].january,
    carTexts[carLanguage].february,
    carTexts[carLanguage].march,
    carTexts[carLanguage].april,
    carTexts[carLanguage].may,
    carTexts[carLanguage].june,
    carTexts[carLanguage].july,
    carTexts[carLanguage].august,
    carTexts[carLanguage].september,
    carTexts[carLanguage].october,
    carTexts[carLanguage].november,
    carTexts[carLanguage].december,
  ];

  for (let i = 0; i < list.length; i++) {
    if (month === list[i]) {
      if (i < 9) {
        setMonthNumber(`0${i + 1}`);
      } else {
        setMonthNumber(`${i + 1}`);
      }
    }
  }
}

let dateString = ''

useEffect(() => {
  handleMonth(chosenMonth)
  dateString = `${chosenYear}/${monthNumber}/${chosenDay}`
  const dateObject = new Date(dateString)

  function isValidDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  if (isValidDate(dateObject) && dateString.length === 10) {
    if (dateObject.toISOString().slice(0, 10).replace(/-/g, '/') === dateString) {
      setCarPurchaseYear(dateObject)
      setDateError(false)
    } else {
      setDateError(true)
      setCarPurchaseYear(null)
    }
  } else {
    setCarPurchaseYear(null)
  }


}, [chosenDay, chosenMonth, chosenYear])

function handleReverseMonth(monthNumber: number) {
  const list = [
    carTexts[carLanguage].january,
    carTexts[carLanguage].february,
    carTexts[carLanguage].march,
    carTexts[carLanguage].april,
    carTexts[carLanguage].may,
    carTexts[carLanguage].june,
    carTexts[carLanguage].july,
    carTexts[carLanguage].august,
    carTexts[carLanguage].september,
    carTexts[carLanguage].october,
    carTexts[carLanguage].november,
    carTexts[carLanguage].december,
  ];

  const selectedMonth = list[monthNumber - 1];
  setMonth(selectedMonth);
  handleMonth(selectedMonth);
}


  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].whenPurchaseCar} subQuestion={carTexts[carLanguage].whenPurchaseCarSub} />
      <div className="date-container">
      <div className="input-container">
      <DropdownMenu type={'year'} list={yearList} setState={setChosenYear} placeholder={carTexts[carLanguage].year} lsValue={'purchaseYear'} />
      </div>
      <div className="input-container">
      <DropdownMenu type={'month'} list={months} setState={setChosenMonth} placeholder={carTexts[carLanguage].month} lsValue={'purchaseMonth'}/>
      </div>
      <div className="input-container">
      <DropdownMenu type={'day'} list={dayList} setState={setChosenDay} placeholder={carTexts[carLanguage].day} lsValue={'purchaseDay'}/>
      </div>
      </div>
      {dateError && <div className="modal-error-msg">{carTexts[carLanguage].dateError}</div>}
      
      <CarFooter
        data={{
          carPurchaseYear: carPurchaseYear
            ?.toLocaleDateString("sv")
            .replaceAll("-", ""),
            purchaseYear: chosenYear,
            purchaseMonth: chosenMonth,
            purchaseDay: chosenDay
        }}
        formSteps={5}
        type="submit"
        disabled={carPurchaseYear === null ? true : false}
        backFormSteps={3}
      />
    </div>
    </div>
  );
}

export default CarStepFour;
