import React from "react";

type ProgressIconProps = {
  progress: "active" | "inactive" | "done";
};
function MyHouseIcon(props: ProgressIconProps) {
  return (
    <span className="progress-icons">
      {props.progress === "done" ? (
        <svg
          width="15"
          height="11"
          viewBox="0 0 15 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.429 11V5.18217L0 5.2156L7.3567 0L14.29 5.2156H12.861V11H8.62693V8.09108H5.66307V11H1.429Z"
            fill="var(--progress)"
          />
        </svg>
      ) : (
        <svg
          width="15"
          height="11"
          viewBox="0 0 15 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.429 11V5.18217L0 5.2156L7.3567 0L14.29 5.2156H12.861V11H8.62693V8.09108H5.66307V11H1.429Z"
            fill="var(--progress)"
          />
        </svg>
      )}
    </span>
  );
}

export default MyHouseIcon;
