import './RefreshStyles.css'
import RadioNo from './RadioNo'
import RadioYes from './RadioYes'
import { useState } from 'react'
import { Dispatch, SetStateAction } from 'react';


type RadioGroupProps = {
    setAccept?: Dispatch<SetStateAction<boolean | null>>,
    setGender?: Dispatch<SetStateAction<string>>,
    gender?: string;
    labelRight?: string,
    labelLeft?: string,
    iconRight?: JSX.Element,
    iconLeft?: JSX.Element,
    setAnotherBoolean?: Dispatch<SetStateAction<boolean>>,
    setProvinceSelector?: Dispatch<SetStateAction<string>>
    accept?: any;
    setDisplayForm?: Dispatch<SetStateAction<boolean>>
}

const RadioButtonGroup = (props: RadioGroupProps) => {

    const handleLabelClick = (parentId: string) => {
        const parentDiv = document.querySelector(`#${parentId}`);
        const childLabel = parentDiv?.querySelector('label');
        
        childLabel?.click();
    }
    
    const handleLabelYes = () => {
        setLabelYesSelected(true)
        setLabelNoSelected(false)
        if (props.setAccept) props.setAccept(true);
        if (props.setAnotherBoolean) props.setAnotherBoolean(true);
        if (props.setGender) props.setGender('Male');
        if (props.setProvinceSelector) props.setProvinceSelector('Quebec');
        if (props.setDisplayForm) props.setDisplayForm(true);
    }
    
    const handleLabelNo = () => {
        setLabelYesSelected(false)
        setLabelNoSelected(true)
        if (props.setAccept) props.setAccept(false);
        if (props.setAnotherBoolean) props.setAnotherBoolean(false);
        if (props.setGender) props.setGender('Female')
        if (props.setProvinceSelector) props.setProvinceSelector('Ontario')
        if (props.setDisplayForm) props.setDisplayForm(false);
    }
    
    const [labelYesSelected, setLabelYesSelected] = useState(false)
    const [labelNoSelected, setLabelNoSelected] = useState(false)

  return (
    <div className="t-radio-buttons-box">
        <RadioYes handleLabelClick={handleLabelClick} handleLabelYes={handleLabelYes} labelYesSelected={labelYesSelected} labelLeft={props.labelLeft} iconLeft={props.iconLeft} checked={props.accept === true || props.gender === 'Male'}/>
        <RadioNo handleLabelClick={handleLabelClick} handleLabelNo={handleLabelNo} labelNoSelected={labelNoSelected} labelRight={props.labelRight} iconRight={props.iconRight} checked={props.accept === false || props.gender === 'Female'}/>
    </div>
  )
}

export default RadioButtonGroup