import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/Footer";
import { texts } from "../../../text";
import { LanguageContext } from "../HomeContext";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import DropdownMenu from "../../../components/refresh/DropdownMenu";

function StepEleven() {
  const { language } = useContext(LanguageContext);
  const [job, setJob] = useState<string>('');
  const LSJob = useLocalStorage("job");

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  useEffect(() => {
    if (LSJob) {
      setJob(LSJob);
    }
  }, [LSJob]);

  const jobOptions = [texts[language].uniGraduate, texts[language].professional, texts[language].retired, texts[language].student, texts[language].none]

  useEffect(() => {
    if (job === texts["fr"].uniGraduate) {
      setJob(texts["en"].uniGraduate)
    }
    if (job === texts["fr"].professional) {
      setJob(texts["en"].professional)
    }
    if (job === texts["fr"].retired) {
      setJob(texts["en"].retired)
    }
    if (job === texts["fr"].student) {
      setJob(texts["en"].student)
    }
    if (job === texts["fr"].none) {
      setJob(texts["en"].none)
    }
  }, [job])

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={texts[language].eligibleDiscounts}
        subQuestion={texts[language].selectOptions}
      />

      <DropdownMenu type={'jobHome'} list={jobOptions} setState={setJob} placeholder={texts[language].select} lsValue={'job'}/>

      <Footer
        type="submit"
        data={{
          job: job
        }}
        formSteps={12}
        disabled={job === null}
        backFormSteps={10}
      />
    </div>
    </div>
  );
}

export default StepEleven;
