import { useContext } from "react";
import {
  CarLanguageContext
} from "../../quotes/car/CarContext";
import { texts, carTexts } from "../../text";
import CarFooter from "../footerCar/CarFooter";
import Question from "../refresh/Question"

function CarFailedQuote() {
  const { carLanguage } = useContext(CarLanguageContext);
  
    return (
      <div className="t-container">
        <div className="t-content-container">
        <Question
          question={texts[carLanguage].failedQuote}
          subQuestion={texts[carLanguage].failedQuoteSub}
          subQuestion2={texts[carLanguage].quoteNumber}
          subQuestion3={texts[carLanguage].failedQuoteSubEnd}
        />
         <a href="tel:4388434814">
        <button className="quote-buy-button">
            {carTexts[carLanguage].phoneUs}
        </button>
        </a>

        <CarFooter newQuote backHidden nextHidden />
      </div>
      </div>
    );
  }
// }

export default CarFailedQuote;
