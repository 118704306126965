import React from "react";

function HomeCarIcon() {
  return (
    <svg
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.5" cy="25.5" r="25.5" fill="#F7F7F7" />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M21.9381 18.4023H29.7585C30.0698 18.4023 30.8091 18.7058 31.276 19.9197C31.5902 20.7368 31.9221 21.494 32.1648 22.0203H33.727C34.1138 22.0203 34.4274 22.3339 34.4274 22.7207C34.4274 23.1074 34.1138 23.421 33.727 23.421H33.1362C33.3617 23.6729 33.4935 24.0035 33.4935 24.3556V30.6586C33.4935 31.4322 32.8664 32.0593 32.0928 32.0593C31.3193 32.0593 30.6922 31.4322 30.6922 30.6586V30.1918H20.4206V30.6586C20.4206 31.4322 19.7935 32.0593 19.02 32.0593C18.2464 32.0593 17.6193 31.4322 17.6193 30.6586V24.2874C17.6193 23.9672 17.7285 23.6636 17.9194 23.421H17.3858C16.999 23.421 16.6854 23.1074 16.6854 22.7207C16.6854 22.3339 16.999 22.0203 17.3858 22.0203H19.0435L20.0705 19.4529C20.1872 19.1027 20.7242 18.4023 21.9381 18.4023ZM22.0545 26.3393C22.0545 27.0484 21.4796 27.6233 20.7705 27.6233C20.0614 27.6233 19.4865 27.0484 19.4865 26.3393C19.4865 25.6302 20.0614 25.0553 20.7705 25.0553C21.4796 25.0553 22.0545 25.6302 22.0545 26.3393ZM30.8088 27.6233C31.5179 27.6233 32.0928 27.0484 32.0928 26.3393C32.0928 25.6302 31.5179 25.0553 30.8088 25.0553C30.0997 25.0553 29.5248 25.6302 29.5248 26.3393C29.5248 27.0484 30.0997 27.6233 30.8088 27.6233Z"
        fill="#37375B"
      />
    </svg>
  );
}

export default HomeCarIcon;
