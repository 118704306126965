import { useContext, useEffect, useState } from "react";
import StepOne from "./step1/StepOne";
import { HomeStepContext, LanguageContext } from "./HomeContext";
import Header from "../../components/refresh/Header";
import StepZero from "./step0/StepZero"
import StepZeroPointFive from "./step0.5/stepZeroPointFive"
// import Navbar from "../../components/navbar/Navbar";
import StepTwo from "./step2/StepTwo";
// import TopLines from "../../components/TopLines";
// import BottomLines from "../../components/BottomLines";
import StepThree from "./step3/StepThree";
import ProgressBar from "../../components/progressBar/ProgressBar";
import StepFive from "./step5/StepFive";
import StepSix from "./step6/StepSix";
import StepSeven from "./step7/StepSeven";
import StepEight from "./step8/StepEight";
import StepNine from "./step9/StepNine";
import StepTen from "./step10/StepTen";
import StepEleven from "./step11/StepEleven";
import StepTwelve from "./step12/StepTwelve";
import StepFour from "./step4/StepFour";
import StepFourteen from "./step14/StepFourteen";
import StepThirteen from "./step13/StepThirteen";
import { carTexts } from "../../text";

function HomeQuoter() {
  const { formSteps } = useContext(HomeStepContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const [width, setWidth] = useState(0);
  const year = new Date().getFullYear();

  useEffect(() => {
    setWidth(window.innerWidth);
    if (language === "fr") {
      document.title = "Assurance KBD";
    } else {
      document.title = "KBD Insurance";
    }
  }, [language]);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.includes("fr")) {
      setLanguage("fr");
    } else {
      setLanguage("en");
    }
    return () => {
      const step = localStorage.getItem("step");
    };
  }, []);

  return (
    <div>
      {/* {width > 700 ? <TopLines /> : null} */}
      <div className="t-main">
      {/* <Navbar /> */}
      <Header />
      {/* <div style={{ width: "98vw" }} className="main"> */}
        {formSteps >= 2 ? <ProgressBar /> : null}
        {formSteps === 0 ? <StepZero /> : null}
        {formSteps === 0.5 ? <StepZeroPointFive /> : null}
        {formSteps === 1 ? <StepOne /> : null}
        {formSteps === 2 ? <StepTwo /> : null}
        {formSteps === 3 ? <StepThree /> : null}
        {formSteps === 4 ? <StepFour /> : null}
        {formSteps === 5 ? <StepFive /> : null}
        {formSteps === 6 ? <StepSix /> : null}
        {formSteps === 7 ? <StepSeven /> : null}
        {formSteps === 8 ? <StepEight /> : null}
        {formSteps === 9 ? <StepNine /> : null}
        {formSteps === 10 ? <StepTen /> : null}
        {formSteps === 11 ? <StepEleven /> : null}
        {formSteps === 12 ? <StepTwelve /> : null}
        {formSteps === 13 ? <StepThirteen /> : null}
        {formSteps === 14 ? <StepFourteen /> : null}
        <div className="t-container">
          <div className="t-content-container">
        <div className="copyright">
                    
          <p>&copy;{year} {carTexts[language].copyright}</p>
          </div>
          </div>
          </div>
      </div>
      {/* {width > 700 ? <BottomLines /> : null} */}
      
    </div>
  // </div>
  );
}

export default HomeQuoter;
