import React from "react";

type ProgressIconProps = {
  progress: "active" | "inactive" | "done";
};

function MyDiscountIcon(props: ProgressIconProps) {
  return (
    <span className="progress-icons">
      {props.progress === "active" ? (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8115 6.36017L6.6495 0.208104C6.58889 0.142722 6.5155 0.0905089 6.43387 0.0547089C6.35224 0.0189088 6.26413 0.000286718 6.175 -1.34959e-07L2.27501 -4.97219e-08C2.18946 -0.000494875 2.10466 0.0159096 2.02547 0.0482727C1.94627 0.0806358 1.87424 0.128321 1.81351 0.188594L0.188511 1.8079C0.128268 1.86867 0.0806064 1.94073 0.0482595 2.01997C0.0159126 2.0992 -0.000483684 2.18404 1.08976e-05 2.26963L1.09829e-05 6.17158C0.00240525 6.34376 0.0697339 6.50868 0.188511 6.63331L6.357 12.8114C6.48156 12.9302 6.6464 12.9976 6.8185 13C6.90404 13.0005 6.98884 12.9841 7.06804 12.9517C7.14723 12.9193 7.21926 12.8717 7.28 12.8114L12.8115 7.27713C12.8717 7.21636 12.9194 7.14429 12.9517 7.06506C12.9841 6.98583 13.0005 6.90098 13 6.8154C12.996 6.64547 12.9288 6.48315 12.8115 6.36017Z"
            fill="var(--progress)"
          />
          <path
            d="M5.44966 7.1829C5.56326 7.0693 5.64062 6.92457 5.67197 6.767C5.70331 6.60943 5.68722 6.44611 5.62574 6.29769C5.56426 6.14926 5.46015 6.0224 5.32657 5.93315C5.19299 5.84389 5.03595 5.79625 4.87529 5.79625C4.71464 5.79625 4.55759 5.84389 4.42401 5.93315C4.29043 6.0224 4.18632 6.14926 4.12484 6.29769C4.06336 6.44611 4.04728 6.60943 4.07862 6.767C4.10996 6.92457 4.18732 7.0693 4.30092 7.1829C4.45337 7.33502 4.65993 7.42045 4.87529 7.42045C5.09065 7.42045 5.29721 7.33502 5.44966 7.1829ZM7.5497 6.03416C7.4361 6.14776 7.35874 6.2925 7.3274 6.45007C7.29606 6.60763 7.31214 6.77096 7.37362 6.91938C7.4351 7.06781 7.53921 7.19467 7.67279 7.28392C7.80637 7.37318 7.96342 7.42081 8.12407 7.42081C8.28472 7.42081 8.44177 7.37318 8.57535 7.28392C8.70893 7.19467 8.81304 7.06781 8.87452 6.91938C8.936 6.77096 8.95209 6.60763 8.92074 6.45007C8.8894 6.2925 8.81204 6.14776 8.69844 6.03416C8.54599 5.88205 8.33943 5.79662 8.12407 5.79662C7.90871 5.79662 7.70215 5.88205 7.5497 6.03416ZM6.49968 3.65742C6.46413 3.65741 6.42892 3.66441 6.39607 3.67801C6.36321 3.69161 6.33337 3.71156 6.30822 3.7367C6.28308 3.76184 6.26314 3.79169 6.24954 3.82454C6.23594 3.85739 6.22894 3.8926 6.22895 3.92815L6.22895 9.28894C6.22873 9.32463 6.23556 9.36002 6.24907 9.39306C6.26258 9.4261 6.28248 9.45615 6.30764 9.48147C6.33281 9.50679 6.36273 9.52688 6.39568 9.54059C6.42864 9.55431 6.46399 9.56136 6.49968 9.56136C6.53538 9.56136 6.57072 9.5543 6.60368 9.54059C6.63664 9.52688 6.66656 9.50678 6.69172 9.48146C6.71688 9.45614 6.73678 9.42609 6.75029 9.39305C6.76379 9.36001 6.77063 9.32462 6.7704 9.28892L6.7704 3.92814C6.77041 3.89259 6.76341 3.85738 6.74981 3.82453C6.73621 3.79168 6.71627 3.76184 6.69113 3.7367C6.66599 3.71156 6.63614 3.69162 6.60329 3.67801C6.57044 3.66441 6.53524 3.65742 6.49968 3.65742Z"
            fill="var(--background-gray)"
          />
        </svg>
      ) : props.progress === "done" ? (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8115 6.36017L6.6495 0.208104C6.58889 0.142722 6.5155 0.0905089 6.43387 0.0547089C6.35224 0.0189088 6.26413 0.000286718 6.175 -1.34959e-07L2.27501 -4.97219e-08C2.18946 -0.000494875 2.10466 0.0159096 2.02547 0.0482727C1.94627 0.0806358 1.87424 0.128321 1.81351 0.188594L0.188511 1.8079C0.128268 1.86867 0.0806064 1.94073 0.0482595 2.01997C0.0159126 2.0992 -0.000483684 2.18404 1.08976e-05 2.26963L1.09829e-05 6.17158C0.00240525 6.34376 0.0697339 6.50868 0.188511 6.63331L6.357 12.8114C6.48156 12.9302 6.6464 12.9976 6.8185 13C6.90404 13.0005 6.98884 12.9841 7.06804 12.9517C7.14723 12.9193 7.21926 12.8717 7.28 12.8114L12.8115 7.27713C12.8717 7.21636 12.9194 7.14429 12.9517 7.06506C12.9841 6.98583 13.0005 6.90098 13 6.8154C12.996 6.64547 12.9288 6.48315 12.8115 6.36017Z"
            fill="var(--progress)"
          />
          <path
            d="M5.44966 7.1829C5.56326 7.0693 5.64062 6.92457 5.67197 6.767C5.70331 6.60943 5.68722 6.44611 5.62574 6.29769C5.56426 6.14926 5.46015 6.0224 5.32657 5.93315C5.19299 5.84389 5.03595 5.79625 4.87529 5.79625C4.71464 5.79625 4.55759 5.84389 4.42401 5.93315C4.29043 6.0224 4.18632 6.14926 4.12484 6.29769C4.06336 6.44611 4.04728 6.60943 4.07862 6.767C4.10996 6.92457 4.18732 7.0693 4.30092 7.1829C4.45337 7.33502 4.65993 7.42045 4.87529 7.42045C5.09065 7.42045 5.29721 7.33502 5.44966 7.1829ZM7.5497 6.03416C7.4361 6.14776 7.35874 6.2925 7.3274 6.45007C7.29606 6.60763 7.31214 6.77096 7.37362 6.91938C7.4351 7.06781 7.53921 7.19467 7.67279 7.28392C7.80637 7.37318 7.96342 7.42081 8.12407 7.42081C8.28472 7.42081 8.44177 7.37318 8.57535 7.28392C8.70893 7.19467 8.81304 7.06781 8.87452 6.91938C8.936 6.77096 8.95209 6.60763 8.92074 6.45007C8.8894 6.2925 8.81204 6.14776 8.69844 6.03416C8.54599 5.88205 8.33943 5.79662 8.12407 5.79662C7.90871 5.79662 7.70215 5.88205 7.5497 6.03416ZM6.49968 3.65742C6.46413 3.65741 6.42892 3.66441 6.39607 3.67801C6.36321 3.69161 6.33337 3.71156 6.30822 3.7367C6.28308 3.76184 6.26314 3.79169 6.24954 3.82454C6.23594 3.85739 6.22894 3.8926 6.22895 3.92815L6.22895 9.28894C6.22873 9.32463 6.23556 9.36002 6.24907 9.39306C6.26258 9.4261 6.28248 9.45615 6.30764 9.48147C6.33281 9.50679 6.36273 9.52688 6.39568 9.54059C6.42864 9.55431 6.46399 9.56136 6.49968 9.56136C6.53538 9.56136 6.57072 9.5543 6.60368 9.54059C6.63664 9.52688 6.66656 9.50678 6.69172 9.48146C6.71688 9.45614 6.73678 9.42609 6.75029 9.39305C6.76379 9.36001 6.77063 9.32462 6.7704 9.28892L6.7704 3.92814C6.77041 3.89259 6.76341 3.85738 6.74981 3.82453C6.73621 3.79168 6.71627 3.76184 6.69113 3.7367C6.66599 3.71156 6.63614 3.69162 6.60329 3.67801C6.57044 3.66441 6.53524 3.65742 6.49968 3.65742Z"
            fill="var(--background-gray)"
          />
        </svg>
      ) : (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8115 6.36017L6.6495 0.208104C6.58889 0.142722 6.5155 0.0905089 6.43387 0.0547089C6.35224 0.0189088 6.26413 0.000286718 6.175 -1.34959e-07L2.27501 -4.97219e-08C2.18946 -0.000494875 2.10466 0.0159096 2.02547 0.0482727C1.94627 0.0806358 1.87424 0.128321 1.81351 0.188594L0.188511 1.8079C0.128268 1.86867 0.0806064 1.94073 0.0482595 2.01997C0.0159126 2.0992 -0.000483684 2.18404 1.08976e-05 2.26963L1.09829e-05 6.17158C0.00240525 6.34376 0.0697339 6.50868 0.188511 6.63331L6.357 12.8114C6.48156 12.9302 6.6464 12.9976 6.8185 13C6.90404 13.0005 6.98884 12.9841 7.06804 12.9517C7.14723 12.9193 7.21926 12.8717 7.28 12.8114L12.8115 7.27713C12.8717 7.21636 12.9194 7.14429 12.9517 7.06506C12.9841 6.98583 13.0005 6.90098 13 6.8154C12.996 6.64547 12.9288 6.48315 12.8115 6.36017Z"
            fill="#AAAABB"
          />
          <path
            d="M5.44966 7.1829C5.56326 7.0693 5.64062 6.92457 5.67197 6.767C5.70331 6.60943 5.68722 6.44611 5.62574 6.29769C5.56426 6.14926 5.46015 6.0224 5.32657 5.93315C5.19299 5.84389 5.03595 5.79625 4.87529 5.79625C4.71464 5.79625 4.55759 5.84389 4.42401 5.93315C4.29043 6.0224 4.18632 6.14926 4.12484 6.29769C4.06336 6.44611 4.04728 6.60943 4.07862 6.767C4.10996 6.92457 4.18732 7.0693 4.30092 7.1829C4.45337 7.33502 4.65993 7.42045 4.87529 7.42045C5.09065 7.42045 5.29721 7.33502 5.44966 7.1829ZM7.5497 6.03416C7.4361 6.14776 7.35874 6.2925 7.3274 6.45007C7.29606 6.60763 7.31214 6.77096 7.37362 6.91938C7.4351 7.06781 7.53921 7.19467 7.67279 7.28392C7.80637 7.37318 7.96342 7.42081 8.12407 7.42081C8.28472 7.42081 8.44177 7.37318 8.57535 7.28392C8.70893 7.19467 8.81304 7.06781 8.87452 6.91938C8.936 6.77096 8.95209 6.60763 8.92074 6.45007C8.8894 6.2925 8.81204 6.14776 8.69844 6.03416C8.54599 5.88205 8.33943 5.79662 8.12407 5.79662C7.90871 5.79662 7.70215 5.88205 7.5497 6.03416ZM6.49968 3.65742C6.46413 3.65741 6.42892 3.66441 6.39607 3.67801C6.36321 3.69161 6.33337 3.71156 6.30822 3.7367C6.28308 3.76184 6.26314 3.79169 6.24954 3.82454C6.23594 3.85739 6.22894 3.8926 6.22895 3.92815L6.22895 9.28894C6.22873 9.32463 6.23556 9.36002 6.24907 9.39306C6.26258 9.4261 6.28248 9.45615 6.30764 9.48147C6.33281 9.50679 6.36273 9.52688 6.39568 9.54059C6.42864 9.55431 6.46399 9.56136 6.49968 9.56136C6.53538 9.56136 6.57072 9.5543 6.60368 9.54059C6.63664 9.52688 6.66656 9.50678 6.69172 9.48146C6.71688 9.45614 6.73678 9.42609 6.75029 9.39305C6.76379 9.36001 6.77063 9.32462 6.7704 9.28892L6.7704 3.92814C6.77041 3.89259 6.76341 3.85738 6.74981 3.82453C6.73621 3.79168 6.71627 3.76184 6.69113 3.7367C6.66599 3.71156 6.63614 3.69162 6.60329 3.67801C6.57044 3.66441 6.53524 3.65742 6.49968 3.65742Z"
            fill="var(--background-gray)"
          />
        </svg>
      )}
    </span>
  );
}

export default MyDiscountIcon;
