const TagTracking = () => {
    return (
      <>
        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25.5" cy="25.5" r="25.5" fill="#ECECE9"/>
                <path d="M31.5217 20.5465C31.5217 19.5865 30.7426 18.8074 29.7826 18.8074H20.2174C19.2574 18.8074 18.4783 19.5865 18.4783 20.5465V30.1117C18.4783 31.0717 19.2574 31.8508 20.2174 31.8508H29.7826C30.7426 31.8508 31.5217 31.0717 31.5217 30.1117V20.5465ZM21.9565 32.7204V35.3291H21.087V32.7204H21.9565ZM25.4348 32.7204V35.3291H24.5652V32.7204H25.4348ZM27.1739 32.7204V35.3291H26.3043V32.7204H27.1739ZM23.6957 32.7204V35.3291H22.8261V32.7204H23.6957ZM28.913 32.7204V35.3291H28.0435V32.7204H28.913ZM21.9565 15.3291V17.9378H21.087V15.3291H21.9565ZM25.4348 15.3291V17.9378H24.5652V15.3291H25.4348ZM27.1739 15.3291V17.9378H26.3043V15.3291H27.1739ZM23.6957 15.3291V17.9378H22.8261V15.3291H23.6957ZM28.913 15.3291V17.9378H28.0435V15.3291H28.913ZM32.3913 28.3726H35V29.2421H32.3913V28.3726ZM32.3913 24.8943H35V25.7639H32.3913V24.8943ZM32.3913 23.1552H35V24.0248H32.3913V23.1552ZM32.3913 26.6334H35V27.503H32.3913V26.6334ZM32.3913 21.4161H35V22.2856H32.3913V21.4161ZM15 28.3726H17.6087V29.2421H15V28.3726ZM15 24.8943H17.6087V25.7639H15V24.8943ZM15 23.1552H17.6087V24.0248H15V23.1552ZM15 26.6334H17.6087V27.503H15V26.6334ZM15 21.4161H17.6087V22.2856H15V21.4161Z" fill="#0D172E"/>
                <path d="M25 29.4468C26.7904 26.8008 27.9412 25.1457 27.9412 23.5369C27.9412 21.9273 26.6254 20.6232 25 20.6232C23.3746 20.6232 22.0588 21.9273 22.0588 23.5369C22.0588 25.1457 23.2095 26.8008 25 29.4468Z" fill="#ECECE9"/>
            <circle cx="25.0001" cy="23.5645" r="1.76471" fill="#0D172E"/>
        </svg>
      </>
    )
  }
  
  export default TagTracking