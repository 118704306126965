import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../HomeContext";
import { texts } from "../../../text";
import Footer from "../../../components/footer/Footer";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
function StepThirteen() {
  const { language } = useContext(LanguageContext);
  const [creditCheck, setCreditCheck] = useState<boolean | null>(null);
  const LSCreditCheck = useLocalStorage("creditCheck");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  useEffect(() => {
    if (LSCreditCheck) {
      setCreditCheck(LSCreditCheck === "true");
    }
  }, [LSCreditCheck]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={texts[language].saveCreditCheck}
        subQuestion={texts[language].affectScore}
      />
      <RadioButtonGroup setAccept={setCreditCheck} accept={creditCheck} labelLeft={texts[language].yes} labelRight={texts[language].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>

      <Footer
        type="submit"
        data={{
          creditCheck: creditCheck,
        }}
        formSteps={14}
        backFormSteps={12}
        disabled={creditCheck === null}
      />
    </div>
    </div>  
  );
}

export default StepThirteen;
