import './RefreshStyles.css'
import { Dispatch, SetStateAction, KeyboardEvent } from 'react';

type RadioButtonProps = {
    label: string;
    value: string;
    setState: Dispatch<SetStateAction<string>>,
    checked?: boolean;
    multiple?: boolean;
    string?: string;
  };
  

const handleLabelClick = (setState: Dispatch<SetStateAction<string>>, value: string, parentId: string) => {
  const parentDiv = document.querySelector(`#${parentId}`);
  const childLabel = parentDiv?.querySelector('label');
  setState(value);
  childLabel?.click();
}

const AverageRadioButtons = (props: RadioButtonProps) => {
  const valueWithoutSpaces = (props.value !== undefined ? props.value.replace(/ /g, "-").replace("+", "").replace("'", "") : props.value);    
  const divId = `tag-tracking-${valueWithoutSpaces}`;

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setState(event.target.value);
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleLabelClick(props.setState, props.value, e.currentTarget.id) 
    } 
  }

  const classFunc = (multiple: boolean | undefined, checked: boolean | undefined): string => {
    if (multiple && checked) {
      return 't-security-systems-box-multiple active'
    }
    else if (checked) {
      return 't-security-systems-box active'
    }
    else if (multiple) {
      return 't-security-systems-box-multiple'
    }
    else {
      return 't-security-systems-box'
    }
  }

  return (
    <div className={classFunc(props.multiple, props.checked)} tabIndex={0} onKeyDown={handleKeyDown} id={divId} onClick={(e) => handleLabelClick(props.setState, props.value, e.currentTarget.id)}>
      <div className="security-systems-div">
          <label htmlFor={props.label} className={`security-systems-label ${props.string}`}>{props.label}</label>
          <input type="radio" name="trinary" id={props.value} value={props.value} checked={props.checked} onChange={handleRadioChange} />
      </div>  
    </div>
  )
}

export default AverageRadioButtons