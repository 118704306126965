import './RefreshStyles.css'
import { useState } from 'react';
import { Dispatch, SetStateAction, useEffect } from 'react';

type TextInputProps = {
    placeholder: string
    setState?: Dispatch<SetStateAction<string>>,
    setNumberState?: Dispatch<SetStateAction<any>>,
    lsValue?: any;
    label?: string;
    type?: any
    setStateNumber?:  Dispatch<SetStateAction<number | null>>;
    onChange?: (text: string) => void;
    maxLength?: number;
    initialValue?: any
    howOld?: any
  };



const TextInput = (props: TextInputProps) => {


    useEffect(() => {
      if (props.initialValue) setValue(props.initialValue)
    }, [props.initialValue])

    const [value, setValue] = useState('')
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const inputValue = e.target.value;

        if (inputValue.length > (props.maxLength ?? Infinity)) {
          setValue(inputValue.slice(0, props.maxLength));
        } else {
          setValue(inputValue);
        }
    

        if (props.setState) props.setState(inputValue);
        if (props.setStateNumber) props.setStateNumber(Number(inputValue));
        if (props.onChange) props.onChange(inputValue)
      };

  return (
    <div className="address-form-div">
        <input className="text-form" maxLength={props.maxLength} placeholder={props.placeholder} onChange={handleChange} type={props.type === "number" ? "number" : "string"}  value={props.lsValue ? props.lsValue : props.initialValue ? props.initialValue : value}>
        </input>
    </div>
  )
}

export default TextInput