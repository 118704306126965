import { useContext, useState, useEffect } from "react";
import Question from "../../../components/refresh/Question";
import { texts } from "../../../text";
import {
  LanguageContext, MyHomeContext, HomeStepContext, HomeTypeContext
} from "../HomeContext";
import Footer from "../../../components/footer/Footer";
import ModalBox from "../../../components/ModalBox";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import "../../../components/refresh/RefreshStyles.css"
import QuebecProvince from "../../../components/refresh/assets/QuebecProvince";
import OntarioProvince from "../../../components/refresh/assets/OntarioProvince";

function StepOne() {
  const { language } = useContext(LanguageContext);
  const { setHomeFormData } = useContext(MyHomeContext);
  const { setFormSteps } = useContext(HomeStepContext);
  const { setHomeType } = useContext(HomeTypeContext);
  const [accept, setAccept] = useState<boolean | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [resume, setResume] = useState<string>("");
  const [provinceSelector, setProvinceSelector] = useState('')

  useEffect(() => {
    const step = localStorage.getItem("step");

    if (step) {
      setShowModal(true);
      if (resume === "Yes") {
        setShowModal(false);
        const homeData = Object.keys(localStorage);
        homeData.forEach((key) => {
          if (key !== "type" && key !== "step") {
            setHomeFormData((homeFormData: any) => ({
              ...homeFormData,
              [key]: localStorage.getItem(key),
            }));
          }

          if (key === "type") {
            setHomeType(localStorage.getItem(key));
          }
          setFormSteps(parseInt(step));
        });
      }

      if (resume === "No") {
        setShowModal(false);
        localStorage.clear();
      }
    } else {
      setShowModal(false);
    }
  }, [resume]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <ModalBox
        onClick={(e) => {
          setResume(e);
        }}
        open={showModal}
      />
      <Question
        question={texts[language].beforeStartQuestion}
        subQuestion={
          accept === false
            ? `${texts[language].residentOf}`
            :
            `${texts[language].residentOf}`
        }
      />
        <RadioButtonGroup setProvinceSelector={setProvinceSelector} setAccept={setAccept} accept={accept} labelLeft={texts[language].ontario} labelRight={texts[language].quebec} iconLeft={<OntarioProvince />} iconRight={<QuebecProvince />}/>

      <Footer
        formSteps={0.5}
        type="submit"
        disabled={accept === true && provinceSelector === 'Ontario' ? false : accept === null ? true : false}
        backHidden={true}
        province={provinceSelector}
      />
    </div>
    </div>
  );
}

export default StepOne;
