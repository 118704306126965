import React from "react";
import "./questionStyles.css";
// import "../refresh/RefreshStyles.css"

type questionProps = {
  question: string;
  subQuestion?: string;
};

function Question(props: questionProps) {
  return (
    <div className="question-div">
      <div className="question">
        <p className="questions-heading" data-testid="step-question">
          {props.question}
        </p>
      </div>
      { props.subQuestion ?
        <div className="subQuestion">
          <p className="question-label" data-testid="step-sub-question">
            {props.subQuestion}
          </p>
        </div> : null 
      }
    </div>
  );
}

export default Question;
