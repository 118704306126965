import { useContext } from "react";
import ArrowRight from "./assets/ArrowRight";
import {
  HomeStepContext,
  HomeTypeContext,
  LanguageContext,
  MyHomeContext,
} from "../../quotes/shared/HomeContext";

type buttonProps = {
  data?: {};
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  formSteps?: number;
  progress?: number;
  hidden?: boolean;
  onClick?: () => void
};

function NextButton(props: buttonProps) {
  const { setHomeFormData, homeFormData } = useContext(MyHomeContext);
  const { setFormSteps } = useContext(HomeStepContext);
  const { setHomeType } = useContext(HomeTypeContext);

  function handleSubmit(e: any) {
    e.preventDefault();
    const data: any = props.data;
    for (const field in data) {
      if (data[field] === null || data[field] === undefined) {
        localStorage.setItem(field, "");
      } else if (field === "type") {
        setHomeType(data[field]);
        localStorage.setItem("type", data[field]);
      } else {
        setHomeFormData((homeFormData: any) => ({
          ...homeFormData,
          [field]: data[field],
        }));
        localStorage.setItem(field, data[field].toString());
      }
    }

    setFormSteps(props.formSteps);
    if (props.formSteps) {
      localStorage.setItem("step", props.formSteps.toString());
    }

console.log(homeFormData);
  }

  return (
    <button
      style={props.hidden ? { display: "none" } : {}}
      data-testid="next-btn"
      className="btn pink text-with-arrow-left footer-btns"
      disabled={props.disabled}
      type={props.type}
      onClick={(e: any) => {
        handleSubmit(e);
      }}
      id="nextButton"
    >
      <div>
                <ArrowRight />
        </div>
    </button>
  );
}

export default NextButton;
