import { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";

function CarStepNineteen() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [currentPolicy, setCurrentPolicy] = useState<string>('');
  const [currentCompany, setCurrentCompany] = useState("");
  const LSCurrentPolicy = useLocalStorage("currentPolicy");
  const LSHasPolicy = useLocalStorage("hasPolicy")
  const [hasPolicy, setHasPolicy] = useState<boolean | null>(null);
  const [valid, setValid] = useState<boolean | null>(null)
  const insuranceList = [
    "Acme Offer Demo",
    "Alberta Insurance Reform",
    "Alberta Motor Association",
    "Algoma Mutual",
    "Allstate",
    "AMI Autoglass Insurance, $50 Deductible",
    "Assurnat",
    "Aviva",
    "Aviva Elite",
    "Aviva Traders",
    "Ayr Farmers Mutual",
    "BCAA Insurance Corporation",
    "Belair Direct",
    "Bertie &amp; Clinton Mutual",
    "Brant Mutual",
    "CAA Insurance Company",
    "CAA Insurance Company (Future Member)",
    "CAA MyPace",
    "Canadian Northern Shield",
    "Caradoc Townsend Mutual Insurance Co",
    "Carleton-Fundy Mutual Insurance Company",
    "Cayuga Mutual",
    "Chieftain Insurance",
    "Chubb Insurance Company",
    "Coachman",
    "Coseco",
    "Cumis Insurance",
    "Custom",
    "Custom-Lloyds",
    "Desjardins Assurances",
    "Dominion of Canada",
    "Dominion of Canada - IntelliDrive",
    "Dominion of Canada - Single Pay and DPD",
    "Dufferin Insurance",
    "Dumfries Mutual",
    "Echelon",
    "Echelon Moto",
    "Economical Mutual",
    "Edge Mutual Insurance Company",
    "Elite (Non-Autograph)",
    "Exclusive CARP Auto Rate",
    "Facility (old rating)",
    "Facility Association",
    "Family Insurance",
    "Family Insurance Solutions",
    "Family Platinum",
    "Future Guard",
    "Germania Mutual Insurance",
    "Gore",
    "Gore Mutual Sophisticated Rating",
    "Grenville Mutual Insurance Company",
    "Groupe Promutuel",
    "Guarantee Company of North America",
    "Halwell Mutual",
    "Heartland - Thamesford",
    "Heartland - Waterloo",
    "Heartland - Waterloo Policy - Farm",
    "Howard Mutual",
    "Howick Mutual",
    "HTM Insurance",
    "Hub Advantage",
    "Industrielle Alliance",
    "Insurance Company of P.E.I.",
    "Insurance Company of P.E.I. Group 1",
    "Insurance Company of P.E.I. Group 2",
    "Insurative Solutions",
    "InsureBC Select",
    "Intact (West) - my Drive",
    "Intact Assurance Avantage bonne conduite",
    "Intact Assurance Avantages bien chez-soi",
    "Intact Assurance Offre 1 an",
    "Intact Assurance Programme Solution",
    "Intact Insurance",
    "Intact Insurance - my Drive",
    "Intact Insurance (West)",
    "Intergroupe Assurances",
    "JEVCO",
    "JEVCO Ass.",
    "Kent &amp; Essex Mutual",
    "King's Mutual",
    "L'Alpha",
    "L'Unique",
    "L'Unique (24 mois)",
    "L'Unique Tarif Opti-Mise",
    "L'Unique Tarif Opti-Mise (24 mois)",
    "L&amp;A Mutual Insurance Company",
    "Lambton Mutual",
    "Leclerc - VR Tarif",
    "LEDOR Assurances",
    "Lloyd's (Ultima)",
    "Lloyd's SCN-NBS",
    "Lloyd's(Assur Experts)",
    "Marsh PCS - Dominion Group",
    "Max Insurance",
    "McKillop Mutual Insurance Company",
    "Megson FitzPatrick Insurance",
    "Metro General Insurance Corp. Ltd.",
    "MHK Insurance",
    "Middlesex Mutual",
    "Milnco Insurance",
    "Missisquoi",
    "Missisquoi (2 ans)",
    "Missisquoi (3 ans)",
    "Mutual Fire Insurance",
    "My Mutual Insurance",
    "New Business",
    "Niche Assurance",
    "North Blenheim Mutual",
    "North Kent Mutual Insurance",
    "Northbridge General",
    "Nova Mutual Insurance Company",
    "Novex",
    "Novex - my Driving discount",
    "Optimum",
    "Optimum Insurance Company",
    "Optimum Societe d'Assurance inc-Sogedent",
    "Optimum West Insurance",
    "Pafco",
    "Peace Hills",
    "Peel Mutual Insurance",
    "Pembridge",
    "Perth Insurance",
    "Pilot",
    "Plates Plus",
    "Portage La Prairie Mutual",
    "Prestige (KOO)",
    "Primaco",
    "Primmum",
    "Programme SecurPlus",
    "Promutuel",
    "Promutuel Assurance",
    "Promutuel Assurance Centre-Sud",
    "Promutuel Assurance du Lac au Fleuve",
    "Promutuel Assurance Rive - Sud",
    "Promutuel Bois-Francs",
    "Promutuel de L'Estuaire",
    "Promutuel Deux-Montagnes",
    "Promutuel Montmagny-L'Islet",
    "Promutuel Portneuf-Champlain",
    "Promutuel Vaudreuil-Soulanges",
    "RateBridge Demo",
    "RBC Insurance Company of Canada",
    "Red River Mutual",
    "Residual Market",
    "Right For Me (TM)",
    "Royal &amp; SunAlliance",
    "Royal &amp; SunAlliance Atrens-Counsel",
    "RSA (formerly Colonial)",
    "RSA Assurance",
    "S&amp;Y Insurance",
    "Saskatchewan Mutual",
    "Scottish &amp; York",
    "SGI CANADA",
    "Shaw Sabey &amp; Associates Ltd",
    "Smart Coverage Insurance",
    "Smart Coverage Insurance (Broadform)",
    "South Easthope Mutual Insurance",
    "SouthEastern Mutual Insurance",
    "Sovereign General",
    "Square One Insurance Services Inc.",
    "SSA-ECON",
    "St. Paul Fire and Marine Insurance Co.",
    "Stanley Mutual Insurance Company",
    "State Farm Insurance",
    "TD General Insurance Company",
    "TD Home &amp; Auto",
    "The Bay of Quinte Mutual",
    "The Commonwell Mutual Insurance Group",
    "The Waterloo",
    "Town and Country Mutual Insurance",
    "Tradition Mutual Insurance Company",
    "Trafalgar",
    "Travelers Insurance Company of Canada",
    "Trillium Mutual Insurance Company",
    "Unica Insurance Inc.",
    "Unifund Assurance Company",
    "United General Insurance Corporation",
    "Usborne and Hibbert Mutual",
    "Wawanesa Farm",
    "Wawanesa Mutual",
    "Waypoint Insurance",
    "West Elgin Mutual",
    "West Wawanosh Mutual",
    "Western Assurance Company",
    "Western Coast Insurance Services Ltd",
    "Western Direct Insurance",
    "Westminster Mutual",
    "Yarmouth Mutual Insurance Company",
    "Zenith Insurance",
    "Zenith Insurance Company",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (LSCurrentPolicy) {
      setCurrentPolicy(LSCurrentPolicy);
    }
    if (LSCurrentPolicy === "No") {
      setHasPolicy(false)
    }
    if (LSCurrentPolicy === "1 Year" || LSCurrentPolicy === "2 Years" || LSCurrentPolicy === "3 Years" || LSCurrentPolicy === "4 Years" || LSCurrentPolicy === "5+ Years") {
      setHasPolicy(true)
    }
    if (LSHasPolicy === false) {
      setHasPolicy(false)
    }
  }, [LSCurrentPolicy, LSHasPolicy]);


  useEffect(() => {
    if (currentPolicy === carTexts["fr"].oneYear) {
      setCurrentPolicy(carTexts["en"].oneYear)
    }
    if (currentPolicy === carTexts["fr"].twoYears) {
      setCurrentPolicy(carTexts["en"].twoYears)
    }
    if (currentPolicy === carTexts["fr"].threeYears) {
      setCurrentPolicy(carTexts["en"].threeYears)
    }
    if (currentPolicy === carTexts["fr"].fourYears) {
      setCurrentPolicy(carTexts["en"].fourYears)
    }
    if (currentPolicy === carTexts["fr"].fiveYears) {
      setCurrentPolicy(carTexts["en"].fiveYears)
    }
  }, [currentPolicy])

  const currentPolicyList = [carTexts[carLanguage].oneYear, carTexts[carLanguage].twoYears, carTexts[carLanguage].threeYears, carTexts[carLanguage].fourYears, carTexts[carLanguage].fiveYears]

useEffect(() => {
  if (hasPolicy === false) {
    setCurrentPolicy(carTexts["en"].no)
    setCurrentCompany('')
  }
}, [hasPolicy])

useEffect(() => {
  if (hasPolicy === null) {
    setValid(false); 
  } else if (hasPolicy) {
    if (currentPolicy !== "") {
      setValid(true); 
    } else {
      setValid(false); 
    }
  } else if (hasPolicy === false && currentPolicy === "No") {
    setValid(true); 
  }
}, [hasPolicy, currentPolicy, currentCompany]);


  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].haveCurrentPolicy}

      />
      <RadioButtonGroup setAccept={setHasPolicy} accept={hasPolicy} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>

      {hasPolicy ? (
        <div>
          <p className="dropdown-title">{carTexts[carLanguage].howLongInsurance} </p>
          <DropdownMenu type={'policy'} list={currentPolicyList} setState={setCurrentPolicy} placeholder={carTexts[carLanguage].select} lsValue={'currentPolicy'}/>

            {/* <p className="dropdown-title">{carTexts[carLanguage].currentInsuranceCompany}</p>
                <DropdownMenu list={insuranceList} setState={setCurrentCompany} placeholder={carTexts[carLanguage].select} lsValue={'currentCompany'}/> */}
          </div>
      ) : null}
      <CarFooter
        data={{
          currentPolicy: currentPolicy,
          currentCompany: "Desjardins Assurances",
          hasPolicy: hasPolicy
        }}
        formSteps={21}
        backFormSteps={18}
        type="submit"
        disabled={
          !valid
        }
      />
    </div>
    </div>
  );
}

export default CarStepNineteen;
