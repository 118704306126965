
const Restart = () => {
    return (
      <><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="#B0B0AD"/>
      <path d="M6.45508 7.125H7.4375C7.7832 7.125 8.0625 7.4043 8.0625 7.75C8.0625 8.0957 7.7832 8.375 7.4375 8.375H4.9375C4.5918 8.375 4.3125 8.0957 4.3125 7.75V5.25C4.3125 4.9043 4.5918 4.625 4.9375 4.625C5.2832 4.625 5.5625 4.9043 5.5625 5.25V6.25L5.90625 5.90625C7.61523 4.19727 10.3848 4.19727 12.0937 5.90625C13.8027 7.61523 13.8027 10.3848 12.0937 12.0937C10.3848 13.8027 7.61523 13.8027 5.90625 12.0937C5.66211 11.8496 5.66211 11.4531 5.90625 11.209C6.15039 10.9648 6.54687 10.9648 6.79102 11.209C8.01172 12.4297 9.99023 12.4297 11.2109 11.209C12.4316 9.98828 12.4316 8.00977 11.2109 6.78906C9.99023 5.56836 8.01172 5.56836 6.79102 6.78906L6.45508 7.125Z" fill="white"/>
      </svg>
      </>
    )
  }
  
  export default Restart