import { useContext, useState, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import Question from "../../../components/refresh/Question";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";

function CarStepTwentySeven() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [creditCheck, setCreditCheck] = useState<boolean | null>(null);
  const LSCreditCheck = useLocalStorage("creditCheck");

  useEffect(() => {
    if (LSCreditCheck) {
      setCreditCheck(LSCreditCheck === "true");
    }
  }, [LSCreditCheck]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].saveCreditCheck}
        subQuestion={carTexts[carLanguage].affectScore}
      />
      <RadioButtonGroup setAccept={setCreditCheck} accept={creditCheck} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>

      <CarFooter
        data={{
          creditCheck: creditCheck,
        }}
        formSteps={28}
        type="submit"
        disabled={creditCheck === null}
        backFormSteps={26}
      />
    </div>
    </div>
  );
}

export default CarStepTwentySeven;
