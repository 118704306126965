import './RefreshStyles.css'
import { useState } from 'react';
import MapMarker from './assets/MapMarker';

type AddressInputProps = {
    inputRef: React.LegacyRef<HTMLInputElement> | undefined
    placeholder: any
    defaultValue?: any
    lsValue?: any;
  };

const AddressInput = (props: AddressInputProps) => {
    const [address, setAddress] = useState('')
  return (
    <div className="address-form-div">
        <span className="map-marker-icon"><MapMarker /></span>
        <input className="address-form" placeholder={props.placeholder} ref={props.inputRef} onChange={(event) => setAddress(event.target.value)}>
        </input>
    </div>
  )
}

export default AddressInput