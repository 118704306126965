import ArrowLeft from "./assets/ArrowLeft"
import './RefreshStyles.css'
import { useContext } from "react";
import {
    CarStepContext,
    CarLanguageContext,
  } from "../../quotes/car/CarContext"
import useIsMobile from "../hooks/useIsMobile";
import { texts } from "../../text";

type BackButtonProps = {
  hidden?: boolean;
  newQuote?: boolean;
  disabled?: boolean;
  backFormSteps?: number;
};

const CarBackButton = (props: BackButtonProps) => {

    const { carFormSteps, setCarFormSteps } = useContext(CarStepContext);
    const { carLanguage } = useContext<any>(CarLanguageContext);
    const isMobile = useIsMobile();
  
    const handleClick = () => {
      setCarFormSteps(props.backFormSteps);
      if (props.backFormSteps) {
        localStorage.setItem("carStep", props.backFormSteps.toString());
      }
    };
    const handleNewQuote = () => {
      setCarFormSteps(1);
      localStorage.clear();
    };

  if (props.newQuote) {
    return (

      <button className="quote-buy-button new" onClick={handleNewQuote}>
        {texts[carLanguage].startOver}
      </button>

  )
} else {
  return (
    <button
      style={
        props.hidden
          ? isMobile
            ? { display: "none" }
            : { display: "none" }
          : {}
      }
      className="btn pink with-arrow-right footer-btns"
      disabled={carFormSteps === 1}
      onClick={handleClick}
    >
            <div>
                <ArrowLeft />
            </div>
    </button>
  );
}
}

export default CarBackButton