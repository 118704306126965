
const QuebecProvince = () => {
  return (
    <>
<svg id="Layer_1" width="51" height="51" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51">
  <defs>
  </defs>
  <circle className="cls-1" cx="25.5" cy="25.5" r="25.5" fill={"#f6f6f3"} />
  <path className="cls-2" fill={"#0047b2"} d="m16.71,22.52c.74-1.53-1.56-4.33-2.6-4.5l-.32-1.57c1.09-.91-.43-2.94-.43-2.94l-.44-.06-.06-1.32c.37.06.2-.22.2-.22-.19-.2-.99-.41-.99-.41.24-.45,1.02-1.9,1.02-1.9,0,0,.74.31,1.25.34,1.14.07,1.61-.72,1.61-.72l2.33.95c.09,1.02,1.53.39,1.53.39,0,0,.15.2.49.19.26,0,.29-.37.29-.37.35.24.28.5.07.97s.52,1.56,1.15,1.95.54.76.54.76c0,0,.74-.32,1.17.2s1.45.34,1.36-.61.17,0,.43-.97-.56-1.55-.32-1.94.02-1.08.02-1.08c0,0,.5.5.61,1.09.07.39.55.32.55.32,0,.36.23.83.59.97l.32-.29.31.21s-.09.22-.2.35l.37.32c-.36.36-.06.99.3.85s.99.87,1.04,1.52.39,1.64.72,1.71.29-.47.57-.25-.35.61.25,1.05.86.66.58,1.01.17.43.17.43c-.15.21-.15.29-.54.18s-.57.53-.62.23-.46-.22-.46-.22c0,0,.17.29-.15.62s-.29-.07-.79-.06-.96-.33-.96-.33c0,0,.67.95.43,1.02s-.84-.44-.84-.44l-.11.17s.18.19.52.35.21.28-.19.81.79,1.41,1.26,1.34l.39.77c-.43.15,0,.35.33.51l.29-.8.56.1.09,1.13h2.89s-.61-1.27-.73-1.66l.46-.57.69,1.32,6.76-4,.48.83s-2.93,4.84-3.16,5.18-.19.26-.55.08c0,0-5.19,3.11-5.47,3.7l-.04,1.1-3.4,6.65.39-.47s1.99-3.11,2.32-3.76c1.16-2.3,3.04-3.34,3.78-3.57s1.34.04,1.49.86-.92,1.53-1.59,1.55-1.28,1.07-1.53,1.13l-.49.06-.85,2.04-.67.16-1,4.73-4.15,2.08s-4.47,0-5.97-.79c-1.12-.59-1.89-1.21-2.12-2.05s-1.99-7.63-1.99-7.63c0,0,.71-.39.96-1.41s-.47-.88-.96-2.26-1.54-2.29-1.54-2.29c0,0,1.57-1.29,2.32-2.82Zm17.83,5.47l.24.34c.29.04,2.15.05,2.8-.12l.19-.78c-1.15-.24-1.89-.22-3.23.56Z"/>
</svg>
</>
  )
}

export default QuebecProvince