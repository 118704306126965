import { useContext, useState, useEffect } from "react";
import Footer from "../../../components/footer/Footer";
import { texts } from "../../../text";
import { LanguageContext } from "../HomeContext";
import Question from "../../../components/refresh/Question";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";

function StepEight() {
  const { language } = useContext(LanguageContext);
  const [yearList, setYearList] = useState<string[]>([]);
  const [yearMovedIn, setYearMovedIn] = useState<string>("");
  const LSYear = useLocalStorage("yearMovedIn");

  useEffect(() => {
    if (LSYear) {
      setYearMovedIn(LSYear);
    }
  }, [LSYear]);

  useEffect(() => {
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1923; i < currentYear + 1; i++) {
      years.push(i.toString());
    }
    setYearList(years.reverse());
    setYearMovedIn(currentYear.toString());
  }, []);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={texts[language].yearMovedIn} />
      <DropdownMenu 
      list={yearList}
      lsValue={'yearMovedIn'}
      setState={setYearMovedIn}
      placeholder={texts[language].year}
      />

      <Footer
        type="submit"
        data={{ yearMovedIn: yearMovedIn }}
        formSteps={9}
        disabled={yearMovedIn === "" ? true : false}
      />
    </div>
    </div>
  );
}

export default StepEight;
