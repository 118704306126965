import { useEffect, useState, useContext } from "react";
import CarProgressBar from "../../components/progressBar/CarProgressBar";
import { carTexts } from "../../text";
import Header from "../../components/refresh/Header";
import { CarStepContext } from "../car/CarContext";
import CarStepZero from "./step0/CarStepZero";
import CarStepOne from "./step1/CarStepOne";
import CarStepTen from "./step10/CarStepTen";
import CarStepEleven from "./step11/CarStepEleven";
import CarStepTwelve from "./step12/CarStepTwelve";
import CarStepThirteen from "./step13/CarStepThirteen";
import CarStepTwo from "./step2/CarStepTwo";
import CarStepThree from "./step3/CarStepThree";
import CarStepFour from "./Step4/CarStepFour";
import CarStepFive from "./step5/CarStepFive";
import CarStepSix from "./step6/CarStepSix";
import CarStepSeven from "./step7/CarStepSeven";
import CarStepEight from "./step8/CarStepEight";
import CarStepNine from "./step9/CarStepNine";
import CarStepFourteen from "./step14/CarStepFourteen";
import CarStepFifteen from "./step15/CarStepFifteen";
import CarStepSixteen from "./step16.tsx/CarStepSixteen";
import CarStepSeventeen from "./step17/CarStepSeventeen";
import CarStepEighteen from "./step18/CarStepEighteen";
import CarStepNineteen from "./step19/CarStepNineteen";
import CarStepTwenty from "./step20/CarStepTwenty";
import CarStepTwentyOne from "./step21/CarStepTwentyOne";
import CarStepTwentyTwo from "./step22/CarStepTwentyTwo";
import CarStepTwentyThree from "./step23/CarStepTwentyThree";
import CarStepTwentyFour from "./step24/CarStepTwentyFour";
import CarStepTwentyFive from "./step25/CarStepTwentyFive";
import CarStepTwentySix from "./step26/CarStepTwentySix";
import CarStepTwentySeven from "./step27/CarStepTwentySeven";
import CarStepTwentyEight from "./step28/CarStepTwentyEight";
import { CarLanguageContext } from "./CarContext";
import { useLocation } from "react-router";
import CarStepOnePointFive from "./step1.5/CarStepOnePointFive";

function CarQuoter() {
  const [width, setWidth] = useState(0);
  const { carFormSteps } = useContext(CarStepContext);
  const { setCarLanguage, carLanguage } = useContext(CarLanguageContext);
  const location = useLocation();
  const year = new Date().getFullYear();

  console.log(year)

  useEffect(() => {
    
    setWidth(window.innerWidth);
    if (location.state) {
      setCarLanguage(location.state);
    }

    const pathname = window.location.pathname;
    if (pathname.includes("fr")) {
      setCarLanguage("fr");
    } else {
      setCarLanguage("en");
    }

    return () => {
      const step = localStorage.getItem("carStep");
    };

  }, []);

  useEffect(() => {
    if (carLanguage === "fr") {
      document.title = "Assurance KBD";
    } else {
      document.title = "KBD Insurance";
    }
  }, [carLanguage]);

  return (
    <div>
      <div className="t-main">
        <Header />
        {carFormSteps >= 3 ? <CarProgressBar /> : null}
        {carFormSteps === 0 ? <CarStepZero /> : null}
        {carFormSteps === 1 ? <CarStepOne /> : null}
        {carFormSteps === 1.5 ? <CarStepOnePointFive /> : null}
        {carFormSteps === 2 ? <CarStepTwo /> : null}
        {carFormSteps === 3 ? <CarStepThree /> : null}
        {carFormSteps === 4 ? <CarStepFour /> : null}
        {carFormSteps === 5 ? <CarStepFive /> : null}
        {carFormSteps === 6 ? <CarStepSix /> : null}
        {carFormSteps === 7 ? <CarStepSeven /> : null}
        {carFormSteps === 8 ? <CarStepEight /> : null}
        {carFormSteps === 9 ? <CarStepNine /> : null}
        {carFormSteps === 10 ? <CarStepTen /> : null}
        {carFormSteps === 11 ? <CarStepEleven /> : null}
        {carFormSteps === 12 ? <CarStepTwelve /> : null}
        {carFormSteps === 13 ? <CarStepThirteen /> : null}
        {carFormSteps === 14 ? <CarStepFourteen /> : null}
        {carFormSteps === 15 ? <CarStepFifteen /> : null}
        {carFormSteps === 16 ? <CarStepSixteen /> : null}
        {carFormSteps === 17 ? <CarStepSeventeen /> : null}
        {carFormSteps === 18 ? <CarStepEighteen /> : null}
        {carFormSteps === 19 ? <CarStepNineteen /> : null}
        {carFormSteps === 20 ? <CarStepTwenty /> : null}
        {carFormSteps === 21 ? <CarStepTwentyOne /> : null}
        {carFormSteps === 22 ? <CarStepTwentyTwo /> : null}
        {carFormSteps === 23 ? <CarStepTwentyThree /> : null}
        {carFormSteps === 24 ? <CarStepTwentyFour /> : null}
        {carFormSteps === 25 ? <CarStepTwentyFive /> : null}
        {carFormSteps === 26 ? <CarStepTwentySix /> : null}
        {carFormSteps === 27 ? <CarStepTwentySeven /> : null}
        {carFormSteps === 28 ? <CarStepTwentyEight /> : null}
        <div className="t-container">
          <div className="t-content-container">
        <div className="copyright">
          <p>&copy;{year} {carTexts[carLanguage].copyright}</p>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default CarQuoter;
