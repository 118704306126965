import ArrowRight from "./assets/ArrowRight";
import { LanguageContext } from "../../quotes/shared/HomeContext";
import { CarLanguageContext } from "../../quotes/car/CarContext";
import { texts, carTexts } from "../../text";
import { useContext, useEffect, useState } from "react";
import Question from "../refresh/Question";


const OntarioButton = () => {
    const { language } = useContext(LanguageContext)
    const { carLanguage } = useContext(CarLanguageContext)
    const [mobile, setMobile] = useState<boolean>(false)


    useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 576px)");
    
      const handleMediaQueryChange = (e: MediaQueryListEvent) => {
        setMobile(e.matches);
      };
    
      // Set initial value
      setMobile(mediaQuery.matches);
    
      // Add listener for changes
      mediaQuery.addEventListener("change", handleMediaQueryChange);
    
      // Cleanup
      return () => {
        mediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }, []);

    return (
        <div className="ontario-button">
            {mobile ? (
            <>
              {language ? 
              <>
              <Question
                subQuestion={texts[language].forOntario}
                className={"ontario"}
              /> 
              <button
                className="btn pink text-with-arrow-left footer-btns"
                onClick={() => window.open('https://webrater.appliedsystems.com/WR/Default.aspx?GC=KBDI&LANG=EN&LOB=PROP', '_blank')}
                type="button"
              >
              <div>
                <ArrowRight />
              </div>
              </button>
              </>
              : 
              <>
              <Question
                subQuestion={carTexts[carLanguage].forOntario}
                className={"ontario"}
              /> 
              <button
                className="btn pink text-with-arrow-left footer-btns"
                onClick={() => window.open('https://webrater.appliedsystems.com/WR/Default.aspx?GC=KBDI&LANG=EN&LOB=AUTO', '_blank')}
                type="button"
              >
              <div>
                <ArrowRight />
              </div>
              </button>
            </>
            }
            </>
            ) : (
            <>
              {language ? 
              <>
              <button
                className="btn pink text-with-arrow-left footer-btns"
                onClick={() => window.open('https://webrater.appliedsystems.com/WR/Default.aspx?GC=KBDI&LANG=EN&LOB=PROP', '_blank')}
                type="button"
                >
              <div>
                <ArrowRight />
              </div>
              </button>  
              <Question
                subQuestion={texts[language].forOntario}
                className={"ontario"}
              />
            </>
              :
            <>
              <button
                className="btn pink text-with-arrow-left footer-btns"
                onClick={() => window.open('https://webrater.appliedsystems.com/WR/Default.aspx?GC=KBDI&LANG=EN&LOB=AUTO', '_blank')}
                type="button"
              >
              <div>
                <ArrowRight />
              </div>
              </button>  
              <Question
                subQuestion={carTexts[carLanguage].forOntario}
                className={"ontario"}
              />
            </>
              }
            </>
          )}
        </div>
      );
    }

export default OntarioButton