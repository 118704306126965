import './RefreshStyles.css'
import Logo from './assets/Logo'
import DarkButton from './assets/DarkButton'
import Restart from './assets/RestartButton'
import DarkSun from './assets/DarkSun'
import HelpModal from '../refresh/HelpModal'
import { useEffect, useContext, useState, KeyboardEvent } from 'react'
import { LanguageContext } from "../../quotes/shared/HomeContext";
import RestartModal from './RestartModal'
import { CarLanguageContext } from '../../quotes/car/CarContext'
import { useNavigate } from "react-router-dom";
import Phone from './assets/Phone'
import { ThemeContext } from './ThemeContext'
import DarkMoon from './assets/DarkMoon'


const Header = () => {
    const [showModal, setShowModal] = useState(false)
    const [restartModal, setRestartModal] = useState(false)
    const { darkMode, toggleDarkMode } = useContext(ThemeContext)
    const { language, setLanguage } = useContext(LanguageContext);
    const { carLanguage, setCarLanguage } = useContext(CarLanguageContext);
    const navigate = useNavigate();

    useEffect(() => {
      document.addEventListener("wheel", handleWheelEvent);
      return () => {
        document.removeEventListener("wheel", handleWheelEvent);
      };
    }, []);
  
    const handleWheelEvent = (event: WheelEvent) => {
      const activeElement = document.activeElement as HTMLInputElement;
      if (activeElement && activeElement.type === "number") {
        (activeElement as HTMLElement).blur();
      }
    };

      const handleModalClose = () => {
        setShowModal(false);
        document.body.style.overflow = 'auto';
      }

      const handleRestartModalOpen = () => {
        setRestartModal(true)
        document.body.style.overflow = "hidden";
      }

      const handleRestartModalClose = () => {
        setRestartModal(false)
        document.body.style.overflow = "auto";
      }

      const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 13) {
          toggleDarkMode();
        }
      }

      function handleEnglish() {
        if (carLanguage && carLanguage === "en") {
          return;
        } else if (carLanguage && carLanguage !== "en") {
          setCarLanguage("en")
          localStorage.setItem("language", "en");
          navigate("/car/en");
        } else if (language && language === "en") {
          return
        } else if (language && language !== "en" && window.location.pathname === "/") {
          setLanguage("en")
          localStorage.setItem("language", "en");
          navigate("/")
        }
         else if (language && language !== "en") {
          setLanguage("en");
          localStorage.setItem("language", "en");
          navigate("/home/en")
        }

      }
    
      function handleFrench() {
        if (carLanguage && carLanguage === "fr") {
          return;
        } else if (carLanguage && carLanguage !== "fr") {
          setCarLanguage("fr")
          localStorage.setItem("language", "fr");
          navigate("/car/fr");
        } else if (language && language === "fr") {
          return
        } else if (language && language !== "fr" && window.location.pathname === "/") {
          setLanguage("fr")
          localStorage.setItem("language", "fr");
          navigate("/")
        }
         else if (language && language !== "fr") {
          setLanguage("fr")
          localStorage.setItem("language", "fr");
          navigate("/home/fr")
        }
      }

      console.log(darkMode)
  return (
    <>
        <div className="t-header">
          <div className="t-container">
          <div className="header-left">
                <div className="t-kbd-logo">
                <a href="https://kbdinsurance.com/" aria-label="go to homesite" target="_blank" rel="noopener noreferrer">
                    <Logo />
                    </a>
                </div>
            </div>
            <div className="header-right">
                    <div className="header-lang">
                      
                        <button className={`t-language-button ${language === "en" ? 'active' : ''} ${carLanguage === "en" ? 'active' : ''}`}
                        onClick={(e) => {
                          handleEnglish();
                        }}
                        >EN</button>
                        <button className={`t-language-button ${language === "fr" ? 'active' : ''} ${carLanguage === "fr" ? 'active' : ''}`}  
                        onClick={(e) => {
                        handleFrench();
                        }}
                        >FR</button>
                    </div>

                      <div className="header-theme">
                          <div className={darkMode ? "toggle-switch active" : "toggle-switch"} onKeyDown={handleKeyDown} tabIndex={0} onClick={toggleDarkMode}>
                              <DarkButton /> 
                              <div className={darkMode ? "toggle-circle active" : "toggle-circle"}></div>
                              <div className="dark-sun"><DarkSun /></div>
                          </div>
                          
                      </div>

                    <div className="header-icons">
                        <button className="icon-button restart-button" id="al" aria-label="restart" onClick={handleRestartModalOpen}><Restart /></button>
                        <p 
                        className="security-systems-popup restart">Restart</p>
                    </div>

                    {showModal && <HelpModal handleModalClose={handleModalClose}/>}
                    {restartModal && <RestartModal handleRestartModalClose={handleRestartModalClose} setRestartModal={setRestartModal}/>}
                      <div className="num">
                        { carLanguage === "en" || language === "en" ?
                        <a href='tel:5146360002'>514-636-0002</a> :
                        <a href='tel:5146360002'>514-636-0002</a>
                        }
                      </div>
                        <button className="phone-icon">
                        <a href='tel:5146360002'>
                        <Phone />
                        </a>
                        </button>  
              </div>
          </div>
        </div>
    </>
  )
}

export default Header