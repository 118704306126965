import { useState, } from "react";
import CarNextButton from "../refresh/CarNextButton"
import CarBackButton from "../refresh/CarBackButton";
import OntarioButton from "../refresh/OntarioButton";

type FooterProps = {
  data?: {};
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  formSteps?: number;
  progress?: number;
  backHidden?: boolean;
  nextHidden?: boolean;
  newQuote?: boolean;
  province?: string;
  backFormSteps?: number;
};

function CarFooter(props: FooterProps) {
  const [isBackHidden, setIsBackHidden] = useState(false);

  return (

        <div className="footer-buttons">
          { isBackHidden ? 
          null :
          <CarBackButton
          hidden={props.backHidden}
          newQuote={props.newQuote}
          backFormSteps={props.backFormSteps}
          />  } {props.province === 'Quebec' ? <OntarioButton /> : 
            <CarNextButton
              hidden={props.nextHidden}
              data={props.data}
              disabled={props.disabled}
              type={props.type}
              formSteps={props.formSteps}
              progress={props.progress}
            /> }

      </div>
  );
}

export default CarFooter;
