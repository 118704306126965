import { useContext, useEffect, useState } from "react";
import HomeOwnerStepFour from "../../home/step4/HomeOwnerStepFour";
import { HomeTypeContext, MyHomeContext } from "../HomeContext";
import TenantStepFour from "../../tenant/step4/TenantStepFour";
import CondoStepFour from "../../condo/step4/CondoStepFour";
import axios from "axios";
import { answers } from "../../../text";
import { LanguageContext } from "../../shared/HomeContext";
import KbdLogo from "../../../components/refresh/assets/KbdLogo";

export type Autofill = {
  yearBuilt: number | null | undefined;
  livingArea: number | null | undefined;
  garageType: string | null | undefined;
  basementPercentageFinished: string | null | undefined;
  numOfStoreys: number | null | undefined;
  buildingStyle?: string | null | undefined;
  exteriorFinish: string | null | undefined;
  yearRoofReplaced: number | null | undefined;
  replacementCost: number | null | undefined;
};

function StepFour() {
  const { homeType } = useContext(HomeTypeContext);
  const { homeFormData } = useContext(MyHomeContext);
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Autofill | null>(null);
  const [exteriorFinish, setExteriorFInish] = useState<string | null>(
    answers[language].brickVeneer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .post("https://shrouded-thicket-97880.herokuapp.com/home-info", {
        addressNumber: homeFormData.addressNumber,
        address: homeFormData.address,
        city: homeFormData.city,
        postalCode: homeFormData.postalCode,
      })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (data?.exteriorFinish) {
      if (language === "fr") {
        for (let element in answers.en) {
          if (answers.en[element] === data.exteriorFinish) {
            setExteriorFInish(answers.fr[element]);
          }
        }
      } else {
        setExteriorFInish(data.exteriorFinish);
      }
    }
    if (!data?.garageType) {
      setData((prev: any) => ({
        ...prev,
        garageType: "Attached-Frame",
      }));
    }
    if( !data?.replacementCost ){
      setData((prev: any) => ({
        ...prev,
        replacementCost: data?.replacementCost
      }))
    }
  }, [data?.exteriorFinish, data?.garageType, language, data?.replacementCost]);

  if (loading) {
    return <div className="t-container"><div className="t-content-container"><div className="loading-state"><KbdLogo /></div></div></div>;
  } else if (homeType === "Home owner insurance") {
    return (
      <HomeOwnerStepFour
        yearBuilt={data?.yearBuilt}
        livingArea={data?.livingArea}
        garageType={data?.garageType}
        basementPercentageFinished={data?.basementPercentageFinished}
        numOfStoreys={data?.numOfStoreys}
        exteriorFinish={exteriorFinish}
        yearRoofReplaced={data?.yearRoofReplaced}
        replacementCost={data?.replacementCost}
      />
    );
  } else if (homeType === "Tenant insurance") {
    return <TenantStepFour yearBuilt={data?.yearBuilt} />;
  } else {
    return <CondoStepFour yearBuilt={data?.yearBuilt} />;
  }
}

export default StepFour;
