import { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import MoneyInput from "../../../components/refresh/MoneyInput";

function CarStepFourteen() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [howOldDriversLicense, setHowOldDriversLicense] = useState<
    number | null
  >(null);
  const [DriversAgeError, setDriversAgeError] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const LSHowOld = useLocalStorage("howOldDriversLicense");
  

  useEffect(() => {
    if (LSHowOld) {
      setHowOldDriversLicense(LSHowOld);
      setValid(true)
    }
  }, [LSHowOld]);

  useEffect(() => {
    if (howOldDriversLicense) {
      if (howOldDriversLicense < 16) {
        setDriversAgeError(true);
      } else if (howOldDriversLicense === null) {
        setDriversAgeError(false);
      } else if (LSHowOld) {
        setDriversAgeError(false)
      } else {
        setDriversAgeError(false)
      }
      if (howOldDriversLicense === 16) {
          setHowOldDriversLicense(17)
      }
    }
  }, [howOldDriversLicense]);



  useEffect(() => {
    setValid(true);
    if (DriversAgeError || howOldDriversLicense === null || howOldDriversLicense >= 99) {
      setValid(false);
    }
  }, [DriversAgeError, howOldDriversLicense]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].howOldDriversLicense} />
      {DriversAgeError ? (
        <Question subQuestion={carTexts[carLanguage].driverAgeCheck} />
      ) : null}
      <MoneyInput
      type="number"
      placeholder={carTexts[carLanguage].age}
      setState={setHowOldDriversLicense}
      lsValue={howOldDriversLicense}
      maxLength={2}
      />
      
      <CarFooter
        type="submit"
        data={{ howOldDriversLicense: howOldDriversLicense }}
        formSteps={15}
        disabled={!valid}
        backFormSteps={13}
      />
    </div>
    </div>
  );
}

export default CarStepFourteen;
