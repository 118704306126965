import './RefreshStyles.css'
import { KeyboardEvent } from 'react'

type RadioNoProps = {
    handleLabelClick: (parentId: string) => void,
    handleLabelNo: () => void,
    labelNoSelected: boolean,
    labelRight?: string,
    iconRight?: JSX.Element,
    checked?: boolean,
}

const RadioNo = ({handleLabelClick, handleLabelNo, labelNoSelected, labelRight, iconRight, checked}: RadioNoProps) => {
    
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleLabelNo()
    } 
  }

  return (
            <div className={labelNoSelected || checked ? "t-radio-button-group active" : "t-radio-button-group"} id="radio-button-group-no" tabIndex={0} onKeyDown={handleKeyDown} onClick={(e) => handleLabelClick(e.currentTarget.id)}>
                    {iconRight}
                <label htmlFor="no" onClick={handleLabelNo} className={labelNoSelected || checked ? "radio-btn-label active" : "radio-btn-label"}>{labelRight}</label>
                <input type="radio" name="binary" id="no" checked={checked}/>
            </div>
  )
}

export default RadioNo