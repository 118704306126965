import axios from "axios";
import React, { useEffect, useState } from "react";

function AdminHome() {
  const [homeSubmissions, setHomeSUbmissions] = useState<any[]>([]);
  const [singedIn, setSignedIn] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<any>({ userName: "", password: "" });

  useEffect(() => {
    axios
      .get("https://shrouded-thicket-97880.herokuapp.com/get-submissions")
      .then((res) => {
        if (res.data.length) {
          const mapped = res.data.map((element: any, index: number) => {
            if (element.xmlRequest) {
              const fileData = JSON.stringify(res.data[index].xmlRequest);
              const blob = new Blob([fileData], { type: "text/plain" });
              const url = URL.createObjectURL(blob);

              const resFileData = JSON.stringify(res.data[index].xmlResponse);
              const resBlob = new Blob([resFileData], { type: "text/plain" });
              const resUrl = URL.createObjectURL(resBlob);

              return { ...element, url: url, resUrl: resUrl };
            } else {
              return element;
            }
          });
          setHomeSUbmissions(mapped);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleClick() {
    if (
      userInfo.userName === "kbdinsurance" &&
      userInfo.password === "JQKisUBG7JrmfiizbDcY"
    ) {
      setSignedIn(true);
      setShowForm(false);
    }
  }

  return (
    <div>
      {showForm && (
        <div>
          <input
            onChange={(e) => {
              setUserInfo((info: any) => ({
                ...info,
                userName: e.target.value,
              }));
            }}
          />
          <input
            onChange={(e) => {
              setUserInfo((info: any) => ({
                ...info,
                password: e.target.value,
              }));
            }}
          />
          <button onClick={(e) => handleClick()}>Log In</button>
        </div>
      )}
      {singedIn === true && (
        <div>
          <h1>Admin Home</h1>
          {homeSubmissions.length > 0 &&
            homeSubmissions.map((element: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <p>{index}</p>
                  <p>{`${element.firstName} ${element.lastName}`}</p>
                  <a download={`request.txt`} href={element.url}>
                    Download XML Request
                  </a>
                  <a download={`response.txt`} href={element.resUrl}>
                    Download Fortus Response
                  </a>
                  <p>{element.quote}</p>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default AdminHome;
