import { useState } from "react";
import "../refresh/RefreshStyles.css"
import HomeNextButton from "../refresh/HomeNextButton"
import HomeBackButton from "../refresh/HomeBackButton"
import OntarioButton from "../refresh/OntarioButton";

type FooterProps = {
  data?: {};
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  formSteps?: number;
  progress?: number;
  backHidden?: boolean;
  nextHidden?: boolean;
  backDisabled?: boolean;
  newQuote?: boolean;
  province?: string;
  backFormSteps?: number;
};

function Footer(props: FooterProps) {
  const [isBackHidden, setIsBackHidden] = useState(false);
    

  return (
    <div className="footer-buttons">
          { isBackHidden ? 
          null :
          <HomeBackButton
          hidden={props.backHidden}
          newQuote={props.newQuote}
          disabled={props.backDisabled}
          backFormSteps={props.backFormSteps}
          />  } {props.province === 'Quebec' ? <OntarioButton /> :
                      <HomeNextButton
              hidden={props.nextHidden}
              data={props.data}
              disabled={props.disabled}
              type={props.type}
              formSteps={props.formSteps}
              progress={props.progress}
            /> }
      </div>
  );
}

export default Footer;
