import { createContext, useState, useEffect, ReactChild } from 'react';

export const ThemeContext = createContext({} as any);

type ThemeProviderProps = {
  children: ReactChild;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setDarkMode(prefersDarkMode);
    
  }, []);

  window.matchMedia("(prefers-color-scheme: dark").addEventListener('change', event => {
      
    let newColorScheme = event.matches ? "dark" : "light";
    if (newColorScheme === "dark") {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
});

  useEffect(() => {
    const root = document.documentElement;
    if (darkMode) {
      // root.style.setProperty('--gray', '#0D172E');
      // root.style.setProperty('--royal-blue-sidebar', '#0D172E');
      // root.style.setProperty('--button-fill', '#B6B6CE');
      // root.style.setProperty('--language-fill', '#FFFFFF');
      // root.style.setProperty('--background-gray', 'rgb(29, 43, 75)');
      // root.style.setProperty('--body-text', '#FFFFFF');
      // root.style.setProperty('--reverse-body-text', '#000000');
      // root.style.setProperty('--progress-bar', '#637191');
      // root.style.setProperty('--progress', 'white');
      // root.style.setProperty('--button-color', '#0D172E');
      // root.style.setProperty('--phone-number', '#637191');
      // root.style.setProperty('--button-background', '#FFFFFF');
      // root.style.setProperty('--button-text', '#0D172E');
      // root.style.setProperty('--modal-background', '#1D2B4B');
      // root.style.setProperty('--modal-close-button', '#0D172E');
      // root.style.setProperty('--discount-path', '#1D2B4B');
      // root.style.setProperty('--modal-close-button-hover', '#0047B3');
      // root.style.setProperty('--disabled', '#0D172E');
      // root.style.setProperty('--copyright', 'rgba(0, 0, 0, .5)');
      // root.style.setProperty('--dim-black-2', '#FFFFFF');
      // root.style.setProperty('--magenta', '#FFFFFF');
      // root.style.setProperty('--language-active', '#8CC1FF');
      root.setAttribute('style',
    `--gray: #0D172E;
    --royal-blue-sidebar: #0D172E;
    --button-fill: #B6B6CE;
    --language-fill: #FFFFFF;
    --background-gray: #1D2B4B;
    --body-text: #FFFFFF;
    --reverse-body-text: #000000;
    --progress-bar: #637191;
    --progress: white;
    --button-color: #0D172E;
    --phone-number: #637191;
    --button-background: #FFFFFF;
    --button-text: #0D172E;
    --modal-background: #1D2B4B;
    --modal-close-button: #0D172E;
    --discount-path: #1D2B4B;
    --modal-close-button-hover: #0047B3;
    --disabled: #0D172E;
    --copyright: #00000080;
    --dim-black-2: #FFFFFF;
    --magenta: #FFFFFF;
    --language-active: #8CC1FF;`
      )
    } else {
      // root.style.removeProperty('--gray');
      // root.style.removeProperty('--royal-blue-sidebar');
      // root.style.removeProperty('--button-fill');
      // root.style.removeProperty('--background-gray');
      // root.style.removeProperty('--body-text');
      // root.style.removeProperty('--reverse-body-text');
      // root.style.removeProperty('--progress-bar');
      // root.style.removeProperty('--progress');
      // root.style.removeProperty('--button-color');
      // root.style.removeProperty('--phone-number');
      // root.style.removeProperty('--button-background');
      // root.style.removeProperty('--button-text');
      // root.style.removeProperty('--modal-background');
      // root.style.removeProperty('--modal-close-button');
      // root.style.removeProperty('--discount-path');
      // root.style.removeProperty('--modal-close-button-hover');
      // root.style.removeProperty('--disabled');
      // root.style.removeProperty('--copyright');
      // root.style.removeProperty('--dim-black-2');
      // root.style.removeProperty('--magenta');
      // root.style.removeProperty('--language-fill');
      // root.style.removeProperty('--language-active');
      root.setAttribute('style','')
    }
  }, [darkMode]);

  return <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>{children}</ThemeContext.Provider>;
};