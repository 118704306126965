import { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import TextInput from "../../../components/refresh/TextInput";

function CarStepTwelve() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [valid, setValid] = useState(false);
  const LSFirstName = useLocalStorage("firstName");
  const LSLastName = useLocalStorage("lastName");

  useEffect(() => {
    if (LSFirstName) {
      setFirstName(LSFirstName);
      setLastName(LSLastName);
    }
  }, [LSFirstName, LSLastName]);

  useEffect(() => {
    setValid(true);
    if (firstName === "" || firstName === " " || firstName.length < 2) {
      setValid(false);
    }
    if (lastName === "" || lastName === " " || lastName.length < 2) {
      setValid(false);
    }
  }, [firstName, lastName]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].nameQuestion}
        subQuestion={carTexts[carLanguage].nameSubQuestion}
      />
      <div className="two-input-div">
        <TextInput 
        placeholder={carTexts[carLanguage].firstName}
        label={carTexts[carLanguage].weeks}
        setState={setFirstName}
        lsValue={firstName}
        type="string"
        />

        <TextInput 
        placeholder={carTexts[carLanguage].lastName}
        label={carTexts[carLanguage].weeks}
        setState={setLastName}
        lsValue={lastName}
        type="string"
        />
      </div>

      <CarFooter
        type="submit"
        data={{ firstName: firstName, lastName: lastName }}
        formSteps={13}
        disabled={!valid}
        backFormSteps={11}
      />
    </div>
    </div>
  );
}

export default CarStepTwelve;
