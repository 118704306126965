import './RefreshStyles.css'
import SelectArrow from './assets/SelectArrow';
import { useState, useRef, useEffect, Dispatch, SetStateAction, useContext } from 'react'
import { texts, answers, carTexts } from "../../text"
import { LanguageContext } from '../../quotes/shared/HomeContext';
import { CarLanguageContext } from '../../quotes/car/CarContext';
import useLocalStorage from '../hooks/useLocalStorage';

type DropdownMenuProps = {
  type?: string;
  list: any[];
  setState?: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  setBoolean?: Dispatch<SetStateAction<boolean | null>>;
  options?: string[];
  setSelect?: (selection: string) => void;
  nonCenter?: boolean;
  lsValue?: any;
  initialValue?: any;
  error?: boolean;
  onClick?: (text: boolean) => void;
  setBooleanNull?: Dispatch<SetStateAction<boolean | null>>;
  dateError?: boolean;
}

const DropdownMenu = (props: DropdownMenuProps) => {

    const [showMenu, setShowMenu] = useState(false);
    const [select, setSelect] = useState('')
    const [selected, setSelected] = useState(false)
    const { language } = useContext(LanguageContext)
    const { carLanguage } = useContext(CarLanguageContext);
    const items = {...localStorage}
    const item = useLocalStorage(props.lsValue)
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    useEffect(() => {
      let inputTimeout: NodeJS.Timeout | null = null;
      let accumulatedInput = '';
  
      const handleKeyDown = (event: KeyboardEvent) => {
        const keyPressed = event.key.toLowerCase();
  
        accumulatedInput += keyPressed;
  
        if (inputTimeout) {
          clearTimeout(inputTimeout);
        }
  
        inputTimeout = setTimeout(() => {

//         const index = props.list.findIndex((item) =>
//   item.toString().toLowerCase().startsWith(accumulatedInput.toLowerCase())
// );


// if (index !== -1) {
//   setHoveredIndex(index)
//   scrollSelectedItemIntoView(index)
// } else {

// }

const reversedArray = props.list.slice().reverse();
const index = reversedArray.findIndex((item) =>
  item.toString().toLowerCase().startsWith(accumulatedInput.toLowerCase())
);

if (index !== -1) {
  setHoveredIndex(props.list.length - 1 - index)
  scrollSelectedItemIntoView(props.list.length - 1 - index)
} else {
  // setHoveredIndex(index)
}

          accumulatedInput = '';
        }, 500);
      };
  
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        if (inputTimeout) {
          clearTimeout(inputTimeout);
        }
      };
    }, [props.list]);



  const handleSelect = (selectedValue: string) => {
    setSelect(selectedValue);
    setShowMenu(false);
    setSelected(true);

    if (props.setBoolean) {
      if (selectedValue === texts['en'].yes || selectedValue === texts['fr'].yes) {
        props.setBoolean(true);
      } else if (selectedValue === texts['en'].no || selectedValue === texts['fr'].no) {
        props.setBoolean(false);
      }
    }
    if (props.setState) {
      props.setState(selectedValue);
    }
    if (props.setSelect) {
      props.setSelect(selectedValue);
    }
    if (props.onClick) {
      if (selectedValue === texts['en'].yes || selectedValue === texts['fr'].yes) {
        props.onClick(true);
      } else if (selectedValue === texts['en'].no || selectedValue === texts['fr'].no) {
        props.onClick(false);
      }
    }
  };

  const scrollSelectedItemIntoView = (index: number) => {
    const dropdownMenu = dropdownRef.current;
    if (dropdownMenu) {
      const selectedItem = dropdownMenu.querySelector(`[data-index="${index}"]`);
      if (selectedItem) {
        selectedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  };


  const handleLiClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const selectedValue = event.currentTarget.getAttribute('value') ?? '';
    handleSelect(selectedValue);
  };

      const dropdownRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        const handleClickOutside = (event: Event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowMenu(false);
          }
        };

        const handleKeyDown = (event: KeyboardEvent) => {
          if (showMenu) {
            if (event.key === 'ArrowUp') {
              event.preventDefault();
              setHoveredIndex((prevIndex) => (prevIndex === 0 ? props.list.length - 1 : prevIndex - 1));
              scrollSelectedItemIntoView(hoveredIndex - 1);
            } else if (event.key === 'ArrowDown') {
              event.preventDefault();
              setHoveredIndex((prevIndex) => (prevIndex === props.list.length - 1 ? 0 : prevIndex + 1));
              scrollSelectedItemIntoView(hoveredIndex + 1);
            } else if (event.key === 'Enter') {
              event.preventDefault();
              const selectedValue = props.list[hoveredIndex];
              handleSelect(selectedValue);
            }
          }
        };
        
    
        window.addEventListener("click", handleClickOutside);

        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener("click", handleClickOutside);

          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [dropdownRef, select, showMenu, hoveredIndex]);
      
      useEffect(() => {
        if (!item && props.initialValue === 'High Rise Apartment') {
          handleSelect(answers[language].highRiseApartment)
        }
        else if (!item && props.initialValue === 'Detached') {
          handleSelect(answers[language].detached)
        }
        else if (!item && props.initialValue === 'Semi-Detached') {
          handleSelect(answers[language].semiDetached)
        }
        else if (!item && props.initialValue === 'Town House') {
          handleSelect(answers[language].townHouse)
        }
        else if (!item && props.initialValue === "Row House") {
          handleSelect(answers[language].rowHouse)
        }
        else if (!item && props.initialValue === "Duplex (side-by-side)") {
          handleSelect(answers[language].duplexSide)
        }
        else if (!item && props.initialValue === "Triplex (side-by-side") {
          handleSelect(answers[language].triplex)
        }
        else if (!item && props.initialValue === "Duplex (up-and-down)") {
          handleSelect(answers[language].duplexUpDown)
        }
        else if (!item && props.initialValue === "Triplex") {
          handleSelect(answers[language].triplex)
        }
        else if (!item && props.initialValue === "Low Rise Apartment") {
          handleSelect(answers[language].lowRiseApartment)
        }
        else if (!item && props.initialValue === "Commercial") {
          handleSelect(answers[language].commercial)
        }
        else if (!item && props.initialValue === "5km") {
          handleSelect(answers[language].fiveKm)
        }
        else if (!item && props.initialValue === "8km") {
          handleSelect(answers[language].eightKM)
        }
        else if (!item && props.initialValue === "Over 8km") {
          handleSelect(answers[language].overEightKm)
        }
        else if (!item && props.initialValue === 'Condo owner insurance') {
          handleSelect(answers[language].condoInsurance)
        }
        else if (!item && props.initialValue === 'Home owner insurance') {
          handleSelect(answers[language].homeownerInsurance)
        }
        else if (!item && props.initialValue === 'Tenant insurance') {
          handleSelect(answers[language].tenantInsurance)
        }
        else if (!item && props.initialValue === "Log") {
          handleSelect(answers[language].log)
        }
        else if (!item && props.initialValue === "Monocoque") {
          handleSelect(answers[language].monocoque)
        }
        else if (!item && props.initialValue === "Cement") {
          handleSelect(answers[language].cement)
        }
        else if (!item && props.initialValue === "Concrete block") {
          handleSelect(answers[language].concreteBlock)
        }
        else if (!item && props.initialValue === "Frame wood") {
          handleSelect(answers[language].frameWood)
        }
        else if (!item && props.initialValue === "Stone") {
          handleSelect(answers[language].stone)
        }
        else if (!item && props.initialValue === "Brick veneer") {
          handleSelect(answers[language].brickVeneer)
        }
        else if (!item && props.initialValue === "Vinyl") {
          handleSelect(answers[language].vinyl)
        }
        else if (!item && props.initialValue === "Other") {
          handleSelect(answers[language].other)
        }
  
        else if (!item && props.initialValue === 'Attached-Frame') {
          handleSelect(answers[language].attachedFrame)
        }
        else if (!item && props.initialValue === 'Attached-Masonry') {
          handleSelect(answers[language].attachedMasonry)
        }
        else if (!item && props.initialValue === 'Attached-Masonry or Masonry/Veneer') {
          handleSelect(answers[language].attachedMasonryOrVeneer)
        }
        else if (!item && props.initialValue === "Basement") {
          handleSelect(answers[language].basement)
        }
        else if (!item && props.initialValue === "Basement Under Attached Garage") {
          handleSelect(answers[language].basementUnderGarage)
        }
        else if (!item && props.initialValue === "Built-In") {
          handleSelect(answers[language].builtIn)
        }
        else if (!item && props.initialValue === "Carport") {
          handleSelect(answers[language].carport)
        }
        else if (!item && props.initialValue === "Carport With Storage") {
          handleSelect(answers[language].carportStorage)
        }
        else if (!item && props.initialValue === "Detached-Masonry") {
          handleSelect(answers[language].detachedMasonry)
        }
        else if (!item && props.initialValue === "Detached-Frame") {
          handleSelect(answers[language].detachedFrame)
        }
        else if (!item && props.initialValue === "Detached-Masonry or Masonry/Veneer") {
          handleSelect(answers[language].detachedMasonryOrVeneer)
        }
        else if (!item && props.initialValue === "Other") {
          handleSelect(answers[language].other)
        }
        else if (!item && props.initialValue === "None") {
          handleSelect(answers[language].none)
        }
        else if (!item && props.initialValue === "Within 300m") {
          handleSelect(answers[language].withinThreeHunderdM)
        }
        else if (!item && props.initialValue === "Over 300m") {
          handleSelect(answers[language].overThreeHunderedM)
        }
  
        else if (!item && props.initialValue === 'Central Furnace-Gas') {
          handleSelect(answers[language].centralFurnaceGas)
        }
        else if (!item && props.initialValue === 'Central Furnace-Oil') {
          handleSelect(answers[language].centralFurnaceOil)
        }
        else if (!item && props.initialValue === 'Central Furnace-Propane') {
          handleSelect(answers[language].centralFurnacePropane)
        }
        else if (!item && props.initialValue === "Central Furnace-Wood") {
          handleSelect(answers[language].centralFurnaceWood)
        }
        else if (!item && props.initialValue === "Electric") {
          handleSelect(answers[language].electric)
        }
        else if (!item && props.initialValue === "Hot Water/Steam") {
          handleSelect(answers[language].hotWaterSteam)
        }
        else if (!item && props.initialValue === "Combination Furnace") {
          handleSelect(answers[language].combinationFurnace)
        }
        else if (!item && props.initialValue === "Space Heater-Gas") {
          handleSelect(answers[language].spaceHeaterGas)
        }
        else if (!item && props.initialValue === "Space Heater-Oil") {
          handleSelect(answers[language].spaceHeaterOil)
        }
        else if (!item && props.initialValue === "Space Heater-Electric") {
          handleSelect(answers[language].spaceHeaterElectric)
        }
        else if (!item && props.initialValue === "Wood Burning Stove") {
          handleSelect(answers[language].woodBurningStove)
        }
        else if (!item && props.initialValue === "Fireplace") {
          handleSelect(answers[language].fireplace)
        }
        else if (!item && props.initialValue === "Solar") {
          handleSelect(answers[language].solar)
        }
        else if (!item && props.initialValue === "Other") {
          handleSelect(answers[language].other)
      }
      else if (!item && props.initialValue === "Log") {
        handleSelect(answers[language].log)
      }
      else if (!item && props.initialValue === "Monocoque") {
        handleSelect(answers[language].monocoque)
      }
      else if (!item && props.initialValue === "Cement") {
        handleSelect(answers[language].cement)
      }
      else if (!item && props.initialValue === "Concrete block") {
        handleSelect(answers[language].concreteBlock)
      }
      else if (!item && props.initialValue === "Frame wood") {
        handleSelect(answers[language].frameWood)
      }
      else if (!item && props.initialValue === "Stone") {
        handleSelect(answers[language].stone)
      }
      else if (!item && props.initialValue === "Brick veneer") {
        handleSelect(answers[language].brickVeneer)
      }
      else if (!item && props.initialValue === "Vinyl") {
        handleSelect(answers[language].vinyl)
      }
      else if (!item && props.initialValue === "Other") {
        handleSelect(answers[language].other)
      }
      else if (!item && props.initialValue) {
        handleSelect(props.initialValue)
      }
    }, [props.initialValue])


      useEffect(() => {
      //carForm
      
      if (props.type !== 'booleanHome' && item === 'true') {
        // setSelect(carTexts[carLanguage].yes)
        handleSelect(carTexts[carLanguage].yes)
      }
      else if (props.type !== 'booleanHome' && item === 'false') {
        // setSelect(carTexts[carLanguage].no)
        handleSelect(carTexts[carLanguage].no)
      }
      else if (props.type === 'job' && item === 'None') {
        handleSelect(carTexts[carLanguage].none)
      }
      else if (props.type === 'job' && item === 'University graduate') {
        handleSelect(carTexts[carLanguage].uniGraduate)
      }
      else if (props.type === 'job' && item === 'Professional') {
        handleSelect(carTexts[carLanguage].professional)
      }
      else if (props.type === 'job' && item === 'Retired') {
        handleSelect(carTexts[carLanguage].retired)
      }
      else if (props.type === 'job' && item === 'Student') {
        handleSelect(carTexts[carLanguage].student)
      }
      else if (item === items.currentPolicy && item === '1 Year') {
        handleSelect(carTexts[carLanguage].oneYear)
      }
      else if (item === items.currentPolicy && item === '2 Years') {
        handleSelect(carTexts[carLanguage].twoYears)
      }
      else if (item === items.currentPolicy && item === '3 Years') {
        handleSelect(carTexts[carLanguage].threeYears)
      }
      else if (item === items.currentPolicy && item === '4 Years') {
        handleSelect(carTexts[carLanguage].fourYears)
      }
      else if (item === items.currentPolicy && item === '5 Years') {
        handleSelect(carTexts[carLanguage].fiveYears)
      }
      else if (item === 'Male') {
        handleSelect(carTexts[carLanguage].male)
      }
      else if (item === 'Female') {
        handleSelect(carTexts[carLanguage].female)
      }
      else if (item === 'Married') {
        handleSelect(carTexts[carLanguage].married)
      }
      else if (item === 'Single') {
        handleSelect(carTexts[carLanguage].single)
      }
      else if (item === 'Common Law') {
        handleSelect(carTexts[carLanguage].commonLaw)
      }
      // else if (item === ''){
      //   setSelect(carTexts[carLanguage].select)
      // }
      else if ((item === items.make) || (item === items.model) || (item === items.year) || (item === items.currentCompany && item !== "") ||
      (item === items.purchaseYear) || (item === items.purchaseMonth) || (item === items.purchaseDay) || (item === items.birthYear) || (item === items.birthMonth) || (item === items.birthDay)
      || (item === items.addressYear) || (item === items.addressMonth) || (item === items.addressDay)|| (item === items.secondaryYear) || (item === items.secondaryMonth) || (item === items.secondaryDay) ||
      (item === items.policyYear) || (item === items.policyMonth) || (item === items.policyDay) || (item === items.basementPercentageFinished) || (item === items.numOfStoreys) || (item === items.yearRoofReplaced)
      || (item === items.numOfStories) || (item === items.year) || (item === items.homeBirthYear) || (item === items.homeBirthMonth) || (item === items.homeBirthDay) ||
      (item === items.yearMovedIn) || (item === items.policyStartMonth) || (item === items.policyStartYear)) {
        handleSelect(item)
      }

      //homeForm
      else if (item === 'Condo owner insurance') {
        handleSelect(answers[language].condoInsurance)
      }
      else if (item === 'Home owner insurance') {
        handleSelect(answers[language].homeownerInsurance)
      }
      else if (item === 'Tenant insurance') {
        handleSelect(answers[language].tenantInsurance)
      }
      else if (item === "Log") {
        handleSelect(answers[language].log)
      }
      else if (item === "Monocoque") {
        handleSelect(answers[language].monocoque)
      }
      else if (item === "Cement") {
        handleSelect(answers[language].cement)
      }
      else if (item === "Concrete block") {
        handleSelect(answers[language].concreteBlock)
      }
      else if (item === "Frame wood") {
        handleSelect(answers[language].frameWood)
      }
      else if (item === "Stone") {
        handleSelect(answers[language].stone)
      }
      else if (item === "Brick veneer") {
        handleSelect(answers[language].brickVeneer)
      }
      else if (item === "Vinyl") {
        handleSelect(answers[language].vinyl)
      }
      else if (item === "Other") {
        handleSelect(answers[language].other)
      }

      else if (item === 'Attached-Frame') {
        handleSelect(answers[language].attachedFrame)
      }
      else if (item === 'Attached-Masonry') {
        handleSelect(answers[language].attachedMasonry)
      }
      else if (item === 'Attached-Masonry or Masonry/Veneer') {
        handleSelect(answers[language].attachedMasonryOrVeneer)
      }
      else if (item === "Basement") {
        handleSelect(answers[language].basement)
      }
      else if (item === "Basement Under Attached Garage") {
        handleSelect(answers[language].basementUnderGarage)
      }
      else if (item === "Built-In") {
        handleSelect(answers[language].builtIn)
      }
      else if (item === "Carport") {
        handleSelect(answers[language].carport)
      }
      else if (item === "Carport With Storage") {
        handleSelect(answers[language].carportStorage)
      }
      else if (item === "Detached-Masonry") {
        handleSelect(answers[language].detachedMasonry)
      }
      else if (item === "Detached-Frame") {
        handleSelect(answers[language].detachedFrame)
      }
      else if (item === "Detached-Masonry or Masonry/Veneer") {
        handleSelect(answers[language].detachedMasonryOrVeneer)
      }
      else if (item === "Other") {
        handleSelect(answers[language].other)
      }
      else if (item === items.garageType && item === "None") {
        handleSelect(answers[language].none)
      }

      else if (item === "5km") {
        handleSelect(answers[language].fiveKm)
      }
      else if (item === "8km") {
        handleSelect(answers[language].eightKM)
      }
      else if (item === "Over 8km") {
        handleSelect(answers[language].overEightKm)
      }

      else if (item === "Within 300m") {
        handleSelect(answers[language].withinThreeHunderdM)
      }
      else if (item === "Over 300m") {
        handleSelect(answers[language].overThreeHunderedM)
      }

      else if (item === 'Central Furnace-Gas') {
        handleSelect(answers[language].centralFurnaceGas)
      }
      else if (item === 'Central Furnace-Oil') {
        handleSelect(answers[language].centralFurnaceOil)
      }
      else if (item === 'Central Furnace-Propane') {
        handleSelect(answers[language].centralFurnacePropane)
      }
      else if (item === "Central Furnace-Wood") {
        handleSelect(answers[language].centralFurnaceWood)
      }
      else if (item === "Electric") {
        handleSelect(answers[language].electric)
      }
      else if (item === "Hot Water/Steam") {
        handleSelect(answers[language].hotWaterSteam)
      }
      else if (item === "Combination Furnace") {
        handleSelect(answers[language].combinationFurnace)
      }
      else if (item === "Space Heater-Gas") {
        handleSelect(answers[language].spaceHeaterGas)
      }
      else if (item === "Space Heater-Oil") {
        handleSelect(answers[language].spaceHeaterOil)
      }
      else if (item === "Space Heater-Electric") {
        handleSelect(answers[language].spaceHeaterElectric)
      }
      else if (item === "Wood Burning Stove") {
        handleSelect(answers[language].woodBurningStove)
      }
      else if (item === "Fireplace") {
        handleSelect(answers[language].fireplace)
      }
      else if (item === "Solar") {
        handleSelect(answers[language].solar)
      }
      else if ((item === items.primaryHeatingType || item === items.secondaryHeatingType) && item === "Other") {
        handleSelect(answers[language].other)
      }
      else if ((item === items.primaryHeatingType || item === items.secondaryHeatingType) && item === "None") {
        handleSelect(answers[language].none)
      }

      else if (item === 'Detached') {
        handleSelect(answers[language].detached)
      }
      else if (item === 'Semi-Detached') {
        handleSelect(answers[language].semiDetached)
      }
      else if (item === 'Town House') {
        handleSelect(answers[language].townHouse)
      }
      else if (item === "Row House") {
        handleSelect(answers[language].rowHouse)
      }
      else if (item === "Duplex (side-by-side)") {
        handleSelect(answers[language].duplexSide)
      }
      else if (item === "Triplex (side-by-side") {
        handleSelect(answers[language].triplex)
      }
      else if (item === "Duplex (up-and-down)") {
        handleSelect(answers[language].duplexUpDown)
      }
      else if (item === "Triplex") {
        handleSelect(answers[language].triplex)
      }
      else if (item === "High Rise Apartment") {
        handleSelect(answers[language].highRiseApartment)
      }
      else if (item === "Low Rise Apartment") {
        handleSelect(answers[language].lowRiseApartment)
      }
      else if (item === "Commercial") {
        handleSelect(answers[language].commercial)
      }

      else if (item === "You and your family") {
        handleSelect(answers[language].youAndYourFamily)
      }
      else if (item === "Your family and another family") {
        handleSelect(answers[language].yourFamilyAndNotherFamily)
      }
      else if (item === "Your family and two other families") {
        handleSelect(answers[language].yourFamilyAndTwoFamilies)
      }
      else if (item === "It is rented out/ Used for commercial") {
        handleSelect(answers[language].rentedOut)
      }

      else if (item === items.insuranceGap && item === '1 Year') {
        handleSelect(answers[language].oneYear)
      }
      else if (item === items.insuranceGap && item === '2 Years') {
        handleSelect(answers[language].twoYears)
      }
      else if (item === items.insuranceGap && item === '3 Years') {
        handleSelect(answers[language].threeYears)
      }
      else if (item === items.insuranceGap && item === '4 Years') {
        handleSelect(answers[language].fourYears)
      }
      else if (item === items.insuranceGap && item === '5 Years') {
        handleSelect(answers[language].fiveYears)
      }


      else if (item === items.howLongInsuredCurrentCompany && item === '1 Year') {
        handleSelect(answers[language].oneYear)
      }
      else if (item === items.howLongInsuredCurrentCompany && item === '2 Years') {
        handleSelect(answers[language].twoYears)
      }
      else if (item === items.howLongInsuredCurrentCompany && item === '3 Years') {
        handleSelect(answers[language].threeYears)
      }
      else if (item === items.howLongInsuredCurrentCompany && item === '4 Years') {
        handleSelect(answers[language].fourYears)
      }
      else if (item === items.howLongInsuredCurrentCompany && item === '5 Years') {
        handleSelect(answers[language].fiveYears)
      }

      else if (item === 'No claims') {
        handleSelect(answers[language].noClaims)
      }
      else if (item === '1 claim') {
        handleSelect(answers[language].oneClaim)
      }
      else if (item === '2 claims') {
        handleSelect(answers[language].twoClaims)
      }
      else if (item === '3+ claims') {
        handleSelect(answers[language].threePlusClaims)
      }

      else if (item === 'No cancellations') {
        handleSelect(answers[language].noCancellations)
      }
      else if (item === '1 cancellation') {
        handleSelect(answers[language].oneCancellation)
      }
      else if (item === '2 cancellations') {
        handleSelect(answers[language].twoCancellations)
      }
      else if (item === '3+ cancellations') {
        handleSelect(answers[language].threePlusCancellations)
      }

      else if (item === 'No cancellations') {
        handleSelect(answers[language].noCancellations)
      }
      else if (item === '1 cancellation') {
        handleSelect(answers[language].oneCancellation)
      }
      else if (item === '2 cancellations') {
        handleSelect(answers[language].twoCancellations)
      }
      else if (item === '3+ cancellations') {
        handleSelect(answers[language].threePlusCancellations)
      }

      else if (props.type === 'jobHome' && item === 'University graduate') {
        handleSelect(texts[language].uniGraduate)
      }
      else if (props.type === 'jobHome' && item === 'Professional') {
        handleSelect(texts[language].professional)
      }
      else if (props.type === 'jobHome' && item === 'Retired') {
        handleSelect(texts[language].retired)
      }
      else if (props.type === 'jobHome' && item === 'Student') {
        handleSelect(texts[language].student)
      }
      else if (props.type === 'jobHome' && item === 'None') {
        handleSelect(texts[language].none)
      }

      if (props.type === 'booleanHome' && item === 'true') {
        // setSelect(carTexts[carLanguage].yes)
        handleSelect(texts[language].yes)
      }
      else if (props.type === 'booleanHome' && item === 'false') {
        // setSelect(texts[language].no)
        handleSelect(texts[language].no)
      }

      else if (props.type === "dropdown" && item === '0') {
        handleSelect(answers[language].zero)
      }
      else if (props.type === "dropdown" && item === '1') {
        handleSelect(answers[language].one)
      }
      else if (props.type === "dropdown" && item === '2') {
        handleSelect(answers[language].two)
      }
      else if (props.type === "dropdown" && item === '3+') {
        handleSelect(answers[language].three)
      }

      }, [item])



      useEffect(() => {
        //boolean
        if (props.type === "boolean" && (select === texts["en"].yes || select === texts["fr"].yes)) {
          setSelect(texts[carLanguage].yes)
        }
        if (props.type === "boolean" && (select === texts["en"].no || select === texts["fr"].no)) {
          setSelect(texts[carLanguage].no)
        }
        if (props.type === "booleanHome" && (select === texts["en"].yes || select === texts["fr"].yes)) {
          setSelect(texts[language].yes)
        }
        if (props.type === "booleanHome" && (select === texts["en"].no || select === texts["fr"].no)) {
          setSelect(texts[language].no)
        }
        //months
        if (props.type === "month" && (select === carTexts["en"].january || select === carTexts["fr"].january)) {
          setSelect(carTexts[carLanguage].january)
        }
        if (props.type === "month" && (select === carTexts["en"].february || select === carTexts["fr"].february)) {
          setSelect(carTexts[carLanguage].february)
        }
        if (props.type === "month" && (select === carTexts["en"].march || select === carTexts["fr"].march)) {
          setSelect(carTexts[carLanguage].march)
        }
        if (props.type === "month" && (select === carTexts["en"].april || select === carTexts["fr"].april)) {
          setSelect(carTexts[carLanguage].april)
        }
        if (props.type === "month" && (select === carTexts["en"].may || select === carTexts["fr"].may)) {
          setSelect(carTexts[carLanguage].may)
        }
        if (props.type === "month" && (select === carTexts["en"].june || select === carTexts["fr"].june)) {
          setSelect(carTexts[carLanguage].june)
        }
        if (props.type === "month" && (select === carTexts["en"].january || select === carTexts["fr"].january)) {
          setSelect(carTexts[carLanguage].january)
        }
        if (props.type === "month" && (select === carTexts["en"].july || select === carTexts["fr"].july)) {
          setSelect(carTexts[carLanguage].july)
        }
        if (props.type === "month" && (select === carTexts["en"].august || select === carTexts["fr"].august)) {
          setSelect(carTexts[carLanguage].august)
        }
        if (props.type === "month" && (select === carTexts["en"].september || select === carTexts["fr"].september)) {
          setSelect(carTexts[carLanguage].september)
        }
        if (props.type === "month" && (select === carTexts["en"].october || select === carTexts["fr"].october)) {
          setSelect(carTexts[carLanguage].october)
        }
        if (props.type === "month" && (select === carTexts["en"].november || select === carTexts["fr"].november)) {
          setSelect(carTexts[carLanguage].november)
        }
        if (props.type === "month" && (select === carTexts["en"].december || select === carTexts["fr"].december)) {
          setSelect(carTexts[carLanguage].december)
        }
        //condition
        if (props.type === "condition" && (select === carTexts["en"].new || select === carTexts["fr"].new)) {
          setSelect(carTexts[carLanguage].new)
        }
        if (props.type === "condition" && (select === carTexts["en"].used || select === carTexts["fr"].used)) {
          setSelect(carTexts[carLanguage].used)
        }
        if (props.type === "condition" && (select === carTexts["en"].demo || select === carTexts["fr"].demo)) {
          setSelect(carTexts[carLanguage].demo)
        }
        //purchase
        if (props.type === "purchase" && (select === carTexts["en"].financed || select === carTexts["fr"].financed)) {
          setSelect(carTexts[carLanguage].financed)
        }
        if (props.type === "purchase" && (select === carTexts["en"].leased || select === carTexts["fr"].leased)) {
          setSelect(carTexts[carLanguage].leased)
        }
        if (props.type === "purchase" && (select === carTexts["en"].ownedOutright || select === carTexts["fr"].ownedOutright)) {
          setSelect(carTexts[carLanguage].ownedOutright)
        }
        //security
        if (props.type === "security" && (select === carTexts["en"].tagTracking || select === carTexts["fr"].tagTracking)) {
          setSelect(carTexts[carLanguage].tagTracking)
        }
        if (props.type === "security" && (select === carTexts["en"].sherlock || select === carTexts["fr"].sherlock)) {
          setSelect(carTexts[carLanguage].sherlock)
        }
        if (props.type === "security" && (select === carTexts["en"].tagTrackingAndSherlock || select === carTexts["fr"].tagTrackingAndSherlock)) {
          setSelect(carTexts[carLanguage].tagTrackingAndSherlock)
        }
        if (props.type === "security" && (select === carTexts["en"].none || select === carTexts["fr"].none)) {
          setSelect(carTexts[carLanguage].none)
        }
        //use
        if (props.type === "use" && (select === carTexts["en"].pleasure || select === carTexts["fr"].pleasure)) {
          setSelect(carTexts[carLanguage].pleasure)
        }
        if (props.type === "use" && (select === carTexts["en"].pleasureAndCommuting || select === carTexts["fr"].pleasureAndCommuting)) {
          setSelect(carTexts[carLanguage].pleasureAndCommuting)
        }
        //insurance
        if (props.type === "insurance" && (select === carTexts["en"].comprehensive || select === carTexts["fr"].comprehensive)) {
          setSelect(carTexts[carLanguage].comprehensive)
        }
        if (props.type === "insurance" && (select === carTexts["en"].collision || select === carTexts["fr"].collision)) {
          setSelect(carTexts[carLanguage].collision)
        }
        if (props.type === "insurance" && (select === carTexts["en"].comprehensiveAndCollision || select === carTexts["fr"].comprehensiveAndCollision)) {
          setSelect(carTexts[carLanguage].comprehensiveAndCollision)
        }
        if (props.type === "insurance" && (select === carTexts["en"].none || select === carTexts["fr"].none)) {
          setSelect(carTexts[carLanguage].none)
        }
        //job
        if (props.type === "job" && (select === carTexts["en"].uniGraduate || select === carTexts["fr"].uniGraduate)) {
          setSelect(carTexts[carLanguage].uniGraduate)
        }
        if (props.type === "job" && (select === carTexts["en"].professional || select === carTexts["fr"].professional)) {
          setSelect(carTexts[carLanguage].professional)
        }
        if (props.type === "job" && (select === carTexts["en"].retired || select === carTexts["fr"].retired)) {
          setSelect(carTexts[carLanguage].retired)
        }
        if (props.type === "job" && (select === carTexts["en"].student || select === carTexts["fr"].student)) {
          setSelect(carTexts[carLanguage].student)
        }
        if (props.type === "job" && (select === carTexts["en"].none || select === carTexts["fr"].none)) {
          setSelect(carTexts[carLanguage].none)
        }
        //policy
        if (props.type === "policy" && (select === carTexts["en"].oneYear || select === carTexts["fr"].oneYear)) {
          setSelect(carTexts[carLanguage].oneYear)
        }
        if (props.type === "policy" && (select === carTexts["en"].twoYears || select === carTexts["fr"].twoYears)) {
          setSelect(carTexts[carLanguage].twoYears)
        }
        if (props.type === "policy" && (select === carTexts["en"].threeYears || select === carTexts["fr"].threeYears)) {
          setSelect(carTexts[carLanguage].threeYears)
        }
        if (props.type === "policy" && (select === carTexts["en"].fourYears || select === carTexts["fr"].fourYears)) {
          setSelect(carTexts[carLanguage].fourYears)
        }
        if (props.type === "policy" && (select === carTexts["en"].fiveYears || select === carTexts["fr"].fiveYears)) {
          setSelect(carTexts[carLanguage].fiveYears)
        }
        //claim
        if (props.type === "claim" && (select === carTexts["en"].hail || select === carTexts["fr"].hail)) {
          setSelect(carTexts[carLanguage].hail)
        }
        if (props.type === "claim" && (select === carTexts["en"].vandalism || select === carTexts["fr"].vandalism)) {
          setSelect(carTexts[carLanguage].vandalism)
        }
        if (props.type === "claim" && (select === carTexts["en"].fire || select === carTexts["fr"].fire)) {
          setSelect(carTexts[carLanguage].fire)
        }
        if (props.type === "claim" && (select === carTexts["en"].theft || select === carTexts["fr"].theft)) {
          setSelect(carTexts[carLanguage].theft)
        }
        if (props.type === "claim" && (select === carTexts["en"].glassBreakage || select === carTexts["fr"].glassBreakage)) {
          setSelect(carTexts[carLanguage].glassBreakage)
        }
        if (props.type === "claim" && (select === carTexts["en"].glassRepair || select === carTexts["fr"].glassRepair)) {
          setSelect(carTexts[carLanguage].glassRepair)
        }
        if (props.type === "claim" && (select === carTexts["en"].nonResponsibleCollision || select === carTexts["fr"].nonResponsibleCollision)) {
          setSelect(carTexts[carLanguage].nonResponsibleCollision)
        }
        if (props.type === "claim" && (select === carTexts["en"].liabilityAndCollision || select === carTexts["fr"].liabilityAndCollision)) {
          setSelect(carTexts[carLanguage].liabilityAndCollision)
        }
        if (props.type === "claim" && (select === carTexts["en"].collisionClaim || select === carTexts["fr"].collisionClaim)) {
          setSelect(carTexts[carLanguage].collisionClaim)
        }
        if (props.type === "claim" && (select === carTexts["en"].liabilityClaim || select === carTexts["fr"].liabilityClaim)) {
          setSelect(carTexts[carLanguage].liabilityClaim)
        }
        if (props.type === "claim" && (select === carTexts["en"].theftEntireVehicle || select === carTexts["fr"].theftEntireVehicle)) {
          setSelect(carTexts[carLanguage].theftEntireVehicle)
        }
        if (props.type === "claim" && (select === carTexts["en"].minorCollision || select === carTexts["fr"].minorCollision)) {
          setSelect(carTexts[carLanguage].minorCollision)
        }
        //convictions
        if (props.type === "convictions" && (select === carTexts["en"].wrongWay || select === carTexts["fr"].wrongWay)) {
          setSelect(carTexts[carLanguage].wrongWay)
        }
        if (props.type === "convictions" && (select === carTexts["en"].unsafeVehicle || select === carTexts["fr"].unsafeVehicle)) {
          setSelect(carTexts[carLanguage].unsafeVehicle)
        }
        if (props.type === "convictions" && (select === carTexts["en"].unsafeTurn || select === carTexts["fr"].unsafeTurn)) {
          setSelect(carTexts[carLanguage].unsafeTurn)
        }
        if (props.type === "convictions" && (select === carTexts["en"].slowDriving || select === carTexts["fr"].slowDriving)) {
          setSelect(carTexts[carLanguage].slowDriving)
        }
        if (props.type === "convictions" && (select === carTexts["en"].trafficLight || select === carTexts["fr"].trafficLight)) {
          setSelect(carTexts[carLanguage].trafficLight)
        }
        if (props.type === "convictions" && (select === carTexts["en"].stopSign || select === carTexts["fr"].stopSign)) {
          setSelect(carTexts[carLanguage].stopSign)
        }
        if (props.type === "convictions" && (select === carTexts["en"].speeding || select === carTexts["fr"].speeding)) {
          setSelect(carTexts[carLanguage].speeding)
        }
        if (props.type === "convictions" && (select === carTexts["en"].railway || select === carTexts["fr"].railway)) {
          setSelect(carTexts[carLanguage].railway)
        }
        if (props.type === "convictions" && (select === carTexts["en"].prohibitedDevice || select === carTexts["fr"].prohibitedDevice)) {
          setSelect(carTexts[carLanguage].prohibitedDevice)
        }
        if (props.type === "convictions" && (select === carTexts["en"].overload || select === carTexts["fr"].overload)) {
          setSelect(carTexts[carLanguage].overload)
        }
        if (props.type === "convictions" && (select === carTexts["en"].obstructTraffic || select === carTexts["fr"].obstructTraffic)) {
          setSelect(carTexts[carLanguage].obstructTraffic)
        }
        if (props.type === "convictions" && (select === carTexts["en"].insecureLoad || select === carTexts["fr"].insecureLoad)) {
          setSelect(carTexts[carLanguage].insecureLoad)
        }       
        if (props.type === "convictions" && (select === carTexts["en"].dividedHighway || select === carTexts["fr"].dividedHighway)) {
          setSelect(carTexts[carLanguage].dividedHighway)
        }
        if (props.type === "convictions" && (select === carTexts["en"].improperTurn || select === carTexts["fr"].improperTurn)) {
          setSelect(carTexts[carLanguage].improperTurn)
        }
        if (props.type === "convictions" && (select === carTexts["en"].improperTowing || select === carTexts["fr"].improperTowing)) {
          setSelect(carTexts[carLanguage].improperTowing)
        }
        if (props.type === "convictions" && (select === carTexts["en"].improperPassing || select === carTexts["fr"].improperPassing)) {
          setSelect(carTexts[carLanguage].improperPassing)
        }
        if (props.type === "convictions" && (select === carTexts["en"].badOpeningDoor || select === carTexts["fr"].badOpeningDoor)) {
          setSelect(carTexts[carLanguage].badOpeningDoor)
        }
        if (props.type === "convictions" && (select === carTexts["en"].badLaneChange || select === carTexts["fr"].badLaneChange)) {
          setSelect(carTexts[carLanguage].badLaneChange)
        }
        if (props.type === "convictions" && (select === carTexts["en"].busLane || select === carTexts["fr"].busLane)) {
          setSelect(carTexts[carLanguage].busLane)
        }
        if (props.type === "convictions" && (select === carTexts["en"].headlightOffense || select === carTexts["fr"].headlightOffense)) {
          setSelect(carTexts[carLanguage].headlightOffense)
        }
        if (props.type === "convictions" && (select === carTexts["en"].followClosely || select === carTexts["fr"].followClosely)) {
          setSelect(carTexts[carLanguage].followClosely)
        }       
        if (props.type === "convictions" && (select === carTexts["en"].noYieldPedestrian || select === carTexts["fr"].noYieldPedestrian)) {
          setSelect(carTexts[carLanguage].noYieldPedestrian)
        }
        if (props.type === "convictions" && (select === carTexts["en"].noYield || select === carTexts["fr"].noYield)) {
          setSelect(carTexts[carLanguage].noYield)
        }
        if (props.type === "convictions" && (select === carTexts["en"].noSeatBelts || select === carTexts["fr"].noSeatBelts)) {
          setSelect(carTexts[carLanguage].noSeatBelts)
        }
        if (props.type === "convictions" && (select === carTexts["en"].noSignal || select === carTexts["fr"].noSignal)) {
          setSelect(carTexts[carLanguage].noSignal)
        }
        if (props.type === "convictions" && (select === carTexts["en"].noSharRoad || select === carTexts["fr"].noSharRoad)) {
          setSelect(carTexts[carLanguage].noSharRoad)
        }
        if (props.type === "convictions" && (select === carTexts["en"].noInsuranceEvidence || select === carTexts["fr"].noInsuranceEvidence)) {
          setSelect(carTexts[carLanguage].noInsuranceEvidence)
        }
        if (props.type === "convictions" && (select === carTexts["en"].noInsuranceCard || select === carTexts["fr"].noInsuranceCard)) {
          setSelect(carTexts[carLanguage].noInsuranceCard)
        }
        if (props.type === "convictions" && (select === carTexts["en"].dlViolation || select === carTexts["fr"].dlViolation)) {
          setSelect(carTexts[carLanguage].dlViolation)
        }
        if (props.type === "convictions" && (select === carTexts["en"].distractedDriving || select === carTexts["fr"].distractedDriving)) {
          setSelect(carTexts[carLanguage].distractedDriving)
        }
        if (props.type === "convictions" && (select === carTexts["en"].defectiveBrakes || select === carTexts["fr"].defectiveBrakes)) {
          setSelect(carTexts[carLanguage].defectiveBrakes)
        }
        //gender
        if (props.type === "gender" && (select === carTexts["en"].male || select === carTexts["fr"].male)) {
          setSelect(carTexts[carLanguage].male)
        }
        if (props.type === "gender" && (select === carTexts["en"].female || select === carTexts["fr"].female)) {
          setSelect(carTexts[carLanguage].female)
        }
        //relationship
        if (props.type === "maritalStatus" && (select === carTexts["en"].married || select === carTexts["fr"].married)) {
          setSelect(carTexts[carLanguage].married)
        }
        if (props.type === "maritalStatus" && (select === carTexts["en"].single || select === carTexts["fr"].single)) {
          setSelect(carTexts[carLanguage].single)
        }
        if (props.type === "maritalStatus" && (select === carTexts["en"].commonLaw || select === carTexts["fr"].commonLaw)) {
          setSelect(carTexts[carLanguage].commonLaw)
        }
        //home
        if (props.type === "home" && (select === answers["en"].homeownerInsurance || select === answers["fr"].homeownerInsurance)) {
          setSelect(answers[language].homeownerInsurance)
        }
        if (props.type === "home" && (select === answers["en"].condoInsurance || select === answers["fr"].condoInsurance)) {
          setSelect(answers[language].condoInsurance)
        }
        if (props.type === "home" && (select === answers["en"].tenantInsurance || select === answers["fr"].tenantInsurance)) {
          setSelect(answers[language].tenantInsurance)
        }
        //exteriorFinish
        if (props.type === "exteriorFinish" && (select === answers["en"].log || select === answers["fr"].log)) {
          setSelect(answers[language].log)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].monocoque || select === answers["fr"].monocoque)) {
          setSelect(answers[language].monocoque)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].brick || select === answers["fr"].brick)) {
          setSelect(answers[language].brick)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].cement || select === answers["fr"].cement)) {
          setSelect(answers[language].cement)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].concreteBlock || select === answers["fr"].concreteBlock)) {
          setSelect(answers[language].concreteBlock)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].frameWood || select === answers["fr"].frameWood)) {
          setSelect(answers[language].frameWood)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].stone || select === answers["fr"].stone)) {
          setSelect(answers[language].stone)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].brickVeneer || select === answers["fr"].brickVeneer)) {
          setSelect(answers[language].brickVeneer)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].vinyl || select === answers["fr"].vinyl)) {
          setSelect(answers[language].vinyl)
        }
        if (props.type === "exteriorFinish" && (select === answers["en"].other || select === answers["fr"].other)) {
          setSelect(answers[language].other)
        }
        //constructionType
        if (props.type === "construction" && (select === answers["en"].log || select === answers["fr"].log)) {
          setSelect(answers[language].log)
        }
        if (props.type === "construction" && (select === answers["en"].monocoque || select === answers["fr"].monocoque)) {
          setSelect(answers[language].monocoque)
        }
        if (props.type === "construction" && (select === answers["en"].brick || select === answers["fr"].brick)) {
          setSelect(answers[language].brick)
        }
        if (props.type === "construction" && (select === answers["en"].cement || select === answers["fr"].cement)) {
          setSelect(answers[language].cement)
        }
        if (props.type === "construction" && (select === answers["en"].concreteBlock || select === answers["fr"].concreteBlock)) {
          setSelect(answers[language].concreteBlock)
        }
        if (props.type === "construction" && (select === answers["en"].frameWood || select === answers["fr"].frameWood)) {
          setSelect(answers[language].frameWood)
        }
        if (props.type === "construction" && (select === answers["en"].stone || select === answers["fr"].stone)) {
          setSelect(answers[language].stone)
        }
        if (props.type === "construction" && (select === answers["en"].brickVeneer || select === answers["fr"].brickVeneer)) {
          setSelect(answers[language].brickVeneer)
        }
        if (props.type === "construction" && (select === answers["en"].vinyl || select === answers["fr"].vinyl)) {
          setSelect(answers[language].vinyl)
        }
        if (props.type === "construction" && (select === answers["en"].other || select === answers["fr"].other)) {
          setSelect(answers[language].other)
        }
        //garage
        if (props.type === "garage" && (select === answers["en"].attachedFrame || select === answers["fr"].attachedFrame)) {
          setSelect(answers[language].attachedFrame)
        }
        if (props.type === "garage" && (select === answers["en"].attachedMasonry || select === answers["fr"].attachedMasonry)) {
          setSelect(answers[language].attachedMasonry)
        }
        if (props.type === "garage" && (select === answers["en"].attachedMasonryOrVeneer || select === answers["fr"].attachedMasonryOrVeneer)) {
          setSelect(answers[language].attachedMasonryOrVeneer)
        }
        if (props.type === "garage" && (select === answers["en"].basement || select === answers["fr"].basement)) {
          setSelect(answers[language].basement)
        }
        if (props.type === "garage" && (select === answers["en"].basementUnderGarage || select === answers["fr"].basementUnderGarage)) {
          setSelect(answers[language].basementUnderGarage)
        }
        if (props.type === "garage" && (select === answers["en"].builtIn || select === answers["fr"].builtIn)) {
          setSelect(answers[language].builtIn)
        }
        if (props.type === "garage" && (select === answers["en"].carport || select === answers["fr"].carport)) {
          setSelect(answers[language].carport)
        }
        if (props.type === "garage" && (select === answers["en"].carportStorage || select === answers["fr"].carportStorage)) {
          setSelect(answers[language].carportStorage)
        }
        if (props.type === "garage" && (select === answers["en"].detachedMasonry || select === answers["fr"].detachedMasonry)) {
          setSelect(answers[language].detachedMasonry)
        }
        if (props.type === "garage" && (select === answers["en"].detachedFrame || select === answers["fr"].detachedFrame)) {
          setSelect(answers[language].detachedFrame)
        }
        if (props.type === "garage" && (select === answers["en"].detachedMasonryOrVeneer || select === answers["fr"].detachedMasonryOrVeneer)) {
          setSelect(answers[language].detachedMasonryOrVeneer)
        }
        if (props.type === "garage" && (select === answers["en"].other || select === answers["fr"].other)) {
          setSelect(answers[language].other)
        }
        if (props.type === "garage" && (select === answers["en"].none || select === answers["fr"].none)) {
          setSelect(answers[language].none)
        }
        //firehall
        if (props.type === "firehall" && (select === answers["en"].fiveKm || select === answers["fr"].fiveKm)) {
          setSelect(answers[language].fiveKm)
        }
        if (props.type === "firehall" && (select === answers["en"].eightKM || select === answers["fr"].eightKM)) {
          setSelect(answers[language].eightKM)
        }
        if (props.type === "firehall" && (select === answers["en"].overEightKm || select === answers["fr"].overEightKm)) {
          setSelect(answers[language].overEightKm)
        }        
        //fireHydrant
        if (props.type === "fireHydrant" && (select === answers["en"].withinThreeHunderdM || select === answers["fr"].withinThreeHunderdM)) {
          setSelect(answers[language].withinThreeHunderdM)
        }
        if (props.type === "fireHydrant" && (select === answers["en"].overThreeHunderedM || select === answers["fr"].overThreeHunderedM)) {
          setSelect(answers[language].overThreeHunderedM)
        }
        //heating
        if (props.type === "heating" && (select === answers["en"].centralFurnaceGas || select === answers["fr"].centralFurnaceGas)) {
          setSelect(answers[language].centralFurnaceGas)
        }
        if (props.type === "heating" && (select === answers["en"].centralFurnaceOil || select === answers["fr"].centralFurnaceOil)) {
          setSelect(answers[language].centralFurnaceOil)
        }
        if (props.type === "heating" && (select === answers["en"].centerFurnacePropane || select === answers["fr"].centerFurnacePropane)) {
          setSelect(answers[language].centerFurnacePropane)
        }
        if (props.type === "heating" && (select === answers["en"].centralFurnaceWood || select === answers["fr"].centralFurnaceWood)) {
          setSelect(answers[language].centralFurnaceWood)
        }
        if (props.type === "heating" && (select === answers["en"].electric || select === answers["fr"].electric)) {
          setSelect(answers[language].electric)
        }
        if (props.type === "heating" && (select === answers["en"].hotWaterSteam || select === answers["fr"].hotWaterSteam)) {
          setSelect(answers[language].hotWaterSteam)
        }
        if (props.type === "heating" && (select === answers["en"].combinationFurnace || select === answers["fr"].combinationFurnace)) {
          setSelect(answers[language].combinationFurnace)
        }
        if (props.type === "heating" && (select === answers["en"].spaceHeaterGas || select === answers["fr"].spaceHeaterGas)) {
          setSelect(answers[language].spaceHeaterGas)
        }
        if (props.type === "heating" && (select === answers["en"].spaceHeaterOil || select === answers["fr"].spaceHeaterOil)) {
          setSelect(answers[language].spaceHeaterOil)
        }
        if (props.type === "heating" && (select === answers["en"].spaceHeaterElectric || select === answers["fr"].spaceHeaterElectric)) {
          setSelect(answers[language].spaceHeaterElectric)
        }
        if (props.type === "heating" && (select === answers["en"].woodBurningStove || select === answers["fr"].woodBurningStove)) {
          setSelect(answers[language].woodBurningStove)
        }
        if (props.type === "heating" && (select === answers["en"].fireplace || select === answers["fr"].fireplace)) {
          setSelect(answers[language].fireplace)
        }
        if (props.type === "heating" && (select === answers["en"].solar || select === answers["fr"].solar)) {
          setSelect(answers[language].solar)
        }
        if (props.type === "heating" && (select === answers["en"].other || select === answers["fr"].other)) {
          setSelect(answers[language].other)
        }
        if (props.type === "heating" && (select === answers["en"].none || select === answers["fr"].none)) {
          setSelect(answers[language].none)
        }
        //secondHeating
        if (props.type === "secondHeating" && (select === answers["en"].centralFurnaceGas || select === answers["fr"].centralFurnaceGas)) {
          setSelect(answers[language].centralFurnaceGas)
        }
        if (props.type === "secondHeating" && (select === answers["en"].centralFurnaceOil || select === answers["fr"].centralFurnaceOil)) {
          setSelect(answers[language].centralFurnaceOil)
        }
        if (props.type === "secondHeating" && (select === answers["en"].centerFurnacePropane || select === answers["fr"].centerFurnacePropane)) {
          setSelect(answers[language].centerFurnacePropane)
        }
        if (props.type === "secondHeating" && (select === answers["en"].centralFurnaceWood || select === answers["fr"].centralFurnaceWood)) {
          setSelect(answers[language].centralFurnaceWood)
        }
        if (props.type === "secondHeating" && (select === answers["en"].electric || select === answers["fr"].electric)) {
          setSelect(answers[language].electric)
        }
        if (props.type === "secondHeating" && (select === answers["en"].hotWaterSteam || select === answers["fr"].hotWaterSteam)) {
          setSelect(answers[language].hotWaterSteam)
        }
        if (props.type === "secondHeating" && (select === answers["en"].combinationFurnace || select === answers["fr"].combinationFurnace)) {
          setSelect(answers[language].combinationFurnace)
        }
        if (props.type === "secondHeating" && (select === answers["en"].spaceHeaterGas || select === answers["fr"].spaceHeaterGas)) {
          setSelect(answers[language].spaceHeaterGas)
        }
        if (props.type === "secondHeating" && (select === answers["en"].spaceHeaterOil || select === answers["fr"].spaceHeaterOil)) {
          setSelect(answers[language].spaceHeaterOil)
        }
        if (props.type === "secondHeating" && (select === answers["en"].spaceHeaterElectric || select === answers["fr"].spaceHeaterElectric)) {
          setSelect(answers[language].spaceHeaterElectric)
        }
        if (props.type === "secondHeating" && (select === answers["en"].woodBurningStove || select === answers["fr"].woodBurningStove)) {
          setSelect(answers[language].woodBurningStove)
        }
        if (props.type === "secondHeating" && (select === answers["en"].fireplace || select === answers["fr"].fireplace)) {
          setSelect(answers[language].fireplace)
        }
        if (props.type === "secondHeating" && (select === answers["en"].solar || select === answers["fr"].solar)) {
          setSelect(answers[language].solar)
        }
        if (props.type === "secondHeating" && (select === answers["en"].other || select === answers["fr"].other)) {
          setSelect(answers[language].other)
        }
        if (props.type === "secondHeating" && (select === answers["en"].none || select === answers["fr"].none)) {
          setSelect(answers[language].none)
        }
        //building
        if (props.type === "building" && (select === answers["en"].detached || select === answers["fr"].detached)) {
          setSelect(answers[language].detached)
        }
        if (props.type === "building" && (select === answers["en"].semiDetached || select === answers["fr"].semiDetached)) {
          setSelect(answers[language].semiDetached)
        }
        if (props.type === "building" && (select === answers["en"].townHouse || select === answers["fr"].townHouse)) {
          setSelect(answers[language].townHouse)
        }
        if (props.type === "building" && (select === answers["en"].rowHouse || select === answers["fr"].rowHouse)) {
          setSelect(answers[language].rowHouse)
        }
        if (props.type === "building" && (select === answers["en"].duplexSide || select === answers["fr"].duplexSide)) {
          setSelect(answers[language].duplexSide)
        }
        if (props.type === "building" && (select === answers["en"].duplexUpDown || select === answers["fr"].duplexUpDown)) {
          setSelect(answers[language].duplexUpDown)
        }
        if (props.type === "building" && (select === answers["en"].triplex || select === answers["fr"].triplex)) {
          setSelect(answers[language].triplex)
        }
        //buildingApt
        if (props.type === "buildingApt" && (select === answers["en"].townHouse || select === answers["fr"].townHouse)) {
          setSelect(answers[language].townHouse)
        }
        if (props.type === "buildingApt" && (select === answers["en"].rowHouse || select === answers["fr"].rowHouse)) {
          setSelect(answers[language].rowHouse)
        }
        if (props.type === "buildingApt" && (select === answers["en"].triplex || select === answers["fr"].triplex)) {
          setSelect(answers[language].triplex)
        }
        if (props.type === "buildingApt" && (select === answers["en"].highRiseApartment || select === answers["fr"].highRiseApartment)) {
          setSelect(answers[language].highRiseApartment)
        }
        if (props.type === "buildingApt" && (select === answers["en"].lowRiseApartment || select === answers["fr"].lowRiseApartment)) {
          setSelect(answers[language].lowRiseApartment)
        }
        if (props.type === "buildingApt" && (select === answers["en"].commercial || select === answers["fr"].commercial)) {
          setSelect(answers[language].commercial)
        }
        //residence
        if (props.type === "residence" && (select === answers["en"].youAndYourFamily || select === answers["fr"].youAndYourFamily)) {
          setSelect(answers[language].youAndYourFamily)
        }
        if (props.type === "residence" && (select === answers["en"].yourFamilyAndNotherFamily || select === answers["fr"].yourFamilyAndNotherFamily)) {
          setSelect(answers[language].yourFamilyAndNotherFamily)
        }
        if (props.type === "residence" && (select === answers["en"].yourFamilyAndTwoFamilies || select === answers["fr"].yourFamilyAndTwoFamilies)) {
          setSelect(answers[language].yourFamilyAndTwoFamilies)
        }
        if (props.type === "residence" && (select === answers["en"].rentedOut || select === answers["fr"].rentedOut)) {
          setSelect(answers[language].rentedOut)
        }
        //monthHome
        if (props.type === "monthHome" && (select === answers["en"].january || select === answers["fr"].january)) {
          setSelect(answers[language].january)
        }
        if (props.type === "monthHome" && (select === answers["en"].february || select === answers["fr"].february)) {
          setSelect(answers[language].february)
        }
        if (props.type === "monthHome" && (select === answers["en"].march || select === answers["fr"].march)) {
          setSelect(answers[language].march)
        }
        if (props.type === "monthHome" && (select === answers["en"].april || select === answers["fr"].april)) {
          setSelect(answers[language].april)
        }
        if (props.type === "monthHome" && (select === answers["en"].may || select === answers["fr"].may)) {
          setSelect(answers[language].may)
        }
        if (props.type === "monthHome" && (select === answers["en"].june || select === answers["fr"].june)) {
          setSelect(answers[language].june)
        }
        if (props.type === "monthHome" && (select === answers["en"].january || select === answers["fr"].january)) {
          setSelect(answers[language].january)
        }
        if (props.type === "monthHome" && (select === answers["en"].july || select === answers["fr"].july)) {
          setSelect(answers[language].july)
        }
        if (props.type === "monthHome" && (select === answers["en"].august || select === answers["fr"].august)) {
          setSelect(answers[language].august)
        }
        if (props.type === "monthHome" && (select === answers["en"].september || select === answers["fr"].september)) {
          setSelect(answers[language].september)
        }
        if (props.type === "monthHome" && (select === answers["en"].october || select === answers["fr"].october)) {
          setSelect(answers[language].october)
        }
        if (props.type === "monthHome" && (select === answers["en"].november || select === answers["fr"].november)) {
          setSelect(answers[language].november)
        }
        if (props.type === "monthHome" && (select === answers["en"].december || select === answers["fr"].december)) {
          setSelect(answers[language].december)
        }
        //insuranceGap
        if (props.type === "insuranceGap" && (select === answers["en"].oneYear || select === answers["fr"].oneYear)) {
          setSelect(answers[language].oneYear)
        }
        if (props.type === "insuranceGap" && (select === answers["en"].twoYears || select === answers["fr"].twoYears)) {
          setSelect(answers[language].twoYears)
        }
        if (props.type === "insuranceGap" && (select === answers["en"].threeYears || select === answers["fr"].threeYears)) {
          setSelect(answers[language].threeYears)
        }
        if (props.type === "insuranceGap" && (select === answers["en"].fourYears || select === answers["fr"].fourYears)) {
          setSelect(answers[language].fourYears)
        }
        if (props.type === "insuranceGap" && (select === answers["en"].fiveYears || select === answers["fr"].fiveYears)) {
          setSelect(answers[language].fiveYears)
        }
        //insuranceLong
        if (props.type === "insuranceLong" && (select === answers["en"].oneYear || select === answers["fr"].oneYear)) {
          setSelect(answers[language].oneYear)
        }
        if (props.type === "insuranceLong" && (select === answers["en"].twoYears || select === answers["fr"].twoYears)) {
          setSelect(answers[language].twoYears)
        }
        if (props.type === "insuranceLong" && (select === answers["en"].threeYears || select === answers["fr"].threeYears)) {
          setSelect(answers[language].threeYears)
        }
        if (props.type === "insuranceLong" && (select === answers["en"].fourYears || select === answers["fr"].fourYears)) {
          setSelect(answers[language].fourYears)
        }
        if (props.type === "insuranceLong" && (select === answers["en"].fiveYears || select === answers["fr"].fiveYears)) {
          setSelect(answers[language].fiveYears)
        }
        //claimHome
        if (props.type === "claimHome" && (select === answers["en"].noClaims || select === answers["fr"].noClaims)) {
          setSelect(answers[language].noClaims)
        }
        if (props.type === "claimHome" && (select === answers["en"].oneClaim || select === answers["fr"].oneClaim)) {
          setSelect(answers[language].oneClaim)
        }
        if (props.type === "claimHome" && (select === answers["en"].twoClaims || select === answers["fr"].twoClaims)) {
          setSelect(answers[language].twoClaims)
        }
        if (props.type === "claimHome" && (select === answers["en"].threePlusClaims || select === answers["fr"].threePlusClaims)) {
          setSelect(answers[language].threePlusClaims)
        }
        //cancellations
        if (props.type === "cancellations" && (select === answers["en"].noCancellations || select === answers["fr"].noCancellations)) {
          setSelect(answers[language].noCancellations)
        }
        if (props.type === "cancellations" && (select === answers["en"].oneCancellation || select === answers["fr"].oneCancellation)) {
          setSelect(answers[language].oneCancellation)
        }
        if (props.type === "cancellations" && (select === answers["en"].twoCancellations || select === answers["fr"].twoCancellations)) {
          setSelect(answers[language].twoCancellations)
        }
        if (props.type === "cancellations" && (select === answers["en"].threePlusCancellations || select === answers["fr"].threePlusCancellations)) {
          setSelect(answers[language].threePlusCancellations)
        }
        //jobHome
        if (props.type === "jobHome" && (select === texts["en"].uniGraduate || select === texts["fr"].uniGraduate)) {
          setSelect(texts[language].uniGraduate)
        }
        if (props.type === "jobHome" && (select === texts["en"].professional || select === texts["fr"].professional)) {
          setSelect(texts[language].professional)
        }
        if (props.type === "jobHome" && (select === texts["en"].retired || select === texts["fr"].retired)) {
          setSelect(texts[language].retired)
        }
        if (props.type === "jobHome" && (select === texts["en"].student || select === texts["fr"].student)) {
          setSelect(texts[language].student)
        }
        if (props.type === "jobHome" && (select === texts["en"].none || select === texts["fr"].none)) {
          setSelect(texts[language].none)
        }
      }, [carLanguage, language],);


  return (
    <div className='t-dropdown-box' ref={dropdownRef}>
    <button className={showMenu ? "t-dropdown-button active" : props.dateError ? "t-dropdown-button error" : "t-dropdown-button"} onClick={() => setShowMenu(!showMenu)}>
        <span className={selected ? "dropdown-button-text selected" : "dropdown-button-text"}>{select ? select : props.placeholder}</span>
        <div className={showMenu ? "dropdown-select-arrow active" : "dropdown-select-arrow"}><SelectArrow /></div>
    </button>
          {showMenu ? (
    <ul className="t-dropdown-ul">
       {props.list.map((dropOptions: any, index: any) => {
              return (
                <li className={`t-dropdown-li ${index === hoveredIndex ? "hovered" : ""}`} key={index} value={dropOptions} data-index={index} onClick={handleLiClick}><span>{dropOptions}</span></li>
              );
            })}
    </ul>
    ) : null}
</div>
  )
}

export default DropdownMenu