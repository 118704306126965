import React, { useContext, useEffect, useState } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import { carTexts } from "../../../text";
import Question from "../../../components/refresh/Question";
import { CarLanguageContext } from "../CarContext";
import ClaimForm from "./ClaimForm";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import XButton from "../../../components/refresh/assets/XButton";

type Claim = {
  claimType: string | null;
  dateOfLoss: string | undefined;
  amountPaid: number | null;
  id: string;
};

function CarStepTwenty() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [hasClaims, setHasClaims] = useState<boolean | null>(null);
  const [claims, setClaims] = useState<[] | Claim[]>([]);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const LSClaims = useLocalStorage("claims");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (LSClaims) {
      if (JSON.parse(LSClaims).length > 0) {
        setHasClaims(true);
        setDisplayForm(false);
        setClaims(JSON.parse(LSClaims));
      }

      else {
        setHasClaims(false);
        setClaims(JSON.parse(LSClaims));
      }

    }
  }, [LSClaims]);


  const handleRemove = () => {
    if (claims.length === 1) {
      setDisplayForm(true);
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].claimsLastFiveYears} />
      <RadioButtonGroup setDisplayForm={setDisplayForm} setAccept={setHasClaims} accept={hasClaims} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>

      {hasClaims === true ? (
        <div className="t-content-container" style={{ marginLeft: '0%' }}>
          <Question subQuestion={carTexts[carLanguage].claimInfoSubText} />
          {claims.length > 0 
            ? claims.map((claim, index) => {
                return (
                  <div className="claim-body" key={claim.id}>
                    <p className="claim-type">{claim.claimType}</p>
                    <div style={{ display: "flex" }}>
                      <p className="claim-date">{claim.dateOfLoss}</p>
                      <p className="claim-amount">{"$" + claim.amountPaid}</p>
                      <button aria-label="Close" className="modal-close-button"
                      onClick={() => {
                        setClaims(claims.filter((claim, i) => i !== index));
                        handleRemove();
                      }}
                      ><XButton /></button>
                    </div>
                  </div>
                );
              })
            : null}
          {claims.length > 0 && displayForm === false ? (
            <div>
              <button
                className="btn form"
                onClick={() => {
                  setDisplayForm(true);
                }}
              >
                {carTexts[carLanguage].add}
              </button>
            </div>
          ) : null}
          {displayForm === true ? (
            <div>
              <ClaimForm
                onSubmit={(claim: any) => {
                  setClaims((prevClaims: any) => [...prevClaims, claim]);
                  setDisplayForm(false);
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      <CarFooter
        data={{
          claims: claims,
        }}
        formSteps={21}
        type="submit"
        disabled={(displayForm === true && claims.length < 1) || hasClaims === null}
        backFormSteps={19}
      />
    </div>
    </div>
  );
}

export default CarStepTwenty;
