import { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";

function CarStepEighteen() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [job, setJob] = useState<string>('');
  const LSJob = useLocalStorage("job");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (LSJob) {
      setJob(LSJob);
    }
  }, [LSJob]);

  const jobOptions = [`${carTexts[carLanguage].uniGraduate}`, `${carTexts[carLanguage].professional}`, `${carTexts[carLanguage].retired}`, `${carTexts[carLanguage].student}`, `${carTexts[carLanguage].none}`]

  useEffect(() => {
    if (job === carTexts["fr"].uniGraduate) {
      setJob(carTexts["en"].uniGraduate)
    }
    if (job === carTexts["fr"].professional) {
      setJob(carTexts["en"].professional)
    }
    if (job === carTexts["fr"].retired) {
      setJob(carTexts["en"].retired)
    }
    if (job === carTexts["fr"].student) {
      setJob(carTexts["en"].student)
    }
    if (job === carTexts["fr"].none) {
      setJob(carTexts["en"].none)
    }
  }, [job])

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].eligibleDiscounts}
        subQuestion={carTexts[carLanguage].selectOptions}
      />
      <DropdownMenu type='job' list={jobOptions} setState={setJob} placeholder={carTexts[carLanguage].select} lsValue={'job'}/>

      <CarFooter
        data={{
          job: job,
        }}
        formSteps={19}
        type="submit"
        disabled={!job}
        backFormSteps={17}
      />
    </div>
    </div>
  );
}

export default CarStepEighteen;
