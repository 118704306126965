// import Dropdown from "../inputs/Dropdown";
// import InputField from "../inputs/InputField";
// import InputFieldSmall from "../inputs/InputFieldSmall";
import "./questionStyles.css";
import Question from "../../components/refresh/Question";
import UnitNumberInput from "../../components/refresh/UnitNumberInput"
import DropdownMenu from "../refresh/DropdownMenu";
import TextInput from "../refresh/TextInput";
import MoneyInput from "../refresh/MoneyInput";

type SubQuestionsProps = {
  question: string;
  type: "dropdown" | "text" | "number";
  dropdownType?: string;
  placeholder: string;
  options: string[];
  onChange: (text: any) => void;
  inputSize: string;
  nonCenter?: boolean;
  onLeft?: boolean;
  lsValue?: any;
  initialValue?: any;
  error?: boolean;
  maxLength?: number;
};

function SubQuestions(props: SubQuestionsProps) {
  return (
    <div className={!props.onLeft ? "subQuestions" : "subQuestions-flex-start"}>
      {/* <Question subQuestion={props.question} /> */}
      <p className="dropdown-title">{props.question}</p>
      {props.type === "number" ? 

      <MoneyInput 
      placeholder={props.placeholder}
      onChange={(value) => {
        props.onChange(value);
      }}
                  
      lsValue={props.lsValue}
      maxLength={props.maxLength}
      initialValue={props.initialValue}
      /> : 
        props.type === "text"
        ? (
        props.inputSize === "big" ? (
          <TextInput 
            placeholder={props.placeholder}
            onChange={(value) => {
              props.onChange(value);
            }}
            
            lsValue={props.lsValue}
            maxLength={props.maxLength}
            initialValue={props.initialValue}
            />
        ) : (
          <TextInput maxLength={props.maxLength} placeholder={props.placeholder} initialValue={props.initialValue} onChange={(value) => {props.onChange(value)}} type={props.type} lsValue={props.lsValue} />
        )
      ) : (
        <>
        <DropdownMenu type={props.dropdownType} placeholder={props.placeholder} list={props.options} setSelect={(value) => {
            props.onChange(value);
          }} nonCenter={props.nonCenter} lsValue={props.lsValue} initialValue={props.initialValue ? props.initialValue : ''} error={props.error}/>
        </>
      )}
    </div>
  );
}

export default SubQuestions;
