const DarkButton = () => {
    return (
      <>
      <svg width="48" height="24" viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="24" rx="12" fill="#0D172E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.2752 7.01434C32.8829 7.20175 31 9.20242 31 11.6429C31 14.207 33.0787 16.2857 35.6429 16.2857C37.029 16.2857 38.2737 15.6784 39.1248 14.7142C39.107 14.7144 39.0892 14.7145 39.0714 14.7145C36.4283 14.7145 34.2856 12.5719 34.2856 9.92878C34.2856 8.83194 34.6549 7.8212 35.2752 7.01434ZM30 11.6429C30 8.52639 32.5264 6 35.6429 6C35.9334 6 36.2192 6.02201 36.4985 6.06453C36.6955 6.09452 36.8559 6.23865 36.9068 6.43135C36.9576 6.62405 36.8891 6.82853 36.7325 6.95177C35.8505 7.64576 35.2856 8.72116 35.2856 9.92878C35.2856 12.0196 36.9806 13.7145 39.0714 13.7145C39.4101 13.7145 39.7378 13.6701 40.0492 13.5871C40.2417 13.5358 40.4464 13.6037 40.57 13.76C40.6937 13.9162 40.7127 14.131 40.6186 14.3066C39.668 16.0787 37.7968 17.2857 35.6429 17.2857C32.5264 17.2857 30 14.7593 30 11.6429Z" fill="white"/>
<g filter="url(#filter0_d_948_32400)">
</g>
<defs>
<filter id="filter0_d_948_32400" x="1.42847" y="1.42871" width="21.1428" height="21.1431" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_948_32400"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_948_32400" result="shape"/>
</filter>
</defs>
</svg>      
      </>
    )
  }
  
  export default DarkButton