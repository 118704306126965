const ThumbsUp = () => {
    return(
      <>
        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25.5" cy="25.5" r="25.5" fill="#ECECE9"/>
          <path d="M28.2422 16.2209C29.2578 16.4139 29.918 17.3528 29.7148 18.3176L29.625 18.7406C29.418 19.7315 29.0352 20.674 28.5 21.5313H34.125C35.1602 21.5313 36 22.3291 36 23.3125C36 23.999 35.5898 24.5965 34.9883 24.8934C35.4141 25.2199 35.6875 25.7209 35.6875 26.2813C35.6875 27.1496 35.0313 27.8733 34.168 28.0291C34.3398 28.3 34.4375 28.6155 34.4375 28.9531C34.4375 29.7436 33.8945 30.4153 33.1445 30.6453C33.1719 30.7678 33.1875 30.8977 33.1875 31.0313C33.1875 32.0147 32.3477 32.8125 31.3125 32.8125H27.5039C26.7617 32.8125 26.0391 32.6047 25.4219 32.2151L23.918 31.2613C22.875 30.6008 22.25 29.4875 22.25 28.2963V26.875V25.0938V24.1697C22.25 23.0862 22.7695 22.0656 23.6562 21.3865L23.9453 21.1676C24.9805 20.3809 25.6875 19.275 25.9453 18.043L26.0352 17.6199C26.2383 16.6551 27.2266 16.0279 28.2422 16.2209ZM17.25 22.125H19.75C20.4414 22.125 21 22.6557 21 23.3125V31.625C21 32.2819 20.4414 32.8125 19.75 32.8125H17.25C16.5586 32.8125 16 32.2819 16 31.625V23.3125C16 22.6557 16.5586 22.125 17.25 22.125Z" fill="#0D172E"/>
        </svg>
      </>
    )
  }
  
  export default ThumbsUp;