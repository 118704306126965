
import './RefreshStyles.css'
import ArrowButton from './assets/ArrowRight'
import XButton from './assets/XButton';
import { useEffect, useState, ChangeEvent, useRef } from 'react'

type HelpModalProps = {
    handleModalClose: () => void;
  }

const HelpModal: React.FC<HelpModalProps> = ( props: HelpModalProps ) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        issue: '',
    })
    const modalRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData({ ...formData, [name]: value})

        switch (name) {
          case 'firstName':
                setFirstName(value);
                if (!firstName) {
                  setFirstNameError(true)
                } else if (firstName.trim() === '') {
                  setFirstNameError(true)
                } else {
                  setFirstNameError(false);
                }
                break;
          case 'lastName':
              setLastName(value);
              if (!value) {
                setLastNameError(true)
              } else if (value.trim() === '') {
                setLastNameError(true)
              } else {
                setLastNameError(false);
              }
              break;
          case 'email':
                setEmail(value);
                if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                  setEmailError(false);
                } else {
                  setEmailError(true);
                }
                break;
          case 'phoneNumber':
                setPhoneNumber(value);
                if (/^\d{10}$/.test(value)) {
                  setPhoneNumberError(false);
                } else {
                  setPhoneNumberError(true);
                }
                break;
          case  'issue':
                setIssue(value);
                if (!issue) {
                  setIssueError(true)
                } else if (value.trim() === '') {
                  setIssueError(true)
                } else {
                  setIssueError(false)
                }
          break;
          default:
          break;
      }
    }

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          props.handleModalClose();
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.handleModalClose]);

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData({ ...formData, [name]: value});

        if (name === 'issue') {
          setIssue(value);
          setIssueError(false);
      }
    }

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          props.handleModalClose();
        }
      };
  
      document.addEventListener("keydown", handleKeyDown);
  
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [props.handleModalClose]);

      const handleHelpSubmitClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();


      if (!firstName) {
          setFirstNameError(true);
      }
      if (!lastName) {
          setLastNameError(true);
      }
      if (!email) {
          setEmailError(true);
      }
      if (!phoneNumber) {
          setPhoneNumberError(true);
      }
      if (!issue) {
          setIssueError(true);
      }

      if (firstNameError || lastNameError || emailError || phoneNumberError || issueError) {
          setError(true);
          return
      } else {
        const formData = new FormData(event.currentTarget.form as HTMLFormElement);
        handleHelpSubmit(formData);
      };
    }  

      const handleHelpSubmit = (formData: FormData): void => {
        const form = {
            firstName: formData.get('firstName'),
            lastName: formData.get('lastName'),
            email: formData.get('email'),
            phoneNumber: formData.get('phoneNumber'),
            issue: formData.get('issue')
        }
        setError(false)
        setFormData({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          issue: ''
        });
      }

      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [issue, setIssue] = useState('');
      const [firstNameError, setFirstNameError] = useState(false);
      const [lastNameError, setLastNameError] = useState(false);
      const [emailError, setEmailError] = useState(false);
      const [phoneNumberError, setPhoneNumberError] = useState(false);
      const [issueError, setIssueError] = useState(false);
      const [error, setError] = useState(false)
      const errorMsg = 'This field is required'

  return (
    <div className="modal-container">
        <div ref={modalRef} className="modal-wrapper">
        <p className="modal-heading">Ask us anything.</p>
        <p className='modal-sub-heading'>We will get back to you as soon as possible. You can also call us toll-free at <span className="modal-sub-heading-phone-number">1-855-636-0002</span>.</p>
        <form className="modal-form" onSubmit={(event) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  handleHelpSubmit(formData);
}}>
            <div className="modal-input-div">
                <label htmlFor="firstName"></label>
                <input className={`modal-input-fields ${firstNameError ? 'error' : ''}`} placeholder='First Name *' type="text" id="firstName" name="firstName" value={formData.firstName} onChange={(e) => handleInputChange(e)} required/>
                <label htmlFor="lastName"></label>
                <input className={`modal-input-fields ${lastNameError ? 'error' : ''}`} placeholder='Last Name *' type="text" id="lastName" name="lastName" value={formData.lastName} onChange={(e) => handleInputChange(e)} required/>
            </div>
            <div className="modal-input-div">
                <label htmlFor="email"></label>
                <input className={`modal-input-fields ${emailError ? 'error' : ''}`} placeholder='Email *' type="text" id="email" name="email" value={formData.email} onChange={(e) => handleInputChange(e)} required/>
                <label htmlFor="phoneNumber"></label>
                <input className={`modal-input-fields ${phoneNumberError ? 'error' : ''}`} placeholder='Phone Number *' type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={(e) => handleInputChange(e)} required/>
            </div>
            <div className="modal-input-div">
                <label htmlFor="issue"></label>
                <textarea
                className={`modal-input-textarea ${issueError ? ' error' : ''}`}
                placeholder="How can we help?"
                id="issue"
                name="issue"
                value={formData.issue}
                onChange={(e) => handleTextAreaChange(e)}
                ></textarea>
            </div>
            {error && <div className="modal-error-msg">{errorMsg}</div>}
            <div className="button-div">
            <button className="btn dark-blue text-with-arrow-next" onClick={handleHelpSubmitClick}><span>Send</span><ArrowButton /></button>
            <button className="modal-close-button" onClick={props.handleModalClose}><XButton /></button>
            </div>
        </form>
        </div>
    </div>
  )
}

export default HelpModal