import { useContext, useState } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import Question from "../../../components/refresh/Question";

function CarStepOnePointFive() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [accept, setAccept] = useState<boolean | null>(null);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].beforeStartQuestion}
        subQuestion={
          accept === false
            ? carTexts[carLanguage].refusedMessage
            : accept === null
            ? carTexts[carLanguage].refusedMessage
            : (
              <>
              {carTexts[carLanguage].beforeStartMessage2}{carTexts[carLanguage].quebecClient}<a href="tel:5146360002" className="phone-number">{carTexts[carLanguage].quebecNumber}</a>. {carTexts[carLanguage].ontarioClient}<a href="tel:6132122352" className="phone-number">{carTexts[carLanguage].ontarioNumber}</a>.
              </>
            )
        }
      />
      <div className="t-boolean-buttons-container">
      <RadioButtonGroup setAccept={setAccept} accept={accept} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>
      </div>
      <CarFooter
        formSteps={2}
        type="submit"
        disabled={accept === true ? true : accept === null ? true : false}
        backHidden={true}
      />
    </div>
    </div>
  );
}

export default CarStepOnePointFive;
