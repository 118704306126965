export const texts: any = {
  en: {
    yes: "Yes",
    no: "No",
    buttonText: "Continue",
    beforeStartQuestion: "Before we get started, just a few quick questions:",
    beforeStartSubQuestion1:
      "Have you or a person listed on your policy been cancelled or refused by another insurance company in the past 3 years?",
    beforeStartMessage:
      "No stress. We can still help you out, but it will need to be by telephone. Please call us at ",
      homeNumber: "514-636-0002",
      homeNumberAlt: "438-843-4814",
    typeOfHomeInsurance: "What type of insurance are you looking for?",
    typeSub: "Type of insurance",
    address: "Where do you live?",
    typeAddress:
      "Type your address out in the following format and then select your address from the dropdown in order to move to the next step: H9P 2W9 1425 Trans Canada Route Suite 300, Dorval, Quebec",
    prefilledInfo:
      "We have gathered and pre-filled the information below about your property. <br><br>Please verify 😊 ",
    yearBuilt:
      "What year was your property built? (you can give an approximate date)",
    totalLivingArea:
      "What is the total living area of your property in sq ft? (to the best of your knowledge)",
    exteriorFinish: "What is the exterior finish of your property?",
    constructionType: "What is the construction type of your property?",
    garageType: "What is the type of garage?",
    basementPercentageFinished: "What percentage of the basement in finished?",
    numOfStoreys: "How many storeys are there in your property?",
    yearRoofReplaced: "What year was your roof most recently replaced?",
    distanceToFirehall:
      "What is the approximate distance to the nearest fire hall?",
    distanceToFireHydrant:
      "What is the approximate distance to the nearest fire hydrant?",
    primaryHeatingType:
      "What is the type of the primary heating installed at your property?",
    secondaryHeatingType:
      "What is the type of the secondary heating installed at your property?",
    buildingStyle: "What is the style of the building?",
    numOfUnits: "How many units are there in your property?",
    nameQuestion: "What's your name?",
    nameSubQuestion:
      "Please use full name that appears on your driver’s license.",
    oldestApplicantDOB: "Date of birth of the eldest applicant.",
    residenceOccupancy: "Who occupies this residence?",
    yearMovedIn: "What year did you move into this property?",
    policyStartDate: "When do you want to start the policy?",
    activePolicy: "Do you have a current active policy?",
    insuranceGap: "How long have you had insurance without gaps?",
    howLongInsuredCurrentCompany:
      "How long have you been insured with your current company?",
    claimsFiveYears: "Any claims within the last 5 years?",
    nonPaymentCancellations:
      "How many cancellations have you had due to non-payment?",
    havePriorPolicy: "Have you ever had a home insurance policy?",
    selectDiscountQuestion: "Select the discounts you would like to include:",
    discountQuestionOne:
      "Save up to 18% more. Are you open to bundling multiple policies (e.g. auto) with the same company?",
    discountQuestionTwo: "Does your property have a monitored burglar alarm?",
    discountQuestionThree: "Does your property have a monitored fire alarm?",
    discountQuestionFour: "Does your property have a sprinkler system?",
    discountQuestionFive: "Are the occupants non-smokers?",
    discountQuestionSix: "How many mortgages do you have on the property?",
    discountQuestionSeven:
      "Please provide your email address so we can send you a copy of your quotes",
    phoneNumber: "Phone number",
    yourQuote: "Your Quote",
    quotePrice: "/month (plus 9% tax)",
    quoteNextStep: "What are the next steps?",
    quoteCall:
      "Call us now at ",
      quoteNumber: "438-843-4814",
      review: " to review with a KBD insurance broker and finalize your policy.",
    failedQuote: "Oops! Looks like there was an error with your quote",
    failedQuoteSub:
    "Not to worry though! Give us a call at ",
    failedQuoteSubEnd: " and one of our team members will be able to get you sorted in no time.",
    buy: "Buy",
    back: "Back",
    startOver: "Start a new quote",
    dateFormat: "MM/DD/YYYY",
    copyright: "KBD Insurance. All rights reserved.",
    myHouse: "My House",
    aboutMe: "About Me",
    myPolicy: "My Policy",
    discounts: "Discounts",
    year: "Year",
    month: "Month",
    day: "Day",
    email: "Email address",
    homeQuestion: "What kind of insurance are you looking for?",
    homeInsuranceTypeCar: "Auto",
    homeInsuranceTypeHome: "Home",
    phoneUs: "Phone us",
    unitNumber: "Unit Number",
    provinceError:
      "Sorry! We currently only service the Quebec area. If you are out of province, no stress! Please give us a call at 438-843-4814",
    postalCodeError:
      "We've noticed you forgot to input a street number! Please make sure to do so and choose your proper address.",
      addressError:
      "This address doesn't seem to have a postal code, please choose another one.",
      streetError:
      "We've noticed you forgot to input your street name and address! Please make sure to do so and choose your proper address.",
      eligibleDiscounts: "Let's see if you're eligible for any discounts",
    selectOptions: "Select which option applies below",
    uniGraduate: "University graduate",
    professional: "Professional",
    retired: "Retired",
    student: "Student",
    none: "None",
    saveCreditCheck:
      "Save up to an additional 30% by agreeing to a soft credit check",
    affectScore: "It doesn't affect your credit score.",
    select: "Select",
    ontario: "Ontario",
    quebec: "Quebec",
    residentOf: "Are you a resident of Ontario or Quebec?",
    forOntario: "For Ontario residents you will be redirected to our partner website.",
    here: "here",
    currentClient: "Are you currently a home, condo or tenant insurance client of KBD?",
    dateError: "This date is invalid, please choose another date",
    yearError: "Please make sure you are over 16",
    restart: "Are you sure you want to restart?",
    tel: "tel.",
    contactInfo: "What's your contact info so we can spam you?",
    justKidding:
      "Kidding, it's to send you a copy of your insurance quote.",
  },
  fr: {
    yes: "Oui",
    no: "Non",
    buttonText: "Suivant",
    beforeStartQuestion:
      "Avant de commencer, voici quelques questions rapides:",
    beforeStartSubQuestion1:
      "Est-ce que vous ou une personne figurant sur votre police avez fait l'objet d'un refus ou d'une annulation par une autre compagnie d'assurance au cours des 3 dernières années?",
    beforeStartMessage:
      "Pas de souci. Nous pouvons toujours vous aider, mais il faudra le faire par téléphone. Veuillez nous appeler au ",
      homeNumber: "514-636-0002",
      homeNumberAlt: "438-843-4814",
    typeOfHomeInsurance: "Quel type d'assurance recherchez-vous?",
    typeSub: "Type d'assurance",
    address: "Où habitez-vous?",
    typeAddress:
      "Saisissez votre adresse dans le format suivant et sélectionnez-la dans le menu déroulant pour passer à l'étape suivante : H9P 2W9 1425 Trans Canada Route Suite 300, Dorval, Quebec",
    prefilledInfo:
      "Nous avons rassemblé et prérempli les renseignements ci-dessous sur votre propriété afin de simplifier le processus de soumission.",
    yearBuilt:
      "En quelle année votre propriété a-t-elle été construite? (vous pouvez donner une date approximative)",
    totalLivingArea:
      "Quelle est la surface habitable totale de votre propriété en pieds carrés? (à votre connaissance)",
    exteriorFinish: "Quelle est la finition extérieure de votre propriété?",
    constructionType: "Quel est le type de construction de votre propriété?",
    garageType: "Quel type de garage avez-vous?",
    basementPercentageFinished: "Quel pourcentage du sous-sol est aménagé?",
    numOfStoreys: "Combien d'étages y a-t-il dans votre propriété?",
    yearRoofReplaced:
      "En quelle année votre toit a-t-il été remplacé pour la dernière fois? ",
    distanceToFirehall:
      "À quelle distance (environ) se trouve la caserne de pompiers la plus proche?",
    distanceToFireHydrant:
      "Quelle est la distance approximative de la bouche d'incendie la plus proche?",
    primaryHeatingType:
      "Quel est le système de chauffage principal installé dans votre propriété?",
    secondaryHeatingType:
      "Quel est le système de chauffage secondaire installé dans votre propriété?",
    buildingStyle: "Quel est le style du bâtiment?",
    numOfUnits: "Combien d'unités y a-t-il dans votre propriété?",
    nameQuestion: "Quel est votre nom?",
    nameSubQuestion:
      "Veuillez utiliser le nom complet qui apparaît sur votre permis de conduire.",
    oldestApplicantDOB: "Date de naissance du demandeur le plus âgé",
    residenceOccupancy: "Qui occupe cette résidence?",
    yearMovedIn: "En quelle année avez-vous emménagé dans cette propriété?",
    policyStartDate: "Quand voulez-vous que la police commence?",
    activePolicy: "Avez-vous une police active actuellement?",
    insuranceGap:
      "Depuis combien de temps avez-vous eu une assurance sans interruption?",
    howLongInsuredCurrentCompany:
      "Depuis combien de temps êtes-vous assuré par votre compagnie actuelle?",
    claimsFiveYears:
      "Avez-vous fait des réclamations au cours des 5 dernières années?",
    nonPaymentCancellations:
      "Combien d'annulations avez-vous eues en raison d'un non-paiement?",
    havePriorPolicy: "Avez-vous déjà eu une police d'assurance habitation?",
    selectDiscountQuestion:
      "Sélectionnez les réductions que vous souhaitez inclure:",
    discountQuestionOne:
      "Économisez jusqu'à 18 % de plus. Êtes-vous ouvert à l'idée de regrouper plusieurs polices (p. ex., l'assurance auto) auprès d'une même compagnie?",
    discountQuestionTwo: "Votre propriété dispose-t-elle d'une alarme antivol",
    discountQuestionThree: "Votre propriété compte-t-elle une alarme-incendie",
    discountQuestionFour:
      "Votre propriété est-elle munie d'un système de gicleurs?",
    discountQuestionFive: "Les occupants sont-ils non-fumeurs?",
    discountQuestionSix: "Combien d'hypothèques avez-vous sur la propriété?",
    discountQuestionSeven:
      "Veuillez fournir votre adresse électronique pour que nous puissions vous envoyer une copie de vos soumissions.",
    phoneNumber: "Numéro de téléphone",
    yourQuote: "Votre soumission",
    quotePrice: "/mois (plus 9% de taxe)",
    quoteNextStep: "Quelles sont les prochaines étapes?",
    quoteCall:
      "Composez maintenant le ",
      quoteNumber: "438-843-4814",
      review: " pour examiner votre soumission avec un courtier d'assurance KBD et conclure votre police.", 
    failedQuote: "Oops! Il semble qu'il y ait eu une erreur avec votre devis",
    failedQuoteSub:
      "Ne vous inquiétez pas cependant! Appelez-nous au ",
      failedQuoteSubEnd: " et un membre de notre équipe sera en mesure de vous trier en un rien de temps.",
    buy: "Acheter",
    back: "Précedent",
    startOver: "Commencer un nouveau devis",
    dateFormat: "MM/JJ/AAAA",
    copyright: "KBD Insurance. Tous les droits sont réservés.",
    myHouse: "Ma maison",
    aboutMe: "À propos",
    myPolicy: "Ma politique",
    discounts: "Rabais",
    year: "Année",
    month: "Mois",
    day: "Jour",
    email: "Adresse courriel",
    homeQuestion: "Quel type d'assurance cherchez-vous?",
    homeInsuranceTypeCar: "Automobile",
    homeInsuranceTypeHome: "Habitation",
    phoneUs: "Appelez-nous",
    unitNumber: "No. d'unité",
    provinceError:
      "Pardon! Nous ne desservons actuellement que la région de Québec. Si vous êtes hors province, pas de stress! Veuillez nous appeler au 438-843-4814",
      postalCodeError:
      "Nous avons remarqué que vous avez oublié de saisir un numéro de rue! Veuillez réessayer et choisir votre adresse correcte.",
      addressError:
      "Cette adresse ne semble pas avoir de code postal, veuillez en choisir un autre.",
      streetError:
      "Nous avons remarqué que vous avez oublié de saisir le nom et l'adresse de votre rue! Assurez-vous de le faire et choisissez votre adresse appropriée.",
      eligibleDiscounts: "Voyons si vous êtes admissibles à des réductions.",
    selectOptions: "Sélectionnez les options ci-dessous qui s'appliquent.",
    uniGraduate: "Diplômé universitaire",
    professional: "Professionnel",
    retired: "Retraité",
    student: "Étudiant",
    none: "Aucun",
    saveCreditCheck:
      "Économisez jusqu'à 30 % supplémentaires en acceptant une vérification de crédit souple.",
    affectScore: "Cela n'affecte pas votre cote de crédit.",
    select: "Sélectionner",
    ontario: "Ontario",
    quebec: "Québec",
    residentOf: "Êtes-vous résident de l'Ontario ou du Québec?",
    forOntario: "Pour les résidents de l'Ontario, vous serez redirigés vers le site de notre partenaire.",
    here: "ici",
    currentClient: "Vous êtes actuellement client de la KBD pour votre assurance habitation, copropriété ou locataire?",
    dateError: "Cette date n'est pas valide, veuillez choisir une autre",
    yearError: "Veuillez vous assurer que vous avez plus de 16 ans",
    restart: "Êtes-vous sûr de vouloir redémarrer?",
    tel: "tél.",
    contactInfo:
    "Quelles sont vos coordonnées pour que nous puissions vous spammer?",
  justKidding:
    "Je plaisante, c'est pour vous envoyer une copie de votre devis d'assurance.",
  },
};

export const answers: any = {
  en: {
    homeownerInsurance: "Home owner insurance",
    condoInsurance: "Condo owner insurance",
    tenantInsurance: "Tenant insurance",
    stepTwoPlaceHolder: "e.g. Home Owner Insurance",
    log: "Log",
    monocoque: "Monocoque",
    brick: "Brick",
    cement: "Cement",
    concreteBlock: "Concrete Block/Masonry Frame",
    frameWood: "Frame (Wood)",
    stone: "Stone",
    brickVeneer: "Brick Veneer",
    vinyl: "Vinyl",
    other: "Other",
    attachedFrame: "Attached-Frame",
    attachedMasonry: "Attached-Masonry",
    attachedMasonryOrVeneer: "Attached-Masonry or Masonry/Veneer",
    basement: "Basement",
    basementUnderGarage: "Basement Under Attached Garage",
    builtIn: "Built-In",
    carport: "Carport",
    carportStorage: "Carport With Storage",
    detachedMasonry: "Detached-Masonry",
    detachedFrame: "Detached-Frame",
    detachedMasonryOrVeneer: "Detached-Masonry or Masonry/Veneer",
    none: "None",
    centralFurnaceGas: "Central Furnace-Gas",
    centralFurnaceOil: "Central Furnace-Oil",
    centralFurnacePropane: "Central Furnace-Propane",
    centralFurnaceWood: "Central Furnace-Wood",
    electric: "Electric",
    hotWaterSteam: "Hot Water/Steam",
    combinationFurnace: "Combination Furnace",
    spaceHeaterGas: "Space Heater-Gas",
    spaceHeaterOil: "Space Heater-Oil",
    spaceHeaterElectric: "Space Heater-Electric",
    woodBurningStove: "Wood Burning Stove",
    fireplace: "Fireplace",
    solar: "Solar",
    detached: "Detached",
    semiDetached: "Semi-Detached",
    townHouse: "Town House",
    rowHouse: "Row House",
    duplexSide: "Duplex (side-by-side)",
    duplexUpDown: "Duplex (up-and-down)",
    triplex: "Triplex",
    highRiseApartment: "High Rise Apartment",
    lowRiseApartment: "Low Rise Apartment",
    commercial: "Commercial",
    fiveMeters: "5m",
    twentyFiveMeters: "25m",
    fiftyMeters: "50m",
    hundredMeters: "100m",
    hundredFiftyMeters: "150m",
    twoHundredMeters: "200m",
    threeHundredMeters: "300m",
    firstName: "First name",
    lastName: "Last name",
    youAndYourFamily: "You and your family",
    yourFamilyAndNotherFamily: "Your family and another family",
    yourFamilyAndTwoFamilies: "Your family and two other families",
    rentedOut: "It is rented out/ Used for commercial",
    oneYear: "1 Year",
    twoYears: "2 Years",
    threeYears: "3 Years",
    fourYears: "4 Years",
    fiveYears: "5+ Years",
    noClaims: "No claims",
    oneClaim: "1 claim",
    twoClaims: "2 claims",
    threePlusClaims: "3+ claims",
    noCancellations: "No cancellations",
    oneCancellation: "1 cancellation",
    twoCancellations: "2 cancellations",
    threePlusCancellations: "3+ cancellations",
    zero: "0",
    one: "1",
    two: "2",
    three: "3+",
    fiveKm: "5km",
    eightKM: "8km",
    overEightKm: "Over 8km",
    withinThreeHunderdM: "Within 300m",
    overThreeHunderedM: "Over 300m",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  fr: {
    homeownerInsurance: "Assurance habitation",
    condoInsurance: "Assurance propriétaire de condo",
    tenantInsurance: "Assurance locataire",
    stepTwoPlaceHolder: "e.g. Assurance habitation",
    log: "Rondin",
    monocoque: "Monocoque",
    brick: "Brique",
    cement: "Ciment",
    concreteBlock: "Bloc de béton/Cadre de maçonnerie",
    frameWood: "Cadre (Bois)",
    stone: "Pierre",
    brickVeneer: "Placage de brique",
    vinyl: "Vinyle",
    other: "Autre",
    attachedFrame: "Cadre attaché",
    attachedMasonry: "Attaché-Maçonnerie",
    attachedMasonryOrVeneer: "Attaché-Maçonnerie ou Maçonnerie/Placage",
    basement: "Sous-sol",
    basementUnderGarage: "Sous-sol sous garage attenant",
    builtIn: "Intégré",
    carport: "Abri d'auto",
    carportStorage: "Abri d'auto avec rangement",
    detachedMasonry: "Détaché-Maçonnerie",
    detachedFrame: "Cadre détaché",
    detachedMasonryOrVeneer: "Maçonnerie détachée ou maçonnerie/placage",
    none: "Aucun",
    centralFurnaceGas: "Fournaise Centrale-Gaz",
    centralFurnaceOil: "Fournaise centrale au mazout",
    centralFurnacePropane: "Fournaise centrale au propane",
    centralFurnaceWood: "Centrale de Fournaise-Bois",
    electric: "Électrique",
    hotWaterSteam: "Eau chaude/vapeur",
    combinationFurnace: "Four mixte",
    spaceHeaterGas: "Chauffage d'appoint au gaz",
    spaceHeaterOil: "Chauffage d'espace-Huile",
    spaceHeaterElectric: "Chauffage d'appoint électrique",
    woodBurningStove: "Poêle à bois",
    fireplace: "Cheminée",
    solar: "Solaire",
    detached: "Détaché",
    semiDetached: "Jumelé",
    townHouse: "Maison de ville",
    rowHouse: "Maison en rangée",
    duplexSide: "Duplex (côte à côte)",
    duplexUpDown: "Duplex (haut et bas)",
    triplex: "Triplex",
    highRiseApartment: "Appartement de grande hauteur",
    lowRiseApartment: "Appartement de faible hauteur",
    commercial: "Commerciale",
    fiveMeters: "5m",
    twentyFiveMeters: "25m",
    fiftyMeters: "50m",
    hundredMeters: "100m",
    hundredFiftyMeters: "150m",
    twoHundredMeters: "200m",
    threeHundredMeters: "300m",
    firstName: "Prénom",
    lastName: "Nom de famille",
    youAndYourFamily: "Vous et votre famille",
    yourFamilyAndNotherFamily: "Votre famille et une autre famille",
    yourFamilyAndTwoFamilies: "Votre famille et deux autres familles",
    rentedOut: "La résidence est louée ou utilisée à des fins commerciales",
    oneYear: "1 an",
    twoYears: "2 années",
    threeYears: "3 années",
    fourYears: "4 années",
    fiveYears: "5+ années",
    noClaims: "Aucunes réclamations",
    oneClaim: "1 réclamation",
    twoClaims: "2 réclamations",
    threePlusClaims: "3+ réclamations",
    noCancellations: "Aucune annulation",
    oneCancellation: "1 annulation",
    twoCancellations: "2 annulations",
    threePlusCancellations: "3+ annulations",
    zero: "0",
    one: "1",
    two: "2",
    three: "3+",
    fiveKm: "5km",
    eightKM: "8km",
    overEightKm: "Plus de 8km",
    withinThreeHunderdM: "A moins de 300m",
    overThreeHunderedM: "Plus de 300m",
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
  },
};

export const carTexts: any = {
  en: {
    yes: "Yes",
    no: "No",
    buttonText: "Continue",
    beforeStartQuestion: "Before we get started, just a few quick questions:",
    beforeStartSubQuestion1:
      "Are you currently a car insurance client of KBD Insurance?",
    beforeStartMessage:
      // "We currently offer online quotes for new customers only. We'll be able to help you by telephone or chat though! Please call us at 438-843-4814 or visit our online chat service.",
      "We currently only offer online quotes for new customers only. We’ll be able to help you by telephone though! ",
      beforeStartMessage2:
      "We’ll be able to help you by telephone! " ,
      quebecClient: "If you are a Quebec client please call ",
      ontarioClient: "If you are an Ontario client please call ",
      quebecNumber: "514-636-0002",
      ontarioNumber: "613-212-2352",
    licenseSusMessage:
      "Do you or anyone listed on your policy have a license suspension in the past 3 years?",
    refusedMessage:
      "Have you or a person listed on your policy been cancelled or refused by another insurance company in the past 3 years?",
    letsStart: "All right, let's get started then!",
    carDetails: "What is the year, make and model of your car?",
    year: "Year",
    make: "Make",
    model: "Model",
    whenPurchaseCar: "When did you purchase your car?",
    whenPurchaseCarSub: "To the best of your memory",
    primaryCarCondition: "What was the condition of your car when purchased?",
    new: "New",
    used: "Used",
    demo: "Demo",
    primaryCarHowManyKM:
      "How many kilometres on the odometer when it was purchased?",
    purchaseType: "Is your car financed, leased or owned outright?",
    financed: "Financed",
    leased: "Leased",
    ownedOutright: "Owned outright",
    carPrice: "What was the purchase price of your car (before taxes)?",
    securitySystem: "Do you have a security system on your car?",
    tagTracking: "Tag Tracking",
    sherlock: "Sherlock",
    tagTrackingAndSherlock: "Tag Tracking + Sherlock",
    tagDescription:
    " is a hybrid vehicle theft prevention and tracking system that features electronic marking. Using multiple transmitters installed in your vehicle, it combines prevention, identification and tracking.",
    tagTitle: 'TAG',
    sherlockTitle: 'Sherlock / Vinlock',
    sherlockDescription:
      " is intensive engraving whereby certain parts of your car are marked with a unique serial number. If you have Sherlock, you'll see a crab sticker on your window.",
    none: "None",
    primaryUseOfCar: "What is the primary use of your car?",
    pleasure: "Pleasure",
    pleasureAndCommuting: "Pleasure + commuting to work or school",
    annualKM: "How many annual KM do you drive?",
    commuteLength: "How many KM one-way to get to work?",
    useCarOutsideQuebec: "Will you be using your car outside of Quebec?",
    numOfWeeksOutsideQuebec:
      "For how many total weeks in a year will your car be outside of Quebec?",
    includeCompAndCollision:
      "Would you like your car to include comprehensive and / or collision?",
    dealershipRequired:
      "If your car is financed or leased, the dealership will require you to carry both of these protections.",
    comprehensive: "Comprehensive",
    collision: "Collision",
    comprehensiveAndCollision: "Comprehensive + collision",
    comprehensiveDescription:
      " covers your car against fire, theft, vandalism & glass breakage.",
    collisionDescription:
      " covers your car against responsible accidents & loss of control.",
    addAnotherVehicle: "Would you like to add another vehicle for your quote?",
    twoVehiclesDiscount:
      "Insurance companies offer up to a 15% discount when you insure two vehicles or more",
    newVehicle: "New Vehicle:",
    vehicles: "Vehicles",
    nameQuestion: "What's your name?",
    nameSubQuestion:
      "Please use full name that appears on your driver's license.",
    firstName: "First name",
    lastName: "Last name",
    dob: "Date of birth?",
    howOldDriversLicense:
      "How old were you when you received your Canadian drivers license?",
    whatIsYourGender: "What is your gender?",
    gender: "Gender",
    male: "Male",
    female: "Female",
    maritalStatus: "Not to be rude, but can we have your marital status?",
    married: "Married",
    single: "Single",
    commonLaw: "Common Law",
    address: "Where do you live?",
    whenAddress: "When did you move to this address?",
    eligibleDiscounts: "Let's see if you're eligible for any discounts",
    selectOptions: "Select which options apply below",
    uniGraduate: "University graduate",
    professional: "Professional",
    retired: "Retired",
    student: "Student",
    haveCurrentPolicy: "Do you currently have a car insurance policy in force?",
    currentInsuranceCompany: "Who is your current insurance company?",
    howLongInsurance:
      "How long have you had an insurance policy in your name?",
    oneYear: "1 Year",
    twoYears: "2 Years",
    threeYears: "3 Years",
    fourYears: "4 Years",
    fiveYears: "5+ Years",
    claimsLastFiveYears:
      "Do you have any car insurance claims in the last 5 years?",
    claimInfo: "Claim Info",
    claimInfoSubText:
      "Please enter in (to the best of your ability) your claim info.",
    add: "Add",
    remove: "Remove",
    typeOfClaim: "Type of claim",
    dateOfLoss: "Date of loss",
    amountPaid: "Amount $ insurance company paid",
    hail: "Hail",
    vandalism: "Vandalism",
    fire: "Fire",
    theft: "Theft",
    glassBreakage: "Glass Breakage",
    glassRepair: "Glass Repair",
    nonResponsibleCollision: "Non-responsible Collision",
    liabilityAndCollision: "Liability & Collision",
    collisionClaim: "Collision",
    liabilityClaim: "Liability",
    theftEntireVehicle: "Theft - Entire Vehicle",
    minorCollision: "Minor At-Fault Collision",
    anyTrafficViolations:
      "In the last 3 years, do you have any traffic violations?",
    lessSixPoints: "I've lost less than 6 points in the past 3 years",
    moreSixPoints: "I've lost more than 6 points in the last 3 years",
    addAnotherDriver: "Do we need to add another driver to your policy?",
    secondDriver: "Second Driver",
    name: "Name",
    birthday: "Birthday",
    relationship: "Relationship",
    secondDriveSituation:
      "How would you describe the second driver's situation?",
    secondDriveLicenseAge:
      "At what age did the second driver obtain its driver's license (excluding temporary license)?",
    secondDriveAccidents:
      "In the last 6 years, was the second driver involved in any car accidents?",
    secondDriveConvictions:
      "In the last 5 years, has the second driver had any driving convictions?",
    whenStartPolicy: "When would you like your policy to start?",
    bundleHome: "Would you like to save 15% and bundle your home?",
    mobileTelematics:
      "85% of our clients sign up for mobile telematics that give discounts for good driving.",
    contactInfo: "What's your contact info so we can spam you?",
    justKidding:
      "Kidding, it's to send you a copy of your car insurance quote.",
    phoneNumber: "Phone Number",
    email: "Email address",
    saveCreditCheck:
      "Save up to an additional 30% by agreeing to a soft credit check",
    affectScore: "It doesn't affect your credit score.",
    failedQuote: "Oops! Looks like there was an error with your quote",
    failedQuoteSub:
      "Not to worry though! Give us a call at ",
      failedQuoteSubEnd: " and one of our team members will be able to get you sorted in no time.",
    buy: "Buy",
    back: "Back",
    dateFormat: "MM/DD/YYYY",
    copyright: "KBD Insurance. All rights reserved.",
    myCar: "My Car",
    aboutMe: "About Me",
    myPolicy: "My Policy",
    discounts: "Discounts",
    weeks: "Weeks",
    age: "Age",
    goodDriver: "Yes, I'm a good driver",
    badDriver: "No, I drive like a cowboy",
    saveTenPercent: "Would you like to enroll and save 10% on your quote?",
    defectiveBrakes: "Defective Brakes",
    distractedDriving: "Distracted Driving",
    dlViolation: "Drivers License Violations",
    noInsuranceCard: "Failure to carry or have insurance card",
    noInsuranceEvidence: "Failure to produce evidence of insurance",
    noSharRoad: "Failing to share road",
    noSignal: "Failing to signal",
    noSeatBelts: "Failure to use seatbelts",
    noYield: "Failing to yield",
    noYieldPedestrian: "Failing to yield to pedestrian",
    followClosely: "Following too closely",
    headlightOffense: "Headlight Offenses",
    busLane: "Improper driving in a Bus Lane",
    badLineCHange: "Improper lane change",
    badOpenningDoor: "Improper opening of door",
    improperPassing: "Improper passing",
    improperTowing: "Improper towing, persons on sled, bicycle, etc.",
    improperTurn: "Improper turn",
    dividedHighway: "Improper use of divided highway",
    insecureLoad: "Insecure load",
    obstructTraffic: "Obstructing traffic",
    overload: "Overload",
    prohibitedDevice: "Prohibited use of hand-held device",
    railway: "Railway crossing",
    speeding: "Speeding",
    stopSign: "Stop sign",
    trafficLight: "Traffic light",
    slowDriving: "Unnecessary slow driving",
    unsafeMove: "Unsafe move",
    unsafeTurn: "Unsafe or prohibited turn",
    unsafeVehicle: "Unsafe Vehicle",
    wrongWay: "Wrong way on one way",
    unitNumber: "Unit Number",
    convictionTitle: "Conviction info",
    convictionInfo:
      "Please enter in (to the best of your abilities) your conviction info.",
    conviction: "Conviction",
    date: "Date",
    provinceError:
      "Sorry! We currently only service the Quebec area. If you are out of province, no stress! Please give us a call at 438-843-4814",
    postalCodeError:
      "We've noticed you forgot to input a street number! Please make sure to do so and choose your proper address.",
      addressError:
      "This address doesn't seem to have a postal code associated with it, please choose another one.",
      streetError:
      "We've noticed you forgot to input your street name and address! Please make sure to do so and choose your proper address.",
      driverAgeCheck:
      "The driver must have been at least 16 years old when they first received a driver's licence",
      typeAddress:
      "Type your address out in the following format and then select your address from the dropdown in order to move to the next step: H9P 2W9 1425 Trans Canada Route Suite 300, Dorval, Quebec",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    month: "Month",
    day: "Day",
    select: "Select",
    ontario: "Ontario",
    quebec: "Quebec",
    phoneUs: "Phone us",
    dateError: "This date is invalid, please choose another date",
    yearError: "Please make sure you are over 16",
    yearError2: "Please make sure they are over 16",
    weeksError: "This number is invalid, please choose another number",
    restart: "Are you sure you want to restart?",
    tel: "tel." ,
    cancel: "Cancel",
    residentOf: "Are you a resident of Ontario or Quebec?",
    forOntario: "For Ontario residents you will be redirected to our partner website.",
  },

  fr: {
    yes: "Oui",
    no: "Non",
    buttonText: "Suivant",
    beforeStartQuestion:
      "Avant de commencer, voici quelques questions rapides:",
    beforeStartSubQuestion1:
      "Êtes-vous actuellement un client d'assurance auto chez Assurance KBD?",
    beforeStartMessage:
      "Nous proposons des soumissions en ligne pour les nouveaux clients seulement à l'heure actuelle. Nous pouvons toutefois vous aider par téléphone ou par clavardage! ",
      beforeStartMessage2:
      "Nous pouvons vous aidez par téléphone ou par clavardage! ",
      quebecClient: "Si vous habitez en Québec appelez ",
      ontarioClient: "Si vous habitez en Ontario appelez ",
      quebecNumber: "514-636-0002",
      ontarioNumber: "613-212-2352",
    refusedMessage:
      "Vous ou une personne inscrite sur votre police avez-vous été résilié ou refusé par une autre compagnie d'assurance au cours des 3 dernières années?",
    licenseSusMessage:
      "Est-ce que vous ou une personne figurant sur votre police avez fait l'objet d'un refus ou d'une annulation par une autre compagnie d'assurance au cours des 3 dernières années?",
    letsStart: "Très bien, commençons alors!",
    carDetails: "Quelle est l'année, la marque et le modèle de votre voiture?",
    year: "Année",
    make: "Marque",
    model: "Modèle",
    whenPurchaseCar:
      "Quand avez-vous acheté votre voiture? (au meilleur de votre mémoire)",
    whenPurchaseCarSub: "Au meilleur de votre mémoire",
    primaryCarCondition:
      "Quel était l'état de votre voiture au moment de l'achat?",
    new: "Neuve",
    used: "Usagée",
    demo: "Démo",
    primaryCarHowManyKM: "Combien de kilomètres au compteur lors de son achat?",
    purchaseType:
      "Votre voiture est-elle financée, louée ou payée en totalité?",
    financed: "Financé",
    leased: "Louée",
    ownedOutright: "Payée en totalité",
    carPrice: "Quel était le prix d'achat de votre voiture (avant les taxes)?",
    securitySystem: "Avez-vous un système de sécurité sur votre voiture?",
    tagTracking: "Repérage TAG",
    sherlock: "Sherlock",
    tagTrackingAndSherlock: "Repérage TAG + Sherlock",
    tagTitle: 'Repérage TAG',
    sherlockTitle: 'Marquage Sherlock / Vinlock',
    none: "Aucune",
    tagDescription:
      " est un système hybride de prévention du vol et de repérage de véhicules qui comprend un marquage électronique. En utilisant de multiples émetteurs installés dans votre véhicule, ce système combine la prévention, l'identification et le repérage.",
    sherlockDescription:
      " est un marquage intensif par burinage d'un numéro de série unique sur certaines parties de votre voiture. Si vous avez Sherlock, vous verrez un autocollant de crabe sur votre fenêtre.",
    primaryUseOfCar: "Quelle est l'utilisation principale de votre voiture?",
    pleasure: "Pour le plaisir ",
    pleasureAndCommuting: "Pour se rendre à l'école ou au travail",
    annualKM:
      "Combien de kilomètres parcourez-vous par année avec votre voiture?",
    commuteLength:
      "Combien de kilomètres y a-t-il dans un aller simple pour se rendre au travail ou à lécole?",
    useCarOutsideQuebec:
      "Utiliserez-vous votre voiture à l'extérieur du Québec?",
    numOfWeeksOutsideQuebec:
      "Pendant combien de semaines au total votre voiture sera-t-elle à l'extérieur du Québec dans une année?",
    includeCompAndCollision:
      "Souhaitez-vous une protection sans collision ni versement et/ou collision ou versement pour votre voiture?",
    dealershipRequired:
      "Si votre voiture est financée ou louée, le concessionnaire vous demandera de porter ces deux protections",
    comprehensive: "Compréhensif",
    collision: "Collision",
    comprehensiveAndCollision: "Compréhensif + collision",
    comprehensiveDescription:
      " couvre votre voiture contre l'incendie, le vol, le vandalisme et le bris de glace.",
    collisionDescription:
      " couvre votre voiture contre les accidents responsables et la perte de contrôle.",
    addAnotherVehicle:
      "Souhaitez-vous ajouter un autre véhicule pour votre soumission?",
    twoVehiclesDiscount:
      "Les compagnies d'assurance offrent jusqu'à 15 % de réduction lorsque vous assurez deux véhicules ou plus.",
    newVehicle: "Nouveau véhicule:",
    vehicles: "Véhicules",
    nameQuestion: "Quel est votre nom?",
    nameSubQuestion:
      "Veuillez utiliser le nom complet qui apparaît sur votre permis de conduire.",
    firstName: "Prénom",
    lastName: "Nom de famille",
    dob: "Quelle est votre date de naissance?",
    howOldDriversLicense:
      "Quel âge aviez-vous lorsque vous avez reçu votre permis de conduire canadien?",
    whatIsYourGender: "Quel est votre sexe?",
    gender: "Sexe",
    male: "Homme",
    female: "Femme",
    maritalStatus:
      "Sans vouloir être impolis, pouvons-nous connaître votre état civil?",
    married: "Marié",
    single: "Célibataire",
    commonLaw: "Conjoint de fait",
    address: "Où habitez-vous?",
    whenAddress: "Quand avez-vous déménagé à cette adresse?",
    eligibleDiscounts: "Voyons si vous êtes admissibles à des réductions.",
    selectOptions: "Sélectionnez les options ci-dessous qui s'appliquent.",
    uniGraduate: "Diplômé universitaire",
    professional: "Professionnel",
    retired: "Retraité",
    student: "Étudiant",
    haveCurrentPolicy: "Avez-vous déjà une police d'assurance automobile?",
    currentInsuranceCompany: "Quelle est votre compagnie d'assurance actuelle?",
    howLongInsurance:
      "Depuis combien de temps avez-vous une police d'assurance à votre nom?",
    oneYear: "1 an",
    twoYears: "2 années",
    threeYears: "3 années",
    fourYears: "4 années",
    fiveYears: "5 ans et plus",
    claimsLastFiveYears:
      "Avez-vous fait des réclamations d'assurance automobile au cours des 5 dernières années?",
    claimInfo: "Informations sur la réclamation",
    claimInfoSubText:
      "Veuillez saisir (du mieux que vous pouvez) les renseignements sur votre réclamation",
    add: "Ajouter",
    remove: "Supprimer",
    typeOfClaim: "Type de réclamation",
    dateOfLoss: "Date de la perte",
    amountPaid: "Montant $ payé par la compagnie d'assurance",
    hail: "Grêle",
    vandalism: "Vandalisme",
    fire: "Feu",
    theft: "Vol",
    glassBreakage: "Bris de glace",
    glassRepair: "Réparation de glace",
    nonResponsibleCollision: "Accident non responsable",
    liabilityAndCollision: "R.C. & Collision",
    collisionClaim: "Collision",
    liabilityClaim: "Responsabilité Civile",
    theftEntireVehicle: "Vol - Total",
    minorCollision: "Collision mineure avec responsabilité",
    anyTrafficViolations:
      "Au cours des 3 dernières années, avez-vous commis des infractions au Code de la route?",
    lessSixPoints:
      "J'ai perdu moins de 6 points au cours des 3 dernières années",
    moreSixPoints:
      "J'ai perdu plus de 6 points au cours des 3 dernières années",
    addAnotherDriver: "Devons-nous ajouter un autre conducteur à votre police?",
    secondDriver: "Deuxième conducteur:",
    name: "Nom",
    birthday: "Date de naissance",
    relationship: "Relation",
    secondDriveSituation:
      "Comment décririez-vous la situation du deuxième conducteur?",
    secondDriveLicenseAge:
      "A quel âge le deuxième conducteur a-t-il obtenu son permis de conduire (le permis temporaire ne compte pas)?",
    secondDriveAccidents:
      "Au cours des 6 dernières années, le deuxième conducteur a-t-il été impliqué dans des accidents de voiture?",
    secondDriveConvictions:
      "Au cours des 6 dernières années, le deuxième conducteur a-t-il été impliqué dans des accidents de voiture?",
    whenStartPolicy: "Quand souhaitez-vous que votre police commence?",
    bundleHome:
      "Souhaitez-vous économiser 15 % et regrouper aussi votre assurance habitation?",
    mobileTelematics:
      "85 % de nos clients s'inscrivent à la télématique mobile afin d'obtenir des réductions pour bonne conduite. Souhaitez-vous vous inscrire et économiser 10 % sur votre soumission?",
    contactInfo:
      "Quelles sont vos coordonnées pour que nous puissions vous spammer?",
    justKidding:
      "Je plaisante, c'est pour vous envoyer une copie de votre devis d'assurance auto.",
    phoneNumber: "Numéro de téléphone",
    email: "Adresse courriel",
    saveCreditCheck:
      "Économisez jusqu'à 30 % supplémentaires en acceptant une vérification de crédit souple.",
    affectScore: "Cela n'affecte pas votre cote de crédit.",
    failedQuote: "Oops! Il semble qu'il y ait eu une erreur avec votre devis",
    failedQuoteSub:
    "Ne vous inquiétez pas cependant! Appelez-nous au ",
    failedQuoteSubEnd: " et un membre de notre équipe sera en mesure de vous trier en un rien de temps.",
    buy: "Acheter",
    back: "Précedent",
    dateFormat: "MM/JJ/AAAA",
    copyright: "KBD Insurance. Tous les droits sont réservés.",
    myCar: "Ma voiture",
    aboutMe: "À propos",
    myPolicy: "Ma politique",
    discounts: "Rabais",
    weeks: "semaines",
    age: "Âge",
    goodDriver: "Oui, je suis un bon conducteur",
    badDriver: "Non, je conduis comme un cow-boy",
    saveTenPercent:
      "Vous souhaitez vous inscrire et économiser 10% sur votre devis?",
    defectiveBrakes: "Freins défectueux",
    distractedDriving: "Distraction du conducteur",
    dlViolation: "Violations du permis de conduire",
    noInsuranceCard: "Défaut de transporter carte d'assurance",
    noInsuranceEvidence: "Défaut de fournir preuve dassurance",
    noSharRoad: "Défaut de partager la voie",
    noSignal: "Défaut de signaler",
    noSeatBelts: "Défaut de porter la ceinture de sécurité",
    noYield: "Défaut de céder",
    noYieldPedestrian: "Défaut de céder le passage à un piéton",
    followClosely: "Conduite sans distance prudente et raisonnable",
    headlightOffense: "Défaut de diminuer l'intensité de ses phares",
    busLane:
      "Conduite imprudente et interdite dans une voie réservée aux autobus",
    badLineCHange: "Changement de voie imprudent ou interdit",
    badOpenningDoor: "Ouverture imprudente d'une portière",
    improperPassing: "Dépassement imprudent ou interdit",
    improperTowing: "Remorquage imprudent ou interdit",
    improperTurn: "Virage imprudent ou interdit",
    dividedHighway: "Conduite imprudente ou interdite sur une route divisée",
    insecureLoad: "Chargement non sécuritaire",
    obstructTraffic: "Entrave à la circulation",
    overload: "Surchargement/Surcharge",
    prohibitedDevice: "	Utilisation d'un appareil mobile interdite",
    railway: "Traverse de chemin de fer",
    speeding: "Excès de vitesse",
    stopSign: "Panneau d'arrêt",
    trafficLight: "Feux de signalisation",
    slowDriving: "Conduite inutilement lente",
    unsafeMove: "Déplacement dangeureux",
    unsafeTurn: "Virage dangeureux ou prohibé",
    unsafeVehicle: "Véhicule dangeureux",
    wrongWay: "Conduite en sens inverse dans un chemin à sens unique",
    unitNumber: "No. d'unité",
    convictionTitle: "Informations sur la condamnation",
    convictionInfo:
      "Veuillez entrer (au mieux de vos capacités) vos informations de conviction.",
    conviction: "Conviction",
    date: "Date",
    provinceError:
      "Pardon! Nous ne desservons actuellement que la région de Québec. Si vous êtes hors province, pas de stress! Veuillez nous appeler au 438-843-4814",
      postalCodeError:
      "Nous avons remarqué que vous avez oublié de saisir un numéro de rue! Veuillez réessayer et choisir votre adresse correcte.",
      addressError:
      "Cette adresse ne semble pas avoir de code postal! Veuillez en choisir un autre.",
      streetError:
      "Nous avons remarqué que vous avez oublié de saisir le nom et l'adresse de votre rue! Assurez-vous de le faire et choisissez votre adresse appropriée.",
      typeAddress:
      "Saisissez votre adresse dans le format suivant et sélectionnez-la dans le menu déroulant pour passer à l'étape suivante : H9P 2W9 1425 Trans Canada Route Suite 300, Dorval, Quebec",
      driverAgeCheck:
      "Le conducteur doit avoir eu au moins 16 ans lorsqu'il a reçu son permis de conduire",
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
    month: "Mois",
    day: "Jour",
    select: "Sélectionner",
    ontario: "Ontario",
    quebec: "Québec",
    phoneUs: "Appelez-nous",
    dateError: "Cette date n'est pas valide, veuillez choisir une autre",
    yearError: "Veuillez vous assurer que vous avez plus de 16 ans",
    yearError2: "Veuillez vous assurer qu'ils sont plus de 16 ans",
    weeksError: "Cette nombre n'est pas valide, veuillez écrire une autre",
    restart: "Êtes-vous sûr de vouloir redémarrer?",
    tel: "tél.",
    cancel: "Annuler",
    residentOf: "Êtes-vous résident de l'Ontario ou du Québec?",
    forOntario: "Pour les résidents de l'Ontario, vous serez redirigés vers le site de notre partenaire.",
  },
};
