import { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import TextInput from "../../../components/refresh/TextInput";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import MoneyInput from "../../../components/refresh/MoneyInput";


function CarStepTwentyTwo() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [anotherDriver, setAnotherDriver] = useState<boolean | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState<Date | null>(null);
  const [gender, setGender] = useState<string>("");
  const [maritalStatus, setMaritalStatus] = useState<string>("");
  const [job, setJob] = useState<string>('');
  const [howOldDriversLicense, setHowOldDriversLicense] = useState<number | null>(null);
  const [accidents, setAccidents] = useState<boolean | null>(null);
  const [drivingConvictions, setDrivingConvictions] = useState<boolean | null>(
    null
  );
  const [valid, setIsValid] = useState<boolean>(false);
  const LSFirstName = useLocalStorage("secondaryFirstName");
  const LSLastName = useLocalStorage("secondaryLastName");
  const LSBirthday = useLocalStorage("secondaryBirthday");
  const LSGender = useLocalStorage("secondaryGender");
  const LSMarital = useLocalStorage("secondaryMaritalStatus");
  const LSJob = useLocalStorage("secondaryJob");
  const LSHowOld = useLocalStorage("secondaryHowOldDriversLicense");
  const LSAccidents = useLocalStorage("secondaryAccidents");
  const LSConvictions = useLocalStorage("secondaryDrivingConvictions");
  const LSSecondaryDriver = useLocalStorage("secondaryDriver");

  const [month, setMonth] = useState<null | string>(null);
  const [monthNumber, setMonthNumber] = useState<null | string>(null);
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [yearList, setYearList] = useState<string[]>([]);
  const [dayList, setDayList] = useState<string[]>([]);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [chosenMonth, setChosenMonth] = useState<string>('');
  const [chosenDay, setChosenDay] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [yearError, setYearError] = useState<boolean>(false);
  const [ageError, setAgeError] = useState<boolean>(false);
  const [dayNumber, setDayNumber] = useState<number>(0)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (LSBirthday) {
      let year = LSBirthday.substring(0, 4);
      let month = LSBirthday.substring(4, 6);
      let day = LSBirthday.substring(6, 8);
      setYear(year);
      setDay(day);
      handleReverseMonth(month);
    }
  }, [LSBirthday]);

  useEffect(() => {

    const isLeap = (numberYear: number): boolean => {
      return new Date(numberYear, 1, 29).getDate() === 29;
    };
  
    if (chosenMonth) {
      if (chosenMonth === 'January' || chosenMonth === 'March' || chosenMonth === 'May' || chosenMonth === 'July' || chosenMonth === 'August' || chosenMonth === 'October' || chosenMonth === 'December'
      || chosenMonth === 'Janvier' || chosenMonth === 'Mars' || chosenMonth === 'Mai' || chosenMonth === 'Juillet' || chosenMonth === 'Août' || chosenMonth === 'Octobre' || chosenMonth === 'Décembre') {
        setDayNumber(32)
      }
      else if (chosenMonth === 'April' || chosenMonth === 'June' || chosenMonth === 'September' || chosenMonth === 'November'
      || chosenMonth === 'Avril' || chosenMonth === 'Juin' || chosenMonth === 'Septembre' || chosenMonth === 'Novembre') {
        setDayNumber(31)
      }
      else if (chosenMonth === 'February' || chosenMonth === 'Février') {
        if (isLeap(Number(chosenYear))) {
          setDayNumber(30)
        } else {
        setDayNumber(29)
      }
      }
      else {
        setDayNumber(32)
      }
    }
  }, [chosenMonth, chosenYear]);
  

  useEffect(() => {
    const years = [];
    const days = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1935; i < currentYear + 1; i++) {
      years.push(i.toString());
    }
    setYearList(years);

    for (let i = 1; i < dayNumber; i++) {
      if (i <= 9) {
        days.push(`0${i}`);
      } else {
        days.push(i.toString());
      }
    }
    setDayList(days);
  }, [dayNumber]);
  
  const months = [
  carTexts[carLanguage].january,
  carTexts[carLanguage].february,
  carTexts[carLanguage].march,
  carTexts[carLanguage].april,
  carTexts[carLanguage].may,
  carTexts[carLanguage].june,
  carTexts[carLanguage].july,
  carTexts[carLanguage].august,
  carTexts[carLanguage].september,
  carTexts[carLanguage].october,
  carTexts[carLanguage].november,
  carTexts[carLanguage].december,]

  function handleMonth(month: string) {
    setMonth(month);
    const list = [
      carTexts[carLanguage].january,
      carTexts[carLanguage].february,
      carTexts[carLanguage].march,
      carTexts[carLanguage].april,
      carTexts[carLanguage].may,
      carTexts[carLanguage].june,
      carTexts[carLanguage].july,
      carTexts[carLanguage].august,
      carTexts[carLanguage].september,
      carTexts[carLanguage].october,
      carTexts[carLanguage].november,
      carTexts[carLanguage].december,
    ];

    for (let i = 0; i < list.length; i++) {
      if (month === list[i]) {
        if (i < 9) {
          setMonthNumber(`0${i + 1}`);
        } else {
          setMonthNumber(`${i + 1}`);
        }
      }
    }
  }

  
let dateString = ''

useEffect(() => {
  handleMonth(chosenMonth)
  dateString = `${chosenYear}/${monthNumber}/${chosenDay}`
  const dateObject = new Date(dateString)

  function isValidDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  if (isValidDate(dateObject) && dateString.length === 10) {
    if (dateObject.toISOString().slice(0, 10).replace(/-/g, '/') === dateString) {
      setBirthday(dateObject)
      setDateError(false)
    } else {
      setDateError(true)
      setBirthday(null)
    }
  } else {
    setBirthday(null)
  }


}, [chosenDay, chosenMonth, chosenYear])

useEffect(() => {
  const currentYear = new Date().getFullYear();
  if (currentYear - Number(chosenYear) < 15) {
    setYearError(true)
  } else {
    setYearError(false)
  }
}, [chosenYear])


  function handleReverseMonth(monthNumber: number) {
    const list = [
      carTexts[carLanguage].january,
      carTexts[carLanguage].february,
      carTexts[carLanguage].march,
      carTexts[carLanguage].april,
      carTexts[carLanguage].may,
      carTexts[carLanguage].june,
      carTexts[carLanguage].july,
      carTexts[carLanguage].august,
      carTexts[carLanguage].september,
      carTexts[carLanguage].october,
      carTexts[carLanguage].november,
      carTexts[carLanguage].december,
    ];

    const selectedMonth = list[monthNumber - 1];
    setMonth(selectedMonth);
    handleMonth(selectedMonth);
  }


  useEffect(() => {
    if (LSFirstName) {
      setFirstName(LSFirstName);
      setLastName(LSLastName);
      setGender(LSGender);
      setMaritalStatus(LSMarital);
      setJob(LSJob);
      setHowOldDriversLicense(LSHowOld);
      setAccidents(LSAccidents === "true");
      setDrivingConvictions(LSConvictions === "true");
      let year = LSBirthday.substring(0, 4);
      let month = LSBirthday.substring(4, 6);
      let day = LSBirthday.substring(6, 8);
      let date = new Date(year, month - 1, day);
      setBirthday(date);
      setAnotherDriver(true);
    }
    else if (!LSSecondaryDriver) {
      setAnotherDriver(null)
    }
    else if (firstName === "" ||
    lastName === "" ||
    birthday === null ||
    gender === "" ||
    maritalStatus === "" ||
    job === null ||
    howOldDriversLicense === null ||
    accidents === null ||
    drivingConvictions === null) {
      setAnotherDriver(false)
      setIsValid(true)
    }
  }, [
    LSAccidents,
    LSBirthday,
    LSConvictions,
    LSFirstName,
    LSGender,
    LSHowOld,
    LSJob,
    LSLastName,
    LSMarital,
    LSSecondaryDriver
  ]);

  useEffect(() => {
    setIsValid(true);
    if (anotherDriver === null) {
      setIsValid(false);
    }
    if (yearError) {
      setIsValid(false)
    }
    if (howOldDriversLicense) {
    if (howOldDriversLicense < 16) {
      setAgeError(true)
      setIsValid(false)
    } else if (howOldDriversLicense > 16) {
      setAgeError(false)
      setIsValid(true)
    } else if (howOldDriversLicense === 16) {
      setHowOldDriversLicense(17)
      setAgeError(false)
      setIsValid(true)
    } else if (howOldDriversLicense === null) {
      setAgeError(false)
      setIsValid(true)
    }
  }
    if (anotherDriver === true) {
      if (
        firstName === "" ||
        lastName === "" ||
        birthday === null ||
        gender === "" ||
        maritalStatus === "" ||
        job === null ||
        howOldDriversLicense === null ||
        accidents === null ||
        drivingConvictions === null
      ) {
        setIsValid(false);
      }
    }
    if (anotherDriver === false) {
      setFirstName("");
      setLastName("");
      setBirthday(null);
      setGender("");
      setMaritalStatus("");
      setJob("");
      setHowOldDriversLicense(0);
      setAccidents(null);
      setDrivingConvictions(null);
      setChosenYear("")
      setChosenMonth("")
      setChosenDay("")
      setYearError(false)
      setAgeError(false)
    } 
  }, [
    accidents,
    anotherDriver,
    birthday,
    drivingConvictions,
    firstName,
    gender,
    howOldDriversLicense,
    job,
    lastName,
    maritalStatus,
    yearError
  ]);

  const genderList = [carTexts[carLanguage].male, carTexts[carLanguage].female]
  const relationshipList = [carTexts[carLanguage].married, carTexts[carLanguage].single, carTexts[carLanguage].commonLaw]
  const situationList = [carTexts[carLanguage].professional, carTexts[carLanguage].retired, carTexts[carLanguage].student]
  const binaryList = [carTexts[carLanguage].yes, carTexts[carLanguage].no]

  useEffect(() => {
    if (gender === carTexts["fr"].male) {
      setGender(carTexts["en"].male)
    }
    if (gender === carTexts["fr"].female) {
      setGender(carTexts["en"].female)
    }
  }, [gender])

  useEffect(() => {
    if (maritalStatus === carTexts["fr"].married) {
      setMaritalStatus(carTexts["en"].married)
    }
    if (maritalStatus === carTexts["fr"].single) {
      setMaritalStatus(carTexts["en"].single)
    }
    if (maritalStatus === carTexts["fr"].commonLaw) {
      setMaritalStatus(carTexts["en"].commonLaw)
    }
  }, [maritalStatus])

  useEffect(() => {
    if (job === carTexts["fr"].professional) {
      setJob(carTexts["en"].professional)
    }
    if (job === carTexts["fr"].married) {
      setJob(carTexts["en"].married)
    }
    if (job === carTexts["fr"].student) {
      setJob(carTexts["en"].student)
    }
  }, [job])


  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].addAnotherDriver} />
      <RadioButtonGroup setAccept={setAnotherDriver} accept={anotherDriver} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>
      {anotherDriver === true ? (
        <>
        <p className="form-title">{carTexts[carLanguage].secondDriver}</p>
        <p className="dropdown-title">{carTexts[carLanguage].name}</p>
        <div className="two-input-div">
          <TextInput 
          placeholder={carTexts[carLanguage].firstName}
          label={carTexts[carLanguage].weeks}
          setState={setFirstName}
          lsValue={LSFirstName}
          type="string"
          />
          <TextInput 
          placeholder={carTexts[carLanguage].lastName}
          label={carTexts[carLanguage].weeks}
          setState={setLastName}
          lsValue={LSLastName}
          type="string"
          />
        </div>  
        <p className="dropdown-title">{carTexts[carLanguage].birthday}</p>
        <div className="date-container">
          <div className="input-container">
          <DropdownMenu dateError={yearError} list={yearList} setState={setChosenYear} placeholder={carTexts[carLanguage].year} lsValue={'secondaryYear'}/>
          </div>
          <div className="input-container">
          <DropdownMenu type={'month'} list={months} setState={setChosenMonth} placeholder={carTexts[carLanguage].month} lsValue={'secondaryMonth'}/>
          </div>
          <div className="input-container">
          <DropdownMenu dateError={dateError} list={dayList} setState={setChosenDay} placeholder={carTexts[carLanguage].day} lsValue={'secondaryDay'}/>
          </div>
          </div>
          {dateError && <div className="modal-error-msg">{carTexts[carLanguage].dateError}</div>}
          {yearError && <div className="modal-error-msg">{carTexts[carLanguage].yearError2}</div>}
        <p className="dropdown-title">{carTexts[carLanguage].gender}</p>
          <div className="t-boolean-buttons-container">
          <DropdownMenu type={'gender'} list={genderList} setState={setGender} placeholder={carTexts[carLanguage].select} lsValue={'secondaryGender'}/>
          </div>
        <p className="dropdown-title">{carTexts[carLanguage].relationship}</p>
          <DropdownMenu type={'maritalStatus'} list={relationshipList} setState={setMaritalStatus} placeholder={carTexts[carLanguage].select} lsValue={'secondaryMaritalStatus'}/>
        <p className="dropdown-title">{carTexts[carLanguage].secondDriveSituation}</p>
          <DropdownMenu type={'job'} list={situationList} setState={setJob} placeholder={carTexts[carLanguage].select} lsValue={'secondaryJob'}/>
        <p className="dropdown-title">{carTexts[carLanguage].secondDriveLicenseAge}</p>
          <MoneyInput 
            type="number"
            placeholder={carTexts[carLanguage].age}
            setState={setHowOldDriversLicense}
            lsValue={Number(howOldDriversLicense)}
            maxLength={2}
          />
          {ageError && <div className="modal-error-msg">{carTexts[carLanguage].yearError2}</div>}
        <p className="dropdown-title">{carTexts[carLanguage].secondDriveAccidents}</p>
          <DropdownMenu type={'boolean'} list={binaryList} onClick={setAccidents} placeholder={carTexts[carLanguage].select} lsValue={'secondaryAccidents'}/>
        <p className="dropdown-title">{carTexts[carLanguage].secondDriveConvictions}</p>
          <DropdownMenu type={'boolean'} list={binaryList} onClick={setDrivingConvictions} placeholder={carTexts[carLanguage].select} lsValue={'secondaryDrivingConvictions'}/>

        </>
      ) : null}
      <CarFooter
        data={{
          secondaryFirstName: firstName,
          secondaryLastName: lastName,
          secondaryBirthday: birthday
            ?.toLocaleDateString("sv")
            .replaceAll("-", ""),
          secondaryGender: gender,
          secondaryMaritalStatus: maritalStatus,
          secondaryJob: job,
          secondaryHowOldDriversLicense: howOldDriversLicense,
          secondaryAccidents: accidents,
          secondaryDrivingConvictions: drivingConvictions,
          secondaryDriver: anotherDriver,
          secondaryYear: chosenYear,
          secondaryMonth: chosenMonth,
          secondaryDay: chosenDay
        }}
        formSteps={23}
        type="submit"
        disabled={!valid}
      />
    </div>
    
    </div>
    
  );
}

export default CarStepTwentyTwo;
