import { useState, useContext, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import AverageRadioButtons from "../../../components/refresh/AverageRadioButtons";
import MoneyInput from "../../../components/refresh/MoneyInput";

function CarStepEight() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [primaryUseOfCar, setPrimaryUseOfCar] = useState<string>("");
  const [annualKM, setAnnualKM] = useState<number>(0);
  const [commuteLength, setCommuteLength] = useState<number>(0);
  const [valid, setValid] = useState<boolean>(false);
  const LSPrimayUse = useLocalStorage("primaryUseOfCar");
  const LSAnnualKM = useLocalStorage("annualKM");
  const LScommuteLength = useLocalStorage("commuteLength");


  useEffect(() => {
    if (LSPrimayUse) {
      setPrimaryUseOfCar(LSPrimayUse);
      setAnnualKM(LSAnnualKM);
      setCommuteLength(LScommuteLength);
    }
  }, [LSAnnualKM, LSPrimayUse, LScommuteLength]);

  useEffect(() => {
    setValid(true);
    if (primaryUseOfCar === carTexts[carLanguage].pleasure) {
      if (annualKM === 0) {
        setValid(false);
      }
    } else if (primaryUseOfCar === carTexts[carLanguage].pleasureAndCommuting) {
      if (annualKM === 0 || commuteLength === 0) {
        setValid(false);
      }
    } else if (primaryUseOfCar === "") {
      setValid(false);
    }
  }, [annualKM, commuteLength, primaryUseOfCar]);

  useEffect(() => {
    if (primaryUseOfCar === carTexts["fr"].pleasure) {
      setPrimaryUseOfCar(carTexts["en"].pleasure)
    }
    if (primaryUseOfCar === carTexts["fr"].pleasureAndCommuting) {
      setPrimaryUseOfCar(carTexts["en"].pleasureAndCommuting)
    }
  }, [primaryUseOfCar])

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].primaryUseOfCar} />
      <AverageRadioButtons 
      multiple={true}
      value={carTexts[carLanguage].pleasure}
      label={carTexts[carLanguage].pleasure}
      setState={setPrimaryUseOfCar}
      checked={primaryUseOfCar === carTexts["en"].pleasure}
      />
      <AverageRadioButtons 
      multiple={true}
      value={carTexts[carLanguage].pleasureAndCommuting}
      label={carTexts[carLanguage].pleasureAndCommuting}
      setState={setPrimaryUseOfCar}
      string={'Pleasure'}
      checked={primaryUseOfCar === carTexts["en"].pleasureAndCommuting}
      />
      {primaryUseOfCar === carTexts["en"].pleasure ? (
        <>
        <p className="dropdown-title">{carTexts[carLanguage].annualKM}</p>
        <MoneyInput 
        placeholder='e.g. 50000'
        label="KM"
        setState={setAnnualKM}
        type="number"
        maxLength={7}
        lsValue={annualKM}
        />
        </>
      ) : null}
      {primaryUseOfCar === carTexts["en"].pleasureAndCommuting ? (
        <>
        <p className="dropdown-title">{carTexts[carLanguage].annualKM}</p>
        <MoneyInput 
        placeholder='e.g. 50000'
        label="KM"
        setState={setAnnualKM}
        lsValue={annualKM}
        type="number"
        maxLength={7}
        />

        <p className="dropdown-title">{carTexts[carLanguage].commuteLength}</p>
        <MoneyInput 
        placeholder='e.g. 10000'
        label="KM"
        setState={setCommuteLength}
        lsValue={commuteLength}
        type="number"
        maxLength={7}
      
        />
        </>
      ) : null}

      <CarFooter
        data={{
          primaryUseOfCar: primaryUseOfCar,
          annualKM: parseInt(annualKM.toString().replace(/\s/g, "")),
          commuteLength: commuteLength
            ? parseInt(commuteLength.toString().replace(/\s/g, ""))
            : null,
        }}
        formSteps={9}
        type="submit"
        disabled={!valid}
        backFormSteps={7}
      />
    </div>
  </div> 
  );
}

export default CarStepEight;
