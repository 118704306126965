import ArrowRight from "./assets/ArrowRight"
import './RefreshStyles.css'
import { useContext } from "react";
import {
    CarStepContext,
    CarContext
  } from "../../quotes/car/CarContext";

type buttonProps = {
  data?: {};
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  formSteps?: number;
  progress?: number;
  hidden?: boolean;
};

const CarNextButton = (props: buttonProps) => {
    const { setCarFormSteps } = useContext(CarStepContext);
    const { setCarFormData } = useContext(CarContext);
  
    function handleSubmit(e: any) {
      e.preventDefault();
      const data: any = props.data;
      for (const field in data) {
        setCarFormData((carFormData: any) => ({
          ...carFormData,
          [field]: data[field],
        }));
        if (data[field] === undefined || data[field] === null) {
          localStorage.setItem(field, "");
        } else if (data[field].constructor === Array) {
          localStorage.setItem(field, JSON.stringify(data[field]));
        } else {
          localStorage.setItem(field, data[field].toString());
        }
      }
  
      setCarFormSteps(props.formSteps);
      if (props.formSteps) {
        localStorage.setItem("carStep", props.formSteps.toString());
      }
    }
  return (
    <button 
    className="btn pink text-with-arrow-left footer-btns"
    style={props.hidden ? { display: "none" } : {}}
    data-testid="next-btn"
    disabled={props.disabled}
      type={props.type}
      onClick={(e: any) => {
        handleSubmit(e);
      }}
      id="nextButton"
    >

            <div>
                <ArrowRight />
            </div>
    </button>
  )
}

export default CarNextButton