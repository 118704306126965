
import { useContext, useState } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import uuid from "react-uuid";
import { useEffect } from "react";

type Claim = {
  convictionType: string | null;
  dateOfLoss: string | undefined;
  id: string;
};

type ClaimFormProps = {
  onSubmit: (claim: Claim) => null | void;
};

function ClaimForm(props: ClaimFormProps) {
  const { carLanguage } = useContext(CarLanguageContext);
  const [claimType, setClaimType] = useState<string>('');
  const [date, setDate] = useState<Date | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [dob, setDob] = useState<Date | null>(null);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [chosenMonth, setChosenMonth] = useState<string>('');
  const [chosenDay, setChosenDay] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [month, setMonth] = useState<null | string>(null);
  const [monthNumber, setMonthNumber] = useState<null | string>(null);
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [yearList, setYearList] = useState<string[]>([]);
  const [dayList, setDayList] = useState<string[]>([]);
  const [dayNumber, setDayNumber] = useState<number>(0)

  useEffect(() => {

    const isLeap = (numberYear: number): boolean => {
      return new Date(numberYear, 1, 29).getDate() === 29;
    };
  
    if (chosenMonth) {
      if (chosenMonth === 'January' || chosenMonth === 'March' || chosenMonth === 'May' || chosenMonth === 'July' || chosenMonth === 'August' || chosenMonth === 'October' || chosenMonth === 'December'
      || chosenMonth === 'Janvier' || chosenMonth === 'Mars' || chosenMonth === 'Mai' || chosenMonth === 'Juillet' || chosenMonth === 'Août' || chosenMonth === 'Octobre' || chosenMonth === 'Décembre') {
        setDayNumber(32)
      }
      else if (chosenMonth === 'April' || chosenMonth === 'June' || chosenMonth === 'September' || chosenMonth === 'November'
      || chosenMonth === 'Avril' || chosenMonth === 'Juin' || chosenMonth === 'Septembre' || chosenMonth === 'Novembre') {
        setDayNumber(31)
      }
      else if (chosenMonth === 'February' || chosenMonth === 'Février') {
        if (isLeap(Number(chosenYear))) {
          setDayNumber(30)
        } else {
        setDayNumber(29)
      }
      }
      else {
        setDayNumber(32)
      }
    }
  }, [chosenMonth, chosenYear]);
  

  useEffect(() => {
    const years = [];
    const days = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1900; i < currentYear + 1; i++) {
      years.push(i.toString());
    }
    setYearList(years.reverse());

    for (let i = 1; i < dayNumber; i++) {
      if (i <= 9) {
        days.push(`0${i}`);
      } else {
        days.push(i.toString());
      }
    }
    setDayList(days);
  }, [dayNumber]);

  useEffect(() => {
    setValid(true);
    if (year === "" || day === "" || month === null) {
      setValid(false);
    }
  }, [day, month, year]);
  
  const months = [
  carTexts[carLanguage].january,
  carTexts[carLanguage].february,
  carTexts[carLanguage].march,
  carTexts[carLanguage].april,
  carTexts[carLanguage].may,
  carTexts[carLanguage].june,
  carTexts[carLanguage].july,
  carTexts[carLanguage].august,
  carTexts[carLanguage].september,
  carTexts[carLanguage].october,
  carTexts[carLanguage].november,
  carTexts[carLanguage].december,]

  function handleMonth(month: string) {
    setMonth(month);
    const list = [
      carTexts[carLanguage].january,
      carTexts[carLanguage].february,
      carTexts[carLanguage].march,
      carTexts[carLanguage].april,
      carTexts[carLanguage].may,
      carTexts[carLanguage].june,
      carTexts[carLanguage].july,
      carTexts[carLanguage].august,
      carTexts[carLanguage].september,
      carTexts[carLanguage].october,
      carTexts[carLanguage].november,
      carTexts[carLanguage].december,
    ];

    for (let i = 0; i < list.length; i++) {
      if (month === list[i]) {
        if (i < 9) {
          setMonthNumber(`0${i + 1}`);
        } else {
          setMonthNumber(`${i + 1}`);
        }
      }
    }
  }

  
let dateString = ''

useEffect(() => {
  handleMonth(chosenMonth)
  dateString = `${chosenYear}-${monthNumber}-${chosenDay}`
  const dateObject = new Date(dateString)

  function isValidDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  if (isValidDate(dateObject) && dateString.length === 10) {
    if (dateObject.toISOString().slice(0, 10) === dateString) {
      setDate(dateObject)
      setDateError(false)
    } else {
      setDateError(true)
      setDob(null)
    }
  } else {
    setDob(null)
  }


}, [chosenDay, chosenMonth, chosenYear])


  function handleReverseMonth(monthNumber: number) {
    const list = [
      carTexts[carLanguage].january,
      carTexts[carLanguage].february,
      carTexts[carLanguage].march,
      carTexts[carLanguage].april,
      carTexts[carLanguage].may,
      carTexts[carLanguage].june,
      carTexts[carLanguage].july,
      carTexts[carLanguage].august,
      carTexts[carLanguage].september,
      carTexts[carLanguage].october,
      carTexts[carLanguage].november,
      carTexts[carLanguage].december,
    ];

    const selectedMonth = list[monthNumber - 1];
    setMonth(selectedMonth);
    handleMonth(selectedMonth);
  }


  const convictionOptions = [
    carTexts[carLanguage].defectiveBrakes,
    carTexts[carLanguage].distractedDriving,
    carTexts[carLanguage].dlViolation,
    carTexts[carLanguage].noInsuranceCard,
    carTexts[carLanguage].noInsuranceEvidence,
    carTexts[carLanguage].noSharRoad,
    carTexts[carLanguage].noSignal,
    carTexts[carLanguage].noSeatBelts,
    carTexts[carLanguage].noYield,
    carTexts[carLanguage].noYieldPedestrian,
    carTexts[carLanguage].followClosely,
    carTexts[carLanguage].headlightOffense,
    carTexts[carLanguage].busLane,
    carTexts[carLanguage].badLineCHange,
    carTexts[carLanguage].badOpenningDoor,
    carTexts[carLanguage].improperPassing,
    carTexts[carLanguage].improperTowing,
    carTexts[carLanguage].improperTurn,
    carTexts[carLanguage].dividedHighway,
    carTexts[carLanguage].insecureLoad,
    carTexts[carLanguage].obstructTraffic,
    carTexts[carLanguage].overload,
    carTexts[carLanguage].prohibitedDevice,
    carTexts[carLanguage].railway,
    carTexts[carLanguage].speeding,
    carTexts[carLanguage].stopSign,
    carTexts[carLanguage].trafficLight,
    carTexts[carLanguage].slowDriving,
    carTexts[carLanguage].unsafeMove,
    carTexts[carLanguage].unsafeTurn,
    carTexts[carLanguage].unsafeVehicle,
    carTexts[carLanguage].wrongWay,
  ]

  useEffect(() => {
    if (claimType === carTexts["fr"].wrongWay) {
      setClaimType(carTexts["en"].wrongWay)
    }
    if (claimType === carTexts["fr"].unsafeVehicle) {
      setClaimType(carTexts["en"].unsafeVehicle)
    }
    if (claimType === carTexts["fr"].unsafeTurn) {
      setClaimType(carTexts["en"].unsafeTurn)
    }
    if (claimType === carTexts["fr"].slowDriving) {
      setClaimType(carTexts["en"].slowDriving)
    }
    if (claimType === carTexts["fr"].trafficLight) {
      setClaimType(carTexts["en"].trafficLight)
    }
    if (claimType === carTexts["fr"].stopSign) {
      setClaimType(carTexts["en"].stopSign)
    }
    if (claimType === carTexts["fr"].speeding) {
      setClaimType(carTexts["en"].speeding)
    }
    if (claimType === carTexts["fr"].railway) {
      setClaimType(carTexts["en"].railway)
    }
    if (claimType === carTexts["fr"].prohibitedDevice) {
      setClaimType(carTexts["en"].prohibitedDevice)
    }
    if (claimType === carTexts["fr"].overload) {
      setClaimType(carTexts["en"].overload)
    }
    if (claimType === carTexts["fr"].obstructTraffic) {
      setClaimType(carTexts["en"].obstructTraffic)
    }
    if (claimType === carTexts["fr"].insecureLoad) {
      setClaimType(carTexts["en"].insecureLoad)
    }
    if (claimType === carTexts["fr"].dividedHighway) {
      setClaimType(carTexts["en"].dividedHighway)
    }
    if (claimType === carTexts["fr"].improperTurn) {
      setClaimType(carTexts["en"].improperTurn)
    }
    if (claimType === carTexts["fr"].improperTowing) {
      setClaimType(carTexts["en"].improperTowing)
    }
    if (claimType === carTexts["fr"].improperPassing) {
      setClaimType(carTexts["en"].improperPassing)
    }   
    if (claimType === carTexts["fr"].badOpeningDoor) {
      setClaimType(carTexts["en"].badOpeningDoor)
    }
    if (claimType === carTexts["fr"].badLineCHange) {
      setClaimType(carTexts["en"].badLineCHange)
    }
    if (claimType === carTexts["fr"].busLane) {
      setClaimType(carTexts["en"].busLane)
    }
    if (claimType === carTexts["fr"].HeadlightOffense) {
      setClaimType(carTexts["en"].HeadlightOffense)
    }
    if (claimType === carTexts["fr"].followClosely) {
      setClaimType(carTexts["en"].followClosely)
    }
    if (claimType === carTexts["fr"].noYieldPedestrian) {
      setClaimType(carTexts["en"].noYieldPedestrian)
    }
    if (claimType === carTexts["fr"].noYield) {
      setClaimType(carTexts["en"].noYield)
    }
    if (claimType === carTexts["fr"].noSeatBelts) {
      setClaimType(carTexts["en"].noSeatBelts)
    }
    if (claimType === carTexts["fr"].noSignal) {
      setClaimType(carTexts["en"].noSignal)
    }
    if (claimType === carTexts["fr"].noSharRoad) {
      setClaimType(carTexts["en"].noSharRoad)
    }
    if (claimType === carTexts["fr"].noInsuranceEvidence) {
      setClaimType(carTexts["en"].noInsuranceEvidence)
    }
    if (claimType === carTexts["fr"].noInsuranceCard) {
      setClaimType(carTexts["en"].noInsuranceCard)
    }
    if (claimType === carTexts["fr"].dlViolation) {
      setClaimType(carTexts["en"].dlViolation)
    }
    if (claimType === carTexts["fr"].distractedDriving) {
      setClaimType(carTexts["en"].distractedDriving)
    }
    if (claimType === carTexts["fr"].defectiveBrakes) {
      setClaimType(carTexts["en"].defectiveBrakes)
    }
  }, [claimType])

  return (
    <div className="t-content-container" style={{ marginLeft: '0%' }}>
    
        <p className="dropdown-title">Conviction</p>
          <DropdownMenu type={'convictions'} list={convictionOptions} setState={setClaimType} placeholder={carTexts[carLanguage].select}/>
        <p className="dropdown-title">{carTexts[carLanguage].date}</p>
          <div className="date-container">
            <div className="input-container">
          <DropdownMenu list={yearList} setState={setChosenYear} placeholder={carTexts[carLanguage].year}/>
            </div>
            <div className="input-container">
          <DropdownMenu type={'month'} list={months} setState={setChosenMonth} placeholder={carTexts[carLanguage].month}/>
            </div>
            <div className="input-container">
          <DropdownMenu dateError={dateError} list={dayList} setState={setChosenDay} placeholder={carTexts[carLanguage].day}/>
            </div>
          </div>

          {dateError && <div className="modal-error-msg">{carTexts[carLanguage].dateError}</div>}

      <button
        onClick={() => {
          props.onSubmit({
            convictionType: claimType,
            dateOfLoss: date?.toLocaleDateString("sv").replaceAll("-", ""),
            id: uuid(),
          });
        }}
        className="btn form"
        disabled={claimType === '' ||
        date === null}
      >
        {carTexts[carLanguage].add}
      </button>
    </div>
  );
}

export default ClaimForm;
