import { useContext, useState, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import Question from "../../../components/refresh/Question";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";


function CarStepTwentyThree() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [date, setDate] = useState<Date | null>(null);
  const LSDate = useLocalStorage("policyStartDate");

  const [month, setMonth] = useState<null | string>(null);
  const [monthNumber, setMonthNumber] = useState<null | string>(null);
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [yearList, setYearList] = useState<string[]>([]);
  const [dayList, setDayList] = useState<string[]>([]);
  const [valid, setValid] = useState<boolean>(false);

  const [chosenYear, setChosenYear] = useState<string>('');
  const [chosenMonth, setChosenMonth] = useState<string>('');
  const [chosenDay, setChosenDay] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [currentYearMonths, setCurrentYearMonths] = useState<string[]>([])
  const [dayNumber, setDayNumber] = useState<number>(0)

  useEffect(() => {
    if (LSDate) {
      let year = LSDate.substring(0, 4);
      let month = LSDate.substring(4, 6);
      let day = LSDate.substring(6, 8);
      let myDate = new Date(year, month - 1, day);
      setDate(myDate);
      setChosenYear(year);
      setChosenDay(day);
      handleReverseMonth(month);
    }
  }, [LSDate]);

  useEffect(() => {

    const isLeap = (numberYear: number): boolean => {
      return new Date(numberYear, 1, 29).getDate() === 29;
    };
  
    if (chosenMonth) {
      if (chosenMonth === 'January' || chosenMonth === 'March' || chosenMonth === 'May' || chosenMonth === 'July' || chosenMonth === 'August' || chosenMonth === 'October' || chosenMonth === 'December'
      || chosenMonth === 'Janvier' || chosenMonth === 'Mars' || chosenMonth === 'Mai' || chosenMonth === 'Juillet' || chosenMonth === 'Août' || chosenMonth === 'Octobre' || chosenMonth === 'Décembre') {
        setDayNumber(32)
      }
      else if (chosenMonth === 'April' || chosenMonth === 'June' || chosenMonth === 'September' || chosenMonth === 'November'
      || chosenMonth === 'Avril' || chosenMonth === 'Juin' || chosenMonth === 'Septembre' || chosenMonth === 'Novembre') {
        setDayNumber(31)
      }
      else if (chosenMonth === 'February' || chosenMonth === 'Février') {
        if (isLeap(Number(chosenYear))) {
          setDayNumber(30)
        } else {
        setDayNumber(29)
      }
      }
      else {
        setDayNumber(32)
      }
    }
  }, [chosenMonth, chosenYear]);
  

useEffect(() => {
  window.scrollTo(0, 0);

  const years = [];
  const days = [];
  const currentYear = new Date().getFullYear();

  for (let i = 2024; i < currentYear + 2; i++) {
    years.push(i.toString());
  }
  setYearList(years);

  for (let i = 1; i < dayNumber; i++) {
    if (i <= 9) {
      days.push(`0${i}`);
    } else {
      days.push(i.toString());
    }
  }
  setDayList(days);
}, [dayNumber]);

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();


const months = [
carTexts[carLanguage].january,
carTexts[carLanguage].february,
carTexts[carLanguage].march,
carTexts[carLanguage].april,
carTexts[carLanguage].may,
carTexts[carLanguage].june,
carTexts[carLanguage].july,
carTexts[carLanguage].august,
carTexts[carLanguage].september,
carTexts[carLanguage].october,
carTexts[carLanguage].november,
carTexts[carLanguage].december,]

useEffect(() => {
  if (chosenYear === '2024') {
    const d = new Date()
    let month = d.getMonth();
    setCurrentYearMonths(months.slice(month, 12))
  } else {
    setCurrentYearMonths(months)
  }
}, [chosenYear])


function handleMonth(month: string) {
  setMonth(month);
  const list = [
    carTexts[carLanguage].january,
    carTexts[carLanguage].february,
    carTexts[carLanguage].march,
    carTexts[carLanguage].april,
    carTexts[carLanguage].may,
    carTexts[carLanguage].june,
    carTexts[carLanguage].july,
    carTexts[carLanguage].august,
    carTexts[carLanguage].september,
    carTexts[carLanguage].october,
    carTexts[carLanguage].november,
    carTexts[carLanguage].december,
  ];

  for (let i = 0; i < list.length; i++) {
    if (month === list[i]) {
      if (i < 9) {
        setMonthNumber(`0${i + 1}`);
      } else {
        setMonthNumber(`${i + 1}`);
      }
    }
  }
}

let dateString = ''

useEffect(() => {
  handleMonth(chosenMonth)
  dateString = `${chosenYear}/${monthNumber}/${chosenDay}`
  const dateObject = new Date(dateString)

  function isValidDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  if (isValidDate(dateObject) && dateString.length === 10) {
    if (dateObject.toISOString().slice(0, 10).replace(/-/g, '/') === dateString) {
      setDate(dateObject)
      setDateError(false)
    } else {
      setDateError(true)
      setDate(null)
    }
  } else {
    setDate(null)
  }


}, [chosenDay, chosenMonth, chosenYear])


function handleReverseMonth(monthNumber: number) {
  const list = [
    carTexts[carLanguage].january,
    carTexts[carLanguage].february,
    carTexts[carLanguage].march,
    carTexts[carLanguage].april,
    carTexts[carLanguage].may,
    carTexts[carLanguage].june,
    carTexts[carLanguage].july,
    carTexts[carLanguage].august,
    carTexts[carLanguage].september,
    carTexts[carLanguage].october,
    carTexts[carLanguage].november,
    carTexts[carLanguage].december,
  ];

  const selectedMonth = list[monthNumber - 1];
  setMonth(selectedMonth);
  handleMonth(selectedMonth);
}

useEffect(() => {
  setValid(true);

  if (chosenMonth === "" || chosenYear === "" || chosenDay === "") {
    setValid(false);
  }

  let monthIndex = months.indexOf(chosenMonth);
  if (parseInt(chosenYear) === currentYear && monthIndex < currentMonth) {
    setValid(false);
    setDateError(true)
  }
}, [date]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].whenStartPolicy} />
      <div className="date-container">
        <div className="input-container">
      <DropdownMenu dateError={dateError} list={yearList} setState={setChosenYear} placeholder={carTexts[carLanguage].year} lsValue={'policyYear'}/>
      </div>
        <div className="input-container">
      <DropdownMenu dateError={dateError} type={'month'} list={currentYearMonths} setState={setChosenMonth} placeholder={carTexts[carLanguage].month} lsValue={'policyMonth'}/>
      </div>
        <div className="input-container">
      <DropdownMenu dateError={dateError} list={dayList} setState={setChosenDay} placeholder={carTexts[carLanguage].day} lsValue={'policyDay'}/>
      </div>  
      </div>
      {dateError && <div className="modal-error-msg">{carTexts[carLanguage].dateError}</div>}

      <CarFooter
        type="submit"
        data={{
          policyStartDate: date?.toLocaleDateString("sv").replaceAll("-", ""),
          policyYear: chosenYear,
          policyMonth: chosenMonth,
          policyDay: chosenDay,
          secondaryFirstName: "",
          secondaryLastName: "",
          secondaryBirthday: "",
          secondaryGender: "",
          secondaryMaritalStatus: "",
          secondaryJob: "",
          secondaryHowOldDriversLicense: null,
          secondaryAccidents: null,
          secondaryDrivingConvictions: null,
          secondaryDriver: "",
          secondaryYear: "",
          secondaryMonth: "",
          secondaryDay: ""
        }}
        formSteps={24}
        backFormSteps={21}
        disabled={!valid}
      />
    </div>
    </div>
  );
}

export default CarStepTwentyThree;
