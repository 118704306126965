import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { CarContext, CarLanguageContext } from "../CarContext";
import CarFailedQuote from "../../../components/FailedQuote/CarFailedQuote";
import Question from "../../../components/questions/Question";
import CarFooter from "../../../components/footerCar/CarFooter";
import { texts } from "../../../text";
import RadioLargeQuote from "../../../components/refresh/RadioLargeQuote";
import NextSteps from "../../../components/refresh/NextSteps";
import KbdLogo from "../../../components/refresh/assets/KbdLogo";

function CarStepTwentyEight() {
  const { carFormData } = useContext(CarContext);
  const { carLanguage } = useContext(CarLanguageContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [quote, setQuote] = useState<number>(0);
  const [failed, setFailed] = useState<boolean | null>(null);

  useEffect(() => {
    axios
      .post("https://shrouded-thicket-97880.herokuapp.com/car-form", {
        data: carFormData,
      })
      .then((res: any) => {
        setQuote(parseInt(res.data) / 12);
        if (!res.data || res.data === 0) {
          setFailed(true);
        } else {
          setFailed(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setFailed(true);
        console.log(err);
      });

    localStorage.clear();
  }, []);
  
    if (loading) {
      return <div className="t-container"><div className="t-content-container"><div className="loading-state"><KbdLogo /></div></div></div>;
    } else if (failed) {
      return (
        <div>
          <CarFailedQuote />
        </div>
      );
    } else {
      return (
        <div className="t-container">
          <div className="t-content-container">
          <Question question={texts[carLanguage].yourQuote} />
          <RadioLargeQuote quotePrice={quote.toFixed(2)} perMonth={texts[carLanguage].quotePrice} carLanguage={carLanguage}/>
        <a href="tel:4388434814">
        <div className="quote-buy-button">
            {texts[carLanguage].buy}
        </div>
        </a>
        <NextSteps  quoteNextStep={texts[carLanguage].quoteNextStep} quoteCall={texts[carLanguage].quoteCall} quoteNumber={texts[carLanguage].quoteNumber} review={texts[carLanguage].review}/>
          <CarFooter newQuote backHidden nextHidden />
        </div>
        </div>
      );
    }
  }
// }

export default CarStepTwentyEight;
