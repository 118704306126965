import { useContext, useState, useEffect } from "react";
import Footer from "../../../components/footer/Footer";
import Question from "../../../components/refresh/Question";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../HomeContext";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";

function StepSeven() {
  const { language } = useContext(LanguageContext);
  const [residenceOccupancy, setResidenceOccupancy] = useState<string>("");
  const LSResidence = useLocalStorage("residenceOccupancy");

  useEffect(() => {
    if (LSResidence) {
      setResidenceOccupancy(LSResidence);
    }
  }, [LSResidence]);

  useEffect(() => {
    if (residenceOccupancy === answers["fr"].youAndYourFamily) {
      setResidenceOccupancy(answers["en"].youAndYourFamily)
    }
    if (residenceOccupancy === answers["fr"].yourFamilyAndNotherFamily) {
      setResidenceOccupancy(answers["en"].yourFamilyAndNotherFamily)
    }
    if (residenceOccupancy === answers["fr"].yourFamilyAndTwoFamilies) {
      setResidenceOccupancy(answers["en"].yourFamilyAndTwoFamilies)
    }
    if (residenceOccupancy === answers["fr"].rentedOut) {
      setResidenceOccupancy(answers["en"].rentedOut)
    }
  }, [residenceOccupancy])

  return (
    <div className="t-container">
      <div className="t-content-container">
      {/* <ProfileCircle /> */}
      <Question question={texts[language].residenceOccupancy} />
      <DropdownMenu
      type={'residence'} 
      placeholder={texts[language].select}
      setState={setResidenceOccupancy}
      list={
        [answers[language].youAndYourFamily,
        answers[language].yourFamilyAndNotherFamily,
        answers[language].yourFamilyAndTwoFamilies,
        answers[language].rentedOut,]
      }
      lsValue={'residenceOccupancy'}
      />
      
      <Footer
        type="submit"
        data={{ residenceOccupancy: residenceOccupancy }}
        formSteps={9}
        backFormSteps={6}
        disabled={residenceOccupancy === "" ? true : false}
      />
    </div>
    </div>
  );
}

export default StepSeven;
