import { useContext, useEffect, useState } from "react";
import Question from "../../../components/refresh/Question";
import Footer from "../../../components/footer/Footer";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../HomeContext";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import useLocalStorage from "../../../components/hooks/useLocalStorage";

function StepNine() {

  const { language } = useContext(LanguageContext);
  const months: string[] = [
    answers[language].january,
    answers[language].february,
    answers[language].march,
    answers[language].april,
    answers[language].may,
    answers[language].june,
    answers[language].july,
    answers[language].august,
    answers[language].september,
    answers[language].october,
    answers[language].november,
    answers[language].december,
  ];
  const [yearList, setYearList] = useState<string[]>([]);
  const [valid, setValid] = useState(false);
  const [selectedYear, setSelectedYear] = useState<any>("");
  const [selectedMonth, setSelectedMonth] = useState<any>("");
  const LSDate = useLocalStorage("policyStartDate");
  const [currentYearMonths, setCurrentYearMonths] = useState<string[]>([])

  useEffect(() => {
    if (LSDate) {
      const split: any = LSDate.split("/");
      setSelectedMonth(split[0]);
      setSelectedYear([split[1]]);
    }
  }, [LSDate]);

  useEffect(() => {
    if (selectedYear === '2023') {
      const d = new Date()
      let month = d.getMonth();
      setCurrentYearMonths(months.slice(month, 12))
    } else {
      setCurrentYearMonths(months)
    }
  }, [selectedYear])

  useEffect(() => {
    setValid(true);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    let myYearlist = [];
    for (let i = currentYear; i < currentYear + 2; i++) {
      myYearlist.push(i.toString());
    }
    setYearList(myYearlist);

    if (selectedMonth === "" || selectedYear === "") {
      // setValid(false);
    }

    let monthIndex = months.indexOf(selectedMonth);
    if (parseInt(selectedYear) === currentYear && monthIndex < currentMonth) {
      setValid(false);
    }
  }, [selectedMonth, selectedYear]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      {/* <CalendarCircle /> */}
      <Question question={texts[language].policyStartDate} />
      <div className="date-container">
        <div className="input-container-two">
        <DropdownMenu dateError={!valid} list={yearList} setState={setSelectedYear} placeholder={texts[language].year} lsValue={'policyStartYear'}/>
      </div>
        <div className="input-container-two">
      <DropdownMenu dateError={!valid} type={'monthHome'} list={currentYearMonths} setState={setSelectedMonth} placeholder={texts[language].month} lsValue={'policyStartMonth'}/>
      </div>
      </div>
      {!valid && <div className="modal-error-msg">{texts[language].dateError}</div>}

      <Footer
        type="submit"
        data={{ policyStartDate: `${selectedMonth}/${selectedYear}`, policyStartMonth: selectedMonth, policyStartYear: selectedYear, yearMovedIn: "2018" }}
        formSteps={10}
        backFormSteps={7}
        disabled={!valid}
      />
    </div>
    </div>
  );
}

export default StepNine;
