import { useContext, useEffect, useState } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import AverageRadioButtons from "../../../components/refresh/AverageRadioButtons";

function CarStepSixteen() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [maritalStatus, setMaritalStatus] = useState<string>("");
  const LSMarital = useLocalStorage("maritalStatus");

  useEffect(() => {
    if (LSMarital) {
      setMaritalStatus(LSMarital);
    }
  }, [LSMarital]);

  useEffect(() => {
    if (maritalStatus === carTexts["fr"].married) {
      setMaritalStatus(carTexts["en"].married)
    }
    if (maritalStatus === carTexts["fr"].single) {
      setMaritalStatus(carTexts["en"].single)
    }
    if (maritalStatus === carTexts["fr"].commonLaw) {
      setMaritalStatus(carTexts["en"].commonLaw)
    }
  }, [maritalStatus])

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].maritalStatus} />

      <AverageRadioButtons multiple={true} setState={setMaritalStatus} value={carTexts[carLanguage].married} label={carTexts[carLanguage].married}
      checked={maritalStatus === carTexts["en"].married} />

      <AverageRadioButtons multiple={true} setState={setMaritalStatus} value={carTexts[carLanguage].single} label={carTexts[carLanguage].single}
      checked={maritalStatus === carTexts["en"].single}
      />
      
      <AverageRadioButtons multiple={true} setState={setMaritalStatus} value={carTexts[carLanguage].commonLaw} label={carTexts[carLanguage].commonLaw}
      checked={maritalStatus === carTexts["en"].commonLaw}
      />

      <CarFooter
        data={{
          maritalStatus: maritalStatus,
        }}
        formSteps={17}
        type="submit"
        disabled={maritalStatus === ""}
      />
    </div>
    </div>  
  );
}

export default CarStepSixteen;
