import { useEffect, useState, useContext } from "react";
import axios from "axios";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import "../../../components/refresh/RefreshStyles.css"

type CarModel = {
  year: number;
  make: string;
  model: string;
  EXT_VICC: string;
  VICC_CODE: string;
};

function CarStepThree() {
  const [yearList, setYearList] = useState<string[]>([]);
  const [carYear, setCarYear] = useState<string>("");
  const [makeList, setMakeList] = useState<string[]>([]);
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [modelList, setModelList] = useState<CarModel[]>([]);
  const [selectedCar, setSelectedCar] = useState<string>("");
  const [carInfo, setCarInfo] = useState<CarModel>({
    year: 0,
    make: "",
    model: "",
    EXT_VICC: "",
    VICC_CODE: "",
  });
  const { carLanguage } = useContext(CarLanguageContext);
  const LSYear = useLocalStorage("year");
  const LSMake = useLocalStorage("make");
  const LSModel = useLocalStorage("model");
  const LSext = useLocalStorage("EXT_VICC")
  const LSvicc = useLocalStorage("VICC_CODE")
  const [valid, setValid] = useState(false)


  useEffect(() => {
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1980; i < currentYear + 2; i++) {
      years.push(i.toString());
    }
    setYearList(years.reverse());

    if (LSYear && LSMake && LSModel) {
      setCarYear(LSYear);
      setSelectedMake(LSMake);
      setSelectedCar(LSModel);
      setCarInfo({
        year: LSYear,
        make: LSMake,
        model: LSModel,
        EXT_VICC: LSext,
        VICC_CODE: LSvicc,
      })
    }
  }, [LSMake, LSModel, LSYear]);

  useEffect(() => {
    if (carYear) {
      axios
        .post("https://shrouded-thicket-97880.herokuapp.com/get-car-makes", {
          year: Number(carYear),
        })
        .then((response) => {
          setMakeList(response.data.sort());
        })
        .catch((err) => {
          setMakeList([]);
        });
    }
  }, [carYear]);

  useEffect(() => {
    if (carYear && selectedMake !== "") {
      axios
        .post("https://shrouded-thicket-97880.herokuapp.com/get-car-models", {
          year: parseInt(carYear),
          make: selectedMake,
        })
        .then((response) => {
          setModelList(response.data.sort());
        })
        .catch((err) => {
          setModelList([]);
        });
    }

    if (carYear && selectedMake !== "" && selectedCar !== "") {
      for (let i = 0; i < modelList.length; i++) {
        if (selectedCar === modelList[i].model) {
          setCarInfo(modelList[i]);
        }
      }
    }
  }, [carYear, selectedMake, selectedCar]);

  useEffect(() => {
    if (carInfo?.EXT_VICC && carInfo.VICC_CODE) {
      if (carInfo.EXT_VICC.length === 5) {
        setCarInfo((info) => ({
          ...info,
          EXT_VICC: `0${carInfo.EXT_VICC}`,
        }));
      }
      if (carInfo.VICC_CODE.length === 3) {
        setCarInfo((info) => ({
          ...info,
          VICC_CODE: `0${carInfo.VICC_CODE}`,
        }));
      }
    }
  }, [carInfo?.EXT_VICC, carInfo?.VICC_CODE]);

  let filteredModelList: any = []
  
  modelList.filter((modelObj) => {
      return filteredModelList.push(modelObj.model)
  })


  useEffect(() => {
    if (carYear && selectedMake && selectedCar) {
      setValid(true)
    }
    else {
      setValid(false)
    }
  }, [carYear, selectedMake, selectedCar])

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].carDetails} />
          <p className="dropdown-title" style={{ marginTop: "-24px" }}>{carTexts[carLanguage].year}</p>
          <DropdownMenu list={yearList} setState={setCarYear} placeholder={carTexts[carLanguage].year} lsValue={'year'}/>
          <p className="dropdown-title">{carTexts[carLanguage].make}</p>
          <DropdownMenu list={makeList} setState={setSelectedMake} placeholder={carTexts[carLanguage].make} lsValue={'make'}/>
          <p className="dropdown-title">{carTexts[carLanguage].model}</p>
          <DropdownMenu list={filteredModelList.sort()} setState={setSelectedCar} placeholder={carTexts[carLanguage].model} lsValue={'model'}/>
      <CarFooter
        data={{
          year: carInfo?.year,
          make: carInfo?.make,
          model: carInfo?.model,
          EXT_VICC: carInfo?.EXT_VICC,
          VICC_CODE: carInfo?.VICC_CODE,
        }}
        formSteps={4}
        type="submit"
        disabled={
          carYear === "" || selectedMake === "" || selectedCar === ""
        }
        backFormSteps={2}
      />
      </div>
    </div>
  );
}

export default CarStepThree;
