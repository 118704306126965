import { useContext, useEffect, useState } from "react";
import Footer from "../../../components/footer/Footer";
import SubQuestions from "../../../components/questions/SubQuestions";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../HomeContext";
import useIsMobile from "../../../components/hooks/useIsMobile";
import Question from "../../../components/refresh/Question";
import DropdownMenu from "../../../components/refresh/DropdownMenu";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import TextInput from "../../../components/refresh/TextInput";
import MoneyInput from "../../../components/refresh/MoneyInput";

function StepTwelve() {
  const { language } = useContext(LanguageContext);
  const [homeType, setHomeType] = useState<string | null>("");
  const LSEmail = useLocalStorage("userEmail");
  const [email, setEmail] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<number>(0)
  const LSPhoneNumber = useLocalStorage("phoneNumber")
  // const LSDiscountNonSmoker = useLocalStorage("discountNonSmoker");
  // const LSDiscountMonitoredBurglar = useLocalStorage("discountMonitoredBurglar");
  // const LSSaveEighteenPercent = useLocalStorage("saveEighteenPercent");
  // const LSDiscountSprinkler = useLocalStorage("discountSprinkler");
  // const LSDiscountFireAlarm = useLocalStorage("discountFireAlarm");
  // const LSNumOfMortgages = useLocalStorage("numOfMortgages");
  

  const [data, setData] = useState<any>({
    saveEighteenPercent: null,
    discountMonitoredBurglar: null,
    discountFireAlarm: null,
    discountSprinkler: null,
    discountNonSmoker: null,
    numOfMortgages: null,
    userEmail: "",
    phoneNumber: null,
  });
  // const [valid, setValid] = useState<boolean>(true);
  // const isMobile = useIsMobile();
  // function validateEmail(emailField: string) {
  //   var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  //   if (reg.test(emailField) === false) {
  //     return false;
  //   }

  //   return true;
  // }

  useEffect(() => {
    const type = localStorage.getItem("type");
    setHomeType(type);
    if (type === "Tenant insurance") {
      setData({ ...data, numOfMortgages: 0 });
    }
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   setValid(true);
  //   for (let key in data) {
  //     if (data[key] === "" || data[key] === null) {
  //       setValid(false);
  //     }
  //   }

  //   if (validateEmail(data.userEmail) === false) {
  //     setValid(false);
  //   }
  // }, [data, valid]);

  useEffect(() => {
    if (LSEmail) {
      setEmail(LSEmail)
      setData((prevData: any) => ({
        ...prevData,
        userEmail: LSEmail
      }))
    }
    if (LSPhoneNumber) {
  setData((prevData: any) => ({
    ...prevData,
        phoneNumber: LSPhoneNumber
      }))
    }
  //   if (LSDiscountNonSmoker) {
  // setData((prevData: any) => ({
  //   ...prevData,
  //       discountNonSmoker: LSDiscountNonSmoker
  //     }))
  //   }
  //   if (LSDiscountFireAlarm) {
  // setData((prevData: any) => ({
  //   ...prevData,
  //       discountFireAlarm: LSDiscountFireAlarm
  //     }))
  //   }
  //   if (LSDiscountMonitoredBurglar) {
  // setData((prevData: any) => ({
  //   ...prevData,
  //       discountMonitoredBurglar: LSDiscountMonitoredBurglar
  //     }))
  //   }
  //   if (LSDiscountSprinkler) {
  // setData((prevData: any) => ({
  //   ...prevData,
  //       discountSprinkler: LSDiscountSprinkler
  //     }))
  //   }
  //   if (LSSaveEighteenPercent) {
  // setData((prevData: any) => ({
  //   ...prevData,
  //       saveEighteenPercent: LSSaveEighteenPercent
  //     }))
  //   }
  //   if (LSNumOfMortgages) {
  // setData((prevData: any) => ({
  //   ...prevData,
  //       numOfMortgages: LSNumOfMortgages
  //     }))
  //   }
  }, [LSEmail,
     LSPhoneNumber,
    //  LSDiscountNonSmoker,
    //  LSDiscountFireAlarm,
    //  LSDiscountMonitoredBurglar,
    //  LSDiscountSprinkler,
    //  LSSaveEighteenPercent,
    //  LSNumOfMortgages
    ])

    useEffect(() => {

          if (phoneNumber) {
            setData((prevData: any) => ({
              ...prevData,
                  phoneNumber: phoneNumber
                }))
              }

          if (email) {
          setData((prevData: any) => ({
            ...prevData,
            userEmail: LSEmail
          }))
        }

    }, [phoneNumber, email])
      
  const answersBoolean = [texts[language].yes, texts[language].no]

  return (
    <div className="t-container">
      <div className="t-content-container">
      {/* <Question question={texts[language].selectDiscountQuestion} />
      <p className="dropdown-title first">{texts[language].discountQuestionOne}</p>
      <DropdownMenu type={'booleanHome'} list={answersBoolean} lsValue={'saveEighteenPercent'} onClick={(text) => {
            setData({ ...data, saveEighteenPercent: text });
          }} placeholder={texts[language].select}/>
      <p className="dropdown-title">{texts[language].discountQuestionTwo}</p>
      <DropdownMenu type={'booleanHome'} list={answersBoolean} lsValue={'discountMonitoredBurglar'} onClick={(text) => {
            setData({ ...data, discountMonitoredBurglar: text });
          }} placeholder={texts[language].select}/>
      <p className="dropdown-title">{texts[language].discountQuestionThree}</p>
      <DropdownMenu type={'booleanHome'} list={answersBoolean} lsValue={'discountFireAlarm'} onClick={(text) => {
            setData({ ...data, discountFireAlarm: text });
          }} placeholder={texts[language].select}/>
      <p className="dropdown-title">{texts[language].discountQuestionFour}</p>
      <DropdownMenu type={'booleanHome'} list={answersBoolean} lsValue={'discountSprinkler'} onClick={(text) => {
            setData({ ...data, discountSprinkler: text });
          }} placeholder={texts[language].select}/>    
      <p className="dropdown-title">{texts[language].discountQuestionFive}</p>
      <DropdownMenu type={'booleanHome'} list={answersBoolean} lsValue={'discountNonSmoker'} onClick={(text) => {
            setData({ ...data, discountNonSmoker: text });
          }} placeholder={texts[language].select}/>    

        {homeType !== "Tenant insurance" ? (
          <SubQuestions
            question={texts[language].discountQuestionSix}
            type="dropdown"
            placeholder={answers[language].one}
            options={[
              answers[language].zero,
              answers[language].one,
              answers[language].two,
              answers[language].three,
            ]}
            onChange={(value) => {
              setData({ ...data, numOfMortgages: value });
            }}
            inputSize="big"
            lsValue={'numOfMortgages'}
            nonCenter={true}
            onLeft={true}
            dropdownType="dropdown"
          />
        ) : null} */}
        <Question
        question={texts[language].contactInfo}
        subQuestion={texts[language].justKidding}
        />
        <div className="two-input-div">
          <TextInput 
          placeholder={texts[language].email}
          // label={carTexts[carLanguage].weeks}
          setState={setEmail}
          lsValue={data.userEmail}
          type="string"
          />
          <MoneyInput 
          placeholder={texts[language].phoneNumber}
          // label={carTexts[carLanguage].weeks}
          setState={setPhoneNumber}
          lsValue={data.phoneNumber}
          type="number"
          maxLength={10}
          />
        </div>
        {/* <SubQuestions
          question={texts[language].discountQuestionSeven}
          type="text"
          placeholder={texts[language].email}
          options={[]}
          onChange={(value) => {
            setData({ ...data, userEmail: value });
          }}
          inputSize={isMobile ? "small" : "big"}
          nonCenter={true}
          onLeft={true}
          lsValue={data.userEmail}
        />
        <SubQuestions
          question={texts[language].phoneNumber}
          type="number"
          placeholder={texts[language].phoneNumber}
          options={[]}
          onChange={(value) => {
            setData({ ...data, phoneNumber: value });
          }}
          inputSize={"small"}
          nonCenter={true}
          onLeft={true}
          maxLength={10}
          lsValue={data.phoneNumber}
        /> */}
        
      <Footer
        type="submit"
        data={{
          saveEighteenPercent: true,
          discountMonitoredBurglar: true,
          discountFireAlarm: true,
          discountSprinkler: true,
          discountNonSmoker: true,
          numOfMortgages: true,
          userEmail: email,
          phoneNumber: data.phoneNumber,
        }}
        formSteps={13}
        backFormSteps={11}
        // disabled={!valid || (data.phoneNumber ? data.phoneNumber.length <= 9 : undefined)}\
        disabled={String(data.phoneNumber).length < 10 || email === ""}
      />
    </div>
    </div>
  );
}

export default StepTwelve;
