import { useContext, useEffect } from "react";
// import Navbar from "./components/navbar/Navbar";
import Header from "./components/refresh/Header";
import HomeCarIcon from "./components/HomeCarIcon";
import HomeHomeIcon from "./components/HomeHomeIcon";
// import Footer from "./components/footer/Footer";
import { LanguageContext } from "./quotes/shared/HomeContext";
import { texts } from "./text";
import { Link } from "react-router-dom";

function Homepage() {
  const { setLanguage, language } = useContext(LanguageContext);

  useEffect(() => {
    const lang =
      (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
      navigator.language;

    if (lang === "en-GB" || lang === "en-US" || lang === "en") {
      setLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      setLanguage("fr");
      localStorage.setItem("language", "fr");
    }
    var ref = document.referrer;
    
    if (ref.includes("fr")) {
      setLanguage("fr");
      localStorage.setItem("language", "fr");
    } else {
      setLanguage("en");
      localStorage.setItem("language", "en");
    }
  }, []);

  useEffect(() => {
    if (language === "fr") {
      document.title = "Assurance KBD";
    } else {
      document.title = "KBD Insurance";
    }
  }, [language]);

  return (
    <div>
      {/* <Navbar /> */}
      {/* <div className="main"> */}
        <div className="t-main">
          <Header />
          <div className="t-container">
          <div className="t-content-container">
          <div className="t-text-box">
          {/* <h1 className="questions-heading">{texts[language].homeQuestion}</h1> */}
          <h1 className="question-heading">{texts[language].homeQuestion}</h1>
          </div>
          <div className="quoter-selects-container">
            {language === 'en' ? 
            <Link to={"/car"} state={language} tabIndex={1}>
            <button className="quoter-select-block">
              <HomeCarIcon />
              <p className="p-small">
                {texts[language].homeInsuranceTypeCar}
              </p>
            </button>
          </Link>
          :
          <Link to={"/car/fr"} state={language} tabIndex={1}>
              <button className="quoter-select-block">
                <HomeCarIcon />
                <p className="p-small">
                  {texts[language].homeInsuranceTypeCar}
                </p>
              </button>
            </Link>
            }
            
            {language === 'en' ?
            <Link to={"/home"} tabIndex={1}>
               <button className="quoter-select-block">
                <HomeHomeIcon />
                <p className="p-small">
                  {texts[language].homeInsuranceTypeHome}
                </p>
              </button>
            </Link>
              :
              <Link to={"/home/fr"} tabIndex={1}>
              <button className="quoter-select-block">
               <HomeHomeIcon />
               <p className="p-small">
                 {texts[language].homeInsuranceTypeHome}
               </p>
             </button>
           </Link>
            }

          </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
}

export default Homepage;
