import React from "react";
type ProgressIconProps = {
  progress: "active" | "done";
};
function MyCarIcon(props: ProgressIconProps) {
  return (
    <span className="progress-icons">
      {props.progress === "done" ? (
        <svg
          width="15"
          height="11"
          viewBox="0 0 15 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.23079 0H10.5299C10.7807 0 11.3761 0.244445 11.7522 1.22222C12.0054 1.8806 12.2728 2.49067 12.4684 2.91466H13.7265C14.038 2.91466 14.2906 3.16722 14.2906 3.47877C14.2906 3.79031 14.038 4.04287 13.7265 4.04287H13.2516C13.4328 4.24564 13.5387 4.5116 13.5387 4.79485V9.87178C13.5387 10.4949 13.0336 11 12.4105 11C11.7875 11 11.2823 10.4949 11.2823 9.87178V9.49576H3.0087V9.8718C3.0087 10.4949 2.50359 11 1.8805 11C1.25741 11 0.75229 10.4949 0.75229 9.8718V4.74C0.75229 4.48241 0.840021 4.23814 0.993434 4.04287H0.564104C0.252558 4.04287 0 3.79031 0 3.47877C0 3.16722 0.252558 2.91466 0.564103 2.91466H1.89911L2.72651 0.846155C2.82053 0.564103 3.25301 0 4.23079 0ZM4.32491 6.39321C4.32491 6.96437 3.86189 7.4274 3.29072 7.4274C2.71956 7.4274 2.25653 6.96437 2.25653 6.39321C2.25653 5.82204 2.71956 5.35902 3.29072 5.35902C3.86189 5.35902 4.32491 5.82204 4.32491 6.39321ZM11.3762 7.4274C11.9474 7.4274 12.4104 6.96437 12.4104 6.39321C12.4104 5.82204 11.9474 5.35902 11.3762 5.35902C10.8051 5.35902 10.3421 5.82204 10.3421 6.39321C10.3421 6.96437 10.8051 7.4274 11.3762 7.4274Z"
            fill="var(--progress)"
          />
        </svg>
      ) : (
        <svg
          width="15"
          height="11"
          viewBox="0 0 15 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.23079 0H10.5299C10.7807 0 11.3761 0.244445 11.7522 1.22222C12.0054 1.8806 12.2728 2.49067 12.4684 2.91466H13.7265C14.038 2.91466 14.2906 3.16722 14.2906 3.47877C14.2906 3.79031 14.038 4.04287 13.7265 4.04287H13.2516C13.4328 4.24564 13.5387 4.5116 13.5387 4.79485V9.87178C13.5387 10.4949 13.0336 11 12.4105 11C11.7875 11 11.2823 10.4949 11.2823 9.87178V9.49576H3.0087V9.8718C3.0087 10.4949 2.50359 11 1.8805 11C1.25741 11 0.75229 10.4949 0.75229 9.8718V4.74C0.75229 4.48241 0.840021 4.23814 0.993434 4.04287H0.564104C0.252558 4.04287 0 3.79031 0 3.47877C0 3.16722 0.252558 2.91466 0.564103 2.91466H1.89911L2.72651 0.846155C2.82053 0.564103 3.25301 0 4.23079 0ZM4.32491 6.39321C4.32491 6.96437 3.86189 7.4274 3.29072 7.4274C2.71956 7.4274 2.25653 6.96437 2.25653 6.39321C2.25653 5.82204 2.71956 5.35902 3.29072 5.35902C3.86189 5.35902 4.32491 5.82204 4.32491 6.39321ZM11.3762 7.4274C11.9474 7.4274 12.4104 6.96437 12.4104 6.39321C12.4104 5.82204 11.9474 5.35902 11.3762 5.35902C10.8051 5.35902 10.3421 5.82204 10.3421 6.39321C10.3421 6.96437 10.8051 7.4274 11.3762 7.4274Z"
            fill="var(--progress)"
          />
        </svg>
      )}
    </span>
  );
}

export default MyCarIcon;
