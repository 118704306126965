import { useContext, useState, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import Question from "../../../components/refresh/Question";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";

function CarStepTwentyFour() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [bundleHome, setBundleHome] = useState<boolean | null>(null);
  const LSBundle = useLocalStorage("bundleHome");

  useEffect(() => {
    if (LSBundle) {
      setBundleHome(LSBundle === "true");
    }
  }, [LSBundle]);

  useEffect(() => {
    setBundleHome(null)
  }, [])


  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].bundleHome} />
      <RadioButtonGroup setAccept={setBundleHome} accept={bundleHome} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>

      <CarFooter
        data={{
          bundleHome: bundleHome,
        }}
        formSteps={25}
        backFormSteps={23}
        type="submit"
        disabled={bundleHome === null}
      />
    </div>
    </div>
  );
}

export default CarStepTwentyFour;
