import { useState, useContext, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import AddCarForm from "./AddCarForm";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import XButton from "../../../components/refresh/assets/XButton";

type CarInfo = {
  year: number | undefined;
  make: string | undefined;
  model: string | undefined;
  EXT_VICC: string | undefined;
  VICC_CODE: string | undefined;
  carPurchaseYear: Date | null;
  carCondition: string;
  purchaseType: string;
  tagTracking: boolean;
  sherlock: boolean;
  noSecurity: boolean;
  useOfCar: string;
  comprehensive: boolean | null;
  collision: boolean | null;
};

function CarStepEleven() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [anotherVehicle, setAnotherVehicle] = useState<boolean | null>(null);
  const [anotherVehicleBoolean, setAnotherVehicleBoolean] = useState<boolean>(false) 
  const [vehicleList, setVehicleList] = useState<CarInfo[] | []>([]);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false)

  const handleRemove = () => {
    if (vehicleList.length === 1) {
      setDisplayForm(true);
    }
  };
  const LSExtraVehicleList = useLocalStorage("extraVehicleList");

  useEffect(() => {
    if (LSExtraVehicleList) {
      if (JSON.parse(LSExtraVehicleList).length > 0) {
        setAnotherVehicle(true);
        setDisplayForm(false);
        setVehicleList(JSON.parse(LSExtraVehicleList));
      } else {
        setAnotherVehicle(false);
        setVehicleList(JSON.parse(LSExtraVehicleList));
      }
    }
  }, [LSExtraVehicleList]);

  useEffect(() => {
    setValid(true)
    if (displayForm === true || anotherVehicle === null) {
      setValid(false)
    }
    if (anotherVehicle === false) {
      setVehicleList([])
      setAnotherVehicleBoolean(false)
    }

  }, [displayForm, vehicleList, anotherVehicle])
  

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={carTexts[carLanguage].addAnotherVehicle}
        subQuestion={carTexts[carLanguage].twoVehiclesDiscount}
      />
      <RadioButtonGroup setAccept={setAnotherVehicle} accept={anotherVehicle} setAnotherBoolean={setDisplayForm} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>
      
      {vehicleList.length > 0 ? (
        <div className="claims-main">
          <Question subQuestion={carTexts[carLanguage].vehicles} />
          {vehicleList.length > 0
            ? vehicleList.map((vehicle, index) => {
                return (
                  <div className="claim-body" key={vehicle.EXT_VICC}>
                      <p className="claim-type">
                        {vehicle.make} {vehicle.model}
                      </p>
                    <div style={{ display: "flex" }}>
                      <p className="claim-date">{vehicle.year}</p>
                      {vehicleList.length > 1 ?
                      <button aria-label="Close" tabIndex={0} className="modal-close-button"
                      onClick={() => {
                        setVehicleList(
                          vehicleList.filter((claim, i) => i !== index)
                          );
                          handleRemove();
                          setAnotherVehicleBoolean(true);
                        }}
                      ><XButton /></button> :
                      <button aria-label="Close" tabIndex={0} className="modal-close-button"
                      onClick={() => {
                        setVehicleList(
                          vehicleList.filter((claim, i) => i !== index)
                          );
                          handleRemove();
                          setAnotherVehicleBoolean(false);
                        }}
                      ><XButton /></button>}
                      </div>
                  </div>
                );
              })
            : null}
          {displayForm === false && vehicleList.length > 0 ? (
            <div>
              <button
                className="btn form"
                onClick={() => {
                  setDisplayForm(true);
                }}
                tabIndex={0}
              >
                {carTexts[carLanguage].add}
              </button>
            </div>
          ) : null}
        </div>
      ) : null}

      {anotherVehicle === true && displayForm === true ? (
        <AddCarForm setDisplayForm={setDisplayForm}
          anotherVehicleBoolean={anotherVehicleBoolean}
          onSubmit={(e) => {
            setVehicleList((prevVehicle: any) => [...prevVehicle, e]);
            setDisplayForm(false);
            setAnotherVehicleBoolean(true)
          }}
        />
      ) : null}

      <CarFooter
        data={{
          extraVehicleList: vehicleList,
        }}
        formSteps={12}
        type="submit"
        disabled={!valid}
        backFormSteps={10}
      />
    </div>
    </div>
  );
}

export default CarStepEleven;
