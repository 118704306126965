import './RefreshStyles.css'
import { KeyboardEvent } from 'react'


type RadioYesProps = {
    handleLabelClick: (parentId: string) => void,
    handleLabelYes: () => void,
    labelYesSelected: boolean,
    labelLeft?: string,
    iconLeft?: JSX.Element,
    checked?: boolean,
}

const RadioYes = ({handleLabelClick, handleLabelYes, labelYesSelected, labelLeft, iconLeft, checked}: RadioYesProps) => {


const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
  if (e.key === 'Enter') {
    handleLabelYes()
  } 
}

  return (
            <div className={labelYesSelected || checked ? "t-radio-button-group active" : "t-radio-button-group"} id="radio-button-group-yes" tabIndex={0} onKeyDown={handleKeyDown} onClick={(e) => handleLabelClick(e.currentTarget.id)}>
                        {iconLeft}
                        <label htmlFor="yes" onClick={handleLabelYes} className={labelYesSelected || checked ? "radio-btn-label active" : "radio-btn-label"}>{labelLeft}</label>
                        <input type="radio" name="binary" id="yes" checked={checked}/>
            </div>
  )
}

export default RadioYes