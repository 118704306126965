import './RefreshStyles.css'
import IntactLogo from './assets/IntactLogo';
import IntactLogoFr from './assets/IntactLogoFr';

type RadioQuoteLargeProps = {
    quotePrice: string;
    perMonth: string;
    carLanguage?: string;
    language?: string;
  };

const RadioLargeQuote = (props: RadioQuoteLargeProps) => {

  return (
    <>
        <button className="quote-single active" id="quote-single">
          <div className="quote-container">
            <div className="quote-price">
                ${props.quotePrice}
            </div>
            <div className="quote-price-division">
                {props.perMonth}
            </div>
          </div>  
            <label htmlFor="quote-price" className="quote-price-label">
            </label>
            <input type="radio" name="quote-price" id="quote-price" checked/>
            {props.carLanguage === 'en' || props.language === 'en' ? 
            <div className="intact-logo"><IntactLogo /></div>
            :
            <div className="intact-logo"><IntactLogoFr /></div>
            }
        </button>
    </>
  )
}

export default RadioLargeQuote