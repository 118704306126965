import { useContext, useState, useEffect, LegacyRef } from "react";
import Footer from "../../../components/footer/Footer";
import { texts } from "../../../text";
import { LanguageContext } from "../HomeContext";
import { usePlacesWidget } from "react-google-autocomplete";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import AddressInput from "../../../components/refresh/AddressInput";
import UnitNumberInput from "../../../components/refresh/UnitNumberInput"


function StepThree() {
  const { language } = useContext(LanguageContext);
  const [addressNumber, setAddressNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [unitNumber, setUnitNumber] = useState<string | null>(null);
  const [provinceError, setProvinceError] = useState<boolean>(false);
  const LSAddressNumber = useLocalStorage("addressNumber");
  const LSAddress = useLocalStorage("address");
  const LSPostalcode = useLocalStorage("postalCode");
  const LSCity = useLocalStorage("city");
  const LSProvince = useLocalStorage("province");
  const [postalCodeError, setPostalCodeError] = useState<boolean | null>(null);
  const [addressError, setAddressError] = useState<boolean | null>(null)
  const [streetError, setStreetError] = useState<boolean | null>(null)

  const  { ref: materialRef }: { ref: LegacyRef<HTMLInputElement> } = usePlacesWidget({
    apiKey: "AIzaSyAtd3_E1gfiWI5S3RhICppVT_S0Sm6lZq4",
    onPlaceSelected: (place) => {
      for (var i = 0; i < place.address_components.length; i++) {
        for (var j = 0; j < place.address_components[i].types.length; j++) {
          if (place.address_components[i].types[j] === "street_number") {
            setAddressNumber(place.address_components[i].long_name);
          }
          if (place.address_components[i].types[j] === "route") {
            setAddress(place.address_components[i].long_name);
          }
          if (place.address_components[i].types[j] === "postal_code") {
            setPostalCode(place.address_components[i].long_name);
          }
          if (place.address_components[i].types[j] === "locality") {
            setCity(place.address_components[i].long_name);
          }
          if (
            place.address_components[i].types[j] ===
            "administrative_area_level_1"
          ) {
            setProvince(place.address_components[i].long_name);
          }
        }
      }
    },
    inputAutocompleteValue: "country",
    options: {
      types: [],
      componentRestrictions: { country: "ca" },
    },
  });

  useEffect(() => {
    if (LSAddress) {
      setAddressNumber(LSAddressNumber);
      setAddress(LSAddress);
      setPostalCode(LSPostalcode);
      setCity(LSCity);
      setProvince(LSProvince);
      if (materialRef.current) {
        // @ts-ignore
        materialRef.current.value = `${LSAddressNumber} ${LSAddress}, ${LSCity}, ${LSProvince}, ${LSPostalcode}`;
      }
    }
  }, [
    LSAddress,
    LSAddressNumber,
    LSCity,
    LSPostalcode,
    LSProvince,
    address,
    addressNumber,
    city,
    materialRef,
    postalCode,
    province,
  ]);

  useEffect(() => {
    if (province !== "" && province !== "Quebec" && province !== "Québec" && province !== "QC") {
      setProvinceError(true);
    } else {
      setProvinceError(false);
    }
    if (postalCode && postalCode.length < 6 && province && address && city && addressNumber.length < 1 ) {
      setPostalCodeError(true)
    } else {
      setPostalCodeError(false)
    }
    if (province && address && (postalCode === '' || postalCode.length < 6)) {
      setAddressError(true)
    } else {
      setAddressError(false)
    }
  }, [address, province, postalCode, addressNumber, city]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={texts[language].address}
        subQuestion={texts[language].typeAddress}
      />
      {provinceError ? (
            <Question subQuestion={texts[language].provinceError} />
      ) :
      postalCodeError ? (
            <Question subQuestion={texts[language].postalCodeError} />
      ) :
      addressError ? (
            <Question subQuestion={texts[language].addressError} />
      ) :
      null}
          <AddressInput
          inputRef={materialRef}
          placeholder="e.g. H9P 2W0 1425 Trans Canada Route Suite 300, Dorval, Quebec"
          defaultValue={`${LSAddress} ${LSCity} ${LSPostalcode} ${LSProvince}`}
           />
        <UnitNumberInput placeholder={texts[language].unitNumber} setStringState={setUnitNumber} maxLength={5} lsValue={unitNumber}/>

      <Footer
        type="submit"
        data={{
          unitNumber: unitNumber,
          addressNumber: addressNumber,
          address: address,
          postalCode: postalCode,
          city: city,
          province: province,
        }}
        formSteps={4}
        backFormSteps={2}
        disabled={address === "" ? true : provinceError ? true : postalCodeError ? true : addressError ? true : false}
      />
    </div>
    </div>
  );
}

export default StepThree;
