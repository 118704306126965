
const KbdLogo = () => {
  return (
    <div className="loading-logo">
<svg width="50" height="50" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
  {/* <defs>
    <style>
      .cls-1 {
        fill: #0047b3;
      }
    </style>
  </defs> */}
  <polygon className="cls-1" fill="var(--progress)" points="500 209.239 348.785 209.239 455.977 102.047 398.332 44.4 291.139 151.593 291.139 0 209.616 0 209.616 151.593 102.424 44.4 44.778 102.046 151.97 209.239 0 209.239 0 290.761 151.97 290.761 44.778 397.954 102.424 455.6 209.616 348.408 209.616 500 291.139 500 291.139 348.407 398.332 455.6 455.977 397.954 348.785 290.761 500 290.761 500 209.239"/>
</svg>
    </div>
  )
}

export default KbdLogo