import { useState, useEffect } from "react";

function useLocalStorage(key: string) {
  const [result, setResult] = useState<any>(null);

  useEffect(() => {
    setResult(localStorage.getItem(key));
  }, []);

  return result;
}

export default useLocalStorage;
