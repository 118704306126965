import './RefreshStyles.css'
import TagTracking from './assets/TagTracking'
import Help from './assets/Help'
import { Dispatch, SetStateAction, KeyboardEvent } from 'react';

type RadioButtonProps = {
  label: string;
  value: string;
  setState: Dispatch<SetStateAction<boolean>>,
  checked: boolean;
  image?: JSX.Element;
  description?: string;
  title?: string;
  class?: any;
  handleFunc?: () => void;
  handleSher?: () => void;
  mobile?: boolean;
  handleKey?: () => void;
  labelId?: string;
};

const handleLabelClick = (parentId: string, currentState: boolean, setState: Dispatch<SetStateAction<boolean>>) => {
  const parentDiv = document.querySelector(`#${parentId}`);
  // const childLabel = parentDiv?.querySelector('label');
  
  parentDiv?.classList.toggle('active');
  // childLabel?.click();
  setState(!currentState);
}

const SecurityRadioButtons = (props: RadioButtonProps) => {
  const divId = `tag-tracking-${props.value}`;

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleLabelClick(divId, props.checked, props.setState) 
    } 
  }

  return (
    <div className={props.checked ? "t-security-systems-box active" : "t-security-systems-box"} id={divId} tabIndex={0} onKeyDown={handleKeyDown} onClick={(e) => handleLabelClick(divId, props.checked, props.setState)}>
      <div className="security-systems-div">
        <span className="security-systems-svg"><TagTracking /></span>
          <label htmlFor={props.labelId} tabIndex={0} className={`security-systems-label ${props.value}`} onKeyDown={handleKeyDown}>{props.label}</label>
          {props.description ? 
          <>
          <span className="help-span" onKeyDown={props.handleKey} tabIndex={0} onClick={props.handleFunc} ><Help /></span>
          <p style={{ display: props.mobile ? 'none' : '' }} className={`security-systems-popup ${props.class}`}>
          <span className="description-title">{props.title}</span>
          {props.description}</p> 
          </>
          : null
          }
        <input type="radio" name={props.labelId} id={props.value} checked={props.checked}/>
      </div>  
    </div>
  )
}

export default SecurityRadioButtons