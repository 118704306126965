import { useContext, useState, useEffect } from "react";
import Question from "../../../components/refresh/Question";
import { texts, answers } from "../../../text";
import { LanguageContext } from "../HomeContext";
import Footer from "../../../components/footer/Footer";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import DropdownMenu from "../../../components/refresh/DropdownMenu";

function StepTwo() {
  const { language } = useContext(LanguageContext);
  const [selection, setSelection] = useState<string>('');
  const LSType = useLocalStorage("type");

  useEffect(() => {
    if (LSType) {
      setSelection(LSType);
    }
  }, [LSType]);

  useEffect(() => {
    if (selection === answers["fr"].homeownerInsurance) {
      setSelection(answers["en"].homeownerInsurance)
    }
    if (selection === answers["fr"].condoInsurance) {
      setSelection(answers["en"].condoInsurance)
    }
    if (selection === answers["fr"].tenantInsurance) {
      setSelection(answers["en"].tenantInsurance)
    }
  }, [selection])


  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={texts[language].typeOfHomeInsurance} />
      <DropdownMenu type={'home'} list={[
          answers[language].homeownerInsurance,
          answers[language].condoInsurance,
          answers[language].tenantInsurance,
        ]} setState={setSelection} placeholder={texts[language].select} lsValue={'type'}/>
      <Footer
        data={{ type: selection }}
        formSteps={3}
        backFormSteps={1}
        type="submit"
        disabled={selection === '' ? true : false}
      />
    </div>
  </div>
  );
}

export default StepTwo;
