const XButton = () => {
    return (
      <><svg width="9" height="9" viewBox="0 0 9 9" fill="  " xmlns="http://www.w3.org/2000/svg">
      <path d="M7.8335 0.0551831L8.94467 1.16635L1.1665 8.94453L0.055329 7.83336L7.8335 0.0551831Z" fill="#0D172E"/>
      <path d="M8.94467 7.83336L7.8335 8.94453L0.0553293 1.16635L1.1665 0.0551828L8.94467 7.83336Z" fill="#0D172E"/>
      </svg>
      </>
    )
  }
  
  export default XButton