import { useState, useContext, useEffect, KeyboardEvent } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import SecurityRadioButtons from "../../../components/refresh/SecurityRadioButtons";
import DescriptionModal from "../../../components/refresh/DescriptionModal";

function CarStepSeven() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [tagTracking, setTagTracking] = useState<boolean>(false);
  const [sherlock, setSherlock] = useState<boolean>(false);
  const [noSecurity, setNoSecurity] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const LSTag = useLocalStorage("tagTracking");
  const LSSherlock = useLocalStorage("sherlock");
  const LSNoSecurity = useLocalStorage("noSecurity");

  const [mobile, setMobile] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [hoverOff, setHoverOff] = useState<boolean>(false)

  useEffect(() => {
    if (LSTag && LSSherlock && LSNoSecurity) {
      setTagTracking(LSTag === "true");
      setSherlock(LSSherlock === "true");
      setNoSecurity(LSNoSecurity === "true");
    }
  }, [LSNoSecurity, LSSherlock, LSTag]);

  useEffect(() => {
    if (noSecurity === true) {
      setTagTracking(false);
      setSherlock(false);
    }
  }, [noSecurity]);

  useEffect(() => {
    if (tagTracking === true || sherlock === true) {
      setNoSecurity(false);
    }
  }, [sherlock, tagTracking]);

  useEffect(() => {
    setValid(true);
    if (tagTracking === false && sherlock === false && noSecurity === false) {
      setValid(false);
    }
  }, [sherlock, tagTracking, noSecurity]);

  const engraving = 'engraving';
  const tag = 'tag';

  const handleTag = () => {
    // setMobile(true)
    setTitle(carTexts[carLanguage].tagTitle)
    setDescription(carTexts[carLanguage].tagDescription)
    document.body.style.overflow = "hidden";

    if (mobile) {
      setMobile(true)
      setModal(true)
      document.body.style.overflow = "hidden";
    }
    if (!hoverOff) {
      document.body.style.overflow = "auto";
    }
  }
  
  const handleSher = () => {
    // setMobile(true)
    setTitle(carTexts[carLanguage].sherlockTitle)
    setDescription(carTexts[carLanguage].sherlockDescription)
    document.body.style.overflow = "hidden";

    if (mobile) {
      setMobile(true)
      setModal(true)
      document.body.style.overflow = "hidden";
    }
    if (!hoverOff) {
      document.body.style.overflow = "auto";
    }
  }

  const handleKeyTag = (e?: KeyboardEvent<HTMLElement>) => {
    if (e && e.key === "Enter") {
      if (hoverOff) {
        handleTag();
      } else if (!mobile) {
        e.stopPropagation()
        const pElement = document.querySelector(
          ".security-systems-popup.tag"
        ) as HTMLElement;
        if (pElement) {
          pElement.style.display = "block";
          setTimeout(() => {
            pElement.style.display = "";
          }, 1500);
        }
      }
    }
  };

  const handleKeySher = (e?: KeyboardEvent<HTMLElement>) => {
    if (e && e.key === "Enter") {
      if (hoverOff) {
        handleSher()
      } else if (!mobile) {
        e.stopPropagation()
      const pElement = document.querySelector(
        ".security-systems-popup.engraving"
      ) as HTMLElement;
      if (pElement) {
        pElement.style.display = "block";
        setTimeout(() => {
          pElement.style.display = ""
        }, 1500);
        }
      }
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setMobile(true)
      setHoverOff(true)
    }
  }, [])


  const handleModalClose = () => {
    setModal(false);
    document.body.style.overflow = 'auto';
  }


  return (
    <div className="t-container">
      <div className="t-content-container">
      {modal ? <DescriptionModal handleModalClose={handleModalClose} title={title} description={description} /> : null}
      <Question question={carTexts[carLanguage].securitySystem} subQuestion={carTexts[carLanguage].selectOptions  } />
      <SecurityRadioButtons 
      value={carTexts[carLanguage].tagTracking}
      label={carTexts[carLanguage].tagTracking}
      title={carTexts[carLanguage].tagTitle}
      description={carTexts[carLanguage].tagDescription}
      setState={setTagTracking}
      checked={tagTracking === true}
      class={tag}
      handleFunc={handleTag}
      handleKey={handleKeyTag}
      mobile={mobile}
      labelId={'tag'}
      />

      <SecurityRadioButtons 
      value={carTexts[carLanguage].sherlock}
      label='Sherlock / Vinlock Engraving'
      title={carTexts[carLanguage].sherlockTitle}
      description={carTexts[carLanguage].sherlockDescription}
      setState={setSherlock}
      checked={sherlock === true}
      class={engraving}
      handleFunc={handleSher}
      handleKey={handleKeySher}
      mobile={mobile}
      labelId={'sher'}
      />
      
      <SecurityRadioButtons 
      value={carTexts[carLanguage].none}
      label={carTexts[carLanguage].none}
      setState={setNoSecurity}
      checked={noSecurity === true}
      labelId={'none'}
      />
      
      <CarFooter
        data={{
          tagTracking: tagTracking,
          sherlock: sherlock,
          noSecurity: noSecurity,
        }}
        formSteps={8}
        type="submit"
        disabled={!valid}
        backFormSteps={6}
      />
    </div>
    </div>
  );
}

export default CarStepSeven;
