import { useState, useContext, useEffect } from "react";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import CarFooter from "../../../components/footerCar/CarFooter";
import Question from "../../../components/refresh/Question";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import AverageRadioButtons from "../../../components/refresh/AverageRadioButtons";
import MoneyInput from "../../../components/refresh/MoneyInput";

function CarStepFive() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [primaryCarCondition, setPrimaryCarCondition] = useState<string>("");
  const [primaryCarHowManyKM, setPrimaryCarHowManyKM] = useState<number>(0);
  const LSPrimaryCondition = useLocalStorage("primaryCarCondition");
  const LSPrimaryKM = useLocalStorage("primaryCarHowManyKM");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (LSPrimaryCondition && LSPrimaryKM) {
      setPrimaryCarCondition(LSPrimaryCondition);
      setPrimaryCarHowManyKM(LSPrimaryKM);
    }
  }, [LSPrimaryCondition, LSPrimaryKM]);

  useEffect(() => {
    setValid(true);
    if (primaryCarCondition === "New") {
      setValid(true);
    }
    if (primaryCarCondition === "") {
      setValid(false);
    }

    if (primaryCarCondition !== "New" && primaryCarHowManyKM === 0) {
      setValid(false);
    }

    if (primaryCarCondition === carTexts["fr"].new) {
      setPrimaryCarCondition(carTexts["en"].new)
    }

    if (primaryCarCondition === carTexts["fr"].used) {
      setPrimaryCarCondition(carTexts["en"].used)
    }

    if (primaryCarCondition === carTexts["fr"].demo) {
      setPrimaryCarCondition(carTexts["en"].demo)
    }
  }, [primaryCarCondition, primaryCarHowManyKM]);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].primaryCarCondition} />


      <AverageRadioButtons multiple={true} setState={setPrimaryCarCondition} value={carTexts[carLanguage].new} label={carTexts[carLanguage].new} checked={primaryCarCondition === carTexts["en"].new}/>

      <AverageRadioButtons multiple={true} setState={setPrimaryCarCondition} value={carTexts[carLanguage].used} label={carTexts[carLanguage].used} checked={primaryCarCondition === carTexts["en"].used}/>

      <AverageRadioButtons multiple={true} setState={setPrimaryCarCondition} value={carTexts[carLanguage].demo} label={carTexts[carLanguage].demo} checked={primaryCarCondition === carTexts["en"].demo}/>

      {primaryCarCondition !== "" && primaryCarCondition !== "New" ? (
        <>
                <p className="dropdown-title">{carTexts[carLanguage].primaryCarHowManyKM}</p>
                <MoneyInput 
                placeholder='e.g. 1000'
                setState={setPrimaryCarHowManyKM}
                type="number"
                maxLength={7}
                lsValue={primaryCarHowManyKM}
                />
        </>
      ) : null}
      <CarFooter
        data={{
          primaryCarCondition: primaryCarCondition,
          primaryCarHowManyKM: primaryCarHowManyKM,
        }}
        formSteps={6}
        type="submit"
        disabled={!valid}
        backFormSteps={4}
      />
    </div>
  </div>
  );
}

export default CarStepFive;
