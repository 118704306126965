import { useContext, useState, useEffect } from "react";
import CarFooter from "../../../components/footerCar/CarFooter";
import useLocalStorage from "../../../components/hooks/useLocalStorage";
import Question from "../../../components/refresh/Question";
import { carTexts } from "../../../text";
import { CarLanguageContext } from "../CarContext";
import ConvictionsForm from "./ConvictionsForm";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import XButton from "../../../components/refresh/assets/XButton";

type Conviction = {
  convictionType: string | null;
  dateOfLoss: string | undefined;
  id: string;
};

function CarStepTwentyOne() {
  const { carLanguage } = useContext(CarLanguageContext);
  const [violations, setViolations] = useState<boolean | null>(null);
  const [convictions, setConvictions] = useState<[] | Conviction[]>([]);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const LSViolations = useLocalStorage("violations");
  const LSConvictions = useLocalStorage("convictions");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  useEffect(() => {
    if (LSViolations === false) {
      setViolations(false)
    } else if (LSConvictions) {
      if (JSON.parse(LSConvictions).length > 0) {
        setViolations(true);
        setDisplayForm(false);
        setConvictions(JSON.parse(LSConvictions));
      } else {
        setViolations(false)
        setDisplayForm(false);
        setConvictions(JSON.parse(LSConvictions));
      }
    }
  }, [LSConvictions, LSViolations]);



  const handleRemove = () => {
    if (convictions.length === 1) {
      setDisplayForm(true);
    }
    window.scrollTo(0, 0);
  };


  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question question={carTexts[carLanguage].anyTrafficViolations} />
      <RadioButtonGroup setDisplayForm={setDisplayForm} setAccept={setViolations} accept={violations} labelLeft={carTexts[carLanguage].yes} labelRight={carTexts[carLanguage].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>
      {violations === true ? (
        <div className="t-content-container" style={{ marginLeft: '0%' }}>
          <Question subQuestion={carTexts[carLanguage].convictionInfo} />
          {convictions.length > 0
            ? convictions.map((conviction, index) => {
                return (
                  <div className="claim-body" key={conviction.id}>
                    <p className="claim-type">{conviction.convictionType}</p>
                    <div style={{ display: "flex" }}>
                      <p className="claim-date">{conviction.dateOfLoss}</p>
                      <button aria-label="Close" className="modal-close-button"
                      onClick={() => {
                        setConvictions(
                          convictions.filter((claim, i) => i !== index)
                        );
                        handleRemove();
                      }}
                      ><XButton /></button>
                    </div>
                  </div>
                );
              })
            : null}
          {convictions.length > 0 && displayForm === false ? (
            <div>
              <button
                className="btn form"
                onClick={() => {
                  setDisplayForm(true);
                }}
              >
                {carTexts[carLanguage].add}
              </button>
            </div>
          ) : null}
          {displayForm === true ? (
            <div>
              <ConvictionsForm
                onSubmit={(claim: any) => {
                  setConvictions((prevClaims: any) => [...prevClaims, claim]);
                  setDisplayForm(false);
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      <CarFooter
        data={{
          violations: violations,
          convictions: convictions,
          claims: []
        }}
        formSteps={23}
        backFormSteps={19}
        type="submit"
        disabled={(displayForm === true && convictions.length < 1 )|| (violations === null && LSViolations === null)}
      />
    </div>
    </div>
  );
}

export default CarStepTwentyOne;
