import { useContext, useState } from "react";
import Question from "../../../components/refresh/Question";
import { texts } from "../../../text";
import {
  LanguageContext,
} from "../HomeContext";
import Footer from "../../../components/footer/Footer";
import RadioButtonGroup from "../../../components/refresh/RadioButtonGroup";
import ThumbsDown from "../../../components/refresh/assets/ThumbsDown";
import ThumbsUp from "../../../components/refresh/assets/ThumsUp";
import "../../../components/refresh/RefreshStyles.css"

function StepOne() {
  const { language } = useContext(LanguageContext);
  const [accept, setAccept] = useState<boolean | null>(null);

  return (
    <div className="t-container">
      <div className="t-content-container">
      <Question
        question={texts[language].beforeStartQuestion}
        subQuestion={
          accept === false
            ? `${texts[language].currentClient}`
            
            : accept === null
            ? `${texts[language].currentClient}`
            : (
              <>
                {texts[language].beforeStartMessage}
                  <a href="tel:5146360002" className="phone-number">{texts[language].homeNumber}</a>{'.'}
              </>
            )
        }
      />
        <RadioButtonGroup setAccept={setAccept} accept={accept} labelLeft={texts[language].yes} labelRight={texts[language].no} iconLeft={<ThumbsUp />} iconRight={<ThumbsDown />}/>

      <Footer
        formSteps={1}
        type="submit"
        disabled={accept === true ? true : accept === null ? true : false}
        backHidden={true}
      />
    </div>
    </div>
  );
}

export default StepOne;
