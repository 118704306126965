export const MapMarker = () => {
    return (
      <>
        <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 15C8.04375 10.5019 10 7.68813 10 4.95313C10 2.21688 7.76313 1.71179e-06 5 1.47023e-06C2.23688 1.22867e-06 1.63862e-07 2.21688 -7.53487e-08 4.95313C-3.1445e-07 7.68812 1.95625 10.5019 5 15Z" fill="#0D172E"/>
            <circle cx="5" cy="5" r="3" fill="white"/>
        </svg>
      </>
    )
  }
  
  export default MapMarker;