import { useContext } from "react";
import {
  LanguageContext,
} from "../../quotes/shared/HomeContext";
import { texts } from "../../text";
import Footer from "../footer/Footer";
import Question from "../refresh/Question"

function FailedQuote() {
  const { language } = useContext(LanguageContext);

    return (
      <div className="t-container">
        <div className="t-content-container">
        <Question
          question={texts[language].failedQuote}
          subQuestion={texts[language].failedQuoteSub}
          subQuestion2={texts[language].quoteNumber}
          subQuestion3={texts[language].failedQuoteSubEnd}
        />
         <a href="tel:4388434814">
        <button className="quote-buy-button">
            {texts[language].phoneUs}
        </button>
        </a>

        <Footer newQuote backDisabled nextHidden />
      </div>
      </div>
    );
  }
// }

export default FailedQuote;
