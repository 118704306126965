import { useContext, useEffect, useState } from "react";
import {
  HomeStepContext,
  LanguageContext,
} from "../../quotes/shared/HomeContext";
import MyDiscountIcon from "../progress-icons/MyDiscountIcon";
import MyHouseIcon from "../progress-icons/MyHouseIcon";
import MyPolicyIcon from "../progress-icons/MyPolicyIcon";
import MyProfileIcon from "../progress-icons/MyProfileIcon";
import { texts } from "../../text";
import "../refresh/RefreshStyles.css"

function ProgressBar() {
  const { formSteps } = useContext(HomeStepContext);
  const [myHouseProgress, setMyHouseProgress] = useState("0");
  const [aboutMeProgress, setAboutMeProgress] = useState("0");
  const [myPolicyProgress, setMyPolicyProgress] = useState("0");
  const [discountProgress, setDiscountProgress] = useState("0");
  const { language } = useContext(LanguageContext);

  const myHouseStyles = {
    height: "100%",
    width: myHouseProgress,
    borderRadius: "0.4rem",
    background: "var(--progress)",
    transition: "width 0.4s ease",
  };

  const aboutMeStyles = {
    height: "100%",
    width: aboutMeProgress,
    borderRadius: "0.4rem",
    background: "var(--progress)",
    transition: "width 0.4s ease",
  };

  const myPolicyStyles = {
    height: "100%",
    width: myPolicyProgress,
    borderRadius: "0.4rem",
    background: "var(--progress)",
    transition: "width 0.4s ease",
  };

  const discountStyles = {
    height: "100%",
    width: discountProgress,
    borderRadius: "0.4rem",
    background: "var(--progress)",
    transition: "width 0.4s ease",
  };

  useEffect(() => {
    switch (formSteps) {
      case 2:
        setMyHouseProgress("50%");
        setAboutMeProgress("0%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 3:
        setMyHouseProgress("100%");
        setAboutMeProgress("0%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 4:
        setMyHouseProgress("100%");
        setAboutMeProgress("17%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 5:
        setMyHouseProgress("100%");
        setAboutMeProgress("34%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 6:
        setMyHouseProgress("100%");
        setAboutMeProgress("51%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 7:
        setMyHouseProgress("100%");
        setAboutMeProgress("69%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 8:
        setMyHouseProgress("100%");
        setAboutMeProgress("86%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 9:
        setMyHouseProgress("100%");
        setAboutMeProgress("100%");
        setMyPolicyProgress("0%");
        setDiscountProgress("0%");
        break;
      case 10:
        setMyHouseProgress("100%");
        setAboutMeProgress("100%");
        setMyPolicyProgress("50%");
        setDiscountProgress("0%");
        break;
      case 11:
        setMyHouseProgress("100%");
        setAboutMeProgress("100%");
        setMyPolicyProgress("100%");
        setDiscountProgress("0%");
        break;
      case 12:
        setMyHouseProgress("100%");
        setAboutMeProgress("100%");
        setMyPolicyProgress("100%");
        setDiscountProgress("50%");
        break;
      case 13:
        setMyHouseProgress("100%");
        setAboutMeProgress("100%");
        setMyPolicyProgress("100%");
        setDiscountProgress("100%");
        break;
    }
  }, [formSteps]);

  return (
    <div className="progress-bar-parent">
      <div className="t-container">
      <div className="progress-bar-main t-content-container">
        <div>
          <p className="t-icon-title">
            <MyHouseIcon
              progress={myHouseProgress === "100%" ? "done" : "active"}
            />{" "}
            {texts[language].myHouse}
          </p>
          <div className="progress-bar">
            <div style={myHouseStyles}></div>
          </div>
        </div>
        <div>
          <p
            style={
              aboutMeProgress === "0%"
                ? { color: "var(--progress-bar)" }
                : { color: "var(--progress)" }
            }
            className="t-icon-title"
          >
            <MyProfileIcon
              progress={
                aboutMeProgress === "0%"
                  ? "inactive"
                  : aboutMeProgress === "100%"
                  ? "done"
                  : "active"
              }
            />{" "}
            {texts[language].aboutMe}
          </p>
          <div className="progress-bar">
            <div style={aboutMeStyles}></div>
          </div>
        </div>
        <div>
          <p
            style={
              myPolicyProgress === "0%"
                ? { color: "var(--progress-bar)" }
                : { color: "var(--progress)" }
            }
            className="t-icon-title"
          >
            <MyPolicyIcon
              progress={
                myPolicyProgress === "0%"
                  ? "inactive"
                  : myPolicyProgress === "100%"
                  ? "done"
                  : "active"
              }
            />{" "}
            {texts[language].myPolicy}
          </p>
          <div className="progress-bar">
            <div style={myPolicyStyles}></div>
          </div>
        </div>
        <div>
          <p
            style={
              discountProgress === "0%"
                ? { color: "var(--progress-bar)" }
                : { color: "var(--progress)" }
            }
            className="t-icon-title"
          >
            <MyDiscountIcon
              progress={
                discountProgress === "0%"
                  ? "inactive"
                  : discountProgress === "100%"
                  ? "done"
                  : "active"
              }
            />{" "}
            {texts[language].discounts}
          </p>
          <div className="progress-bar">
            <div style={discountStyles}></div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default ProgressBar;
