import React from "react";
type ProgressIconProps = {
  progress: "active" | "inactive" | "done";
};
function MyProfileIcon(props: ProgressIconProps) {
  return (
    <span className="progress-icons">
      {props.progress === "active" ? (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0C5.23691 0.00239775 4.00178 0.371736 2.94505 1.06303C1.88831 1.75432 1.05559 2.73773 0.548307 3.89347C0.041023 5.04922 -0.118928 6.32741 0.087938 7.57237C0.294803 8.81732 0.859557 9.97531 1.71341 10.9053C2.32324 11.5657 3.06338 12.0928 3.8872 12.4534C4.71101 12.8139 5.60063 13 6.5 13C7.39937 13 8.28899 12.8139 9.1128 12.4534C9.93661 12.0928 10.6768 11.5657 11.2866 10.9053C12.1404 9.97531 12.7052 8.81732 12.9121 7.57237C13.1189 6.32741 12.959 5.04922 12.4517 3.89347C11.9444 2.73773 11.1117 1.75432 10.055 1.06303C8.99822 0.371736 7.76309 0.00239775 6.5 0ZM11 9.10942C10.4182 8.11503 9.52265 7.34156 8.45371 6.91014C8.7853 6.53448 9.00135 6.0712 9.07595 5.5759C9.15054 5.08059 9.08051 4.5743 8.87425 4.11778C8.66799 3.66126 8.33426 3.2739 7.91312 3.00218C7.49198 2.73046 7.00132 2.58593 6.5 2.58593C5.99868 2.58593 5.50802 2.73046 5.08688 3.00218C4.66574 3.2739 4.33201 3.66126 4.12575 4.11778C3.91949 4.5743 3.84946 5.08059 3.92405 5.5759C3.99865 6.0712 4.2147 6.53448 4.54629 6.91014C3.47735 7.34156 2.58181 8.11503 1.99995 9.10942C1.53624 8.32022 1.29122 7.42187 1.2901 6.50673C1.2901 5.12617 1.839 3.80216 2.81605 2.82597C3.79309 1.84977 5.11825 1.30135 6.5 1.30135C7.88175 1.30135 9.20691 1.84977 10.184 2.82597C11.161 3.80216 11.7099 5.12617 11.7099 6.50673C11.7088 7.42187 11.4638 8.32022 11 9.10942Z"
            fill="var(--progress)"
          />
        </svg>
      ) : props.progress === "done" ? (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0C5.23691 0.00239775 4.00178 0.371736 2.94505 1.06303C1.88831 1.75432 1.05559 2.73773 0.548307 3.89347C0.041023 5.04922 -0.118928 6.32741 0.087938 7.57237C0.294803 8.81732 0.859557 9.97531 1.71341 10.9053C2.32324 11.5657 3.06338 12.0928 3.8872 12.4534C4.71101 12.8139 5.60063 13 6.5 13C7.39937 13 8.28899 12.8139 9.1128 12.4534C9.93661 12.0928 10.6768 11.5657 11.2866 10.9053C12.1404 9.97531 12.7052 8.81732 12.9121 7.57237C13.1189 6.32741 12.959 5.04922 12.4517 3.89347C11.9444 2.73773 11.1117 1.75432 10.055 1.06303C8.99822 0.371736 7.76309 0.00239775 6.5 0ZM11 9.10942C10.4182 8.11503 9.52265 7.34156 8.45371 6.91014C8.7853 6.53448 9.00135 6.0712 9.07595 5.5759C9.15054 5.08059 9.08051 4.5743 8.87425 4.11778C8.66799 3.66126 8.33426 3.2739 7.91312 3.00218C7.49198 2.73046 7.00132 2.58593 6.5 2.58593C5.99868 2.58593 5.50802 2.73046 5.08688 3.00218C4.66574 3.2739 4.33201 3.66126 4.12575 4.11778C3.91949 4.5743 3.84946 5.08059 3.92405 5.5759C3.99865 6.0712 4.2147 6.53448 4.54629 6.91014C3.47735 7.34156 2.58181 8.11503 1.99995 9.10942C1.53624 8.32022 1.29122 7.42187 1.2901 6.50673C1.2901 5.12617 1.839 3.80216 2.81605 2.82597C3.79309 1.84977 5.11825 1.30135 6.5 1.30135C7.88175 1.30135 9.20691 1.84977 10.184 2.82597C11.161 3.80216 11.7099 5.12617 11.7099 6.50673C11.7088 7.42187 11.4638 8.32022 11 9.10942Z"
            fill="var(--progress)"
          />
        </svg>
      ) : (
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0C5.23691 0.00239775 4.00178 0.371736 2.94505 1.06303C1.88831 1.75432 1.05559 2.73773 0.548307 3.89347C0.041023 5.04922 -0.118928 6.32741 0.087938 7.57237C0.294803 8.81732 0.859557 9.97531 1.71341 10.9053C2.32324 11.5657 3.06338 12.0928 3.8872 12.4534C4.71101 12.8139 5.60063 13 6.5 13C7.39937 13 8.28899 12.8139 9.1128 12.4534C9.93661 12.0928 10.6768 11.5657 11.2866 10.9053C12.1404 9.97531 12.7052 8.81732 12.9121 7.57237C13.1189 6.32741 12.959 5.04922 12.4517 3.89347C11.9444 2.73773 11.1117 1.75432 10.055 1.06303C8.99822 0.371736 7.76309 0.00239775 6.5 0ZM11 9.10942C10.4182 8.11503 9.52265 7.34156 8.45371 6.91014C8.7853 6.53448 9.00135 6.0712 9.07595 5.5759C9.15054 5.08059 9.08051 4.5743 8.87425 4.11778C8.66799 3.66126 8.33426 3.2739 7.91312 3.00218C7.49198 2.73046 7.00132 2.58593 6.5 2.58593C5.99868 2.58593 5.50802 2.73046 5.08688 3.00218C4.66574 3.2739 4.33201 3.66126 4.12575 4.11778C3.91949 4.5743 3.84946 5.08059 3.92405 5.5759C3.99865 6.0712 4.2147 6.53448 4.54629 6.91014C3.47735 7.34156 2.58181 8.11503 1.99995 9.10942C1.53624 8.32022 1.29122 7.42187 1.2901 6.50673C1.2901 5.12617 1.839 3.80216 2.81605 2.82597C3.79309 1.84977 5.11825 1.30135 6.5 1.30135C7.88175 1.30135 9.20691 1.84977 10.184 2.82597C11.161 3.80216 11.7099 5.12617 11.7099 6.50673C11.7088 7.42187 11.4638 8.32022 11 9.10942Z"
            fill="#AAAABB"
          />
        </svg>
      )}
    </span>
  );
}

export default MyProfileIcon;
